import { customElement, state } from "lit/decorators.js";
import { Page } from "../components/component";
import { Task } from "@lit/task";
import { html } from "lit";
import { consume } from "@lit/context";
import { RouterContext, routerContext } from "../context/router.context";
import { choose } from "lit/directives/choose.js";
import {
  Attachment,
  NumericBox,
  ResultInteger,
  ResultPercentage,
  Scale,
  TextBox,
  Url,
  YesOrNo,
} from "../components/evaluation";
import {
  EvaluationFieldType,
  type EvaluationSummary,
  type FieldValue,
  type FieldValueAttachment,
  type FieldValueNumericBox,
  type FieldValueResultInteger,
  type FieldValueResultPercentage,
  type FieldValueScale,
  type FieldValueTextBox,
  type FieldValueUrl,
  type FieldValueYesOrNo,
  type ValueAttachment,
  type ValueNumericBox,
  type ValueResultInteger,
  type ValueResultPercentage,
  type ValueTextBox,
  type ValueUrl,
  type ValueYesOrNo,
} from "../repository/report/report";
import { when } from "lit/directives/when.js";
import { reportRepository } from "../repository/report/report.repository";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import type { Content } from "../repository/patient/diagnostic";
import { localized, msg, str } from "@lit/localize";

interface NewContent {
  file: File;
  content: string;
}

@customElement("modal-public-evaluation")
@localized()
export class ModalPublicEvaluation extends Page {
  evaluation!: EvaluationSummary;
  @state() private _fields = new Map<string, FieldValue>();
  @state() private _attachmentContent = new Map<string, NewContent[]>();

  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #evaluation = new Task(this, {
    task: async () => {
      return reportRepository.loadEvaluationWithValues(this.evaluation.id);
    },
    onComplete: (evaluation) => {
      evaluation.sections.forEach((section) => {
        section.fields.forEach((field) => {
          this._fields.set(field.id, field);
          if (field.collectionName === EvaluationFieldType.Attachment && field.value?.content) {
            this.transformContent(field.value.content).then((content) => {
              this._attachmentContent.set(field.id, content);
              this.requestUpdate();
            });
          }
        });
      });
    },
    args: () => [],
  });

  async transformContent(content: string[]) {
    let contentArray: NewContent[] = [];
    if (content) {
      for (const c of content) {
        const response = await fetch(c);
        const blob = await response.blob();
        const fileName = c.split("/").pop()?.split("?")[0] || "file";
        const file = new File([blob], fileName, { type: blob.type });
        contentArray.push({
          file: file as Content,
          content: URL.createObjectURL(file),
        });
      }
    }
    return contentArray;
  }

  calculatePercentage(field: FieldValueResultPercentage) {
    const min = field.minimum;
    const max = field.maximum;
    const input = field.value?.value || 0;
    const clampedInput = Math.max(min, Math.min(input, max));
    const percentage = ((clampedInput - min) / (max - min)) * 100;

    return Math.round(percentage * 100) / 100;
  }

  calculateInteger(field: FieldValueResultInteger) {
    const min = field.minimum;
    const max = field.maximum;
    const input = field.value?.value || 0;
    const clampedInput = Math.max(min, Math.min(input, max));
    const result = Math.round(((clampedInput - min) / (max - min)) * (max - min));

    return result + min;
  }

  computeScaleScore(field: FieldValueScale) {
    const cachedField = this._fields.get(field.id) as FieldValueScale;
    if (!field.value && !cachedField?.value) return 0;

    return this.computeExpression(field);
  }

  computeExpression(evaluationField: FieldValueScale): number {
    const { expression } = evaluationField.scale;
    const valuesMap = new Map<number, number>();

    if (!evaluationField.value) return 0;
    evaluationField.value.forEach((val) => {
      valuesMap.set(val.field.position, val.value.value);
    });

    let parsedExpression = expression;
    let missingValue = false;

    // Replace all occurrences of $N with the corresponding values
    parsedExpression = parsedExpression.replace(/\$[0-9]+/g, (match) => {
      const position = parseInt(match.slice(1));
      const value = valuesMap.get(position-1);
      if (value === undefined) {
        console.warn(`No value found for position ${position}, returning 0 for the whole equation.`);
        missingValue = true;
        return "0";
      }
      return value.toString();
    });

    if (missingValue) {
      return 0;
    }

    try {
      // Evaluate the expression safely
      const result = new Function(`return ${parsedExpression}`)();
      if (typeof result !== "number") {
        throw new Error("Expression did not evaluate to a number");
      }
      return Math.round(result * 100) / 100;
    } catch (error) {
      console.error("Failed to evaluate expression:", error);
      return 0; // Return 0 in case of any evaluation errors
    }
  }

  openScaleModal(field: FieldValueScale, readonly: boolean) {
    this.navigator.push("modal-scale-values", {
      evaluation: this.evaluation,
      fieldValue: this._fields.get(field.id),
      readonly: readonly,
      score: this.computeScaleScore(field),
    });
  }

  render() {
    return html`
      <ion-content fullscreen style="--padding-bottom: 24px" class="no-px">
        <ion-header class="px-4">
          <ion-toolbar class=${this.#evaluation.value?.sections.length === 0 ? "no-border" : ""}>
            <!-- remove the class above 'no-border' when there are sections -->
            <ion-buttons slot="start">
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold"
                @click=${() => this.navigator.goBack()}>
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </ion-buttons>
            <ion-title>${this.#evaluation.value?.name}</ion-title>
          </ion-toolbar>
        </ion-header>

        ${this.#evaluation.render({
          pending: () => {},
          error: (error) => {
            console.log(error);
            return html` <div class="h-[139px] bg-dangerLighter rounded-md col justify-center items-center mt-4 mx-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                  stroke="#ff1a1a"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span class="font-display font-semibold w-2/3 text-center text-dangerLight"
                >${msg("Ocorreu um erro, por favor tente mais tarde")}</span
              >
            </div>`;
          },
          complete: (evaluation) => {
            const sortedSections = evaluation.sections.sort((a, b) => a.position - b.position);
            return html`
              <ion-accordion-group class="mt-2.5 space-y-2.5" multiple>
                ${when(
                  evaluation.sections.length > 0,
                  () => html`
                    ${sortedSections.map(
                      (section) => html`
                        <ion-accordion
                          class="px-4"
                          value="${section.id}"
                          toggle-icon-slot="start"
                          toggle-icon="/assets/icons/chevron-down.svg">
                          <ion-item lines="none" class="no-active-bg no-border no-p-start no-inner-p" slot="header">
                            <div class="flex justify-between items-center w-full">
                              <div class="flex items-center space-x-1 w-full">
                                <h6>${section.label}</h6>
                                <ion-chip style="--background: #EAEAEA; --color: #888888"
                                  >${msg(str`${section.fields.length || 0} Campos`)}</ion-chip
                                >
                              </div>
                            </div>
                          </ion-item>
                          <div slot="content" class="my-1.5 space-y-3">
                            ${section.fields.map((field) =>
                              choose(field.collectionName, [
                                [
                                  EvaluationFieldType.Attachment,
                                  () =>
                                    Attachment(field as FieldValueAttachment, {
                                      value: field.value as ValueAttachment,
                                      content: this._attachmentContent.get(field.id),
                                      readonly: true,
                                    }),
                                ],
                                [
                                  EvaluationFieldType.NumericBox,
                                  () =>
                                    NumericBox(field as FieldValueNumericBox, {
                                      value: field.value as ValueNumericBox,
                                      readonly: true,
                                    }),
                                ],
                                [
                                  EvaluationFieldType.ResultInteger,
                                  () =>
                                    ResultInteger(field as FieldValueResultInteger, {
                                      value: field.value as ValueResultInteger,
                                      score: this.calculateInteger(field as FieldValueResultInteger),
                                      readonly: true,
                                    }),
                                ],
                                [
                                  EvaluationFieldType.ResultPercentage,
                                  () =>
                                    ResultPercentage(field as FieldValueResultPercentage, {
                                      value: field.value as ValueResultPercentage,
                                      score: this.calculatePercentage(field as FieldValueResultPercentage),
                                      readonly: true,
                                    }),
                                ],
                                [
                                  EvaluationFieldType.Scale,
                                  () =>
                                    Scale(field as FieldValueScale, {
                                      readonly: true,
                                      onClick: () => this.openScaleModal(field as FieldValueScale, true),
                                      score: this.computeScaleScore(field as FieldValueScale),
                                    }),
                                ],
                                [
                                  EvaluationFieldType.TextBox,
                                  () =>
                                    TextBox(field as FieldValueTextBox, {
                                      value: field.value as ValueTextBox,
                                      readonly: true,
                                    }),
                                ],
                                [
                                  EvaluationFieldType.Url,
                                  () =>
                                    Url(field as FieldValueUrl, {
                                      value: field.value as ValueUrl,
                                      readonly: true,
                                    }),
                                ],
                                [
                                  EvaluationFieldType.YesOrNo,
                                  () =>
                                    YesOrNo(field as FieldValueYesOrNo, {
                                      value: field.value as ValueYesOrNo,
                                      readonly: true,
                                    }),
                                ],
                              ]),
                            )}
                          </div>
                        </ion-accordion>
                        <div class="h-px w-full bg-[#e5e5e5] last:hidden"></div>
                      `,
                    )}
                  `,
                  () => html` <div
                    class="mx-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                      ${msg("Esta avaliação ainda não tem conteúdo")}
                    </span>
                  </div>`,
                )}
              </ion-accordion-group>
            `;
          },
        })}
        <input id="content-file" class="hidden" accept="image/*, video/*" type="file" capture />
      </ion-content>
    `;
  }
}
