import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { FinalFormController, zodValidator } from "../../controllers/final-form.controller";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import { CreateEvaluationFieldTextBoxSchema, type CreateEvaluationFieldTextBox } from "../../repository/report/report";
import type { ObservableEvaluationSection } from "../../stores/evaluation.store";
import { alertController } from "@ionic/core";
import { reportsStore } from "../../stores/reports.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../../error";

@customElement("modal-text-box")
@localized()
export class ModalTextBox extends Page {
  section!: ObservableEvaluationSection;

  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  #form = new FinalFormController<CreateEvaluationFieldTextBox>(this, {
    validate: zodValidator(CreateEvaluationFieldTextBoxSchema().pick({ label: true })),
    onSubmit: async (values) => {
      try {
        const data: CreateEvaluationFieldTextBox = {
          label: values.label,
          section: this.section.id,
          evaluation: this.section.evaluation,
        };
        await reportsStore.insertEvaluationFieldTextBox(data, this.section);

        this.navigator.close();
      } catch (error) {
        if(error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: [msg("OK")],
          });
          await alert.present();
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
              <span class="mt-[3px] -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg("voltar")}</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">${msg("Caixa de Texto")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        <form
          id="form-text-box"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none">
            <div>
              <span class="font-semibold font-display">${msg("Legenda")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Legenda")}
                  enterkeyhint="send"
                  ${register("label")}>
                </ion-input>
              </ion-item>
              ${renderError("label")}
            </div>
          </ion-list>

          <ion-button
            form="form-text-box"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}>
            ${msg("Adicionar")}
          </ion-button>
        </form>
      </ion-content>
    `;
  }
}
