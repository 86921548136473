import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { Task, TaskStatus } from "@lit/task";
import { ObservablePatient, patientsStore } from "../stores/patients.store";
import { when } from "lit/directives/when.js";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { authContext, type AuthContext } from "../context/auth.context";
import { groupStore } from "../stores/groups.store";
import { localized, msg, str } from "@lit/localize";

@customElement("page-patient-archived")
@localized()
export class PagepatientArchived extends Page {
  @state() private _patientId: string = "";
  @state() private _groupId: string = "";

  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  #patient = new Task(this, {
    task: async () => {
      if (this._patientId === "") return;
      return patientsStore.loadPatient(this._patientId);
    },
    args: () => [this._patientId],
  });

  //this should be inside constructor
  connectedCallback() {
    super.connectedCallback();
    this._groupId = this.router.getParam("groupId")!;
    this._patientId = this.router.getParam("patientId")!;
  }

  #activatePatient = new Task(this, {
    task: async ([patient]: [ObservablePatient]) => {
      await patientsStore.activatePatient(patient);
    },
  });

  calculateAge(birthday: string | undefined) {
    if (!birthday) return;
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  formatDiagnosticDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Pad the month and day with a leading zero if necessary
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  async reactivatePatient() {
    const patient = this.#patient.value;
    if (patient) {
      await this.#activatePatient.run([patient]);
      this.router.push(`/groups/${this._groupId}/patient/${this._patientId}`, "root");
    }
  }

  canEdit() {
    const group = groupStore.find(this._groupId);
    if (!group) return false;
    return group.canEdit(this.auth.user);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <div class="flex justify-between items-center">
            <ion-router-link href=${this.router.backUrl} router-direction="back">
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </ion-router-link>
          </div>
        </ion-toolbar>
      </ion-header>

      <ion-content class="space-y-4 no-px" fullscreen>
        <div></div>
        <!-- HEADER -->
        ${this.#patient.render({
          pending: () => html`
            <div class="flex items-center space-x-3 px-4">
              <ion-skeleton-text animated style="width: 72px; height: 72px;"></ion-skeleton-text>
              <div>
                <ion-skeleton-text animated style="height: 28px; width: 120px"></ion-skeleton-text>
                <div class="flex space-x-1 items-center">
                  <ion-skeleton-text animated style="height: 26px; width: 200px"></ion-skeleton-text>
                </div>
              </div>
            </div>
            <div class="space-y-2">
              <div class="mt-1 mb-3 h-1 w-full bg-accent-7"></div>
              <div class="px-4 space-y-2">
                <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
                <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
                <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
              </div>
              <div>
                <div class="mt-3 h-1 w-full bg-accent-7"></div>
              </div>
            </div>

            <div class="space-y-3 px-4">
              <ion-skeleton-text animated style="height: 24px; width: 150px;"></ion-skeleton-text>
              <ion-skeleton-text animated class="h-40"></ion-skeleton-text>
              <ion-skeleton-text animated class="h-10"></ion-skeleton-text>
            </div>
            <div class="mt-3 h-1 w-full bg-accent-7"></div>

            <div class="space-y-3 px-4">
              <ion-skeleton-text animated style="height: 24px; width: 150px;"></ion-skeleton-text>
              <ion-skeleton-text animated class="h-12"></ion-skeleton-text>
              <ion-skeleton-text animated class="h-10"></ion-skeleton-text>
            </div>
          `,
          error: () => html` <span class="text-accent-6">${msg("Erro ao carregar o utente")}</span> `,
          complete: (patient) => {
            if (!patient) return nothing;
            return html`
              <div class="flex items-center space-x-3 px-4">
                <xt-avatar
                  style="--size: 72px; --border-radius: 99px;"
                  src="${patient.avatar}"
                  name="${patient.name}"></xt-avatar>
                <div>
                  <p class="text-xl font-display font-semibold">${patient.name}</p>
                  <div class="flex space-x-1 items-center">
                    <span class="font-medium">${msg(str`${this.calculateAge(patient.birthdate)} anos`)}</span>
                    <span class="text-accent-6">•</span>
                    <div class="rounded-full px-2 bg-black text-white">
                      <span class="text-sm font-medium">${msg("Arquivado")}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="space-y-2">
                <div class="mt-1 h-1 w-full bg-accent-7"></div>
                <ion-list class="px-4">
                  <ion-item class="no-p no-inner-p text-sm">
                    <div class="py-3.5 flex items-center justify-between w-full">
                      <div class="flex items-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M3.33333 5.00001L8.42303 8.84356L8.42473 8.84497C8.98987 9.25941 9.27261 9.46675 9.5823 9.54685C9.85602 9.61765 10.1438 9.61765 10.4175 9.54685C10.7274 9.46668 11.011 9.25873 11.5771 8.84356C11.5771 8.84356 14.8417 6.33829 16.6667 5.00001M2.5 13.1668V6.8335C2.5 5.90008 2.5 5.43302 2.68166 5.0765C2.84144 4.7629 3.09623 4.50812 3.40983 4.34833C3.76635 4.16667 4.23341 4.16667 5.16683 4.16667H14.8335C15.7669 4.16667 16.233 4.16667 16.5895 4.34833C16.9031 4.50812 17.1587 4.7629 17.3185 5.0765C17.5 5.43267 17.5 5.89917 17.5 6.83076V13.1697C17.5 14.1012 17.5 14.5671 17.3185 14.9232C17.1587 15.2368 16.9031 15.4921 16.5895 15.6519C16.2333 15.8333 15.7675 15.8333 14.8359 15.8333H5.16409C4.23249 15.8333 3.766 15.8333 3.40983 15.6519C3.09623 15.4921 2.84144 15.2368 2.68166 14.9232C2.5 14.5667 2.5 14.1003 2.5 13.1668Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <ion-label class="font-semibold">Email</ion-label>
                      </div>
                      <ion-note>${patient.email}</ion-note>
                    </div>
                  </ion-item>
                  <ion-item class="no-p no-inner-p text-sm">
                    <div class="py-3.5 flex items-center justify-between w-full">
                      <div class="flex items-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M7.91872 3.54768C7.66561 2.91492 7.05276 2.5 6.37126 2.5H4.07895C3.20692 2.5 2.5 3.20675 2.5 4.07878C2.5 11.491 8.50898 17.5 15.9212 17.5C16.7933 17.5 17.5 16.793 17.5 15.921L17.5004 13.6283C17.5004 12.9468 17.0856 12.334 16.4528 12.0809L14.2558 11.2024C13.6874 10.9751 13.0402 11.0774 12.57 11.4693L12.0029 11.9422C11.3407 12.4941 10.3664 12.4502 9.75683 11.8407L8.16018 10.2425C7.55065 9.63302 7.50561 8.65945 8.05745 7.99724L8.53027 7.43025C8.92218 6.95996 9.02541 6.31263 8.79805 5.74424L7.91872 3.54768Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <ion-label class="font-semibold">${msg("Telemóvel")}</ion-label>
                      </div>
                      <ion-note>${patient.phone}</ion-note>
                    </div>
                  </ion-item>
                  <ion-item class="no-p no-inner-p text-sm">
                    <div class="py-3.5 flex items-center justify-between w-full">
                      <div class="flex items-center space-x-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path
                            d="M1.66666 16.6667H3.33333M3.33333 16.6667H8.33333M3.33333 16.6667V9.54352C3.33333 9.09824 3.33333 8.87548 3.38747 8.66828C3.43545 8.48466 3.51482 8.31089 3.62142 8.15387C3.7417 7.97668 3.90886 7.82974 4.24398 7.53652L8.2453 4.03537C8.86654 3.49178 9.17717 3.21997 9.52701 3.11652C9.83551 3.02529 10.1643 3.02529 10.4728 3.11652C10.8229 3.22005 11.134 3.49212 11.7562 4.03652L15.7562 7.53652C16.0913 7.82974 16.2585 7.97668 16.3788 8.15387C16.4854 8.31089 16.5642 8.48466 16.6121 8.66828C16.6663 8.87548 16.6667 9.09824 16.6667 9.54352V16.6667M8.33333 16.6667H11.6667M8.33333 16.6667V13.3334C8.33333 12.4129 9.07952 11.6667 10 11.6667C10.9205 11.6667 11.6667 12.4129 11.6667 13.3334V16.6667M11.6667 16.6667H16.6667M16.6667 16.6667H18.3333"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <ion-label class="font-semibold">${msg("Residência")}</ion-label>
                      </div>
                      <ion-note class="text-right">${patient.address}</ion-note>
                    </div>
                  </ion-item>
                  ${patient.clinicInfo
                    ? html`
                        <ion-item lines="none" class="no-p no-inner-p text-sm">
                          <div class="pt-3.5 w-full">
                            <div class="flex items-center space-x-1 mb-2.5">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none">
                                <path
                                  d="M10 9.16667V13.3333M10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 10 17.5ZM10.0415 6.66667V6.75L9.9585 6.75016V6.66667H10.0415Z"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                              <ion-label class="font-semibold">${msg("Informação Clínica")}</ion-label>
                            </div>
                            <ion-note>${patient.clinicInfo}</ion-note>
                          </div>
                        </ion-item>
                      `
                    : nothing}
                </ion-list>
                <div class="mt-1 h-1 w-full bg-accent-7"></div>
              </div>

              <!-- DIAGNÓSTICOS -->

              <div class="space-y-3">
                <div class="space-y-1.5 px-4">
                  ${patient.diagnostics.length > 0
                    ? html`
                        <p class="font-display font-semibold">${msg("Diagnósticos")}</p>
                        <div class="space-y-2.5">
                          ${patient.diagnostics.map(
                            (diagnostic) => html`
                              <div
                                class="
                            w-full p-3.5 bg-accent-8 border border-solid border-accent-7 rounded-md h-40 col justify-between
                            ${diagnostic.isCompleted && "opacity-70"}
                            ">
                                <div class="col space-y-1 items-start">
                                  <div class="flex justify-between items-center w-full">
                                    <p class="font-semibold">${diagnostic.name}</p>
                                    ${when(
                                      diagnostic.isCompleted,
                                      () => html`
                                        <ion-badge
                                          style="--background: #F7B955;"
                                          class="text-xs font-medium text-foreground px-2 py-0.5"
                                          >${msg("Tratamento Concluído")}</ion-badge
                                        >
                                      `,
                                      () => html`
                                        <ion-badge
                                          style="--background: #79FFE1;"
                                          class="text-xs font-medium text-black px-2 py-0.5"
                                          >${msg("Em tratamento")}</ion-badge
                                        >
                                      `,
                                    )}
                                  </div>
                                  <span class="text-sm text-accent-4 line-clamp-2">${diagnostic.description}</span>
                                </div>
                                <div class="flex items-baseline justify-between">
                                  <span class="text-sm font-semibold font-display"
                                    >${this.formatDiagnosticDate(diagnostic.created)}</span
                                  >
                                  <div class="flex items-baseline -space-x-4">
                                    ${diagnostic.professionals
                                      ?.slice(0, 2)
                                      .map(
                                        (item) => html`
                                          <xt-avatar
                                            style="--size: 40px; --border-radius: 99px; aspect-ratio: 1; object-fit: cover;"
                                            src="${item.avatar}"
                                            name="${item.name}"></xt-avatar>
                                        `,
                                      )}
                                  </div>
                                </div>
                              </div>
                            `,
                          )}

                          <!-- DIAGNÓSTICOS -->
                        </div>
                      `
                    : nothing}

                  <!-- ATIVAR -->
                </div>
                ${patient.diagnostics.length > 0 ? html` <div class="mt-1 h-1 w-full bg-accent-7"></div> ` : nothing}
                ${when(
                  this.canEdit(),
                  () => html`
                    <div class="space-y-3 pb-7 px-4">
                      <div class="space-y-0.5">
                        <p class="font-display font-semibold">${msg("Ativar utente")}</p>
                        <span class="text-sm font-medium"
                          >${msg(
                            "O utente será novamente ativado na aplicação de modo a poder efetuar outras operações com este",
                          )}</span
                        >
                      </div>
                      <ion-button
                        disable=${this.#activatePatient.status === TaskStatus.PENDING}
                        @click=${() => this.reactivatePatient()}
                        shape="round"
                        expand="block"
                        size="small"
                        >${msg("Ativar Utente")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          class="ml-2">
                          <path
                            d="M13 15.8334C13 13.9924 10.7614 12.5 8 12.5C5.23858 12.5 3 13.9924 3 15.8334M16.3333 13.3334V10.8334M16.3333 10.8334V8.33337M16.3333 10.8334H13.8333M16.3333 10.8334H18.8333M8 10C6.15905 10 4.66667 8.50766 4.66667 6.66671C4.66667 4.82576 6.15905 3.33337 8 3.33337C9.84095 3.33337 11.3333 4.82576 11.3333 6.66671C11.3333 8.50766 9.84095 10 8 10Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-button>
                    </div>
                  `,
                )}
              </div>
            `;
          },
        })}
      </ion-content>
    `;
  }
}
