import { action, makeObservable, observable, type IObservableArray, runInAction, computed } from "mobx";
import type {
  Library,
  LibrarySharedPersonal,
  LibrarySharedGroup,
  UpdateLibrary,
  CreateLibrary,
} from "../repository/library/library";
import { libraryRepository, type ExerciseFilters } from "../repository/library/library.repository";
import type { User } from "../repository/user/user";
import { exercisesStore, type ObservableExercise } from "./exercises.store";
import type { Pagination } from "./pagination.store";
import type { UserAuth } from "./auth.store";
import type { UserShareAction } from "./userShare.store";
import type {
  PublicExercise,
  LibraryPublic,
  CreateExercise,
  UpdateExercise,
  ExercisesFilters,
} from "../repository/library/exercises";
import { ErrorExerciseNotFound, ErrorLibraryNotFound, ErrorLibraryNotShared } from "../repository/library/errors";

export class ObservablePublicLibrary {
  @observable exercises: IObservableArray<PublicExercise>;
  @observable popularExercises: IObservableArray<PublicExercise>;
  @observable totalExercises: number;
  @observable isLoaded = false;

  constructor(library: LibraryPublic = { popularExercises: [], totalExercises: 0 }) {
    this.exercises = observable.array([]);
    this.popularExercises = observable.array(library.popularExercises);
    this.totalExercises = library.totalExercises;
    makeObservable(this);
  }
}

export class ObservableLibrary {
  id: string;
  @observable name: string;
  @observable owner: User;
  @observable exercises: IObservableArray<ObservableExercise>;
  @observable popularExercises: IObservableArray<ObservableExercise>;
  @observable sharedPersonal: IObservableArray<LibrarySharedPersonal>;
  @observable sharedGroup: LibrarySharedGroup | null;
  @observable isLoaded = false;
  @observable totalExercises?: number;

  constructor(library: Library) {
    this.id = library.id;
    this.name = library.name;
    this.owner = library.owner;
    this.sharedGroup = library.sharedGroup?.[0] || null;
    this.sharedPersonal = observable.array(library.sharedPersonal);
    this.popularExercises = observable.array(library.popularExercises.map((e) => exercisesStore.subscribe(e)));
    this.exercises = observable.array([]);
    this.totalExercises = library.totalExercises;
    makeObservable(this);
  }

  @action merge(library: Library) {
    this.name = library.name;
    this.owner = library.owner;
    if (library.sharedGroup) this.sharedGroup = library.sharedGroup[0] || null;
    if (library.sharedPersonal) this.sharedPersonal.replace(library.sharedPersonal);
    if (library.totalExercises) this.totalExercises = library.totalExercises;
    //i dont like this, but there's no better way to keep the state consistent, specially in shared libraries
    this.popularExercises.replace(library.popularExercises.map((e) => exercisesStore.subscribe(e)));
  }

  isOwner(user: User | UserAuth) {
    return this.owner.id === user.id;
  }

  isAdmin(user: User | UserAuth) {
    return this.findUser(user)?.access.role === "admin";
  }

  isShared(user: User | UserAuth) {
    return this.findUser(user) !== undefined && !this.isOwner(user);
  }

  isSharedFromGroup() {
    return this.sharedGroup !== null;
  }

  canEdit(user: User | UserAuth) {
    return (
      this.isOwner(user) ||
      this.isAdmin(user) ||
      this.findUser(user)?.access.role === "editor" ||
      this.isSharedFromGroup()
    );
  }

  hasPrivileges(user: User | UserAuth) {
    return this.isOwner(user) || this.isAdmin(user);
  }

  private findUser(user: User | UserAuth) {
    return this.sharedPersonal.find((s) => s.user.id === user.id);
  }
}

const emptyFilters = () => ({
  categories: [],
  muscles: [],
  equipments: [],
});

class LibrariesStore {
  @observable libraries = observable.array<ObservableLibrary>([]);
  @observable isLoaded = false;

  @observable publicLibrary: ObservablePublicLibrary;
  @observable isLoadedPublic = false;

  @observable publicFilters: ExercisesFilters = emptyFilters();
  @observable isLoadedPublicFilters = false;

  public constructor() {
    this.publicLibrary = new ObservablePublicLibrary();
    makeObservable(this);
  }

  find(id: string) {
    return this.libraries.find((l) => l.id === id);
  }

  @action save(library: Library) {
    const observable = new ObservableLibrary(library);
    this.libraries.push(observable);
    return observable;
  }

  @action delete(library: ObservableLibrary) {
    return this.libraries.remove(library);
  }

  exists(library: ObservableLibrary) {
    return this.libraries.includes(library);
  }

  @action clearStore() {
    this.libraries.clear();
    this.publicLibrary = new ObservablePublicLibrary();
  }

  @action async loadLibraries(opts = { cache: true }) {
    if (opts.cache && this.isLoaded) {
      return this.libraries;
    }

    const libraries = await libraryRepository.listLibraries();

    runInAction(() => {
      this.libraries.replace(libraries.map((library) => new ObservableLibrary(library)));
      this.isLoaded = true;
    });

    return this.libraries;
  }

  @action async loadLibrary(id: string, opts = { cache: true }) {
    const foundLibrary = this.libraries.find((l) => l.id === id);
    if (foundLibrary && opts.cache && foundLibrary.isLoaded) {
      return foundLibrary;
    }

    const library = await libraryRepository.getLibrary(id);

    let observable!: ObservableLibrary;

    runInAction(() => {
      if (foundLibrary) {
        foundLibrary.merge(library);
      } else {
        observable = this.save(library);
      }
    });

    if (foundLibrary) {
      return foundLibrary;
    }

    return observable;
  }

  @action async loadExercises(libraryId: string, pagination: Pagination, filters?: ExerciseFilters) {
    const exercises = await libraryRepository.listExercises(libraryId, pagination, filters);

    const library = this.libraries.find((l) => l.id === libraryId);
    if (!library) {
      throw new ErrorLibraryNotFound();
    }

    let observables: ObservableExercise[] = [];

    runInAction(() => {
      if (pagination.page === 1) {
        library.exercises.clear();
      }

      for (let i = 0; i < exercises.items.length; i++) {
        const exercise = exercises.items[i];
        if (exercise) {
          const observable = exercisesStore.subscribe(exercise);
          library.exercises.push(observable);
          observables.push(observable);
        }
      }

      library.totalExercises = exercises.totalItems;
    });

    return {
      ...exercises,
      items: observables,
    };
  }

  @action async loadPublicLibrary(opts = { cache: true }) {
    if (opts.cache && this.isLoadedPublic) {
      return this.publicLibrary;
    }

    const library = await libraryRepository.getPublicLibrary();

    runInAction(() => {
      this.publicLibrary = new ObservablePublicLibrary(library);
      this.isLoadedPublic = true;
    });

    return this.publicLibrary;
  }

  @action async loadPublicExercises(pagination: Pagination, filters?: ExerciseFilters) {
    const exercises = await libraryRepository.listPublicExercises(pagination, filters);

    runInAction(() => {
      if (pagination.page === 1) {
        this.publicLibrary.exercises.clear();
      }

      for (let i = 0; i < exercises.items.length; i++) {
        const exercise = exercises.items[i];
        if (exercise) {
          this.publicLibrary.exercises.push(exercise);
        }
      }
    });

    return exercises;
  }

  @action async loadPublicExercise(id: string, opts = { cache: true }) {
    if (opts.cache) {
      const found = this.publicLibrary.exercises.find((e) => e.id === id);
      if (found) return found;
    }

    const exercise = await libraryRepository.getPublicExercise(id);

    runInAction(() => {
      let found = false;
      for (let i = 0; i < this.publicLibrary.exercises.length; i++) {
        const exercise = this.publicLibrary.exercises[i];
        if (exercise?.id === id) {
          exercise.name = exercise.name;
          exercise.content = exercise.content;
          exercise.thumbnail = exercise.thumbnail;
          exercise.categories = exercise.categories;
          exercise.muscles = exercise.muscles;
          exercise.equipments = exercise.equipments;
          found = true;
        }
      }
    });

    return exercise;
  }

  @action async loadPublicFilters(opts = { cache: true }) {
    if (opts.cache && this.isLoadedPublicFilters) {
      return this.publicFilters;
    }

    const filters = await libraryRepository.listPublicFilters();

    runInAction(() => {
      this.publicFilters = filters;
      this.isLoadedPublicFilters = true;
    });

    return this.publicFilters;
  }

  @action async loadExercise(id: string, opts = { cache: true }) {
    const found = exercisesStore.find(id);
    if (found && opts.cache) {
      return found;
    }

    const exercise = await libraryRepository.getExercise(id);

    let observable!: ObservableExercise;

    runInAction(() => {
      observable = exercisesStore.save(exercise);
    });

    return observable;
  }

  @action async createLibrary(data: CreateLibrary) {
    const library = await libraryRepository.createLibrary(data);

    let observable!: ObservableLibrary;

    runInAction(() => {
      observable = this.save(library);
    });

    return observable;
  }

  @action async updateLibrary(library: ObservableLibrary, data: UpdateLibrary) {
    const updatedLibrary = await libraryRepository.updateLibrary(library.id, data);

    runInAction(() => {
      library.merge(updatedLibrary);
    });

    return library;
  }

  @action async shareWithGroup(library: ObservableLibrary, groupId: string) {
    const sharedGroup = await libraryRepository.shareLibraryWithGroup(library.id, groupId);

    runInAction(() => {
      library.sharedGroup = sharedGroup;
    });

    return library;
  }

  @action async shareWithUsers(library: ObservableLibrary, users: UserShareAction[]) {
    await libraryRepository.shareLibraryWithUsers(library.id, users);

    runInAction(() => {
      // Remove deleted items
      const idsToDelete = users.filter((gs) => gs.action === "delete").map((gs) => gs.user.id);
      library.sharedPersonal.replace(library.sharedPersonal.filter((gs) => !idsToDelete.includes(gs.user.id)));

      // Apply updates
      users.forEach((u) => {
        if (u.action === "update") {
          const sp = library.sharedPersonal.find((item) => item.user.id === u.user.id);
          if (sp) {
            sp.access.id = u.access.id;
            sp.access.role = u.access.role;
          }
        }
      });
    });

    return library;
  }

  @action async leaveLibrary(library: ObservableLibrary, user: UserAuth) {
    const shareId = library.sharedPersonal.find((s) => s.user.id === user.id)?.id;
    if (!shareId) {
      throw new ErrorLibraryNotShared();
    }

    try {
      await libraryRepository.leaveLibrary(shareId);
      runInAction(() => {
        this.delete(library);
      });
      return library;
    } catch (error) {
      if (error instanceof ErrorLibraryNotFound) {
        runInAction(() => {
          this.delete(library);
        });
        return library;
      }
      throw error;
    }
  }

  @action async deleteLibrary(library: ObservableLibrary) {
    try {
      await libraryRepository.deleteLibrary(library.id);

      runInAction(() => {
        this.delete(library);
      });

      return true;
    } catch (error) {
      if (error instanceof ErrorLibraryNotFound) {
        runInAction(() => {
          this.delete(library);
        });
        return true;
      }
      throw error;
    }
  }

  @action async createExercise(library: ObservableLibrary, data: CreateExercise) {
    const exercise = await libraryRepository.createExercise(library.id, data);

    let observable!: ObservableExercise;

    runInAction(() => {
      observable = exercisesStore.save(exercise);
      library.exercises.push(observable);
    });

    return observable;
  }

  @action async updateExercise(library: ObservableLibrary, exercise: ObservableExercise, data: UpdateExercise) {
    const updatedExercise = await libraryRepository.updateExercise(library.id, exercise.id, data);

    runInAction(() => {
      exercise.merge(updatedExercise);
    });

    return exercise;
  }

  @action async deleteExercise(library: ObservableLibrary, exercise: ObservableExercise) {
    try {
      await libraryRepository.deleteExercise(exercise.id);

      runInAction(() => {
        library.exercises.remove(exercise);
        exercisesStore.delete(exercise);
        library.totalExercises = library.totalExercises ? library.totalExercises - 1 : 0;
        const removed = library.popularExercises.remove(exercise);
        if (removed) library.popularExercises.replace(library.exercises.slice(0, 3));
      });

      return true;
    } catch (error) {
      if (error instanceof ErrorExerciseNotFound) {
        runInAction(() => {
          library.exercises.remove(exercise);
          exercisesStore.delete(exercise);
          library.totalExercises = library.totalExercises ? library.totalExercises - 1 : 0;
          const removed = library.popularExercises.remove(exercise);
          if (removed) library.popularExercises.replace(library.exercises.slice(0, 3));
        });
        return true;
      }
      throw error;
    }
  }
}

export const librariesStore = new LibrariesStore();
