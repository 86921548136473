import { computed, makeObservable, observable, runInAction } from "mobx";
import { type User, type UserUpdate, createUser } from "../repository/user/user";
import type PocketBase from "pocketbase";
import { CustomError } from "ts-custom-error";

export class UnauthenticatedError extends CustomError {
  public constructor(message = "user is not authenticated") {
    super(message);
  }
}

export class UserAuth {
  id: string;
  @observable email: string;
  @observable isProfileComplete: boolean;
  @observable name: string;
  @observable username: string;
  @observable avatar: string;
  @observable role: string;
  @observable language: string;

  constructor(data: User) {
    this.id = data.id;
    this.email = data.email || "";
    this.isProfileComplete = data.isProfileComplete;
    this.name = data.name;
    this.username = data.username;
    this.avatar = data.avatar || "";
    this.role = data.role || "";
    this.language = data.language || "pt"; //TODO: get from browser

    makeObservable(this);
  }
}

// From pocketbase
//
// BasicAuthStore: pb.authStore

export class AuthStore {
  @observable _auth: UserAuth | null = null;

  constructor(private pb: PocketBase) {
    const model = this.pb.authStore.model;
    if (!model) {
      return;
    }
    this._auth = new UserAuth(createUser(model));
  }

  @computed
  get user() {
    if (!this._auth) {
      throw new UnauthenticatedError();
    }
    return this._auth;
  }

  get isSessionValid() {
    return this.pb.authStore.isValid;
  }

  get isAuthenticated() {
    try {
      return this.user !== null;
    } catch (e) {
      return false;
    }
  }

  logout() {
    this.pb.authStore.clear();
    this._auth = null;
  }

  async loginWithEmail(email: string, password: string) {
    const { record } = await this.pb.collection("users").authWithPassword(email, password);
    runInAction(() => {
      this._auth = new UserAuth(createUser(record));
    });
    return this._auth; // This returns the updated value of this._auth
  }

  async registerWithEmail(email: string, password: string) {
    const browserLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
    await this.pb.collection("users").create({
      email: email,
      password: password,
      passwordConfirm: password,
      isProfileComplete: false,
      language: browserLanguage === "pt-PT" || browserLanguage === "pt-BR" ? "pt" : "en",
    });
    return this.loginWithEmail(email, password);
  }

  async createProfile(data: FormData) {
    const record = await this.pb.collection("users").update(this.user.id, data);
    runInAction(() => {
      this._auth = new UserAuth(createUser(record));
    });
    return this._auth;
  }

  async updateProfile(user: UserUpdate) {
    const record = await this.pb.collection("users").update(this.user.id, user);
    runInAction(() => {
      this._auth = new UserAuth(createUser(record));
    });
    return this._auth;
  }
}
