import { msg, str } from "@lit/localize";
import { ApiError } from "../../error";

export class ErrorLibraryNotFound extends ApiError {
  public constructor() {
    super("library-not-found", msg("Biblioteca não encontrada"));
  }
}

export class ErrorLibraryNotShared extends ApiError {
  public constructor() {
    super("library-not-shared", msg("Biblioteca não compartilhada com o utilizador"));
  }
}

export class ErrorLibraryInviteExists extends ApiError {
  public constructor(name?: string) {
    super("library-invite-exists", msg(str`O utilizador ${name} já foi convidado para a biblioteca`));
  }
}

export class ErrorExerciseNotFound extends ApiError {
  public constructor() {
    super("exercise-not-found", msg("Exercício não encontrado"));
  }
}

export class ErrorExerciseInvalidThumbnail extends ApiError {
  public constructor() {
    super("exercise-invalid-thumbnail", msg("O thumbnail que colocou é inválido"));
  }
}

export class ErrorExerciseInvalidContent extends ApiError {
  public constructor() {
    super("exercise-invalid-video", msg("O conteúdo que colocou é inválido"));
  }
}

export class ErrorExerciseContentTooLarge extends ApiError {
  public constructor() {
    super("exercise-video-too-large", msg("O conteúdo ultrapassa os 10MB de limite"));
  }
}

export class ErrorExerciseThumbnailNotFound extends ApiError {
  public constructor() {
    super("exercise-thumbnail-not-found", msg("Thumbnail não encontrado"));
  }
}

export class ErrorExerciseContentNotFound extends ApiError {
  public constructor() {
    super("exercise-content-not-found", msg("Conteúdo não encontrado"));
  }
}

/* ************************************
 **************** CATEGORY *************
 ************************************ */

export class ErrorLibraryCategoryExists extends ApiError {
  public constructor() {
    super("library-category-exists", msg("A categoria já existe nesta biblioteca"));
  }
}

export class ErrorLibraryCategoryNotFound extends ApiError {
  public constructor() {
    super("library-category-not-found", msg("A categoria não foi encontrada"));
  }
}

export class ErrorLibraryCategoryLimitReached extends ApiError {
  public constructor() {
    super("library-category-limit-reached", msg("Limite de categorias atingido"));
  }
}

/* ************************************
 ***************** MUSCLES *************
 ************************************ */

export class ErrorLibraryMuscleExists extends ApiError {
  public constructor() {
    super("library-muscle-exists", msg("O músculo já existe nesta biblioteca"));
  }
}

export class ErrorLibraryMuscleNotFound extends ApiError {
  public constructor() {
    super("library-muscle-not-found", msg("O músculo não foi encontrado"));
  }
}

export class ErrorLibraryMuscleLimitReached extends ApiError {
  public constructor() {
    super("library-muscle-limit-reached", msg("Limite de músculos atingido"));
  }
}

/* ************************************
 *************** EQUIPMENT *************
 ************************************ */

export class ErrorLibraryEquipmentExists extends ApiError {
  public constructor() {
    super("library-equipment-exists", msg("O equipamento já existe nesta biblioteca"));
  }
}

export class ErrorLibraryEquipmentNotFound extends ApiError {
  public constructor() {
    super("library-equipment-not-found", msg("O equipamento não foi encontrado"));
  }
}

export class ErrorLibraryEquipmentLimitReached extends ApiError {
  public constructor() {
    super("library-equipment-limit-reached", msg("Limite de equipamentos atingido"));
  }
}

/* ************************************
 *************** DIFFICULTY ************
 ************************************ */

export class ErrorLibraryDifficultyExists extends ApiError {
  public constructor() {
    super("library-difficulty-exists", msg("A dificuldade já existe nesta biblioteca"));
  }
}

export class ErrorLibraryDifficultyNotFound extends ApiError {
  public constructor() {
    super("library-difficulty-not-found", msg("A dificuldade não foi encontrado"));
  }
}

export class ErrorLibraryDifficultyLimitReached extends ApiError {
  public constructor() {
    super("library-difficulty-limit-reached", msg("Limite de dificuldades atingido"));
  }
}
