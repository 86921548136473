import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { repeat } from "lit/directives/repeat.js";

import { Task } from "@lit/task";
import { ObservableGroup } from "../stores/groups.store";
import { Page, required } from "../components/component";
import { groupRepository } from "../repository/group/group.repository";
import type { FilterStore } from "../stores/filter.store";
import { localized, msg } from "@lit/localize";
import { when } from "lit/directives/when.js";
import { ApiError } from "../error";

@customElement("modal-filter-groups")
@localized()
export class ModalFilterGroups extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  @required() filterStore!: FilterStore;
  @required() group!: ObservableGroup;

  typeFilters = [
    { id: "archived", label: msg("Arquivado") },
    { id: "active", label: msg("Ativo") },
  ];

  #loadFilters = new Task(this, {
    task: async () => {
      return groupRepository.getGroupCategories(this.group.id);
    },
    args: () => [],
  });

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click="${() => {
                  this.filterStore.rollback();
                  this.navigator.goBack();
                }}">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Aplicar Filtros")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4" fullscreen>
        <!-- spacer -->
        <div class="mt-2.5">
          <!-- --------------- CONTENT ----------------- -->
          ${this.#loadFilters.render({
            pending: () => {
              return html`
                <div class="space-y-8">
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <div>
                    <ion-skeleton-text animated="true" style="width: 30%; height: 24px;"></ion-skeleton-text>
                    <div class="mt-2 space-y-2">
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                      <ion-skeleton-text animated="true" style="width: 100%; height: 32px;"></ion-skeleton-text>
                    </div>
                  </div>
                </div>
              `;
            },
            error: (err) => {
              console.error(err);
              if (err instanceof ApiError) {
                return html`<div
                  class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${err.message}
                  </span>
                </div>`;
              } else {
                return html`<div
                  class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${msg("Erro ao pesquisar")}
                  </span>
                </div>`;
              }
            },
            complete: (categories) => {
              return html`
                <div class="space-y-8">
                  <div class="space-y-2">
                    <p class="font-semibold font-display">${msg("Por categoria")}</p>
                    <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                      ${when(
                        categories.length > 0,
                        () => html`
                          ${repeat(
                            categories,
                            (category) => category.id,
                            (category) => {
                              const f = { id: category.id, name: category.category };
                              const isSelected = this.filterStore.isActive(f);
                              return html`
                                <ion-chip
                                  color=${isSelected ? "primary" : "light"}
                                  @click=${() => this.filterStore.stage("categories", f)}
                                  class="px-2.5 py-1.5 text-sm font-medium">
                                  ${category.category}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    class=${isSelected ? "ml-1 mb-px" : "hidden ml-1 mb-px"}>
                                    <rect width="12" height="12" rx="6" fill="white" />
                                    <path
                                      d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                      stroke="black"
                                      stroke-linecap="round"
                                      stroke-linejoin="round" />
                                  </svg>
                                </ion-chip>
                              `;
                            },
                          )}
                        `,
                        () => html`
                          <div
                            class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                                stroke="#111111"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                            <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                              ${msg("Este grupo não possui categorias")}
                            </span>
                          </div>
                        `,
                      )}
                    </div>
                  </div>
                  <div class="space-y-2">
                    <p class="font-semibold font-display">${msg("Paciente")}</p>
                    <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                      ${repeat(
                        this.typeFilters,
                        (filter) => filter.id,
                        (filter) => {
                          const f = { id: filter.id, name: filter.label };
                          const isSelected = this.filterStore.isActive({ id: filter.id, name: filter.label });
                          return html`
                            <ion-chip
                              color=${isSelected ? "primary" : "light"}
                              @click=${() => this.filterStore.stageSingle("type", f)}
                              class="px-2.5 py-1.5 text-sm font-medium">
                              ${filter.label}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                class=${isSelected ? "ml-1 mb-px" : "hidden ml-1 mb-px"}>
                                <rect width="12" height="12" rx="6" fill="white" />
                                <path
                                  d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                  stroke="black"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                            </ion-chip>
                          `;
                        },
                      )}
                    </div>
                  </div>
                </div>
              `;
            },
          })}
        </div>
      </ion-content>

      <ion-footer class="--transparent">
        <ion-toolbar>
          <ion-button
            shape="round"
            expand="block"
            class="font-semibold"
            @click=${() => {
              this.filterStore.commit();
              this.navigator.goBack();
            }}
            >${msg("Filtrar Exercícios")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
