import { ClientResponseError } from "pocketbase";
import { ErrorInsufficientPermissions, ErrorUnknown } from "../../error";
import { ERROR_MISSING_REL, expand, expandAt, pb } from "../../pocketbase";
import { createExercise, createPublicExercise } from "../library/exercises";
import {
  createEvaluationSummary,
  createPatientEvaluation,
  createReportCategory,
  type CreatePatientEvaluation,
  type EvaluationRecord,
  type PatientEvaluation,
  type PatientEvaluationRecord,
} from "../report/report";
import { createUser, type UserRecord } from "../user/user";
import {
  createDiagnostic,
  createSession,
  type CreateDiagnostic,
  type CreateExercisePlan,
  type CreateSession,
  type Diagnostic,
  type DiagnosticRecord,
  type ExercisePlan,
  type ExercisePlanRecord,
  type Session,
  type SessionRecord,
  type UpdateDiagnostic,
  type UpdateExercisePlan,
  type UpdateSession,
  createExercisePlan,
} from "./diagnostic";
import {
  ErrorDiagnosticNotFound,
  ErrorExercisePlanExerciseNotFound,
  ErrorExercisePlanNotFound,
  ErrorPatientNotFound,
  ErrorSessionNotFound,
} from "./errors";
import { ErrorPatientEvaluationNotFound } from "../report/errors";

export class DiagnosticRepository {
  async list(patientId: string): Promise<Diagnostic[]> {
    const result = await pb.collection<DiagnosticRecord>("diagnostics").getFullList({
      filter: `patient = "${patientId}"`,
      expand: "professionals",
      sort: "-updated",
    });

    const diagnostics = result.map((diagnostic) => {
      const professionals = expandAt<UserRecord>(diagnostic, "professionals").map(createUser);
      return {
        ...diagnostic,
        professionals,
      };
    });

    return diagnostics.map(createDiagnostic);
  }

  async getDiagnostic(id: string): Promise<Diagnostic> {
    try {
      const result = await pb.collection<DiagnosticRecord>("diagnostics").getOne(id, {
        expand: expand(
          "professionals",
          "sessions_via_diagnostic",
          "sessions_via_diagnostic.exercises_plan_via_session",
          "sessions_via_diagnostic.exercises_plan_via_session.exercise",
          "sessions_via_diagnostic.exercises_plan_via_session.exercisePublic",
          "sessions_via_diagnostic.patient_evaluations_via_session",
          "sessions_via_diagnostic.patient_evaluations_via_session.evaluation",
          "sessions_via_diagnostic.patient_evaluations_via_session.evaluation.evaluations_category_via_evaluation.category",
        ),
      });

      const sessions = expandAt<SessionRecord>(result, "sessions_via_diagnostic").map((s) => {
        const exercisesPlan = expandAt<ExercisePlanRecord>(s, "exercises_plan_via_session").map((e) => {
          // we need to do this because zod schema will fail if we have categories,
          // equipments or muscles as string[] (we don't need this here)
          if (e.expand?.exercisePublic) {
            e.expand.exercisePublic.categories = [];
            e.expand.exercisePublic.equipments = [];
            e.expand.exercisePublic.muscles = [];
          }
          return createExercisePlan({
            ...e,
            exercise: e.expand?.exercise ? createExercise(e.expand?.exercise) : undefined,
            exercisePublic: e.expand?.exercisePublic ? createPublicExercise(e.expand?.exercisePublic) : undefined,
          });
        });
        const evaluations = expandAt<PatientEvaluationRecord>(s, "patient_evaluations_via_session").map((e) => {
          const evaluationArray = expandAt<EvaluationRecord>(e, "evaluation");

          if (!evaluationArray || evaluationArray.length === 0) return [];

          const evaluation = evaluationArray[0];
          return {
            ...e,
            evaluation: createEvaluationSummary({
              ...evaluation,
              categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
                createReportCategory(c.expand.category),
              ),
            }),
          };
        });
        return createSession({
          ...s,
          exercisesPlan,
          evaluations,
        });
      });

      const professionals = expandAt<UserRecord>(result, "professionals").map(createUser);

      return createDiagnostic({
        ...result,
        sessions,
        professionals,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorDiagnosticNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createDiagnostic(data: CreateDiagnostic): Promise<Diagnostic> {
    try {
      const result = await pb.collection<DiagnosticRecord>("diagnostics").create(data, { expand: "professionals" });

      const professionals = expandAt<UserRecord>(result, "professionals").map(createUser);

      return createDiagnostic({
        ...result,
        professionals,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.patient?.code === ERROR_MISSING_REL) {
            throw new ErrorPatientNotFound();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateDiagnostic(diagnosticId: string, data: UpdateDiagnostic): Promise<Diagnostic> {
    try {
      const result = await pb
        .collection<DiagnosticRecord>("diagnostics")
        .update(diagnosticId, data, { expand: "professionals" });

      const professionals = expandAt<UserRecord>(result, "professionals").map(createUser);

      return createDiagnostic({
        ...result,
        professionals,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorDiagnosticNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteDiagnostic(diagnosticId: string): Promise<boolean> {
    try {
      return await pb.collection<DiagnosticRecord>("diagnostics").delete(diagnosticId);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorDiagnosticNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createSession(data: CreateSession): Promise<Session> {
    try {
      const result = await pb.collection<SessionRecord>("sessions").create(data);
      return createSession(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.diagnostic?.code === ERROR_MISSING_REL) {
            throw new ErrorDiagnosticNotFound();
          }
        }
      }
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateSession(sessionId: string, data: UpdateSession): Promise<Session> {
    try {
      const result = await pb.collection<SessionRecord>("sessions").update(sessionId, data);
      return createSession(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorSessionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteSession(id: string): Promise<boolean> {
    try {
      return await pb.collection<SessionRecord>("sessions").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorSessionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createExercisePlan(data: CreateExercisePlan): Promise<ExercisePlan> {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value instanceof File) {
        formData.append(key, value);
      } else if (value !== undefined && value !== null) {
        formData.append(key, value.toString());
      }
    });

    try {
      const result = await pb.collection<ExercisePlanRecord>("exercises_plan").create(formData, {
        expand: expand("exercise", "exercisePublic"),
      });

      // we need to do this because zod schema will fail if we have categories,
      // equipments or muscles as string[] (we don't need this here)
      if (result.expand?.exercisePublic) {
        result.expand.exercisePublic.categories = [];
        result.expand.exercisePublic.equipments = [];
        result.expand.exercisePublic.muscles = [];
      }

      return createExercisePlan({
        ...result,
        exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
        exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.session?.code === ERROR_MISSING_REL) {
            throw new ErrorSessionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateExercisePlan(id: string, data: UpdateExercisePlan): Promise<ExercisePlan> {
    try {
      const result = await pb.collection<ExercisePlanRecord>("exercises_plan").update(id, data, {
        expand: expand("exercise", "exercisePublic"),
      });

      // we need to do this because zod schema will fail if we have categories,
      // equipments or muscles as string[] (we don't need this here)
      if (result.expand?.exercisePublic) {
        result.expand.exercisePublic.categories = [];
        result.expand.exercisePublic.equipments = [];
        result.expand.exercisePublic.muscles = [];
      }

      return createExercisePlan({
        ...result,
        exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
        exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (
            error.response.data?.exercise?.code === ERROR_MISSING_REL ||
            error.response.data?.exercisePublic?.code === ERROR_MISSING_REL
          ) {
            throw new ErrorExercisePlanExerciseNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorExercisePlanNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async injectTemplate(sessionId: string, templateId: string): Promise<ExercisePlan[]> {
    try {
      const results = await pb.send<ExercisePlanRecord[]>(`/api/v1/session/${sessionId}`, {
        method: "POST",
        body: JSON.stringify({ template: templateId }),
      });

      return results.map((result) => {
        // we need to do this because zod schema will fail if we have categories,
        // equipments or muscles as string[] (we don't need this here)
        if (result.expand?.exercisePublic) {
          result.expand.exercisePublic.categories = [];
          result.expand.exercisePublic.equipments = [];
          result.expand.exercisePublic.muscles = [];
        }

        return createExercisePlan({
          ...result,
          exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
          exercisePublic: result.expand?.exercisePublic
            ? createPublicExercise(result.expand?.exercisePublic)
            : undefined,
        });
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 500) {
          if (error.response.data?.session?.code === ERROR_MISSING_REL) {
            throw new ErrorSessionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateExercisePlanPositions(
    sessionId: string,
    exercises: { id: string; position: number }[],
  ): Promise<ExercisePlan> {
    const result = await pb.send<ExercisePlanRecord>(`/api/v1/session/${sessionId}/exercisePlan`, {
      method: "PATCH",
      body: JSON.stringify({ exercises: exercises }),
    });

    // we need to do this because zod schema will fail if we have categories,
    // equipments or muscles as string[] (we don't need this here)
    if (result.expand?.exercisePublic) {
      result.expand.exercisePublic.categories = [];
      result.expand.exercisePublic.equipments = [];
      result.expand.exercisePublic.muscles = [];
    }

    return createExercisePlan({
      ...result,
      exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
      exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
    });
  }

  async deleteExercisePlan(id: string): Promise<boolean> {
    try {
      return await pb.collection<ExercisePlanRecord>("exercises_plan").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorExercisePlanNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createPatientEvaluation(data: CreatePatientEvaluation): Promise<PatientEvaluation> {
    try {
      const result = await pb.collection<PatientEvaluationRecord>("patient_evaluations").create(data, {
        expand: expand("evaluation", "evaluation.evaluations_category_via_evaluation.category"),
      });

      const evaluation = expandAt<EvaluationRecord>(result, "evaluation")[0];

      return createPatientEvaluation({
        ...result,
        evaluation: createEvaluationSummary({
          ...evaluation,
          categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
            createReportCategory(c.expand.category),
          ),
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.session?.code === ERROR_MISSING_REL) {
            throw new ErrorSessionNotFound();
          }
          if (error.response.data?.patient?.code === ERROR_MISSING_REL) {
            throw new ErrorPatientNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deletePatientEvaluation(id: string): Promise<boolean> {
    try {
      return await pb.collection<PatientEvaluationRecord>("patient_evaluations").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorPatientEvaluationNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }
}

export const diagnosticRepository = new DiagnosticRepository();
