// Import LitElement and html from lit
import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

// Define the component
@customElement("xt-avatar")
export class XTAvatar extends LitElement {
  @property({ type: String }) src = "";
  @property({ type: String }) name = "";

  static styles = css`
    :host {
      --size: 52px;
      --background-color: #eaeaea;
      --text-color: #000000;
      --border-radius: 0.375rem;
      --font-size: 0.875rem;
      --font-weight: bold;
    }

    .avatar-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--size);
      height: var(--size);
      border-radius: var(--border-radius);
      background-color: var(--background-color);
      color: var(--text-color);
      font-weight: var(--font-weight);
      font-size: var(--font-size);
      overflow: hidden;
    }

    .avatar-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  `;

  #altText() {
    return this.name ? `${this.name}'s avatar` : "Avatar";
  }

  #getInitials() {
    const name = this.name || "";
    const hasTokens = name.indexOf(" ") !== -1;

    return name.substring(0, hasTokens ? 1 : 2) + (hasTokens ? name.charAt(name.lastIndexOf(" ") + 1) : "");
  }

  render() {
    if (this.src) {
      return html`
        <div class="avatar-container">
          <img
            loading="lazy"
            class="avatar-image"
            src=${this.src}
            alt=${this.#altText()}
            @error=${this.#handleImageError} />
        </div>
      `;
    }

    return html`<div class="avatar-container">${this.#getInitials()}</div>`;
  }

  #handleImageError() {
    this.src = ""; // Reset the src property to trigger the initials view
  }
}
