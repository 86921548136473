import { observable, type IObservableArray, makeObservable, action, computed } from "mobx";
import type { Category, ScaleSummary, ScaleField, ScaleOption, Scale } from "../repository/report/report";

export class ObservableScaleField {
  id: string;
  scale: string;
  @observable label: string;
  @observable position: number;
  @observable options: IObservableArray<ScaleOption>;

  constructor(field: ScaleField) {
    this.id = field.id;
    this.scale = field.scale;
    this.label = field.label;
    this.position = field.position;
    this.options = observable.array(field.options);
    makeObservable(this);
  }

  @action merge(field: ScaleField) {
    this.label = field.label;
    this.position = field.position;
    return this;
  }
}

export class ObservableScale {
  id: string;
  report: string;
  @observable name: string;
  @observable expression: string;
  @observable categories: IObservableArray<Category>;
  @observable fields: IObservableArray<ObservableScaleField>;
  @observable isLoaded = false;

  constructor(scale: ScaleSummary | Scale) {
    this.id = scale.id;
    this.report = scale.report;
    this.name = scale.name;
    this.expression = scale.expression;
    this.categories = observable.array(scale.categories);
    if ("fields" in scale) {
      this.fields = observable.array(scale.fields.map((f) => new ObservableScaleField(f)));
    } else {
      this.fields = observable.array([]);
    }
    makeObservable(this);
  }

  @computed get sortedFields() {
    return this.fields?.toSorted((a, b) => a.position - b.position);
  }

  @action merge(scale: ScaleSummary) {
    this.name = scale.name;
    this.expression = scale.expression;
    this.categories.replace(scale.categories);
    return this;
  }

  @action mergeFields(field: ScaleField[]) {
    this.isLoaded = true;
    this.fields.replace(field.map((f) => scalesStore.saveField(f, this)));
  }

  @action saveFields(fields: ScaleField[]) {
    this.isLoaded = true;
    return fields.map((f) => scalesStore.saveField(f, this));
  }

  @action cleanupFields(fields: ScaleField[]) {
    const fieldIds = new Set(fields.map((field) => field.id));
    const toDelete = this.fields.filter((field) => !fieldIds.has(field.id));
    for (const field of toDelete) {
      this.fields.remove(field);
    }
  }

}

class ScalesStore {
  @observable scales = observable.map<string, ObservableScale>();

  constructor() {
    makeObservable(this);
  }

  find(id: string) {
    return this.scales.get(id);
  }

  exists(scale: ObservableScale) {
    return this.scales.has(scale.id);
  }

  @action save(scale: ScaleSummary) {
    const observable = new ObservableScale(scale);
    this.scales.set(scale.id, observable);
    return observable;
  }

  @action saveField(field: ScaleField, scale: ObservableScale) {
    const observableField = scale.fields.find((f) => f.id === field.id);
    if (observableField) {
      return observableField.merge(field);
    } else {
      const newField = new ObservableScaleField(field);
      scale.fields.push(newField);
      return newField;
    }
  }

  @action delete(scale: ObservableScale) {
    return this.scales.delete(scale.id);
  }

  @action clearStore() {
    this.scales.clear();
  }

  @action subscribe(scale: ScaleSummary) {
    const observable = this.find(scale.id);
    if (observable) {
      return observable.merge(scale);
    } else {
      return this.save(scale);
    }
  }
}

export const scalesStore = new ScalesStore();
