import { compareAsc } from "date-fns";
import { ERROR_MISSING_REL, ERROR_NOT_UNIQUE, expand, expandAt, pb } from "../../pocketbase";
import { GroupRecordSchema } from "../group/group";
import { createUser } from "../user/user";
import {
  createSummaryReport,
  createReportSharedGroup,
  createReportSharedPersonal,
  type CreateReport,
  type ReportSummary,
  type ReportRecord,
  type EvaluationRecord,
  type ScaleRecord,
  createEvaluationSummary,
  createReportCategory,
  createScaleSummary,
  type EvaluationSummary,
  type ScaleSummary,
  type CreateScale,
  type Category,
  type CreateEvaluation,
  type Evaluation,
  createEvaluation,
  createEvaluationFieldAttachment,
  createEvaluationFieldNumericBox,
  createEvaluationFieldResultInteger,
  createEvaluationFieldResultPercentage,
  createEvaluationFieldTextBox,
  createEvaluationFieldUrl,
  createEvaluationFieldYesOrNo,
  createEvaluationSection,
  type CreateEvaluationSection,
  type EvaluationSectionSummary,
  createEvaluationSectionSummary,
  type EvaluationSection,
  type EvaluationSectionRecord,
  type UpdateEvaluationSection,
  type UpdateEvaluation,
  type CreateEvaluationFieldAttachment,
  type EvaluationFieldAttachment,
  type UpdateEvaluationFieldAttachment,
  type CreateEvaluationFieldNumericBox,
  type EvaluationFieldNumericBox,
  type UpdateEvaluationFieldNumericBox,
  type EvaluationFieldTextBox,
  type CreateEvaluationFieldTextBox,
  type UpdateEvaluationFieldTextBox,
  type EvaluationFieldResultInteger,
  type UpdateEvaluationFieldResultInteger,
  type CreateEvaluationFieldResultInteger,
  type CreateEvaluationFieldResultPercentage,
  type EvaluationFieldResultPercentage,
  type UpdateEvaluationFieldResultPercentage,
  type EvaluationFieldUrl,
  type UpdateEvaluationFieldUrl,
  type CreateEvaluationFieldUrl,
  type EvaluationFieldYesOrNo,
  type UpdateEvaluationFieldYesOrNo,
  type CreateEvaluationFieldYesOrNo,
  type Scale,
  createScaleFieldSummary,
  createScaleOption,
  createScaleField,
  createScale,
  type CreateScaleField,
  type ScaleFieldSummary,
  type ScaleFieldRecord,
  type ScaleField,
  type UpdateScale,
  type UpdateScaleField,
  type CreateScaleOption,
  type ScaleOption,
  type UpdateScaleOption,
  EvaluationFieldType,
  createEvaluationFieldScale,
  type EvaluationFieldScale,
  type CreateEvaluationFieldScale,
  type UpdateReport,
  type EvaluationWithValues,
  createEvaluationWithValues,
  createFieldValueNumericBox,
  createFieldValueResultInteger,
  createFieldValueResultPercentage,
  createFieldValueTextBox,
  createFieldValueUrl,
  createFieldValueYesOrNo,
  createValueTextBox,
  createEvaluationSectionWithValues,
  createValueUrl,
  createValueResultPercentage,
  createValueResultInteger,
  createValueNumericBox,
  createValueYesOrNo,
  createFieldValueAttachment,
  type CreateValueTextBox,
  type ValueTextBox,
  type UpdateValueTextBox,
  type ValueNumericBox,
  type ValueResultInteger,
  type CreateValueNumericBox,
  type CreateValueResultInteger,
  type UpdateValueResultInteger,
  type UpdateValueNumericBox,
  type UpdateValueResultPercentage,
  type ValueResultPercentage,
  type CreateValueResultPercentage,
  type CreateValueUrl,
  type ValueUrl,
  type UpdateValueUrl,
  type CreateValueYesOrNo,
  type ValueYesOrNo,
  type UpdateValueYesOrNo,
  type UpdateValueAttachment,
  type ValueAttachment,
  createValueAttachment,
  type ValueAttachmentRecord,
  type CreateValueAttachment,
  createFieldValueScale,
  createValueScale,
  type ValueScale,
  type UpdateValueScale,
  type CreateValueScale,
  type CreateCategory,
  type ReportSharedGroupRecord,
} from "./report";
import type { UserShareAction } from "../../stores/userShare.store";
import { createAccess } from "../group/access";
import { ClientResponseError } from "pocketbase";
import { ErrorInsufficientPermissions, ErrorUnknown } from "../../error";
import {
  ErrorEvaluationFieldNotFound,
  ErrorEvaluationNotFound,
  ErrorEvaluationScaleNotFound,
  ErrorEvaluationSectionNotFound,
  ErrorPatientEvaluationNotFound,
  ErrorReportCategoryExists,
  ErrorReportCategoryNotFound,
  ErrorReportInviteExists,
  ErrorReportNotFound,
  ErrorScaleFieldNotFound,
  ErrorScaleNotFound,
  ErrorScaleOptionNotFound,
} from "./errors";
import { ErrorGroupNotFound } from "../group/errors";
import { th } from "date-fns/locale";

export class ReportRepository {
  async listReports(): Promise<ReportSummary[]> {
    const results = await pb.send<ReportRecord[]>("/api/v1/reports", {
      method: "GET",
    });

    return results.map((r) => {
      return createSummaryReport({
        ...r,
        owner: createUser(r.expand?.owner),
        sharedGroup: expandAt(r, "reports_shared_group_via_report").map((item) => {
          return createReportSharedGroup({
            ...item,
            group: GroupRecordSchema.parse(item.expand?.group),
          });
        }),
        sharedPersonal: expandAt(r, "reports_shared_personal_via_report").map((item) => {
          return createReportSharedPersonal({
            ...item,
            user: createUser(item.expand?.user),
            access: createAccess(item.expand?.access),
          });
        }),
      });
      // expand thingies
    });
  }

  async getReport(id: string): Promise<ReportSummary> {
    try {
      const result = await pb.collection<ReportRecord>("reports").getFirstListItem(`id = "${id}"`, {
        expand: expand(
          "owner",
          "reports_shared_group_via_report.group",
          "reports_shared_personal_via_report.user",
          "reports_shared_personal_via_report.access",
        ),
      });

      return createSummaryReport({
        ...result,
        owner: createUser(result.expand?.owner),
        sharedGroup: expandAt(result, "reports_shared_group_via_report").map((item) => {
          return createReportSharedGroup({
            ...item,
            group: GroupRecordSchema.parse(item.expand?.group),
          });
        }),
        sharedPersonal: expandAt(result, "reports_shared_personal_via_report").map((item) => {
          return createReportSharedPersonal({
            ...item,
            user: createUser(item.expand?.user),
            access: createAccess(item.expand?.access),
          });
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorReportNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async getReportCategories(id: string): Promise<Category[]> {
    try {
      const result = await pb.collection<Category>("reports_category").getFullList({
        filter: `report = "${id}"`,
        sort: "+category",
        expand: "evaluations_category_via_category.category, scales_category_via_category.category",
      });

      return result
        .map((c) => {
          const reportsCount = expandAt(c, "evaluations_category_via_category").length;
          const scalesCount = expandAt(c, "scales_category_via_category").length;
          return createReportCategory({
            ...c,
            count: reportsCount + scalesCount,
          });
        })
        .toSorted((a, b) => {
          return (b?.count || 0) - (a?.count || 0);
        });
    } catch (error) {
      throw new ErrorUnknown(error);
    }
  }

  async createCategory(data: CreateCategory): Promise<Category> {
    try {
      const result = await pb.collection<Category>("reports_category").create(data);
      return createReportCategory(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data.category.code === ERROR_NOT_UNIQUE) {
            throw new ErrorReportCategoryExists();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async removeCategory(id: string) {
    return await pb.collection<Category>("reports_category").delete(id);
  }

  async getEvaluations(id: string): Promise<EvaluationSummary[]> {
    const result = await pb.collection<EvaluationRecord>("evaluations").getFullList({
      filter: `report = "${id}"`,
      sort: "name",
      expand: "evaluations_category_via_evaluation.category",
    });

    return result.map((e) => {
      return createEvaluationSummary({
        ...e,
        categories: expandAt(e, "evaluations_category_via_evaluation").map((c) =>
          createReportCategory(c.expand.category),
        ),
      });
    });
  }

  async updateReport(id: string, data: UpdateReport): Promise<ReportSummary> {
    try {
      const result = await pb.collection<ReportRecord>("reports").update(id, data, {
        expand: expand(
          "owner",
          "reports_shared_group_via_report.group",
          "reports_shared_personal_via_report.user",
          "reports_shared_personal_via_report.access",
        ),
      });

      return createSummaryReport({
        ...result,
        owner: createUser(result.expand?.owner),
        sharedGroup: expandAt(result, "reports_shared_group_via_report").map((item) => {
          return createReportSharedGroup({
            ...item,
            group: GroupRecordSchema.parse(item.expand?.group),
          });
        }),
        sharedPersonal: expandAt(result, "reports_shared_personal_via_report").map((item) => {
          return createReportSharedPersonal({
            ...item,
            user: createUser(item.expand?.user),
            access: createAccess(item.expand?.access),
          });
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorReportNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async shareReportWithGroup(id: string, groupId: string) {
    try {
      const result = await pb.send<ReportSharedGroupRecord>(`api/v1/reports/${id}/share/group`, {
        method: "POST",
        body: JSON.stringify({
          group: groupId,
        }),
      });
      return createReportSharedGroup({
        ...result,
        group: GroupRecordSchema.parse(result.expand?.group),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 500) {
          if (error.response.data?.group?.code === ERROR_MISSING_REL) {
            throw new ErrorGroupNotFound();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async shareReportWithUsers(id: string, users: UserShareAction[]) {
    try {
      const result = await pb.send<boolean>(`/api/v1/reports/${id}/share`, {
        method: "POST",
        body: JSON.stringify({ users }),
      });
      return result;
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 409) {
          //extract user name from error message
          const pattern = /^(.+?) already has an invitation to join this template library$/;
          const match = error.response.message.match(pattern);
          if (match) {
            throw new ErrorReportInviteExists(match[1].trim());
          }
          throw new ErrorReportInviteExists();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteReport(id: string) {
    try {
      return await pb.collection<ReportRecord>("reports").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorReportNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async getAllScales(id: string): Promise<ScaleSummary[]> {
    const result = await pb.collection<ScaleRecord>("scales").getFullList({
      filter: `report = "${id}" || report = "public"`,
      sort: "name",
      expand: "scales_category_via_scale.category",
    });

    return result.map((s) => {
      return createScaleSummary({
        ...s,
        categories: expandAt(s, "scales_category_via_scale").map((c) => createReportCategory(c.expand.category)),
      });
    });
  }

  async getPrivateScales(): Promise<ScaleSummary[]> {
    const result = await pb.send<ScaleRecord[]>(`/api/v1/reports/privateScales`, {
      method: "GET",
    });

    return result.map((s) => {
      return createScaleSummary(s);
    });
  }

  async getScales(id: string): Promise<ScaleSummary[]> {
    const result = await pb.collection<ScaleRecord>("scales").getFullList({
      filter: `report = "${id}"`,
      sort: "name",
      expand: "scales_category_via_scale.category",
    });

    return result.map((s) => {
      return createScaleSummary({
        ...s,
        categories: expandAt(s, "scales_category_via_scale").map((c) => createReportCategory(c.expand.category)),
      });
    });
  }

  async getScale(id: string): Promise<Scale> {
    try {
      const result = await pb.collection<ScaleRecord>("scales").getFirstListItem(`id = "${id}"`, {
        expand: expand(
          "scales_category_via_scale.category",
          "scale_fields_via_scale",
          "scale_fields_via_scale.scale_field_options_via_field",
        ),
      });

      return createScale({
        ...result,
        categories: expandAt(result, "scales_category_via_scale").map((c) => createReportCategory(c.expand.category)),
        fields: expandAt(result, "scale_fields_via_scale").map((f) => {
          const options = expandAt(f, "scale_field_options_via_field").map((o) => createScaleOption(o));
          options.sort((a, b) => compareAsc(a.created, b.created));
          return createScaleField({
            ...f,
            options,
          });
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorScaleNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createReport(report: CreateReport): Promise<ReportSummary> {
    try {
      const result = await pb.collection<ReportRecord>("reports").create(
        {
          ...report,
        },
        { expand: "owner" },
      );

      return createSummaryReport({
        ...result,
        owner: createUser(result.expand?.owner),
      });
    } catch (error) {
      throw new ErrorUnknown(error);
    }
  }

  async createScale(data: CreateScale): Promise<ScaleSummary> {
    try {
      const result = await pb.send<ScaleRecord>(`/api/v1/reports/scale`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      return createScaleSummary({
        ...result,
        categories: expandAt(result, "scales_category_via_scale").map((c) => createReportCategory(c.expand.category)),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.report?.code === ERROR_MISSING_REL) {
            throw new ErrorReportNotFound();
          }
          if (error.response.data?.category?.code === ERROR_MISSING_REL) {
            throw new ErrorReportCategoryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateScale(id: string, data: UpdateScale): Promise<ScaleSummary> {
    try {
      const result = await pb.send<ScaleRecord>(`/api/v1/reports/scale/${id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      });

      return createScaleSummary({
        ...result,
        categories: expandAt(result, "scales_category_via_scale").map((c) => createReportCategory(c.expand.category)),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.report?.code === ERROR_MISSING_REL) {
            throw new ErrorReportNotFound();
          }
          if (error.response.data?.category?.code === ERROR_MISSING_REL) {
            throw new ErrorReportCategoryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorEvaluationScaleNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteScale(id: string) {
    try {
      return await pb.collection<ScaleRecord>("scales").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorScaleNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createScaleField(data: CreateScaleField): Promise<ScaleField> {
    try {
      const result = await pb.collection<ScaleFieldRecord>("scale_fields").create(data);
      return createScaleField({ ...result, options: [] });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.scale?.code === ERROR_MISSING_REL) {
            throw new ErrorScaleNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateScaleFieldLabel(id: string, data: UpdateScaleField): Promise<ScaleFieldSummary> {
    try {
      const result = await pb.collection<ScaleFieldRecord>("scale_fields").update(id, { label: data.label });

      return createScaleFieldSummary(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorScaleFieldNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateScaleFieldPosition(
    id1: string,
    data1: UpdateScaleField,
    id2: string,
    data2: UpdateScaleField,
  ): Promise<[ScaleFieldSummary, ScaleFieldSummary]> {
    try {
      const [result1, result2] = await Promise.all([
        pb.collection<ScaleFieldRecord>("scale_fields").update(id1, { position: data1.position }),
        pb.collection<ScaleFieldRecord>("scale_fields").update(id2, { position: data2.position }),
      ]);

      return [createScaleFieldSummary(result1), createScaleFieldSummary(result2)] as const;
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorScaleFieldNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteScaleField(id: string) {
    try {
      return await pb.collection<ScaleFieldRecord>("scale_fields").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorScaleFieldNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createScaleOption(data: CreateScaleOption): Promise<ScaleOption> {
    try {
      const result = await pb.collection<ScaleOption>("scale_field_options").create(data);
      return createScaleOption(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.scale?.code === ERROR_MISSING_REL) {
            throw new ErrorScaleNotFound();
          }
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorScaleFieldNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateScaleOption(id: string, data: UpdateScaleOption): Promise<ScaleOption> {
    try {
      const result = await pb.collection<ScaleOption>("scale_field_options").update(id, data);
      return createScaleOption(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorScaleOptionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteScaleOption(id: string) {
    try {
      return await pb.collection<ScaleOption>("scale_field_options").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorScaleOptionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async listReportCategories(id: string): Promise<Category[]> {
    const result = await pb.collection<Category>("report_categories").getFullList({
      filter: `report = "${id}"`,
    });

    return result.map((c) => createReportCategory(c));
  }

  async leaveReport(id: string) {
    try {
      return pb.collection("reports_shared_personal").delete(id);
    } catch (error) {
      if(error instanceof ClientResponseError) {
        if(error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if(error.status === 404) {
          throw new ErrorReportNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async getEvaluation(id: string): Promise<Evaluation> {
    try {
      const result = await pb.collection<EvaluationRecord>("evaluations").getFirstListItem(`id = "${id}"`, {
        expand: expand(
          "evaluations_category_via_evaluation.category",
          "evaluation_sections_via_evaluation",
          "evaluation_sections_via_evaluation.evaluation_field_attachment_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_numeric_box_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_result_integer_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_result_percentage_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_text_box_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_url_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_yes_or_no_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_scale_via_section",
          "evaluation_sections_via_evaluation.evaluation_field_scale_via_section.scale",
        ),
      });

      return createEvaluation({
        ...result,
        categories: expandAt(result, "evaluations_category_via_evaluation").map((c) =>
          createReportCategory(c.expand.category),
        ),
        sections: expandAt(result, "evaluation_sections_via_evaluation").map((s) => {
          const attachmentFields = expandAt(s, "evaluation_field_attachment_via_section").map((f) =>
            createEvaluationFieldAttachment(f),
          );
          const numericBoxFields = expandAt(s, "evaluation_field_numeric_box_via_section").map((f) =>
            createEvaluationFieldNumericBox(f),
          );
          const resultIntegerFields = expandAt(s, "evaluation_field_result_integer_via_section").map((f) =>
            createEvaluationFieldResultInteger(f),
          );
          const resultPercentageFields = expandAt(s, "evaluation_field_result_percentage_via_section").map((f) =>
            createEvaluationFieldResultPercentage(f),
          );
          const textBoxFields = expandAt(s, "evaluation_field_text_box_via_section").map((f) =>
            createEvaluationFieldTextBox(f),
          );
          const urlFields = expandAt(s, "evaluation_field_url_via_section").map((f) => createEvaluationFieldUrl(f));
          const yesOrNoFields = expandAt(s, "evaluation_field_yes_or_no_via_section").map((f) =>
            createEvaluationFieldYesOrNo(f),
          );
          const scaleFields = expandAt(s, "evaluation_field_scale_via_section").map((f) => {
            const scale = expandAt(f, "scale")[0];
            return createEvaluationFieldScale({
              ...f,
              scale: createScaleSummary({
                ...scale,
                categories: [],
              }),
            });
          });

          const fields = [
            ...attachmentFields,
            ...numericBoxFields,
            ...resultIntegerFields,
            ...resultPercentageFields,
            ...textBoxFields,
            ...urlFields,
            ...yesOrNoFields,
            ...scaleFields,
          ];

          //order by created
          fields.sort((a, b) => compareAsc(a.created, b.created));

          return createEvaluationSection({
            ...s,
            fields,
          });
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async loadEvaluationWithValues(id: string): Promise<EvaluationWithValues> {
    try {
      const result = await pb.send(`/api/v1/reports/patient/evaluation/${id}`, {});

      const evaluation = expandAt(result, "evaluation")[0];

      return createEvaluationWithValues({
        ...evaluation,
        categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
          createReportCategory(c.expand.category),
        ),
        sections: expandAt(evaluation, "evaluation_sections_via_evaluation").map((s) => {
          const attachmentFields = expandAt(s, "evaluation_field_attachment_via_section").map((f) => {
            if (f.value && f.value.content) {
              const content = f.value.content.map((filename: string) => pb.getFileUrl(f.value, filename));
              return createFieldValueAttachment({ ...f, value: { ...f.value, content } });
            }
            return createFieldValueAttachment(f);
          });
          const numericBoxFields = expandAt(s, "evaluation_field_numeric_box_via_section").map((f) => {
            return createFieldValueNumericBox(f);
          });
          const resultIntegerFields = expandAt(s, "evaluation_field_result_integer_via_section").map((f) => {
            return createFieldValueResultInteger(f);
          });
          const resultPercentageFields = expandAt(s, "evaluation_field_result_percentage_via_section").map((f) => {
            return createFieldValueResultPercentage(f);
          });
          const textBoxFields = expandAt(s, "evaluation_field_text_box_via_section").map((f) => {
            return createFieldValueTextBox(f);
          });
          const urlFields = expandAt(s, "evaluation_field_url_via_section").map((f) => {
            return createFieldValueUrl(f);
          });
          const yesOrNoFields = expandAt(s, "evaluation_field_yes_or_no_via_section").map((f) => {
            return createFieldValueYesOrNo(f);
          });
          const scaleFields = expandAt(s, "evaluation_field_scale_via_section").map((f) => {
            const scale = expandAt(f, "scale")[0];
            const value = f.value;
            if (value) {
              const parsedValue = value.map((v: any) => {
                return createValueScale({
                  ...v,
                  field: createScaleFieldSummary(v.expand?.field),
                  value: createScaleOption(v.expand?.value),
                });
              });
              return createFieldValueScale({
                ...f,
                scale: createScaleSummary({
                  ...scale,
                  categories: [],
                }),
                value: parsedValue,
              });
            }
            return createFieldValueScale({
              ...f,
              scale: createScaleSummary({
                ...scale,
                categories: [],
              }),
            });
          });

          const fields = [
            ...attachmentFields,
            ...numericBoxFields,
            ...resultIntegerFields,
            ...resultPercentageFields,
            ...textBoxFields,
            ...urlFields,
            ...yesOrNoFields,
            ...scaleFields,
          ];

          //order by created
          fields.sort((a, b) => compareAsc(a.created, b.created));

          return createEvaluationSectionWithValues({
            ...s,
            fields,
          });
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.key?.code === "patient_evaluation_not_found") {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluation(data: CreateEvaluation): Promise<EvaluationSummary> {
    try {
      const result = await pb.send<EvaluationRecord>(`/api/v1/reports/evaluation`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      return createEvaluationSummary({
        ...result,
        categories: expandAt(result, "evaluations_category_via_evaluation").map((c) =>
          createReportCategory(c.expand.category),
        ),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.report?.code === ERROR_MISSING_REL) {
            throw new ErrorReportNotFound();
          }
          if (error.response.data?.category?.code === ERROR_MISSING_REL) {
            throw new ErrorReportCategoryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluation(id: string, data: UpdateEvaluation): Promise<EvaluationSummary> {
    try {
      const result = await pb.send<EvaluationRecord>(`/api/v1/reports/evaluation/${id}`, {
        method: "PATCH",
        body: JSON.stringify(data),
      });

      return createEvaluationSummary({
        ...result,
        categories: expandAt(result, "evaluations_category_via_evaluation").map((c) =>
          createReportCategory(c.expand.category),
        ),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.report?.code === ERROR_MISSING_REL) {
            throw new ErrorReportNotFound();
          }
          if (error.response.data?.category?.code === ERROR_MISSING_REL) {
            throw new ErrorReportCategoryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorEvaluationNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteEvaluation(id: string) {
    try {
      await pb.collection<EvaluationRecord>("evaluations").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorEvaluationNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationSection(data: CreateEvaluationSection): Promise<EvaluationSection> {
    try {
      const result = await pb
        .collection<EvaluationSectionRecord>("evaluation_sections")
        .create({ evaluation: data.evaluation, label: data.label, position: data.position });

      return createEvaluationSection({ ...result, fields: [] });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data.evaluation.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationSectionLabel(id: string, data: UpdateEvaluationSection): Promise<EvaluationSectionSummary> {
    try {
      const result = await pb
        .collection<EvaluationSectionRecord>("evaluation_sections")
        .update(id, { label: data.label, position: data.position });

      return createEvaluationSectionSummary(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data.evaluation.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorEvaluationSectionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationSectionPosition(
    id1: string,
    data1: UpdateEvaluationSection,
    id2: string,
    data2: UpdateEvaluationSection,
  ) {
    const [result1, result2] = await Promise.all([
      pb.collection<EvaluationSectionRecord>("evaluation_sections").update(id1, { position: data1.position }),
      pb.collection<EvaluationSectionRecord>("evaluation_sections").update(id2, { position: data2.position }),
    ]);

    return [createEvaluationSectionSummary(result1), createEvaluationSectionSummary(result2)] as const;
  }

  async deleteEvaluationSection(id: string) {
    try {
      await pb.collection<EvaluationSectionRecord>("evaluation_sections").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorEvaluationSectionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldAttachment(data: CreateEvaluationFieldAttachment): Promise<EvaluationFieldAttachment> {
    try {
      const result = await pb.collection<EvaluationFieldAttachment>("evaluation_field_attachment").create(data);
      return createEvaluationFieldAttachment(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldNumericBox(data: CreateEvaluationFieldNumericBox): Promise<EvaluationFieldNumericBox> {
    try {
      const result = await pb.collection<EvaluationFieldNumericBox>("evaluation_field_numeric_box").create(data);
      return createEvaluationFieldNumericBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldTextBox(data: CreateEvaluationFieldTextBox): Promise<EvaluationFieldTextBox> {
    try {
      const result = await pb.collection<EvaluationFieldTextBox>("evaluation_field_text_box").create(data);
      return createEvaluationFieldTextBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldResultInteger(
    data: CreateEvaluationFieldResultInteger,
  ): Promise<EvaluationFieldResultInteger> {
    try {
      const result = await pb.collection<EvaluationFieldResultInteger>("evaluation_field_result_integer").create({
        ...data,
      });

      return createEvaluationFieldResultInteger(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldResultPercentage(
    data: CreateEvaluationFieldResultPercentage,
  ): Promise<EvaluationFieldResultPercentage> {
    try {
      const result = await pb
        .collection<EvaluationFieldResultPercentage>("evaluation_field_result_percentage")
        .create(data);
      return createEvaluationFieldResultPercentage(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldScale(data: CreateEvaluationFieldScale): Promise<EvaluationFieldScale> {
    try {
      const result = await pb
        .collection<EvaluationFieldScale>("evaluation_field_scale")
        .create(data, { expand: "scale" });

      const scale = expandAt(result, "scale")[0];

      return createEvaluationFieldScale({ ...result, scale: createScaleSummary({ ...scale, categories: [] }) });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
          if (error.response.data?.scale?.code === ERROR_MISSING_REL) {
            throw new ErrorScaleNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldUrl(data: CreateEvaluationFieldUrl): Promise<EvaluationFieldUrl> {
    try {
      const result = await pb.collection<EvaluationFieldUrl>("evaluation_field_url").create(data);
      return createEvaluationFieldUrl(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEvaluationFieldYesOrNo(data: CreateEvaluationFieldYesOrNo): Promise<EvaluationFieldYesOrNo> {
    try {
      const result = await pb.collection<EvaluationFieldYesOrNo>("evaluation_field_yes_or_no").create(data);

      return createEvaluationFieldYesOrNo(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.evaluation?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationNotFound();
          }
          if (error.response.data?.section?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationSectionNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldAttachment(
    id: string,
    data: UpdateEvaluationFieldAttachment,
  ): Promise<EvaluationFieldAttachment> {
    try {
      const result = await pb.collection<EvaluationFieldAttachment>("evaluation_field_attachment").update(id, {
        label: data.label,
      });

      return createEvaluationFieldAttachment(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldNumericBox(
    id: string,
    data: UpdateEvaluationFieldNumericBox,
  ): Promise<EvaluationFieldNumericBox> {
    try {
      const result = await pb.collection<EvaluationFieldNumericBox>("evaluation_field_numeric_box").update(id, {
        label: data.label,
      });

      return createEvaluationFieldNumericBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldTextBox(id: string, data: UpdateEvaluationFieldTextBox): Promise<EvaluationFieldTextBox> {
    try {
      const result = await pb.collection<EvaluationFieldTextBox>("evaluation_field_text_box").update(id, {
        label: data.label,
      });

      return createEvaluationFieldTextBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldResultInteger(
    id: string,
    data: UpdateEvaluationFieldResultInteger,
  ): Promise<EvaluationFieldResultInteger> {
    try {
      const result = await pb.collection<EvaluationFieldResultInteger>("evaluation_field_result_integer").update(id, {
        label: data.label,
        minimum: data.minimum,
        maximum: data.maximum,
      });

      return createEvaluationFieldResultInteger(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldResultPercentage(
    id: string,
    data: UpdateEvaluationFieldResultPercentage,
  ): Promise<EvaluationFieldResultPercentage> {
    try {
      const result = await pb
        .collection<EvaluationFieldResultPercentage>("evaluation_field_result_percentage")
        .update(id, {
          label: data.label,
          minimum: data.minimum,
          maximum: data.maximum,
        });

      return createEvaluationFieldResultPercentage(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldUrl(id: string, data: UpdateEvaluationFieldUrl): Promise<EvaluationFieldUrl> {
    try {
      const result = await pb.collection<EvaluationFieldUrl>("evaluation_field_url").update(id, {
        label: data.label,
      });

      return createEvaluationFieldUrl(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateEvaluationFieldYesOrNo(id: string, data: UpdateEvaluationFieldYesOrNo): Promise<EvaluationFieldYesOrNo> {
    try {
      const result = await pb.collection<EvaluationFieldYesOrNo>("evaluation_field_yes_or_no").update(id, {
        label: data.label,
        option1: data.option1,
        option2: data.option2,
        option3: data.option3,
        option4: data.option4,
        option5: data.option5,
      });

      return createEvaluationFieldYesOrNo(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteEvaluationField(id: string, collection: EvaluationFieldType) {
    try {
      switch (collection) {
        case EvaluationFieldType.Attachment:
          await pb.collection<EvaluationFieldAttachment>("evaluation_field_attachment").delete(id);
          break;
        case EvaluationFieldType.NumericBox:
          await pb.collection<EvaluationFieldNumericBox>("evaluation_field_numeric_box").delete(id);
          break;
        case EvaluationFieldType.ResultInteger:
          await pb.collection<EvaluationFieldResultInteger>("evaluation_field_result_integer").delete(id);
          break;
        case EvaluationFieldType.ResultPercentage:
          await pb.collection<EvaluationFieldResultPercentage>("evaluation_field_result_percentage").delete(id);
          break;
        case EvaluationFieldType.Scale:
          await pb.collection<EvaluationFieldScale>("evaluation_field_scale").delete(id);
          break;
        case EvaluationFieldType.TextBox:
          await pb.collection<EvaluationFieldTextBox>("evaluation_field_text_box").delete(id);
          break;
        case EvaluationFieldType.Url:
          await pb.collection<EvaluationFieldUrl>("evaluation_field_url").delete(id);
          break;
        case EvaluationFieldType.YesOrNo:
          await pb.collection<EvaluationFieldYesOrNo>("evaluation_field_yes_or_no").delete(id);
          break;
        default:
          throw new Error("Invalid collection");
      }
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueAttachment(data: CreateValueAttachment): Promise<ValueAttachment> {
    try {
      const result = await pb.collection("value_evaluation_field_attachment").create(data);
      return createValueAttachment(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueNumericBox(data: CreateValueNumericBox): Promise<ValueNumericBox> {
    try {
      const result = await pb.collection("value_evaluation_field_numeric_box").create(data);
      return createValueNumericBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueResultInteger(data: CreateValueResultInteger): Promise<ValueResultInteger> {
    try {
      const result = await pb.collection("value_evaluation_field_result_integer").create(data);
      return createValueResultInteger(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueResultPercentage(data: CreateValueResultPercentage): Promise<ValueResultPercentage> {
    try {
      const result = await pb.collection("value_evaluation_field_result_percentage").create(data);
      return createValueResultPercentage(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueTextBox(data: CreateValueTextBox): Promise<ValueTextBox> {
    try {
      const result = await pb.collection("value_evaluation_field_text_box").create(data);
      return createValueTextBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueUrl(data: CreateValueUrl): Promise<ValueUrl> {
    try {
      const result = await pb.collection("value_evaluation_field_url").create(data);
      return createValueUrl(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueYesOrNo(data: CreateValueYesOrNo): Promise<ValueYesOrNo> {
    try {
      const result = await pb.collection("value_evaluation_field_yes_or_no").create(data);
      return createValueYesOrNo(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createValueScale(data: CreateValueScale): Promise<ValueScale> {
    try {
      const result = await pb.collection("value_evaluation_field_scale").create(data, {
        expand: "field,value",
      });
      return createValueScale({
        ...result,
        field: createScaleFieldSummary(result.expand?.field),
        value: createScaleOption(result.expand?.value),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueAttachment(id: string, data: UpdateValueAttachment): Promise<ValueAttachment> {
    try {
      const result = await pb.collection<ValueAttachmentRecord>("value_evaluation_field_attachment").update(id, data);
      return createValueAttachment(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueNumericBox(id: string, data: UpdateValueNumericBox): Promise<ValueNumericBox> {
    try {
      const result = await pb.collection("value_evaluation_field_numeric_box").update(id, data);
      return createValueNumericBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueResultInteger(id: string, data: UpdateValueResultInteger): Promise<ValueResultInteger> {
    try {
      const result = await pb.collection("value_evaluation_field_result_integer").update(id, data);
      return createValueResultInteger(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueResultPercentage(id: string, data: UpdateValueResultPercentage): Promise<ValueResultPercentage> {
    try {
      const result = await pb.collection("value_evaluation_field_result_percentage").update(id, data);
      return createValueResultPercentage(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueTextBox(id: string, data: UpdateValueTextBox): Promise<ValueTextBox> {
    try {
      const result = await pb.collection("value_evaluation_field_text_box").update(id, data);
      return createValueTextBox(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueUrl(id: string, data: UpdateValueUrl): Promise<ValueUrl> {
    try {
      const result = await pb.collection("value_evaluation_field_url").update(id, data);
      return createValueUrl(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueYesOrNo(id: string, data: UpdateValueYesOrNo): Promise<ValueYesOrNo> {
    try {
      const result = await pb.collection("value_evaluation_field_yes_or_no").update(id, data);
      return createValueYesOrNo(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationFieldNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.field?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationFieldNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateValueScale(id: string, data: UpdateValueScale): Promise<ValueScale> {
    try {
      const result = await pb.collection("value_evaluation_field_scale").update(id, data, {
        expand: "field,value",
      });
      return createValueScale({
        ...result,
        field: createScaleFieldSummary(result.expand?.field),
        value: createScaleOption(result.expand?.value),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorEvaluationScaleNotFound();
        }
        if (error.status === 400) {
          if (error.response.data?.scale?.code === ERROR_MISSING_REL) {
            throw new ErrorEvaluationScaleNotFound();
          }
          if (error.response.data?.["patient_evaluation"].code === ERROR_MISSING_REL) {
            throw new ErrorPatientEvaluationNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }
}

export const reportRepository = new ReportRepository();
