import { z } from "zod";
import { UserSchema } from "../user/user";
import { withThumb } from "../../pocketbase";
import { record } from "../shared/record";
import { file } from "../shared/file";
import { unsafeParse } from "../shared/unsafeParse";
import { AccessSchema } from "../group/access";
import { ExerciseSchema, PublicExerciseSchema } from "../library/exercises";
import { msg } from "@lit/localize";

export const TemplateExercisePlanShareRecordSchema = record({
  template: z.string(),
  user: z.string(),
  access: z.string(),
});
export interface TemplateExercisePlanShareRecord extends z.infer<typeof TemplateExercisePlanShareRecordSchema> {}

export const TemplateExerciseShareSchema = TemplateExercisePlanShareRecordSchema.extend({
  user: UserSchema,
  access: AccessSchema,
}).omit({ expand: true });
export interface TemplateExercisePlanShare extends z.infer<typeof TemplateExerciseShareSchema> {}
export function createTemplateExerciseShare(data: Record<string, unknown>) {
  return unsafeParse(TemplateExerciseShareSchema, data);
}

export const TemplateExercisePlanExerciseRecordSchema = record({
  template: z.string(),
  exercise: z.string().optional(),
  exercisePublic: z.string().optional(),
  series: z.number().optional(),
  repetitions: z.string().optional(),
  weight: z.number().optional(),
  weightUnit: z.string().optional(),
  rest: z.number().optional(),
  duration: z.number().optional(),
  description: z.string().optional(),
});
export interface TemplateExercisePlanExerciseRecord extends z.infer<typeof TemplateExercisePlanExerciseRecordSchema> {}

export const TemplateExercisePlanExerciseSchema = TemplateExercisePlanExerciseRecordSchema.extend({
  exercise: ExerciseSchema.optional(),
  exercisePublic: PublicExerciseSchema.optional(),
}).omit({ expand: true });
export interface TemplateExercisePlanExercise extends z.infer<typeof TemplateExercisePlanExerciseSchema> {}
export function createTemplateExercise(data: Record<string, unknown>) {
  return unsafeParse(TemplateExercisePlanExerciseSchema, data);
}

export const CreateTemplateExercisePlanExerciseSchema = () =>
  z.object({
    template: z.string(),
    exercise: z.string().optional(),
    exercisePublic: z.string().optional(),
    series: z.preprocess(Number, z.number()).optional(),
    repetitions: z.string().optional(),
    weight: z.preprocess(Number, z.number()).optional(),
    weightUnit: z.string().optional(),
    rest: z.preprocess(Number, z.number()).optional(),
    duration: z.preprocess(Number, z.number()).optional(),
    description: z.string().optional(),
  });
export interface CreateTemplateExercisePlanExercise
  extends z.infer<ReturnType<typeof CreateTemplateExercisePlanExerciseSchema>> {}

export const UpdateTemplateExercisePlanExerciseSchema = () =>
  z.object({
    exercise: z.string().optional().nullable(),
    exercisePublic: z.string().optional().nullable(),
    series: z.preprocess(Number, z.number()).optional(),
    repetitions: z.string().optional(),
    weight: z.preprocess(Number, z.number()).optional(),
    weightUnit: z.string().optional(),
    rest: z.preprocess(Number, z.number()).optional(),
    duration: z.preprocess(Number, z.number()).optional(),
    description: z.string().optional(),
  });
export interface UpdateTemplateExercisePlanExercise
  extends z.infer<ReturnType<typeof UpdateTemplateExercisePlanExerciseSchema>> {}

export const TemplateExercisePlanRecordSchema = record({
  name: z.string(),
  owner: z.string(),
});
export interface TemplateExercisePlanRecord extends z.infer<typeof TemplateExercisePlanRecordSchema> {}

export const TemplateExercisePlanSchema = TemplateExercisePlanRecordSchema.extend({
  owner: UserSchema,
  thumbnail: z.string(),
  shared: z.array(TemplateExerciseShareSchema).default([]),
  exercises: z.array(TemplateExercisePlanExerciseSchema).default([]),
  totalExercises: z.number().default(0),
}).omit({ expand: true });
export interface TemplateExercisePlan extends z.infer<typeof TemplateExercisePlanSchema> {}
export function createTemplateExercisePlan(data: Record<string, unknown>) {
  return unsafeParse(TemplateExercisePlanSchema, withThumb(data, "thumbnail"));
}

export const CreateTemplateExercisePlanSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("O nome é obrigatório") })
      .min(3, { message: msg("O nome deve ter pelo menos 3 caracteres") })
      .max(50, { message: msg("O nome deve ter no máximo 50 caracteres") }),
    owner: z.string(),
    thumbnail: file(
      {
        size: 10 * 1024 * 1024,
        allowedFileTypes: [
          "image/jpeg",
          "image/jpg",
          "image/avif",
          "image/png",
          "image/webp",
          "image/heic",
          "image/heif",
          "image/gif",
        ],
      },
      msg("O thumbnail deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif"),
    ).optional(),
  });
export interface CreateTemplateExercisePlan extends z.infer<ReturnType<typeof CreateTemplateExercisePlanSchema>> {}

export const UpdateTemplateExercisePlanSchema = () => z.object({
  name: z
    .string({ required_error: msg("O nome é obrigatório") })
    .min(3, { message: msg("O nome deve ter pelo menos 3 caracteres") })
    .max(50, { message: msg("O nome deve ter no máximo 50 caracteres") })
    .optional(),
  owner: z.string().optional(),
  thumbnail: file(
    {
      size: 10 * 1024 * 1024,
      allowedFileTypes: [
        "image/jpeg",
        "image/jpg",
        "image/avif",
        "image/png",
        "image/webp",
        "image/heic",
        "image/heif",
        "image/gif",
      ],
    },
    msg("O thumbnail deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif"),
  ).optional(),
});
export interface UpdateTemplateExercisePlan extends z.infer<ReturnType<typeof UpdateTemplateExercisePlanSchema>> {}
