import { customElement, state } from "lit/decorators.js";
import { html, nothing } from "lit";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import { librariesStore } from "../stores/libraries.store";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { routerContext, RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { pb } from "../pocketbase";
import { localized, msg } from "@lit/localize";

interface Content {
  file: File;
  content: string;
}

@customElement("page-public-exercise")
@localized()
export class PagePublicExercise extends Page {
  @state() private _id: string = "";

  @state() _content: Content[] = [];

  #modalShowExerciseContentRef: Ref<ModalNav> = createRef();

  @consume({ context: routerContext }) private router!: RouterContext;

  connectedCallback() {
    super.connectedCallback();
    this._id = this.router.getParam("id");
  }

  #loadExercise = new Task(this, {
    task: async () => {
      return await librariesStore.loadPublicExercise(this._id);
    },
    args: () => [this._id],
    onComplete: async (exercise) => {
      if (exercise.thumbnail) {
        const thumb = pb.files.getUrl(exercise, exercise.thumbnail);
        await this.fetchAndSetThumbnail(thumb);
        // await this.fetchAndSetThumbnail(exercise.thumbnail);
      }
      if (exercise.content) {
        exercise.content.forEach((c) => {
          this.fetchAndSetContent(c);
        });
      }
    },
  });

  // Helper function to fetch and set thumbnail
  async fetchAndSetThumbnail(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split("/").pop()?.split("?")[0] || "file";
      const file = new File([blob], fileName, { type: blob.type });

      // Update the button's background image
      const button = this.renderRoot.querySelector("#thumbnail") as HTMLButtonElement;
      const imageUrl = URL.createObjectURL(file);
      button.style.backgroundImage = `url(${imageUrl})`;
      button.style.backgroundSize = "contain";
      button.style.backgroundPosition = "center";
      button.style.backgroundRepeat = "no-repeat";
    } catch (error) {
      console.error("Error fetching thumbnail:", error);
    }
  }

  // Helper function to fetch and set content
  async fetchAndSetContent(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split("/").pop()?.split("?")[0] || "file";
      const file = new File([blob], fileName, { type: blob.type });

      this._content.push({
        file: file,
        content: URL.createObjectURL(file),
      });
      this.requestUpdate();
    } catch (error) {
      console.error("Error fetching content:", error);
    }
  }

  render() {
    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-router-link href="${this.router.backUrl}" router-direction="back">
                <ion-button
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </ion-router-link>
            </div>
          </ion-buttons>
          <ion-title>${msg("Exercício")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        ${this.#loadExercise.render({
          pending: () => {
            return html`
              <div class="space-y-4 mt-4">
                <div class="space-y-1">
                  <span class="font-display font-semibold">
                    <ion-skeleton-text animated style="width: 4em; height: 1em;"></ion-skeleton-text>
                  </span>
                  <ion-skeleton-text animated style="width: 120px; height: 24px;"></ion-skeleton-text>
                </div>
                <div class="space-y-1">
                  <span class="font-display font-semibold">
                    <ion-skeleton-text animated style="width: 4em; height: 1em;"></ion-skeleton-text>
                  </span>
                  <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                </div>
                <div class="space-y-1">
                  <span class="font-display font-semibold">
                    <ion-skeleton-text animated style="width: 4em; height: 1em;"></ion-skeleton-text>
                  </span>
                  <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                </div>
                <div class="space-y-1">
                  <span class="font-display font-semibold">
                    <ion-skeleton-text animated style="width: 4em; height: 1em;"></ion-skeleton-text>
                  </span>
                  <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                </div>
                <div class="space-y-1">
                  <span class="font-display font-semibold">
                    <ion-skeleton-text animated style="width: 4em; height: 1em;"></ion-skeleton-text>
                  </span>
                  <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                </div>
                <div class="space-y-1">
                  <span class="font-display font-semibold">
                    <ion-skeleton-text animated style="width: 4em; height: 1em;"></ion-skeleton-text>
                  </span>
                  <ion-skeleton-text animated style="width: 100%; height: 180px;"></ion-skeleton-text>
                </div>
              </div>
            `;
          },
          error: () => {
            return html`<span class="text-danger">${msg("Erro ao carregar exercício")}</span>`;
          },
          complete: (exercise) => {
            return html`
              <div class="space-y-4 mt-4">
                ${exercise.thumbnail
                  ? html`
                      <div>
                        <span class="font-semibold font-display mb-1.5">${msg("Thumbnail")}</span>
                        <div
                          id="thumbnail"
                          class="w-full h-56 rounded-lg bg-accent-8 border border-solid border-accent-7"
                          aria-label="thumbnail"></div>
                      </div>
                    `
                  : nothing}
                <div class="space-y-1">
                  <span class="font-display font-semibold">${msg("Nome")}</span>
                  <p class="font-medium">${exercise.name}</p>
                </div>
                ${exercise.categories && exercise.categories.length > 0
                  ? html`
                      <div class="space-y-1">
                        <span class="font-display font-semibold">${msg("Categoria")}</span>
                        <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                          ${exercise.categories.map((c) => {
                            return html`
                              <div class="bg-black text-white text-sm px-2.5 py-1.5 rounded-full">
                                <span>${c.category}</span>
                              </div>
                            `;
                          })}
                        </div>
                      </div>
                    `
                  : nothing}
                ${exercise.muscles && exercise.muscles.length > 0
                  ? html`
                      <div class="space-y-1">
                        <span class="font-display font-semibold">${msg("Músculos")}</span>
                        <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                          ${exercise.muscles.map((c) => {
                            return html`
                              <div class="bg-black text-white text-sm px-2.5 py-1.5 rounded-full">
                                <span>${c.muscle}</span>
                              </div>
                            `;
                          })}
                        </div>
                      </div>
                    `
                  : nothing}
                ${exercise.equipments && exercise.equipments.length > 0
                  ? html`
                      <div class="space-y-1">
                        <span class="font-display font-semibold">${msg("Equipamentos")}</span>
                        <div class="flex flex-wrap gap-x-1.5 gap-y-2">
                          ${exercise.equipments.map((c) => {
                            return html`
                              <div class="bg-black text-white text-sm px-2.5 py-1.5 rounded-full">
                                <span>${c.equipment}</span>
                              </div>
                            `;
                          })}
                        </div>
                      </div>
                    `
                  : nothing}
                ${exercise.content
                  ? html`
                      <div>
                        <span class="font-semibold font-display mb-1.5">${msg("Conteúdo")}</span>
                        <div class="flex flex-col space-y-2.5">
                          ${this._content.map((c) => {
                            if (c.file.type.startsWith("video/")) {
                              return html`
                                <div class="w-full">
                                  <video
                                    controls
                                    class="w-full max-w-lg h-[180px] rounded-lg border border-solid border-accent-7">
                                    <source src=${c.content} type=${c.file.type} />
                                  </video>
                                </div>
                              `;
                            } else {
                              return html`
                                <div class="w-full">
                                  <img
                                    loading="lazy"
                                    class="w-full h-auto max-h-96 rounded-lg object-contain border border-solid border-accent-7"
                                    src=${c.content} />
                                </div>
                              `;
                            }
                          })}
                        </div>
                      </div>
                    `
                  : nothing}
              </div>
            `;
          },
        })}
        <modal-nav root="modal-show-exercise-content" ${ref(this.#modalShowExerciseContentRef)}></modal-nav>
      </ion-content>
    `;
  }
}
