import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page } from "../components/component";
import { CreateEvaluationSectionSchema, type CreateEvaluationSection } from "../repository/report/report";
import { alertController } from "@ionic/core";
import { reportsStore } from "../stores/reports.store";
import type { ObservableEvaluation } from "../stores/evaluation.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-create-evaluation-section")
@localized()
export class ModalCreateEvaluationSection extends Page {
  evaluation!: ObservableEvaluation;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  #form = new FinalFormController<CreateEvaluationSection>(this, {
    validate: zodValidator(CreateEvaluationSectionSchema().pick({ label: true })),
    onSubmit: async (values) => {
      try {
        const data: CreateEvaluationSection = {
          evaluation: this.evaluation.id,
          label: values.label,
          position: this.evaluation.sections.length,
        };
        await reportsStore.insertEvaluationSection(data, this.evaluation);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea">
            <ion-buttons slot="start">
              <ion-button @click=${() => this.navigator.close()} class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("fechar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Nome da Secção")}</ion-title>
          </ion-toolbar>
        </ion-header>
        <form
          id="form-evaluation-section-create"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none">
            <div>
              <span class="font-semibold font-display">${msg("Nome")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Secção")}
                  enterkeyhint="send"
                  ${register("label")}>
                </ion-input>
              </ion-item>
              ${renderError("label")}
            </div>
          </ion-list>

          <ion-button
            form="form-evaluation-section-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Adicionar")}</ion-button
          >
        </form>
      </div>
    `;
  }
}
