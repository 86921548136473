import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { patientRepository } from "../repository/patient/patient.repository";
import { when } from "lit/directives/when.js";
import { repeat } from "lit/directives/repeat.js";
import { format } from "date-fns";
import { enUS, pt } from "date-fns/locale";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { localized, msg, str } from "@lit/localize";

@customElement("page-public-patient")
@localized()
export class PagePublicPatient extends Page {
  @state() private _patientId: string = "";

  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #modalPublicSessionRef: Ref<ModalNav> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this._patientId = this.router.getParam("patientId");
  }

  #patient = new Task(this, {
    task: async () => {
      return await patientRepository.getPatientRecord(this._patientId);
    },
    args: () => [],
  });

  calculateAge(birthday: string | undefined) {
    if (!birthday) return;
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  formatDiagnosticDate(dateString: string) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // Pad the month and day with a leading zero if necessary
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDay}`;
  }

  getDayMonth(isoDateString: string) {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    let locale;

    switch (language) {
      case "pt-PT":
        locale = pt;
        break;
      case "pt-BR":
        locale = pt;
        break;
      case "en":
        locale = enUS;
        break;
      default:
        locale = enUS;
        break;
    }
    return format(new Date(isoDateString), "dd MMMM", { locale: locale });
  }

  getDateChip(date: string, startTime: string, endTime: string) {
    const today = new Date();
    const time = today.getHours() + ":" + today.getMinutes();
    const formattedToday = [
      String(today.getDate()).padStart(2, "0"),
      String(today.getMonth() + 1).padStart(2, "0"),
      today.getFullYear(),
    ].join("-");
    if (date === formattedToday && startTime < time && time < endTime) {
      return html`<ion-chip style="--background: #79FFE1; --color: #000000">${msg("A decorrer")}</ion-chip> `;
    }
    return html` <ion-chip class="capitalize font-semibold" style="--background: #EAEAEA; --color: #111111"
      >${this.getDayMonth(date)}</ion-chip
    >`;
  }

  render() {
    return html`
      ${this.#patient.render({
        pending: () => html`
          <div class="flex items-center space-x-3 px-4">
            <ion-skeleton-text animated style="width: 72px; height: 72px;"></ion-skeleton-text>
            <div>
              <ion-skeleton-text animated style="height: 28px; width: 120px"></ion-skeleton-text>
              <div class="flex space-x-1 items-center">
                <ion-skeleton-text animated style="height: 26px; width: 200px"></ion-skeleton-text>
              </div>
            </div>
          </div>
          <div class="space-y-2">
            <div class="mt-1 mb-3 h-1 w-full bg-accent-7"></div>
            <div class="px-4 space-y-2">
              <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
              <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
              <ion-skeleton-text animated style="height: 48px; width: 100%;"></ion-skeleton-text>
            </div>
            <div>
              <div class="mt-3 h-1 w-full bg-accent-7"></div>
            </div>
          </div>

          <div class="space-y-3 px-4">
            <ion-skeleton-text animated style="height: 24px; width: 150px;"></ion-skeleton-text>
            <ion-skeleton-text animated class="h-40"></ion-skeleton-text>
            <ion-skeleton-text animated class="h-10"></ion-skeleton-text>
          </div>
          <div class="mt-3 h-1 w-full bg-accent-7"></div>

          <div class="space-y-3 px-4">
            <ion-skeleton-text animated style="height: 24px; width: 150px;"></ion-skeleton-text>
            <ion-skeleton-text animated class="h-12"></ion-skeleton-text>
            <ion-skeleton-text animated class="h-10"></ion-skeleton-text>
          </div>
        `,
        error: (error) => {
          console.log(error);
          return html`
            <div class="h-[139px] bg-dangerLighter rounded-md col justify-center items-center mt-4 mx-4">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                  stroke="#ff1a1a"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              <span class="font-display font-semibold w-2/3 text-center text-dangerLight"
                >${msg("Ocorreu um erro, por favor tente mais tarde")}</span
              >
            </div>
          `;
        },
        complete: (data) => {
          return html`
            <ion-header>
              <ion-toolbar class="no-border" style="--padding-bottom:16px">
                <div class="flex items-start gap-x-3">
                  <xt-avatar
                    style="--size: 72px; --border-radius: 99px;"
                    src="${data.patient.avatar}"
                    name="${data.patient.name}"></xt-avatar>
                  <div class="self-center">
                    <p class="text-xl font-display font-semibold">${data.patient.name}</p>
                    <div class="flex flex-wrap gap-x-1 items-center">
                      <span class="font-medium">${msg(str`${this.calculateAge(data.patient.birthdate)} anos`)}</span>
                    </div>
                  </div>
                </div>
              </ion-toolbar>
            </ion-header>

            <ion-content fullscreen class="space-y-4 no-px">
              <div class="h-1 w-full bg-accent-7"></div>

              <div class="space-y-2 px-4">
                <span class="font-display font-semibold">${msg("Sessões a decorrer")}</span>
                ${when(
                  data.sessions.length > 0,
                  () => {
                    return html`
                      <div class="space-y-2.5">
                        ${repeat(
                          data.sessions,
                          (session) => session.id,
                          (session) => {
                            return html`
                              <button
                                @click=${() => this.#modalPublicSessionRef.value?.open({ session })}
                                class="w-full h-12 px-3 py-3.5 bg-accent-8 border border-solid border-accent-7 rounded-lg flex justify-between items-center active:bg-accent-7">
                                <div class="space-x-1.5 flex items-center">
                                  <span class="font-semibold text-sm line-clamp-1 text-start">${session.name}</span>
                                  <div class="flex-shrink-0 pr-2">
                                    ${this.getDateChip(session.day, session.startTime, session.endTime)}
                                  </div>
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none">
                                  <path
                                    d="M8.33301 6.66699L11.6663 10.0003L8.33301 13.3337"
                                    stroke="#999999"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              </button>
                            `;
                          },
                        )}
                      </div>
                    `;
                  },
                  () => html`
                    <div class="h-[139px] w-full bg-accent-7 rounded-md col justify-center items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                          stroke="#111111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      <span class="font-display font-semibold w-2/3 text-center">${msg("Não existem sessões de momento")}</span>
                    </div>
                  `,
                )}
              </div>
              <div class="h-1 w-full bg-accent-7"></div>

              <!-- PROFISSIONAIS -->

              <div class="space-y-3 px-4">
                <p class="font-display font-semibold">${msg("Profissionais a cuidado")}</p>
                <span class="mt-0.5 text-sm">${msg("Consulte os profissionais que trabalham com o paciente")}</span>
                <div class="space-y-3">
                  ${when(
                    data.professionals.length > 0,
                    () =>
                      repeat(
                        data.professionals,
                        (professional) => professional.id,
                        (professional) => {
                          return html`
                            <div class="flex space-x-2 items-center">
                              <xt-avatar
                                style="--size: 52px; --border-radius: 6px;"
                                src="${professional.avatar}"
                                name="${professional.name}"></xt-avatar>
                              <div class="col">
                                <span class="font-semibold">${professional.name}</span>
                                <span class="text-accent-5 text-sm">${professional.role}</span>
                              </div>
                            </div>
                          `;
                        },
                      ),
                    () => html`
                      <div class="h-[139px] w-full bg-accent-7 rounded-md col justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                            stroke="#111111"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <span class="font-display font-semibold w-2/3 text-center"
                          >${msg("Não existem profissionais associados de momento")}</span
                        >
                      </div>
                    `,
                  )}
                </div>
              </div>
            </ion-content>

            <modal-nav root="modal-public-session" ${ref(this.#modalPublicSessionRef)}></modal-nav>
          `;
        },
      })}
    `;
  }
}
