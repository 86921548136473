import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { Task, TaskStatus } from "@lit/task";
import { appointmentStore } from "../stores/appointment.store";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { when } from "lit/directives/when.js";
import { Page } from "../components/component";
import { add, format, isToday, isTomorrow } from "date-fns";
import type { IonDatetime } from "@ionic/core/components/ion-datetime";
import type { IonPopover } from "@ionic/core/components/ion-popover";
import { notificationsStore } from "../stores/notifications.store";
import { localized, msg } from "@lit/localize";
import { getDateFnsLocale } from "../date-fns-locale";

function sevenDaysFromNow() {
  const days = [];
  for (let i = 0; i < 7; i++) {
    days.push(format(add(new Date(), { days: i }), "yyyy-MM-dd"));
  }
  return days;
}

@customElement("page-homepage")
@localized()
export class PageHomepage extends Page {
  @state() private selectedDate: string = "";
  @state() private selectedCalendarDates: Array<string> = [];

  sevenDaysFromNow = sevenDaysFromNow();

  // NOTE: this is used to prevent the ion-datetime from firing multiple times
  // when the user selects multiple dates
  hasInitializedEvents = false;

  @consume({ context: authContext }) auth!: AuthContext;

  #modalNotificationsRef: Ref<ModalNav> = createRef();
  #ionRefresher: Ref<IonRefresher> = createRef();
  #calendarRef: Ref<IonDatetime> = createRef();
  #popoverRef: Ref<IonPopover> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this.selectedDate = this.formatDate(new Date());

    this.#appointments.run([this.sevenDaysFromNow]);
    this.#appointmentDays.run();
  }

  #notifications = new Task(this, {
    task: async ([id]) => {
      if (id) await notificationsStore.load(id);
    },
    args: () => [this.auth.user?.id],
  });

  #appointments = new Task(this, {
    task: async ([days]: [string[]]) => {
      const user = this.auth.user;
      if (user) {
        await appointmentStore.loadAppointments(user.id, days);
      }
    },
  });

  #appointmentDays = new Task(this, {
    task: async () => {
      await appointmentStore.loadDates();
    },
    onComplete: () => {
      const calendar = this.#calendarRef.value;
      if (calendar) {
        calendar.highlightedDates = appointmentStore.days;
      }
    },
  });

  getCurrentMonth() {
    return format(new Date(), "MMMM", { locale: getDateFnsLocale(this.auth.user.language) });
  }

  getCurrentDay() {
    return format(new Date(), "EEEE, d MMMM", { locale: getDateFnsLocale(this.auth.user.language) });
  }

  formatDate(date: Date): string {
    return format(date, "yyyy-MM-dd");
  }

  getHoursAndMinutes(date: string) {
    return format(new Date(date), "HH:mm");
  }

  isSelectedDate(date: string) {
    return this.selectedDate === date;
  }

  removeCalendarDate(date: string) {
    this.selectedCalendarDates = this.selectedCalendarDates.filter((d) => d !== date);

    if (this.#calendarRef.value) {
      this.#calendarRef.value.value = this.selectedCalendarDates;
    }
  }

  render() {
    return html`
      <ion-content fullscreen class="no-px" style="--padding-bottom: 24px">
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            const user = this.auth.user;
            if (user) {
              if (this.selectedCalendarDates.length > 0) {
                Promise.all([
                  await this.#appointments.run([this.selectedCalendarDates]),
                  await this.#appointmentDays.run(),
                ]);
              } else if (this.selectedDate.length > 0) {
                Promise.all([await this.#appointments.run([this.sevenDaysFromNow]), await this.#appointmentDays.run()]);
              }
            }

            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        <ion-header>
          <ion-toolbar class="no-border pl-4">
            <div class="flex justify-between items-center">
              <div class="flex items-center space-x-1">
                <h4 class="capitalize">${this.getCurrentMonth()}</h4>
                <button id="datetime-trigger" class="bg-transparent h-8 w-6">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M12.6663 6L7.99967 10.6667L3.33301 6"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </button>
              </div>
              <button
                @click=${() => this.#modalNotificationsRef.value?.open()}
                ?disabled=${this.#notifications.status === TaskStatus.PENDING}
                class="h-8 bg-accent-7 active:bg-accent-6 rounded-full px-2.5 py-1.5 text-accent-1 text-sm font-semibold flex items-center gap-1 relative mr-4">
                ${when(notificationsStore.hasNotifications, () => {
                  return html`
                    <span class="absolute top-0 right-0 flex h-2.5 w-2.5">
                      <span
                        class="animate-ping absolute inline-flex h-full w-full rounded-full bg-warning opacity-75"></span>
                      <span class="relative inline-flex rounded-full h-2.5 w-2.5 bg-warning"></span>
                    </span>
                  `;
                })}
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M10.0003 11.3333V12C10.0003 13.1046 9.10489 14 8.00033 14C6.89576 14 6.00033 13.1046 6.00033 12V11.3333M10.0003 11.3333H6.00033M10.0003 11.3333H12.394C12.649 11.3333 12.7771 11.3333 12.8804 11.2985C13.0776 11.232 13.232 11.0771 13.2985 10.8799C13.3335 10.7762 13.3335 10.6477 13.3335 10.3906C13.3335 10.2782 13.3333 10.2219 13.3245 10.1683C13.3079 10.067 13.2686 9.97089 13.2087 9.88748C13.1771 9.84341 13.1368 9.80318 13.0575 9.72389L12.7979 9.46419C12.7141 9.38041 12.667 9.26676 12.667 9.14827V6.66667C12.667 4.08933 10.5777 1.99999 8.00033 2C5.423 2.00001 3.33366 4.08934 3.33366 6.66667V9.14829C3.33366 9.26678 3.28649 9.38041 3.2027 9.46419L2.94303 9.72386C2.8635 9.8034 2.82369 9.84336 2.79199 9.8875C2.7321 9.97092 2.69242 10.067 2.67579 10.1683C2.66699 10.2219 2.66699 10.2782 2.66699 10.3906C2.66699 10.6477 2.66699 10.7762 2.70196 10.8798C2.76849 11.0771 2.92352 11.232 3.12077 11.2985C3.22403 11.3333 3.35167 11.3333 3.6067 11.3333H6.00033"
                    stroke="#111111"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                ${msg("Avisos")}
              </button>
            </div>
            <span class="text-sm text-accent-4 capitalize">${this.getCurrentDay()}</span>
          </ion-toolbar>
        </ion-header>

        <!-- -------------------SCROLLABLE--------------- -->
        ${this.selectedCalendarDates.length > 0
          ? html`
              <div class="mt-3 w-full">
                <div class="overflow-x-auto flex items-center space-x-1.5 native-scroll pr-4">
                  ${this.selectedCalendarDates.map((date) => {
                    const dateFormatted = format(new Date(date), "dd MMMM", {
                      locale: getDateFnsLocale(this.auth.user.language),
                    });
                    const amount = appointmentStore.appointmentsByDay(date).length;
                    return html`
                      <ion-chip
                        id=${date}
                        color="primary"
                        @click=${() => this.removeCalendarDate(date)}
                        class="first:ml-4 flex-shrink-0 px-2.5 py-1.5 leading-4 font-semibold flex items-center gap-x-1 capitalize">
                        ${dateFormatted} (${amount})
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          class="ml-1">
                          <rect width="12" height="12" rx="6" fill="white" />
                          <path
                            d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                            stroke="black"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-chip>
                    `;
                  })}
                </div>
              </div>
            `
          : html`
              <div class="mt-3 w-full">
                <div class="overflow-x-auto flex items-center space-x-1.5 native-scroll pr-4">
                  ${this.sevenDaysFromNow.map((date) => {
                    const amount = appointmentStore.appointmentsByDay(date).length;
                    return html`
                      <ion-chip
                        id=${date}
                        color=${this.isSelectedDate(date) ? "primary" : "light"}
                        @click=${() => (this.selectedDate = date)}
                        class="first:ml-4 flex-shrink-0 px-2.5 py-1.5 leading-4 font-semibold capitalize">
                        ${when(
                          isToday(date),
                          () => msg("Hoje"),
                          () =>
                            isTomorrow(date)
                              ? msg("Amanhã")
                              : format(new Date(date), "dd MMMM", {
                                  locale: getDateFnsLocale(this.auth.user.language),
                                }),
                        )}
                        (${amount})
                      </ion-chip>
                    `;
                  })}
                </div>
              </div>
            `}
        ${this.#appointments.render({
          pending: () => {
            return html`
              <div class="mt-3 pl-4 w-full">
                <div class="overflow-x-auto flex items-center space-x-1.5 native-scroll">
                  <ion-skeleton-text class="rounded-full h-7 w-16" animated></ion-skeleton-text>
                  <ion-skeleton-text class="rounded-full h-7 w-16" animated></ion-skeleton-text>
                  <ion-skeleton-text class="rounded-full h-7 w-16" animated></ion-skeleton-text>
                  <ion-skeleton-text class="rounded-full h-7 w-16" animated></ion-skeleton-text>
                </div>
              </div>
              <div class="space-y-3 mt-3 px-4">
                <ion-skeleton-text style="width: 100%; height: 88px;" animated></ion-skeleton-text>
                <ion-skeleton-text style="width: 100%; height: 88px;" animated></ion-skeleton-text>
                <ion-skeleton-text style="width: 100%; height: 88px;" animated></ion-skeleton-text>
              </div>
            `;
          },
          error: (error) => {
            console.error(error);
            return html` <span class="text-danger mt-3 px-4">${msg("Erro ao carregar as consultas")}</span> `;
          },
          complete: () => {
            return html`
              <!-- -------------------SESSIONS--------------- -->
              <div class="px-4 mt-3">
                ${this.selectedCalendarDates.length > 0
                  ? html`${this.selectedCalendarDates.map((date) => {
                      const result = appointmentStore.appointmentsByDay(date).toSorted((a, b) => {
                        return new Date(a.session.startTime).getTime() - new Date(b.session.startTime).getTime();
                      });
                      return html`
                        <div class="first:hidden mt-3"></div>
                        <p class="font-display font-semibold capitalize">
                          ${format(new Date(date), "dd MMMM", { locale: getDateFnsLocale(this.auth.user.language) })}
                        </p>
                        ${result.length === 0
                          ? html`
                              <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-3">
                                <div
                                  class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                                  <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                                    ${msg("Não existem sessões agendadas para este dia.")}
                                  </span>
                                </div>
                              </ion-item>
                            `
                          : result.map(
                              (a) => html`
                                <ion-router-link href="/home/appointment/${a.session.id}">
                                  <div class="space-y-3 mt-3">
                                    <div
                                      class="w-full col justify-between bg-accent-8 border border-solid border-accent-7 rounded-lg px-3.5 pt-3 pb-3.5">
                                      <p class="font-semibold">${a.patient.name}</p>

                                      <div class="flex justify-between items-end">
                                        <div class="flex justify-between items-end">
                                          <div class="flex items-center gap-x-2 mr-3">
                                            <div
                                              class="bg-accent-7 text-accent-1 rounded-full px-1.5 py-1 h-6 flex items-center flex-shrink-0">
                                              <span class="text-xs font-medium "
                                                >${this.getHoursAndMinutes(a.session.startTime)} -
                                                ${this.getHoursAndMinutes(a.session.endTime)}</span
                                              >
                                            </div>
                                            <div
                                              class="bg-accent-7 text-accent-1 rounded-full px-1.5 py-1 h-6 flex items-center ">
                                              <span class="text-xs font-medium line-clamp-1">${a.group.name}</span>
                                            </div>
                                          </div>
                                          <xt-avatar
                                            style="--border-radius: 99px; --size: 36px; --font-size: 0.75rem"
                                            src=${a.patient.avatar}
                                            name=${a.patient.name}></xt-avatar>
                                      </div>
                                    </div>
                                  </div>
                                </ion-router-link>
                              `,
                            )}
                      `;
                    })}`
                  : html`
                      ${when(
                        appointmentStore.appointmentsByDay(this.selectedDate).length === 0,
                        () => {
                          return html`
                            <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p">
                              <div
                                class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                                <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                                  ${msg("Não existem sessões agendadas para este dia.")}
                                </span>
                              </div>
                            </ion-item>
                          `;
                        },
                        () => {
                          return appointmentStore
                            .appointmentsByDay(this.selectedDate)
                            .toSorted((a, b) => {
                              return new Date(a.session.startTime).getTime() - new Date(b.session.startTime).getTime();
                            })
                            .map(
                              (a) => html`
                                <div class="first:mt-0 mt-3">
                                  <ion-router-link href="/home/appointment/${a.session.id}">
                                    <div class="space-y-3">
                                      <div
                                        class="w-full col justify-between bg-accent-8 border border-solid border-accent-7 rounded-lg px-3.5 pt-3 pb-3.5">
                                        <p class="font-semibold">${a.patient.name}</p>

                                        <div class="flex justify-between items-end ">
                                          <div class="flex items-center gap-x-2 mr-3">
                                            <div
                                              class="bg-accent-7 text-accent-1 rounded-full px-1.5 py-1 h-6 flex items-center flex-shrink-0">
                                              <span class="text-xs font-medium "
                                                >${this.getHoursAndMinutes(a.session.startTime)} -
                                                ${this.getHoursAndMinutes(a.session.endTime)}</span
                                              >
                                            </div>
                                            <div
                                              class="bg-accent-7 text-accent-1 rounded-full px-1.5 py-1 h-6 flex items-center ">
                                              <span class="text-xs font-medium line-clamp-1">${a.group.name}</span>
                                            </div>
                                          </div>
                                          <xt-avatar
                                            style="--border-radius: 99px; --size: 36px; --font-size: 0.75rem"
                                            src=${a.patient.avatar}
                                            name=${a.patient.name}></xt-avatar>
                                        </div>
                                      </div>
                                    </div>
                                  </ion-router-link>
                                </div>
                              `,
                            );
                        },
                      )}
                    `}
              </div>
            `;
          },
        })}

        <ion-popover
          style="--min-width: fit-content; --max-width: none; --offset-x: 2px;"
          trigger="datetime-trigger"
          @ionPopoverDidPresent=${() => {
            if (this.hasInitializedEvents) return;

            const calendar = this.#calendarRef.value;
            if (calendar) {
              const confirmBtn = calendar.shadowRoot?.querySelector("#confirm-button");
              confirmBtn?.addEventListener("click", async () => {
                await calendar.confirm();
                if (!calendar.value) return;
                let dates = calendar.value as string[];
                this.selectedCalendarDates = dates.toSorted((a, b) => new Date(a).getTime() - new Date(b).getTime());
                await this.#appointments.run([dates]);
              });

              const clearBtn = calendar.shadowRoot?.querySelector("#clear-button");
              clearBtn?.addEventListener("click", () => {
                this.selectedCalendarDates = [];
              });
            }

            this.hasInitializedEvents = true;
          }}
          ${ref(this.#popoverRef)}>
          <ion-datetime
            .showDefaultButtons=${true}
            .showClearButton=${true}
            locale=${this.auth.user.language}
            presentation="date"
            multiple
            clear-text=${msg("Limpar")}
            cancel-text=${msg("Cancelar")}
            done-text=${msg("Confirmar")}
            ${ref(this.#calendarRef)}>
          </ion-datetime>
        </ion-popover>
      </ion-content>

      <modal-nav root="modal-notifications" ${ref(this.#modalNotificationsRef)}></modal-nav>
    `;
  }
}
