import { msg } from "@lit/localize";
import { CustomError } from "ts-custom-error";

export class ApiError extends CustomError {
  public constructor(public code: string, message: string) {
    super(message);
  }
}

export class ErrorUnknown extends ApiError {
  public constructor(e: unknown) {
    super("unknown", msg("Um erro inesperado ocorreu, por favor tente novamente mais tarde ou contacte o suporte"));
    console.warn("An error wasn't caught", JSON.stringify(e, null, 2));
  }
}

export class ErrorInsufficientPermissions extends ApiError {
  public constructor() {
    super("insufficient-permissions", msg("Não tem permissões insuficientes para efetuar esta ação"));
  }
}

export function isApiError(error: unknown): error is ApiError {
  console.log(JSON.stringify(error, null, 2));
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as any).message === 'string' &&
    'code' in error &&
    typeof (error as any).code === 'string'
  );
}