import { msg } from "@lit/localize";
import { ApiError } from "../../error";

export class ErrorPatientNotFound extends ApiError {
  public constructor() {
    super("patient-not-found", msg("Paciente não encontrado"));
  }
}

export class ErrorPatientInvalidAvatar extends ApiError {
  public constructor() {
    super("patient-invalid-avatar", msg("Avatar inválido"));
  }
}

export class ErrorPatientEmailNotUnique extends ApiError {
  public constructor() {
    super("patient-email-not-unique", msg("Este email já está em uso"));
  }
}

export class ErrorPatientPhoneNotUnique extends ApiError {
  public constructor() {
    super("patient-phone-not-unique", msg("Este telefone já está em uso"));
  }
}

export class ErrorPatientGroupSectionNotFound extends ApiError {
  public constructor() {
    super("patient-group-section-not-found", msg("A secção onde está a inserir o paciente não existe"));
  }
}

export class ErrorPatientGroupNotFound extends ApiError {
  public constructor() {
    super("patient-group-not-found", msg("O grupo onde está a inserir o paciente não existe"));
  }
}

export class ErrorDiagnosticNotFound extends ApiError {
  public constructor() {
    super("diagnostic-not-found", msg("Diagnóstico não encontrado"));
  }
}

export class ErrorSessionNotFound extends ApiError {
  public constructor() {
    super("session-not-found", msg("Sessão não encontrada"));
  }
}

export class ErrorExercisePlanNotFound extends ApiError {
  public constructor() {
    super("exercise-plan-not-found", msg("Plano de exercício não encontrado"));
  }
}

export class ErrorExercisePlanExerciseNotFound extends ApiError {
  public constructor() {
    super("exercise-plan-exercise-not-found", msg("Exercício escolhido não encontrado"));
  }
}