import { alertController, toastController } from "@ionic/core";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import { html, nothing, type PropertyValues } from "lit";
import { customElement, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { map } from "lit/directives/map.js";
import { when } from "lit/directives/when.js";
import { Page, required } from "../components/component";
import { authContext, type AuthContext } from "../context/auth.context";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { UpdateLibrarySchema, type UpdateLibrary } from "../repository/library/library";
import { groupStore } from "../stores/groups.store";
import { librariesStore, type ObservableLibrary } from "../stores/libraries.store";
import { UserShareStore } from "../stores/userShare.store";
import { ModalShareEdit } from "./modal-share-edit";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-edit-library")
@localized()
export class ModalEditLibrary extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) auth!: AuthContext;

  @state() users: UserShareStore["users"] = [];
  @required() library!: ObservableLibrary;

  userShareStore = new UserShareStore();

  connectedCallback(): void {
    super.connectedCallback();
    this.userShareStore.set(
      this.library.sharedPersonal.map((share) => ({
        user: share.user,
        access: share.access,
        action: "update",
      })),
    );

    this.users = this.userShareStore.updatedUsers.slice(0, 2);
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    //so that new users stay in the same order in the list depending when they were added
    this.userShareStore.on("create", () => {
      this.users.push(this.userShareStore.newUsers[this.userShareStore.newUsers.length - 1]!);
      this.requestUpdate();
    });

    this.userShareStore.on("delete", () => {
      this.users = this.users.filter((user) => user.action !== "delete");
      // if its a new user that was deleted, remove it from the new users list
      this.users.map((user) => {
        if (user.action === "create" && !this.userShareStore.newUsers.includes(user)) {
          this.users = this.users.filter((u) => u !== user);
        }
      });
      this.requestUpdate();
    });
  }

  groups = new Task(this, {
    task: async () => {
      if (this.library.isSharedFromGroup() && this.library.isOwner(this.auth.user)) {
        return groupStore.loadGroupsByOwner(this.auth.user.id);
      }
      return [];
    },
    args: () => [],
  });

  #controller = new FinalFormController<UpdateLibrary>(this, {
    validate: zodValidator(UpdateLibrarySchema()),
    onSubmit: async (values) => {
      try {
        await librariesStore.updateLibrary(this.library, {
          name: values.name,
        });

        if (this.library.isSharedFromGroup()) {
          if (values.group) await librariesStore.shareWithGroup(this.library, values.group);
        } else {
          await librariesStore.shareWithUsers(this.library, this.userShareStore.users);
        }

        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: [msg("OK")],
          });
          await alert.present();
        }
      }
    },
  });

  async deleteLibrary() {
    const alert = await alertController.create({
      header: msg("Apagar biblioteca"),
      message: msg("Tem a certeza que deseja apagar esta biblioteca? A ação não é reversível."),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await librariesStore.deleteLibrary(this.library).then(async () => {
                const toast = await toastController.create({
                  message: msg("Biblioteca apagada com sucesso"),
                  color: "primary",
                  icon: "/assets/icons/info.svg",
                  duration: 2000,
                });
                await toast.present();
              });
              this.navigator.close();
            } catch (error) {
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: [msg("OK")],
                });
                await alert.present();
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  render() {
    const { form, submit, register, renderError } = this.#controller;
    const formState = form.getState();

    const currentUser = this.auth.user;

    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${this.navigator.goBack}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                ${msg("Cancelar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title>${this.library.name}</ion-title>
          ${when(
            // !(
            //   (this.library.isSharedFromGroup() && !this.library.hasPrivileges(currentUser)) ||
            //   this.library.isShared(currentUser)
            // ),
            this.library.hasPrivileges(currentUser),
            () => html`
              <ion-buttons slot="end">
                <div class="flex items-center">
                  <ion-button
                    @click=${() => this.deleteLibrary()}
                    color="danger"
                    style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                    fill="clear"
                    class="font-semibold">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="mr-1">
                      <path
                        d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    ${msg("Apagar")}
                  </ion-button>
                </div>
              </ion-buttons>
            `,
          )}
        </ion-toolbar>
      </ion-header>

      <ion-content fullscreen>
        <form class="mt-4" id="form-library-edit" @submit=${submit}>
          <div>
            <span class="font-semibold font-display">${msg("Nome")}</span>
            <ion-item class="has-input mt-1">
              <ion-input
                autocapitalize="on"
                ?disabled=${!this.library.hasPrivileges(currentUser)}
                type="text"
                placeholder=${msg("Nome da Biblioteca")}
                ${register("name", { initialValue: this.library.name })}></ion-input>
            </ion-item>
            ${renderError("name")}
          </div>

          ${this.library.isSharedFromGroup() && !this.library.hasPrivileges(currentUser)
            ? html`
                <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                  <div
                    class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                    <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                      <span class="font-medium">${msg("Esta biblioteca é partilhada pelo grupo")}</span>"${this.library
                        .sharedGroup?.group.name}"
                    </span>
                  </div>
                </ion-item>
              `
            : this.library.isSharedFromGroup() && this.library.hasPrivileges(currentUser)
            ? this.groups.render({
                complete: (groups) => html`
                  <div class="mt-6 space-y-2">
                    <span class="font-semibold font-display">${msg("Grupos")}</span>
                    <ion-list>
                      <ion-radio-group
                        allow-empty-selection
                        ${register("group", {
                          initialValue: this.library.sharedGroup?.group.id,
                        })}
                        value=${this.library.sharedGroup?.group.id}>
                        ${map(groups, (group) => {
                          if (group.hasPrivileges(this.auth.user)) {
                            return html`
                              <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                                <ion-radio value=${group.id} class="no-m-inline my-2">${group.name}</ion-radio>
                              </ion-item>
                            `;
                          }
                          return nothing;
                        })}
                      </ion-radio-group>
                    </ion-list>
                    ${renderError("group")}
                  </div>
                `,
              })
            : html`
                <div class="mt-6 space-y-2">
                  <div class="flex justify-between items-center">
                    <span class="font-semibold font-display">${msg("Profissionais")}</span>
                    ${when(
                      this.library.hasPrivileges(this.auth.user),
                      () => html`
                        <ion-button
                          class="h-7 text-sm"
                          style="--padding-start: 8px; --padding-end: 8px;"
                          color="secondary"
                          shape="round"
                          @click=${() => {
                            this.navigator.push("modal-share-add", {
                              shareStore: this.userShareStore,
                            });
                          }}>
                          <svg
                            class="mr-1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none">
                            <path
                              d="M4 8H8M8 8H12M8 8V12M8 8V4"
                              stroke="#111111"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          ${msg("Adicionar")}
                        </ion-button>
                      `,
                    )}
                  </div>
                  <ion-list class="space-y-2.5 no-p" lines="none">
                    <ion-item class="no-p">
                      <div class="flex items-center space-x-2">
                        <xt-avatar src=${this.library.owner.avatar} name=${this.library.owner.name}></xt-avatar>
                        <div class="col items-start">
                          <p class="font-semibold">${this.library.owner.name}</p>
                          <span class="text-danger">${msg("Criador")}</span>
                        </div>
                      </div>
                    </ion-item>

                    <!-- ${this.userShareStore.availableUsers?.map(
                      (share) => html`
                        <ion-item
                          class="no-p"
                          @click=${() => {
                            if (this.library.hasPrivileges(currentUser))
                              this.navigator.push(ModalShareEdit, {
                                share: share,
                                shareStore: this.userShareStore,
                              });
                          }}>
                          <div class="flex items-center space-x-2">
                            <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                            <div class="col items-start">
                              <p class="font-semibold">${share.user.name}</p>
                              ${choose(share.access.role, [
                                ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                                ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                                ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                              ])}
                            </div>
                          </div>
                        </ion-item>
                      `,
                    )} -->
                    ${this.users.map(
                      (share) => html`
                        <ion-item
                          class="no-p"
                          @click=${() => {
                            if (this.library.hasPrivileges(this.auth.user))
                              this.navigator.push("modal-share-edit", {
                                share: share,
                                shareStore: this.userShareStore,
                              });
                          }}>
                          <div class="flex items-center space-x-2">
                            <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                            <div class="col items-start">
                              <p class="font-semibold">${share.user.name}</p>
                              ${choose(share.access.role, [
                                ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                                ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                                ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                              ])}
                            </div>
                          </div>
                        </ion-item>
                      `,
                    )}
                    ${when(
                      this.userShareStore.updatedUsers?.length > 2 &&
                        this.users.filter((user) => user.action === "update").length === 2,
                      () => {
                        return html`
                          <ion-button
                            class="mt-3"
                            size="small"
                            color="secondary"
                            expand="block"
                            shape="round"
                            @click=${() => (
                              this.users.push(...this.userShareStore.updatedUsers.slice(2)), this.requestUpdate()
                            )}
                            >${msg(str`Ver Todos (${this.userShareStore.updatedUsers.length + 1})`)}
                          </ion-button>
                        `;
                      },
                    )}
                  </ion-list>
                </div>
              `}
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          ${when(
            this.library.hasPrivileges(currentUser),
            () => html`
              <ion-button
                form="form-library-edit"
                type="submit"
                expand="block"
                shape="round"
                ?disabled=${formState.submitting}>
                ${msg("Guardar Alterações")}
              </ion-button>
            `,
          )}
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
