import { customElement, state } from "lit/decorators.js";
import { html, type PropertyValues } from "lit";
import { type ItemReorderCustomEvent, alertController } from "@ionic/core";
import { groupStore, type ObservableGroup } from "../stores/groups.store";
import { consume } from "@lit/context";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { authContext, type AuthContext } from "../context/auth.context";
import { choose } from "lit/directives/choose.js";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { when } from "lit/directives/when.js";
import { Page, required } from "../components/component";
import { RouterContext, routerContext } from "../context/router.context";
import { UserShareStore } from "../stores/userShare.store";
import type { UpdateSectionWithId } from "../repository/group/section";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

const formSchema = () =>
  z.object({
    name: z
      .string({
        required_error: msg("O nome é obrigatório"),
      })
      .min(2, msg("O nome deve ter pelo menos 2 caracteres"))
      .max(36, msg("O nome deve ter no máximo 36 caracteres")),
  });

type FormValues = z.infer<ReturnType<typeof formSchema>>;

@customElement("modal-edit-group")
@localized()
export class ModalEditGroup extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  @state() sections: UpdateSectionWithId[] = [];
  @state() users: UserShareStore["users"] = [];
  @required() group!: ObservableGroup;

  shareStore = new UserShareStore();

  connectedCallback(): void {
    super.connectedCallback();

    this.shareStore.set(
      this.group.sharedPersonal.map((share) => ({
        user: share.user,
        access: share.access,
        action: "update",
      })),
    );

    this.users = this.shareStore.updatedUsers.slice(0, 2);

    this.sections = this.group.sortedSections.map((section) => ({
      id: section.id,
      name: section.name,
      position: section.position,
    }));
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    //so that new users stay in the same order in the list depending when they were added
    this.shareStore.on("create", () => {
      this.users.push(this.shareStore.newUsers[this.shareStore.newUsers.length - 1]!);
      this.requestUpdate();
    });

    this.shareStore.on("delete", () => {
      this.users = this.users.filter((user) => user.action !== "delete");
      // if its a new user that was deleted, remove it
      this.users.map((user) => {
        if (user.action === "create" && !this.shareStore.newUsers.includes(user)) {
          this.users = this.users.filter((u) => u !== user);
        }
      });
      this.requestUpdate();
    });
  }

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema()),
    onSubmit: async (values) => {
      try {
        await groupStore.updateGroup(this.group, {
          name: values.name,
        });
        await groupStore.shareWithUsers(this.group, this.shareStore.users);
        await groupStore.updateSections(this.group, this.sections);

        this.navigator.goBack();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        } else {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: msg("Ocorreu um erro inesperado"),
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  onReorder = async (e: Event) => {
    const detail = (e as ItemReorderCustomEvent).detail;

    const fromIdx = detail.from;
    const toIdx = detail.to;

    if (fromIdx === toIdx) {
      detail.complete();
      return;
    }

    this.sections = this.sections.map((section, idx) => {
      if (idx === fromIdx) {
        return { ...section, position: toIdx };
      } else if (fromIdx < toIdx) {
        if (idx > fromIdx && idx <= toIdx) {
          return { ...section, position: idx - 1 };
        }
      } else {
        if (idx < fromIdx && idx >= toIdx) {
          return { ...section, position: idx + 1 };
        }
      }
      return section;
    });

    detail.complete();
  };

  async #arquiveGroup() {
    const alert = await alertController.create({
      header: msg("Arquivar Grupo"),
      message: msg("Tem a certeza que deseja arquivar este grupo?"),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Arquivar"),
          role: "destructive",
          handler: async () => {
            try {
              await groupStore.updateGroup(this.group, { isArchived: true });
              await this.navigator.goBack();
              const backUrl = this.router.backUrl;
              if (backUrl === "/groups") this.router.back();
            } catch (error) {
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["OK"],
                });
                await alert.present();
              } else {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: msg("Ocorreu um erro inesperado"),
                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
        },
      ],
    });

    await alert.present();
  }

  render() {
    const { form, register, renderError, submit } = this.#controller;
    const formState = form.getState();

    return html`
      <!-- HEADER -->

      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div>
              <ion-button
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold"
                @click=${this.navigator.goBack}>
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${this.group?.name}</ion-title>
          <ion-buttons slot="end">
            ${when(
              this.group.hasPrivileges(this.auth.user),
              () => html`
                <ion-button color="danger" class="font-semibold text-sm" @click=${this.#arquiveGroup}>
                  <span class="mr-1 mt-px">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("Arquivar")}
                </ion-button>
              `,
            )}
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <!-- HEADER -->

      <ion-content fullscreen class="space-y-6">
        <form id="form-group-edit" @submit=${submit} class="mt-6">
          <div>
            <span class="font-semibold font-display">${msg("Nome")}</span>
            <ion-item class="mt-1">
              <ion-input
                autocapitalize="on"
                type="text"
                ?disabled=${!this.group.hasPrivileges(this.auth.user)}
                placeholder=${msg("Nome do grupo")}
                @input=${() => {}}
                ${register("name", {
                  initialValue: this.group.name,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("name")}
          </div>

          <div class="mt-6">
            <div class="flex justify-between items-center">
              <span class="font-semibold font-display">${msg("Profissionais")}</span>
              ${when(
                this.group.hasPrivileges(this.auth.user),
                () => html`
                  <ion-button
                    class="h-7 text-sm"
                    style="--padding-start: 8px; --padding-end: 8px;"
                    color="secondary"
                    shape="round"
                    @click=${() => {
                      this.navigator.push("modal-share-add", {
                        shareStore: this.shareStore,
                      });
                    }}>
                    <svg
                      class="mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M4 8H8M8 8H12M8 8V12M8 8V4"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    ${msg("Adicionar")}
                  </ion-button>
                `,
              )}
            </div>
            <ion-list lines="none" class="space-y-2.5 mt-2 flex flex-col">
              <ion-item class="no-p">
                <div class="flex items-center space-x-2">
                  <xt-avatar src=${this.group.owner.avatar} name=${this.group.owner.name}></xt-avatar>
                  <div class="col items-start">
                    <p class="font-semibold">${this.group.owner.name}</p>
                    <span class="text-danger">${msg("Criador")}</span>
                  </div>
                </div>
              </ion-item>
              ${this.users.map(
                (share) => html`
                  <ion-item
                    class="no-p"
                    @click=${() => {
                      if (this.group.hasPrivileges(this.auth.user))
                        this.navigator.push("modal-share-edit", {
                          share: share,
                          shareStore: this.shareStore,
                        });
                    }}>
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${share.user.name}</p>
                        ${choose(share.access.role, [
                          ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                          ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                          ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                        ])}
                      </div>
                    </div>
                  </ion-item>
                `,
              )}
              ${when(
                this.shareStore.updatedUsers?.length > 2 &&
                  this.users.filter((user) => user.action === "update").length === 2,
                () => {
                  return html`
                    <ion-button
                      class="mt-3"
                      size="small"
                      color="secondary"
                      expand="block"
                      shape="round"
                      @click=${() => (this.users.push(...this.shareStore.updatedUsers.slice(2)), this.requestUpdate())}
                      >${msg(str`Ver Todos (${this.shareStore.updatedUsers.length + 1})`)}
                    </ion-button>
                  `;
                },
              )}
            </ion-list>
          </div>
        </form>

        <div class="space-y-1.5">
          <span class="font-semibold font-display">${msg("Secções")}</span>
          <ion-list>
            ${when(
              this.sections.length > 0,
              () => {
                return this.sections.length > 1
                  ? html`
                      <ion-reorder-group
                        .disabled=${!this.group.hasPrivileges(this.auth.user)}
                        @ionItemReorder=${this.onReorder}>
                        ${this.sections.map(
                          (section) => html`
                            <ion-item class="no-p no-inner-p text-accent-5">
                              <ion-label class="my-3">${section.name}</ion-label>
                              <ion-reorder slot="end">
                                <ion-icon icon="/assets/icons/reorder.svg" class="w-6 h-6 text-accent-1"></ion-icon>
                              </ion-reorder>
                            </ion-item>
                          `,
                        )}
                      </ion-reorder-group>
                    `
                  : html`
                      <ion-item class="no-p no-inner-p text-accent-5">
                        <ion-label class="my-3">${this.sections[0]?.name}</ion-label>
                      </ion-item>
                    `;
              },
              () => html`
                <ion-button color="secondary" class="h-36 w-full whitespace-normal" style="--border-radius: 6px;">
                  <span class="col space-y-1 items-center justify-center">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                    <span class="text-sm font-semibold font-display text-center" style="max-inline-size: 20ch;">
                      ${msg("Este grupo ainda não tem secções criadas")}
                    </span>
                  </span>
                </ion-button>
              `,
            )}
          </ion-list>
        </div>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          ${when(
            this.group.hasPrivileges(this.auth.user),
            () => html`
              <ion-button
                form="form-group-edit"
                type="submit"
                color="primary"
                expand="block"
                shape="round"
                class="font-semibold"
                ?disabled=${formState.submitting}
                >${msg("Guardar Alterações")}</ion-button
              >
            `,
          )}
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
