import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { Task } from "@lit/task";
import { groupStore } from "../stores/groups.store";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { repeat } from "lit/directives/repeat.js";
import { navigatorContext, type NavigatorController } from "../controllers/navigator.controller";
import { ObservablePatient } from "../stores/patients.store";
import { Page } from "../components/component";
import { when } from "lit/directives/when.js";
import { localized, msg } from "@lit/localize";

@customElement("modal-choose-group")
@localized()
export class ModalChooseGroup extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  patient!: ObservablePatient;

  #groups = new Task(this, {
    task: async () => {
      groupStore.loadGroups();
    },
    args: () => [],
  });

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click="${() => this.navigator.close()}" class="font-semibold">
              <span class="mt-[3px] -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg("fechar")}</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">${msg("Escolhe o grupo")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        ${this.#groups.render({
          pending: () => html``,
          complete: () => html`
            <ion-list>
              ${repeat(
                groupStore.groups,
                (group) => group.id,
                (group) => {
                  return html`
                    ${when(!group.isArchived, () => {
                      return html`
                        <ion-item
                          button
                          detailIcon="/assets/icons/chevron-right.svg"
                          class="no-p no-inline-p no-inner-p"
                          @click=${() => {
                            this.navigator.push("modal-choose-section", {
                              group: group,
                              patient: this.patient,
                            });
                          }}>
                          <ion-label class="py-2 font-semibold">${group.name}</ion-label>
                        </ion-item>
                      `;
                    })}
                  `;
                },
              )}
            </ion-list>
          `,
          error: () => html``,
        })}
      </ion-content>
    `;
  }
}
