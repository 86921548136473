import { consume, provide } from "@lit/context";
import { LightElement } from "../components/component";
import { routerContext, RouterContext } from "./router.context";
import { customElement, state } from "lit/decorators.js";
import { authContext, type AuthContext } from "./auth.context";
import { appointmentStore } from "../stores/appointment.store";
import { diagnosticsStore } from "../stores/diagnostics.store";
import { evaluationsStore } from "../stores/evaluation.store";
import { groupStore } from "../stores/groups.store";
import { librariesStore } from "../stores/libraries.store";
import { notificationsStore } from "../stores/notifications.store";
import { patientsStore } from "../stores/patients.store";
import { reportsStore } from "../stores/reports.store";
import { scalesStore } from "../stores/scale.store";
import { templatesStore } from "../stores/templateExercisePlan.store";

@customElement("router-provider")
export class RouterProvider extends LightElement {
  @consume({ context: authContext }) auth!: AuthContext;

  @provide({ context: routerContext })
  @state()
  router!: RouterContext;

  constructor() {
    super();
    this.router = new RouterContext({
      useHash: false,
      routes: {
        "/": { component: "page-onboarding", beforeEnter: [this.redirectUser] },
        "/login": { component: "page-login", beforeEnter: [this.redirectUser] },
        "/register": { component: "page-register", beforeEnter: [this.redirectUser] },
        "/register/create": { component: "page-create-account" },
        "/recover-password": { component: "page-recover-password", beforeEnter: [this.redirectUser] },
        "/public/patient/:patientId": { component: "page-public-patient" },
      },
      tabs: {
        // the root component for the tabs
        component: "page-tabs",
        routes: {
          "/home": {
            component: "tab-home",
            beforeEnter: [this.protected],
            children: {
              "/": { component: "page-homepage" },
              "/appointment/:id": { component: "page-appointment", props: { back: "/home" } },
            },
          },
          "/exercises": {
            component: "tab-exercises",
            beforeEnter: [this.protected],
            children: {
              "/": { component: "page-exercises" },
              "/:id": { component: "page-public-exercise", props: { back: "/exercises" } },

              "/public": { component: "page-public-library", props: { back: "/exercises" } },
              "/public/:id": { component: "page-public-exercise", props: { back: "/exercises/public" } },
              "/library/:id": { component: "page-library", props: { back: "/exercises" } },

              "/templates": { component: "page-template-exercise-plan", props: { back: "/exercises" } },
              "/templates/:templateId": {
                component: "page-template-exercise-plan-template",
                props: { back: "/exercises/templates" },
              },
              "/templates/popular/:templateId": {
                component: "page-template-exercise-plan-template",
                props: { back: "/exercises" },
              },
            },
          },
          "/groups": {
            component: "tab-groups",
            beforeEnter: [this.protected],
            children: {
              "/": { component: "page-groups" },

              "/archived": { component: "page-groups-archived", props: { back: "/groups" } },
              "/archived/patient/:patientId": {
                component: "page-groups-archived-group-patient",
                props: { back: "/groups/archived" },
              },
              "/archived/:groupId": { component: "page-groups-archived-group", props: { back: "/groups/archived" } },
              "/archived/:groupId/patient/:patientId": {
                component: "page-groups-archived-group-patient",
                props: { back: "/groups/archived/:groupId" },
              },

              // TODO: This doesn't work
              // "/archived": {
              //   component: "page-groups-archived",
              //   children: {
              //     "/": { component: "page-groups-archived" },
              //     "/:groupId": { component: "page-groups-archived-group" }, // FAILED -> groupdId === archived
              //     "/:groupId/patient/:patientId": { component: "page-groups-archived-group-patient" }, //FAILED -> patientId === null
              //   }
              // },

              "/:groupId": { component: "page-groups-group", props: { back: "/groups" } },

              "/:groupId/patient/:patientId": { component: "page-patient", props: { back: "/groups/:groupId" } },

              "/:groupId/popular/patient/:patientId": { component: "page-patient", props: { back: "/groups" } },

              "/:groupId/patient/:patientId/diagnostics/:diagnosticId": {
                component: "page-diagnostic",
                props: { back: "/groups/:groupId/patient/:patientId" },
              },
              "/:groupId/popular/patient/:patientId/diagnostics/:diagnosticId": {
                component: "page-diagnostic",
                props: { back: "/groups/:groupId/popular/patient/:patientId" },
              },

              "/:groupId/patient-archived/:patientId": {
                component: "page-patient-archived",
                props: { back: "/groups/:groupId" },
              },
              "/:groupId/popular/patient-archived/:patientId": {
                component: "page-patient-archived",
                props: { back: "/groups" },
              },
            },
          },
          "/reports": {
            component: "tab-reports",
            beforeEnter: [this.protected],
            children: {
              "/": { component: "page-reports" },
              "/:reportId": { component: "page-report", props: { back: "/reports" } },
              "/:reportId/evaluation/:evaluationId": {
                component: "page-evaluation",
                props: { back: "/reports/:reportId" },
              },
              "/:reportId/scale/:scaleId": { component: "page-scale", props: { back: "/reports/:reportId" } },
            },
          },
          "/settings": {
            component: "tab-settings",
            beforeEnter: [this.protected],
            children: {
              "/": { component: "page-settings" },
            },
          },
        },
      },
    });
  }

  //
  // Middlewares
  //

  protected = () => {
    if (!this.auth.isAuthenticated || !this.auth.isSessionValid) {
      this.auth.logout();
      appointmentStore.clearStore();
      diagnosticsStore.clearStore();
      librariesStore.clearStore();
      groupStore.clearStore();
      notificationsStore.clearStore();
      patientsStore.clearStore();
      templatesStore.clearStore();
      reportsStore.clearStore();
      evaluationsStore.clearStore();
      scalesStore.clearStore();
      return { redirect: "/login" };
    }
    return true;
  };

  redirectUser = () => {
    if (this.auth.isAuthenticated) {
      return { redirect: "/home" };
    }
    return true;
  };
}
