import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { FinalFormController, zodValidator } from "../../controllers/final-form.controller";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import { UpdateEvaluationFieldYesOrNoSchema, type EvaluationFieldYesOrNo, type UpdateEvaluationFieldYesOrNo } from "../../repository/report/report";
import { reportsStore } from "../../stores/reports.store";
import { alertController } from "@ionic/core";
import type { ObservableEvaluationSection } from "../../stores/evaluation.store";
import { when } from "lit/directives/when.js";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../../error";

@customElement("modal-edit-yes-no-questionnaire")
@localized()
export class ModalEditYesNoQuestionnaire extends Page {
  field!: EvaluationFieldYesOrNo;
  section!: ObservableEvaluationSection;
  private visibleOptions: number = 2;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  connectedCallback(): void {
    super.connectedCallback();
    this.visibleOptions = this.field.option5 ? 5 : this.field.option4 ? 4 : this.field.option3 ? 3 : 2;
  }

  #form = new FinalFormController<UpdateEvaluationFieldYesOrNo>(this, {
    validate: zodValidator(UpdateEvaluationFieldYesOrNoSchema()),
    onSubmit: async (values) => {
      try {
        await reportsStore.updateEvaluationFieldYesOrNo(this.field.id, values, this.section);
        this.navigator.close();
      } catch (error) {
        if(error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  handleNewOptionClick() {
    if (this.visibleOptions < 5) {
      this.visibleOptions++;
      this.requestUpdate();
    }
  }

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    const renderOption = (optionNumber: number) => {
      const optionKey = `option${optionNumber}` as keyof UpdateEvaluationFieldYesOrNo;
      return html`
        ${when(
          this.visibleOptions >= optionNumber,
          () => html`
            <div>
              <span class="font-semibold font-display">${msg(str`Opção ${optionNumber}`)}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Escreva aqui")}
                  enterkeyhint="send"
                  ${register(optionKey, { initialValue: this.field[optionKey] })}>
                </ion-input>
              </ion-item>
              ${renderError(optionKey)}
            </div>
          `,
        )}
      `;
    };

    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Questionário S/N")}</ion-title>
          </ion-toolbar>
        </ion-header>
        <form
          id="form-yes-no-quest"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none" class="space-y-3 flex flex-col">
            <div>
              <span class="font-semibold font-display">${msg("Questão")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Escreva aqui")}
                  ${register("label", { initialValue: this.field.label })}>
                </ion-input>
              </ion-item>
              ${renderError("label")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Opção 1")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Escreva aqui")}
                  ${register("option1", { initialValue: this.field.option1 })}>
                </ion-input>
              </ion-item>
              ${renderError("option1")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Opção 2")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Escreva aqui")}
                  enterkeyhint="send"
                  ${register("option2", { initialValue: this.field.option2 })}>
                </ion-input>
              </ion-item>
              ${renderError("option2")}
            </div>
            ${renderOption(3)} ${renderOption(4)} ${renderOption(5)}
          </ion-list>

          ${when(
            this.visibleOptions < 5,
            () => html`
              <div class="relative w-full flex mt-2">
                <ion-button
                  @click=${this.handleNewOptionClick}
                  color="secondary"
                  size="chip"
                  shape="round"
                  class="mx-auto z-10">
                  ${msg("Nova Opção")}
                  <span class="flex items-center ml-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M4 8H8M8 8H12M8 8V12M8 8V4"
                        stroke="currentColor"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                </ion-button>
                <div class="h-px w-full absolute inset-y-center bg-[#e5e5e5]"></div>
              </div>
            `,
          )}

          <ion-button
            form="form-yes-no-quest"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}>
            ${msg("Guardar Alterações")}
          </ion-button>
        </form>
      </div>
    `;
  }
}
