import { observable, computed, action, makeObservable } from "mobx";

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  hasPrev: boolean;
  hasNext: boolean;
}

export class PaginationStore implements Pagination {
  @observable page;
  @observable pageSize;
  @observable total;

  constructor(page: number, pageSize: number, total: number) {
    this.page = page;
    this.pageSize = pageSize;
    this.total = total;
    makeObservable(this);
  }

  @computed get totalPages() {
    return Math.ceil(this.total / this.pageSize);
  }

  @computed get hasPrev() {
    return this.page > 1;
  }

  @computed get hasNext() {
    return this.page < this.totalPages;
  }

  @action setPage(page: number) {
    this.page = page;
  }

  @action setPageSize(pageSize: number) {
    this.pageSize = pageSize;
  }

  @action setTotal(total: number) {
    this.total = total;
  }

  @action nextPage() {
    if (this.hasNext) {
      this.page++;
    }
  }

  @action prevPage() {
    if (this.hasPrev) {
      this.page--;
    }
  }

  @action reset() {
    this.page = 1;
    this.pageSize = this.pageSize;
    this.total = 0;
  }
}
