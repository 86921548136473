import { action, makeObservable, observable, runInAction, type IObservableArray } from "mobx";
import type { CreateDiagnostic, Diagnostic, UpdateDiagnostic } from "../repository/patient/diagnostic";
import type { User } from "../repository/user/user";
import { diagnosticRepository } from "../repository/patient/diagnostic.repository";
import { ObservablePatient, patientsStore } from "./patients.store";
import { sessionsStore, type ObservableSession } from "./sessions.store";
import { ErrorDiagnosticNotFound } from "../repository/patient/errors";

export class ObservableDiagnostic {
  id: string;
  created: string;
  @observable name: string;
  @observable description: string;
  @observable isCompleted: boolean;
  @observable patient: string;
  @observable professionals: Array<User>;
  @observable sessions: IObservableArray<ObservableSession>;
  @observable isLoaded: boolean;

  constructor(diagnostic: Diagnostic, isLoaded = false) {
    this.id = diagnostic.id;
    this.created = diagnostic.created;
    this.name = diagnostic.name;
    this.description = diagnostic.description;
    this.isCompleted = diagnostic.isCompleted;
    this.patient = diagnostic.patient;
    this.sessions = observable.array(diagnostic.sessions.map((s) => sessionsStore.subscribe(s)));
    this.professionals = diagnostic.professionals;
    this.isLoaded = isLoaded;
    makeObservable(this);
  }

  @action merge(diagnostic: Diagnostic) {
    this.id = diagnostic.id;
    this.created = diagnostic.created;
    this.name = diagnostic.name;
    this.description = diagnostic.description;
    this.isCompleted = diagnostic.isCompleted;
    this.patient = diagnostic.patient;
    this.sessions = observable.array(diagnostic.sessions.map((s) => sessionsStore.subscribe(s)));
    this.professionals = diagnostic.professionals;
  }

  @action update(data: Diagnostic) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.isCompleted = data.isCompleted;
    this.patient = data.patient;
    this.professionals = data.professionals;
    return this;
  }
}

class DiagnosticsStore {
  @observable diagnostics = observable.map<string, ObservableDiagnostic>();

  find(id: string) {
    return this.diagnostics.get(id);
  }

  exists(diagnostic: ObservablePatient) {
    return this.diagnostics.has(diagnostic.id);
  }

  @action save(diagnostic: ObservableDiagnostic) {
    this.diagnostics.set(diagnostic.id, diagnostic);
    return diagnostic;
  }

  @action delete(diagnostic: ObservableDiagnostic) {
    return this.diagnostics.delete(diagnostic.id);
  }

  @action clearStore() {
    this.diagnostics.clear();
  }

  @action subscribe(diagnostic: Diagnostic) {
    const observable = this.find(diagnostic.id);
    if (observable) {
      return observable.update(diagnostic);
    } else {
      return this.save(new ObservableDiagnostic(diagnostic));
    }
  }

  @action
  async load(id: string, opts?: { cache: boolean }) {
    const cachedDiagnostic = this.find(id);
    if (opts?.cache && cachedDiagnostic?.isLoaded) {
      return cachedDiagnostic;
    }

    const diagnostic = await diagnosticRepository.getDiagnostic(id);
    const observableDiagnostic = new ObservableDiagnostic(diagnostic, true);

    runInAction(() => {
      if (cachedDiagnostic) {
        cachedDiagnostic.merge(diagnostic);
      } else {
        this.save(observableDiagnostic);
      }
    });

    if (cachedDiagnostic) {
      return cachedDiagnostic;
    }

    return observableDiagnostic;
  }

  @action
  async createDiagnostic(diagnostic: CreateDiagnostic, patient: ObservablePatient) {
    const result = await diagnosticRepository.createDiagnostic(diagnostic);

    const obDiagnostic = new ObservableDiagnostic(result);

    runInAction(() => {
      this.diagnostics.set(obDiagnostic.id, obDiagnostic);
      patient.diagnostics.push(obDiagnostic);
    });

    return obDiagnostic;
  }

  @action
  async updateDiagnostic(diagnostic: ObservableDiagnostic, data: UpdateDiagnostic) {
    const result = await diagnosticRepository.updateDiagnostic(diagnostic.id, data);

    runInAction(() => {
      diagnostic.update(result);
    });

    return diagnostic;
  }

  @action
  async deleteDiagnostic(diagnostic: ObservableDiagnostic) {
    try {
      await diagnosticRepository.deleteDiagnostic(diagnostic.id);
      runInAction(() => {
        this.diagnostics.delete(diagnostic.id);
        const patient = patientsStore.find(diagnostic.patient);
        if (patient) {
          patient.diagnostics.remove(diagnostic);
        }
      });

      return diagnostic;
    } catch (error) {
      if (error instanceof ErrorDiagnosticNotFound) {
        runInAction(() => {
          this.diagnostics.delete(diagnostic.id);
          const patient = patientsStore.find(diagnostic.patient);
          if (patient) {
            patient.diagnostics.remove(diagnostic);
          }
        });

        return diagnostic;
      } else {
        throw error;
      }
    }
  }
}

export const diagnosticsStore = new DiagnosticsStore();
