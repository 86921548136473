import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { alertController, modalController } from "@ionic/core";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { CreateDiagnosticSchema, type CreateDiagnostic } from "../repository/patient/diagnostic";
import { diagnosticsStore } from "../stores/diagnostics.store";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import type { ObservablePatient } from "../stores/patients.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-create-diagnostic")
@localized()
export class ModalNewDiagnostic extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  patient!: ObservablePatient;

  #controller = new FinalFormController<CreateDiagnostic>(this, {
    validate: zodValidator(CreateDiagnosticSchema().omit({ isCompleted: true, patient: true, professionals: true })),
    onSubmit: async (values) => {
      const patientId = this.router.getParam("patientId");
      if (!this.auth.user || !patientId) return;

      //clear error messages
      this.#controller.clearErrors();

      try {
        const createDiagnostic: CreateDiagnostic = {
          ...values,
          isCompleted: false,
          patient: patientId,
          professionals: [this.auth.user.id],
        };

        await diagnosticsStore.createDiagnostic(createDiagnostic, this.patient);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          alert.present();
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">${msg("Novo Diagnóstico")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form
          id="form-diagnostic-create"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none" class="space-y-4 flex flex-col">
            <div>
              <span class="font-semibold font-display">${msg("Nome")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Nome do diagnóstico")}
                  ${register("name")}>
                </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>

            <div>
              <span class="font-semibold font-display">${msg("Descrição")}</span>
              <ion-item class="mt-1">
                <ion-textarea
                  auto-grow
                  rows="4"
                  ${register("description")}
                  type="text"
                  placeholder=${msg("Descreva a situação clínica...")}>
                </ion-textarea>
              </ion-item>
              ${renderError("description")}
            </div>
          </ion-list>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-diagnostic-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Criar Diagnóstico")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
