import { action, computed, makeObservable, observable, runInAction } from "mobx";
import type {
  GroupNotification,
  LibraryNotification,
  ReportNotification,
  TemplateExercisePlanNotification,
} from "../repository/notifications/notification";
import { notificationRepository } from "../repository/notifications/notification.repository";
import { toastController } from "@ionic/core";
import type { User } from "../repository/user/user";
import type { TemplateExercisePlanRecord } from "../repository/templates/templates";
import type { LibraryRecord } from "../repository/library/library";
import type { GroupRecord } from "../repository/group/group";
import type { ReportRecord } from "../repository/report/report";
import { msg, str } from "@lit/localize";
import { pb } from "../pocketbase";

export class ObservableGroupNotification {
  id: string;
  userSender: User;
  userReceiver: User;
  group: GroupRecord;
  access: string;
  type: string;
  @observable accepted: boolean;
  @observable rejected: boolean;

  constructor(data: GroupNotification) {
    this.id = data.id;
    this.userSender = data.userSender;
    this.userReceiver = data.userReceiver;
    this.group = data.group;
    this.access = data.access;
    this.type = data.type;
    this.accepted = data.accepted;
    this.rejected = data.rejected;

    makeObservable(this);
  }
}

export class ObservableLibraryNotification {
  id: string;
  userSender: User;
  userReceiver: User;
  library: LibraryRecord;
  access: string;
  type: string;
  @observable accepted: boolean;
  @observable rejected: boolean;

  constructor(data: LibraryNotification) {
    this.id = data.id;
    this.userSender = data.userSender;
    this.userReceiver = data.userReceiver;
    this.library = data.library;
    this.access = data.access;
    this.type = data.type;
    this.accepted = data.accepted;
    this.rejected = data.rejected;

    makeObservable(this);
  }
}

export class ObservableTemplateExercisePlanNotification {
  id: string;
  userSender: User;
  userReceiver: User;
  template: TemplateExercisePlanRecord;
  access: string;
  type: string;
  @observable accepted: boolean;
  @observable rejected: boolean;

  constructor(data: TemplateExercisePlanNotification) {
    this.id = data.id;
    this.userSender = data.userSender;
    this.userReceiver = data.userReceiver;
    this.template = data.template;
    this.access = data.access;
    this.type = data.type;
    this.accepted = data.accepted;
    this.rejected = data.rejected;

    makeObservable(this);
  }
}

export class ObservableReportNotification {
  id: string;
  userSender: User;
  userReceiver: User;
  report: ReportRecord;
  access: string;
  type: string;
  @observable accepted: boolean;
  @observable rejected: boolean;

  constructor(data: ReportNotification) {
    this.id = data.id;
    this.userSender = data.userSender;
    this.userReceiver = data.userReceiver;
    this.report = data.report;
    this.access = data.access;
    this.type = data.type;
    this.accepted = data.accepted;
    this.rejected = data.rejected;

    makeObservable(this);
  }
}

class NotificationsStore {
  @observable _groupNotifications = observable.map<string, ObservableGroupNotification>();
  @observable _libraryNotifications = observable.map<string, ObservableLibraryNotification>();
  @observable _templateExercisePlanNotifications = observable.map<string, ObservableTemplateExercisePlanNotification>();
  @observable _reportNotifications = observable.map<string, ObservableReportNotification>();
  // @observable isLoaded = false;

  @computed get groupNotifications() {
    return Array.from(this._groupNotifications.values());
  }

  @computed get libraryNotifications() {
    return Array.from(this._libraryNotifications.values());
  }

  @computed get templateExercisePlanNotifications() {
    return Array.from(this._templateExercisePlanNotifications.values());
  }

  @computed get reportNotifications() {
    return Array.from(this._reportNotifications.values());
  }

  @computed get hasGroupNotifications() {
    return this.groupNotifications.length > 0;
  }

  @computed get hasLibraryNotifications() {
    return this.libraryNotifications.length > 0;
  }

  @computed get hasTemplateExercisePlanNotifications() {
    return this.templateExercisePlanNotifications.length > 0;
  }

  @computed get hasReportNotifications() {
    return this.reportNotifications.length > 0;
  }

  @computed get hasNotifications() {
    return (
      this.groupNotifications.length > 0 ||
      this.libraryNotifications.length > 0 ||
      this.templateExercisePlanNotifications.length > 0 ||
      this.reportNotifications.length > 0
    );
  }

  @action
  async load(userId: string) {
    const [groupData, libraryData, templateExercisePlanData, reportData] = await Promise.all([
      notificationRepository.getGroupNotifications(userId),
      notificationRepository.getLibraryNotifications(userId),
      notificationRepository.getTemplateExerciseNotifications(userId),
      notificationRepository.getReportNotifications(userId),
    ]);
    runInAction(() => {
      this.clearAll();
      groupData.forEach((data) => this._groupNotifications.set(data.id, new ObservableGroupNotification(data)));
      libraryData.forEach((data) => this._libraryNotifications.set(data.id, new ObservableLibraryNotification(data)));
      templateExercisePlanData.forEach((data) =>
        this._templateExercisePlanNotifications.set(data.id, new ObservableTemplateExercisePlanNotification(data)),
      );
      reportData.forEach((data) => this._reportNotifications.set(data.id, new ObservableReportNotification(data)));
    });
  }

  @action clearAll() {
    runInAction(() => {
      this._groupNotifications.clear();
      this._libraryNotifications.clear();
      this._templateExercisePlanNotifications.clear();
      this._reportNotifications.clear();
    });
  }

  @action clearNotification(id: string) {
    runInAction(() => {
      this._groupNotifications.delete(id);
      this._libraryNotifications.delete(id);
      this._templateExercisePlanNotifications.delete(id);
      this._reportNotifications.delete(id);
    });
  }

  @action
  async deleteNotifications() {
    await pb.send(`/api/v1/notifications`, {
      method: "DELETE",
    });

    runInAction(() => {
      //clear only those that are accepted or rejected or are of type removal
      this._groupNotifications.forEach((notification) => {
        if (notification.accepted || notification.rejected || notification.type === "removal") {
          this._groupNotifications.delete(notification.id);
        }
      });
      this._libraryNotifications.forEach((notification) => {
        if (notification.accepted || notification.rejected || notification.type === "removal") {
          this._libraryNotifications.delete(notification.id);
        }
      });
      this._templateExercisePlanNotifications.forEach((notification) => {
        if (notification.accepted || notification.rejected || notification.type === "removal") {
          this._templateExercisePlanNotifications.delete(notification.id);
        }
      });
      this._reportNotifications.forEach((notification) => {
        if (notification.accepted || notification.rejected || notification.type === "removal") {
          this._reportNotifications.delete(notification.id);
        }
      });
    })
  }

  // Toasts are shown when a new notification is received
  //
  // Invite notifications are created when a user is invited to a group, library or template exercise plan
  // Removal notifications are created when a user is removed from a group, library or template exercise plan
  // The user can accept or reject the invite

  @action
  async newGroupInvite(id: string) {
    const data = await notificationRepository.getGroupNotification(id);

    let message = "";

    switch (data.type) {
      case "invite":
        message = msg(str`Tem um novo convite para o grupo ${data.group.name}`);
        break;
      case "removal":
        message = msg(str`Foi removido do grupo ${data.group.name}`);
        break;
    }

    const toast = await toastController.create({
      position: "bottom",
      color: "primary",
      icon: "/assets/icons/info.svg",
      message: message,
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newLibraryInvite(id: string) {
    const data = await notificationRepository.getLibraryNotification(id);

    let message = "";

    switch (data.type) {
      case "invite":
        message = msg(str`Tem um novo convite para a biblioteca ${data.library.name}`);
        break;
      case "removal":
        message = msg(str`Foi removido da biblioteca ${data.library.name}`);
        break;
    }

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: message,
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newTemplateExercisePlanInvite(id: string) {
    const data = await notificationRepository.getTemplateExerciseNotification(id);

    let message = "";

    switch (data.type) {
      case "invite":
        message = msg(str`Tem um novo convite para os templates ${data.template.name}`);
        break;
      case "removal":
        message = msg(str`Foi removido dos templates ${data.template.name}`);
        break;
    }

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: message,
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newReportInvite(id: string) {
    const data = await notificationRepository.getReportNotification(id);

    let message = "";

    switch (data.type) {
      case "invite":
        message = msg(str`Tem um novo convite para as availações ${data.report.name}`);
        break;
      case "removal":
        message = msg(str`Foi removido das availações ${data.report.name}`);
        break;
    }

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: message,
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newGroupInviteAccepted(id: string) {
    const data = await notificationRepository.getGroupNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} aceitou o convite para o grupo ${data.group.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newLibraryInviteAccepted(id: string) {
    const data = await notificationRepository.getLibraryNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} aceitou o convite para a biblioteca ${data.library.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newTemplateExercisePlanInviteAccepted(id: string) {
    const data = await notificationRepository.getTemplateExerciseNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} aceitou o convite para os templates ${data.template.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newReportInviteAccepted(id: string) {
    const data = await notificationRepository.getReportNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} aceitou o convite para as avaliações ${data.report.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newGroupInviteRejected(id: string) {
    const data = await notificationRepository.getGroupNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} rejeitou o convite para o grupo ${data.group.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newLibraryInviteRejected(id: string) {
    const data = await notificationRepository.getLibraryNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} rejeitou o convite para a biblioteca ${data.library.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newTemplateExercisePlanInviteRejected(id: string) {
    const data = await notificationRepository.getTemplateExerciseNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} rejeitou o convite para os templates ${data.template.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async newReportInviteRejected(id: string) {
    const data = await notificationRepository.getReportNotification(id);

    const toast = await toastController.create({
      color: "primary",
      icon: "/assets/icons/info.svg",
      position: "bottom",
      message: msg(str`${data.userReceiver.name} rejeitou o convite para as avaliações ${data.report.name}`),
      duration: 3000,
    });

    await toast.present();
  }

  @action
  async rejectGroupNotification(id: string) {
    await notificationRepository.rejectGroupNotification(id);
    runInAction(() => this._groupNotifications.delete(id));
  }
  @action async markGroupNotificationAsSeen(id: string) {
    await notificationRepository.markGroupNotificationAsSeen(id);
    runInAction(() => this._groupNotifications.delete(id));
  }

  @action
  async rejectLibraryNotification(id: string) {
    await notificationRepository.rejectLibraryNotification(id);
    runInAction(() => this._libraryNotifications.delete(id));
  }
  @action async markLibraryNotificationAsSeen(id: string) {
    await notificationRepository.markLibraryNotificationAsSeen(id);
    runInAction(() => this._libraryNotifications.delete(id));
  }

  @action
  async rejectTemplateExercisePlanNotification(id: string) {
    await notificationRepository.rejectTemplateExerciseNotification(id);
    runInAction(() => this._templateExercisePlanNotifications.delete(id));
  }
  @action async markTemplateExerciseNotificationAsSeen(id: string) {
    await notificationRepository.markTemplateExerciseNotificationAsSeen(id);
    runInAction(() => this._templateExercisePlanNotifications.delete(id));
  }

  @action
  async rejectReportNotification(id: string) {
    await notificationRepository.rejectReportNotification(id);
    runInAction(() => this._reportNotifications.delete(id));
  }
  @action async markReportNotificationAsSeen(id: string) {
    await notificationRepository.markReportNotificationAsSeen(id);
    runInAction(() => this._reportNotifications.delete(id));
  }

  @action
  async acceptGroupNotification(id: string) {
    await notificationRepository.acceptGroupNotification(id);
    runInAction(() => this._groupNotifications.delete(id));
  }

  @action async acceptLibraryNotification(id: string) {
    await notificationRepository.acceptLibraryNotification(id);
    runInAction(() => this._libraryNotifications.delete(id));
  }

  @action async acceptTemplateExercisePlanNotification(id: string) {
    await notificationRepository.acceptTemplateExerciseNotification(id);
    runInAction(() => this._templateExercisePlanNotifications.delete(id));
  }

  @action async acceptReportNotification(id: string) {
    await notificationRepository.acceptReportNotification(id);
    runInAction(() => this._reportNotifications.delete(id));
  }

  clearStore() {
    runInAction(() => {
      this._groupNotifications.clear();
      this._libraryNotifications.clear();
      this._templateExercisePlanNotifications.clear();
      this._reportNotifications.clear();
    });
  }
}

export const notificationsStore = new NotificationsStore();
