import { ClientResponseError } from "pocketbase";
import { ERROR_MISSING_REL, ERROR_NOT_UNIQUE, expand, expandAt, pb, type WithPage } from "../../pocketbase";
import type { Filters } from "../../stores/filter.store";
import type { Pagination } from "../../stores/pagination.store";
import type { UserShareAction } from "../../stores/userShare.store";
import { createAccess } from "../group/access";
import { GroupRecordSchema } from "../group/group";
import { createUser } from "../user/user";
import {
  type ExerciseRecord,
  createExercise,
  type Exercise,
  type PublicExerciseRecord,
  createPublicLibrary,
  createPublicExercise,
  type PublicExercise,
  type CreateExercise,
  type UpdateExercise,
  type LibraryPublic,
  createCategory,
  createEquipment,
  createExercisesFilters,
  createMuscle,
  type Category,
  type Equipment,
  type EquipmentRecord,
  type ExercisesFilters,
  type LibraryCategoryRecord,
  type Muscle,
  type MuscleRecord,
} from "./exercises";
import {
  createLibrary,
  createLibrarySharedGroup,
  createLibrarySharedPersonal,
  type Library,
  type LibraryRecord,
  type LibrarySharedGroupRecord,
  type UpdateLibrary,
  type CreateLibrary,
} from "./library";
import { ErrorInsufficientPermissions, ErrorUnknown } from "../../error";
import {
  ErrorExerciseContentTooLarge,
  ErrorExerciseInvalidContent,
  ErrorExerciseInvalidThumbnail,
  ErrorExerciseNotFound,
  ErrorLibraryCategoryExists,
  ErrorLibraryCategoryLimitReached,
  ErrorLibraryCategoryNotFound,
  ErrorLibraryEquipmentExists,
  ErrorLibraryEquipmentNotFound,
  ErrorLibraryInviteExists,
  ErrorLibraryMuscleExists,
  ErrorLibraryMuscleNotFound,
  ErrorLibraryNotFound,
} from "./errors";

export type ExerciseFilters = Partial<{
  query: string;
  muscles: Filters;
  categories: Filters;
  equipments: Filters;
}>;

export class LibraryRepository {
  async getLibrary(id: string): Promise<Library> {
    try {
      const result = await pb.send<
        LibraryRecord & {
          popularExercises: ExerciseRecord[];
          totalExercises: number;
        }
      >("/api/v1/library/" + id, {
        method: "GET",
      });

      return createLibrary({
        ...result,
        owner: createUser(result.expand?.owner),
        popularExercises: result.popularExercises?.map((e) => {
          return createExercise({
            ...e,
            categories: expandAt(e, "exercises_category_via_exercise").map((c) => createCategory(c.expand?.category)),
            equipment: expandAt(e, "exercises_equipment_via_exercise").map((e) => createEquipment(e.expand?.equipment)),
            muscles: expandAt(e, "exercises_muscle_via_exercise").map((m) => createMuscle(m.expand?.muscle)),
          });
        }),
        sharedGroup: expandAt(result, "exercises_library_shared_group_via_exercisesLibrary").map((sg) => {
          return createLibrarySharedGroup({
            ...sg,
            group: GroupRecordSchema.parse(sg.expand?.group),
          });
        }),
        sharedPersonal: expandAt(result, "exercises_library_shared_personal_via_exercisesLibrary").map((sp) => {
          return createLibrarySharedPersonal({
            ...sp,
            user: createUser(sp.expand?.user),
            access: createAccess(sp.expand?.access),
          });
        }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async listLibraries(): Promise<Library[]> {
    const results = await pb.send<
      (LibraryRecord & {
        popularExercises: ExerciseRecord[];
        totalExercises: number;
      })[]
    >("/api/v1/library", {
      method: "GET",
    });
    return results.map((r) => {
      return createLibrary({
        ...r,
        owner: createUser(r.expand?.owner),
        popularExercises: r.popularExercises?.map((e) => {
          return createExercise({
            ...e,
            categories: expandAt(e, "exercises_category_via_exercise").map((c) => createCategory(c.expand?.category)),
            equipment: expandAt(e, "exercises_equipment_via_exercise").map((e) => createEquipment(e.expand?.equipment)),
            muscles: expandAt(e, "exercises_muscle_via_exercise").map((m) => createMuscle(m.expand?.muscle)),
          });
        }),
        sharedGroup: expandAt(r, "exercises_library_shared_group_via_exercisesLibrary").map((sg) => {
          return createLibrarySharedGroup({
            ...sg,
            group: sg.expand?.group,
          });
        }),
        sharedPersonal: expandAt(r, "exercises_library_shared_personal_via_exercisesLibrary").map((sp) => {
          return createLibrarySharedPersonal({
            ...sp,
            user: createUser(sp.expand?.user),
            access: createAccess(sp.expand?.access),
          });
        }),
      });
    });
  }

  async listExercises(
    libraryId: string,
    pagination: Pagination,
    filters?: ExerciseFilters,
  ): Promise<WithPage<Exercise[]>> {
    const conditions: string[] = [];

    if (filters?.categories && filters.categories.length > 0) {
      const categoryConditions = filters.categories.map(
        (c) => `(exercises_category_via_exercise.category ?= '${c.id}')`,
      );
      conditions.push(`(${categoryConditions.join(" || ")})`);
    }

    if (filters?.muscles && filters.muscles.length > 0) {
      const muscleConditions = filters.muscles.map((c) => `(exercises_muscle_via_exercise.muscle ?= '${c.id}')`);
      conditions.push(`(${muscleConditions.join(" || ")})`);
    }

    if (filters?.equipments && filters.equipments.length > 0) {
      const equipmentConditions = filters.equipments.map(
        (c) => `(exercises_equipment_via_exercise.equipment ?= '${c.id}')`,
      );
      conditions.push(`(${equipmentConditions.join(" || ")})`);
    }

    if (filters?.query && filters.query.length > 0) {
      conditions.push(`(name ~ '${filters.query}')`);
    }

    let filter: string;
    if (conditions.length === 0) {
      filter = `exercisesLibrary = '${libraryId}'`;
    } else {
      filter = `exercisesLibrary = '${libraryId}' && ${conditions.join(" && ")}`;
    }

    try {
      const result = await pb.collection<ExerciseRecord>("exercises").getList(pagination.page, pagination.pageSize, {
        sort: "-created",
        expand: expand(
          "exercises_category_via_exercise.category",
          "exercises_muscle_via_exercise.muscle",
          "exercises_equipment_via_exercise.equipment",
        ),
        filter,
      });

      return {
        items: result.items.map((e) => {
          return createExercise({
            ...e,
            categories: expandAt(e, "exercises_category_via_exercise").map((c) => createCategory(c.expand?.category)),
            equipments: expandAt(e, "exercises_equipment_via_exercise").map((e) =>
              createEquipment(e.expand?.equipment),
            ),
            muscles: expandAt(e, "exercises_muscle_via_exercise").map((m) => createMuscle(m.expand?.muscle)),
          });
        }),
        perPage: result.perPage,
        page: result.page,
        totalPages: result.totalPages,
        totalItems: result.totalItems,
      };
    } catch (error) {
      throw new ErrorUnknown(error);
    }
  }

  async getExercise(id: string): Promise<Exercise> {
    const result = await pb.collection<ExerciseRecord>("exercises").getOne(id, {
      expand: expand(
        "exercises_category_via_exercise.category",
        "exercises_muscle_via_exercise.muscle",
        "exercises_equipment_via_exercise.equipment",
      ),
    });

    return createExercise({
      ...result,
      categories: expandAt<LibraryCategoryRecord>(result, "exercises_category_via_exercise").map((c) =>
        createCategory(c.expand?.category),
      ),
      muscles: expandAt<MuscleRecord>(result, "exercises_muscle_via_exercise").map((m) =>
        createMuscle(m.expand?.muscle),
      ),
      equipments: expandAt<EquipmentRecord>(result, "exercises_equipment_via_exercise").map((e) =>
        createEquipment(e.expand?.equipment),
      ),
    });
  }

  async getPublicLibrary(): Promise<LibraryPublic> {
    const result = await pb.collection<PublicExerciseRecord>("exercises_library_public").getList(1, 3, {
      sort: "-created,name",
      expand: "categories, equipment, muscles",
    });
    return createPublicLibrary({
      popularExercises: result.items.map((r) => {
        const categories = expandAt<LibraryCategoryRecord>(r, "categories").map(createCategory);
        const equipments = expandAt<EquipmentRecord>(r, "equipment").map(createEquipment);
        const muscles = expandAt<MuscleRecord>(r, "muscles").map(createMuscle);

        return createPublicExercise({
          ...r,
          categories,
          equipments,
          muscles,
        });
      }),
      totalExercises: result.totalItems,
    });
  }

  async listPublicExercises(pagination: Pagination, filters?: ExerciseFilters): Promise<WithPage<PublicExercise[]>> {
    const conditions: string[] = [];

    filters?.categories?.forEach((c) => {
      conditions.push(`(categories ?~ '${c.id}')`);
    });

    filters?.muscles?.forEach((c) => {
      conditions.push(`(muscles ?~ '${c.id}')`);
    });

    filters?.equipments?.forEach((c) => {
      conditions.push(`(equipment ?~ '${c.id}')`);
    });

    if (filters?.query && filters.query.length > 0) {
      conditions.push(`(name ~ '${filters.query}')`);
    }

    let filter = conditions.join(" && ");

    const result = await pb
      .collection<PublicExerciseRecord>("exercises_library_public")
      .getList(pagination.page, pagination.pageSize, {
        sort: "-created",
        expand: "categories, equipment, muscles",
        filter,
      });

    return {
      items: result.items.map((r) => {
        const categories = expandAt<LibraryCategoryRecord>(r, "categories").map(createCategory);
        const equipments = expandAt<EquipmentRecord>(r, "equipment").map(createEquipment);
        const muscles = expandAt<MuscleRecord>(r, "muscles").map(createMuscle);
        return createPublicExercise({
          ...r,
          categories,
          equipments,
          muscles,
        });
      }),
      perPage: result.perPage,
      page: result.page,
      totalPages: result.totalPages,
      totalItems: result.totalItems,
    };
  }

  async getPublicExercise(id: string): Promise<PublicExercise> {
    const result = await pb.collection<PublicExerciseRecord>("exercises_library_public").getOne(id, {
      expand: "categories, equipment, muscles",
    });

    const categories = expandAt<LibraryCategoryRecord>(result, "categories").map(createCategory);
    const equipments = expandAt<EquipmentRecord>(result, "equipment").map(createEquipment);
    const muscles = expandAt<MuscleRecord>(result, "muscles").map(createMuscle);

    return createPublicExercise({
      ...result,
      categories,
      equipments,
      muscles,
    });
  }

  async listPublicFilters(): Promise<ExercisesFilters> {
    try {
      const results = await Promise.all([
        pb.collection<LibraryCategoryRecord>("enum_exercises_category").getFullList({
          sort: "category",
        }),
        pb.collection<EquipmentRecord>("enum_exercises_equipment").getFullList({
          sort: "equipment",
        }),
        pb.collection<MuscleRecord>("enum_exercises_muscles").getFullList({
          sort: "muscle",
        }),
      ]);

      return createExercisesFilters({
        categories: results[0].map(createCategory),
        equipments: results[1].map(createEquipment),
        muscles: results[2].map(createMuscle),
      });
    } catch (error) {
      throw new ErrorUnknown(error);
    }
  }

  async createLibrary(data: CreateLibrary): Promise<Library> {
    const result = await pb.send<LibraryRecord>("/api/v1/library", {
      method: "POST",
      body: JSON.stringify(data),
    });
    return createLibrary({
      ...result,
      owner: createUser(result.expand?.owner),
    });
  }

  async shareLibraryWithGroup(id: string, groupId: string) {
    try {
      const result = await pb.send<LibrarySharedGroupRecord>(`api/v1/library/${id}/share/group`, {
        method: "POST",
        body: JSON.stringify({
          group: groupId,
        }),
      });
      return createLibrarySharedGroup({
        ...result,
        group: GroupRecordSchema.parse(result.expand?.group),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (
            error.response.data?.library?.code === ERROR_MISSING_REL ||
            error.response.data?.exercisesLibrary?.code === ERROR_MISSING_REL
          ) {
            throw new ErrorLibraryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async shareLibraryWithUsers(id: string, users: UserShareAction[]) {
    try {
      const result = await pb.send<boolean>(`/api/v1/library/${id}/share`, {
        method: "POST",
        body: JSON.stringify({ users }),
      });
      return result;
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (
            error.response.data?.library?.code === ERROR_MISSING_REL ||
            error.response.data?.exercisesLibrary?.code === ERROR_MISSING_REL
          ) {
            throw new ErrorLibraryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 409) {
          //extract user name from error message
          const pattern = /^(.+?) already has an invitation to join this library$/;
          const match = error.response.message.match(pattern);
          if (match) {
            throw new ErrorLibraryInviteExists(match[1].trim());
          }
          throw new ErrorLibraryInviteExists();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async leaveLibrary(id: string) {
    try {
      return pb.collection("exercises_library_shared_personal").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateLibrary(id: string, data: UpdateLibrary): Promise<Library> {
    try {
      const result = await pb.collection<LibraryRecord>("exercises_library").update(id, data, { expand: "owner" });
      return createLibrary({
        ...result,
        owner: createUser(result.expand?.owner),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteLibrary(id: string): Promise<boolean> {
    try {
      return await pb.collection<LibraryRecord>("exercises_library").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteExercise(id: string): Promise<boolean> {
    try {
      return await pb.collection<ExerciseRecord>("exercises").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorExerciseNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createExercise(libraryId: string, data: CreateExercise): Promise<Exercise> {
    const form = new FormData();

    form.append("name", data.name);
    for (const content of data.content) {
      form.append("content", content);
    }
    form.append("thumbnail", data.thumbnail);
    form.append("categories", data.categories?.join(",") || "");
    form.append("equipment", data.equipments?.join(",") || "");
    form.append("muscles", data.muscles?.join(",") || "");
    form.append("exercisesLibrary", libraryId);

    try {
      const result = await pb.send<ExerciseRecord>(`/api/v1/library/${libraryId}/exercise`, {
        method: "POST",
        body: form,
      });

      return createExercise({
        ...result,
        categories: expandAt<LibraryCategoryRecord>(result, "exercises_category_via_exercise").map((c) =>
          createCategory(c.expand?.category),
        ),
        muscles: expandAt<MuscleRecord>(result, "exercises_muscle_via_exercise").map((m) =>
          createMuscle(m.expand?.muscle),
        ),
        equipments: expandAt<EquipmentRecord>(result, "exercises_equipment_via_exercise").map((e) =>
          createEquipment(e.expand?.equipment),
        ),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.content) {
            if (error.response.data.content?.code === "validation_file_size_limit") {
              throw new ErrorExerciseContentTooLarge();
            } else throw new ErrorExerciseInvalidContent();
          }
          if (error.response.data?.exercisesLibrary?.code === ERROR_MISSING_REL) {
            throw new ErrorLibraryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 500) {
          if (error.response.data?.thumbnail) {
            throw new ErrorExerciseInvalidThumbnail();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateExercise(libraryId: string, exerciseId: string, data: UpdateExercise) {
    const form = new FormData();

    if (data.name) {
      form.append("name", data.name);
    }
    if (data.content) {
      for (const content of data.content) {
        form.append("content", content);
      }
    }
    form.append("content-", data["content-"]?.join(",") || "");

    if (data.thumbnail) {
      form.append("thumbnail", data.thumbnail);
    }
    form.append("categories", data.categories?.join(",") || "");
    form.append("categories-", data["categories-"]?.join(",") || "");
    form.append("equipment", data.equipment?.join(",") || "");
    form.append("equipment-", data["equipment-"]?.join(",") || "");
    form.append("muscles", data.muscles?.join(",") || "");
    form.append("muscles-", data["muscles-"]?.join(",") || "");

    try {
      const result = await pb.send<ExerciseRecord>(`/api/v1/library/${libraryId}/exercise/${exerciseId}`, {
        method: "PATCH",
        body: form,
      });

      return createExercise({
        ...result,
        categories: expandAt<LibraryCategoryRecord>(result, "exercises_category_via_exercise").map((c) =>
          createCategory(c.expand?.category),
        ),
        muscles: expandAt<MuscleRecord>(result, "exercises_muscle_via_exercise").map((m) =>
          createMuscle(m.expand?.muscle),
        ),
        equipments: expandAt<EquipmentRecord>(result, "exercises_equipment_via_exercise").map((e) =>
          createEquipment(e.expand?.equipment),
        ),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.content) {
            if (error.response.data.content?.code === "validation_file_size_limit") {
              throw new ErrorExerciseContentTooLarge();
            } else throw new ErrorExerciseInvalidContent();
          }
          if (error.response.data?.exercisesLibrary?.code === ERROR_MISSING_REL) {
            throw new ErrorLibraryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorExerciseNotFound();
        }
        if (error.status === 500) {
          if (error.response.data?.thumbnail) {
            throw new ErrorExerciseInvalidThumbnail();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async getLibraryCategories(libraryId: string): Promise<Category[]> {
    const result = await pb.collection<LibraryCategoryRecord>("library_category").getFullList({
      filter: `library = '${libraryId}'`,
      sort: "+category",
      expand: "exercises_category_via_category",
    });

    return result
      .map((c) => {
        const count = expandAt(c, "exercises_category_via_category").length;
        return createCategory({ ...c, count });
      })
      .toSorted((a, b) => {
        return (b?.count || 0) - (a?.count || 0);
      });
  }

  async getLibraryMuscles(libraryId: string): Promise<Muscle[]> {
    const result = await pb.collection<MuscleRecord>("library_muscle").getFullList({
      filter: `library = '${libraryId}'`,
      sort: "+muscle",
      expand: "exercises_muscle_via_muscle",
    });

    return result
      .map((muscle) => {
        const count = expandAt(muscle, "exercises_muscle_via_muscle").length;
        return createMuscle({ ...muscle, count });
      })
      .toSorted((a, b) => {
        return (b?.count || 0) - (a?.count || 0);
      });
  }

  async getLibraryEquipments(libraryId: string): Promise<Equipment[]> {
    const result = await pb.collection<EquipmentRecord>("library_equipment").getFullList({
      filter: `library = '${libraryId}'`,
      sort: "+equipment",
      expand: "exercises_equipment_via_equipment",
    });

    return result
      .map((c) => {
        const count = expandAt(c, "exercises_equipment_via_equipment").length;
        return createEquipment({ ...c, count });
      })
      .toSorted((a, b) => {
        return (b?.count || 0) - (a?.count || 0);
      });
  }

  async createCategory(category: string, libraryId: string) {
    try {
      const result = await pb.collection("library_category").create({ category, library: libraryId });
      return createCategory(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.category?.code === ERROR_NOT_UNIQUE) {
            throw new ErrorLibraryCategoryExists();
          }
          if (error.response.data?.title?.code === "limit_reached") {
            throw new ErrorLibraryCategoryLimitReached();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async removeCategory(categoryId: string) {
    try {
      return await pb.collection("library_category").delete(categoryId);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorLibraryCategoryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createMuscle(muscle: string, libraryId: string) {
    try {
      const result = await pb.collection("library_muscle").create({ muscle, library: libraryId });
      return createMuscle(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data.muscle.code === ERROR_NOT_UNIQUE) {
            throw new ErrorLibraryMuscleExists();
          }
          if (error.response.data?.title?.code === "limit_reached") {
            throw new ErrorLibraryCategoryLimitReached();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async removeMuscle(muscleId: string) {
    try {
      return await pb.collection("library_muscle").delete(muscleId);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorLibraryMuscleNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createEquipment(equipment: string, libraryId: string) {
    try {
      const result = await pb.collection("library_equipment").create({ equipment, library: libraryId });
      return createEquipment(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data.equipment.code === ERROR_NOT_UNIQUE) {
            throw new ErrorLibraryEquipmentExists();
          }
          if (error.response.data?.title?.code === "limit_reached") {
            throw new ErrorLibraryCategoryLimitReached();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async removeEquipment(equipmentId: string) {
    try {
      return pb.collection("library_equipment").delete(equipmentId);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorLibraryEquipmentNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }
}

export const libraryRepository = new LibraryRepository();
