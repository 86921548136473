import { msg, str } from "@lit/localize";
import { ApiError } from "../../error";

export class ErrorReportNotFound extends ApiError {
  constructor() {
    super("report-not-found", msg("A biblioteca não foi encontrada"));
  }
}

export class ErrorReportInviteExists extends ApiError {
  constructor(name?: string) {
    super("report-invite-exists", msg(str`O utilizador ${name} já foi convidado para a biblioteca de avaliações`));
  }
}

export class ErrorReportCategoryExists extends ApiError {
  constructor() {
    super("report-category-exists", msg("A categoria já existe nesta biblioteca de avaliações"));
  }
}

export class ErrorReportCategoryNotFound extends ApiError {
  constructor() {
    super("report-category-not-found", msg("A categoria não foi encontrada"));
  }
}

export class ErrorEvaluationScaleNotFound extends ApiError {
  constructor() {
    super("scale-not-found", msg("A avaliação não contém esta escala"));
  }
}

export class ErrorEvaluationNotFound extends ApiError {
  constructor() {
    super("evaluation-not-found", msg("A avaliação não foi encontrada"));
  }
}

export class ErrorEvaluationSectionNotFound extends ApiError {
  constructor() {
    super("evaluation-section-not-found", msg("A seção da avaliação não foi encontrada"));
  }
}

export class ErrorEvaluationFieldNotFound extends ApiError {
  constructor() {
    super("evaluation-field-not-found", msg("O campo da avaliação não existe"));
  }
}

export class ErrorScaleNotFound extends ApiError {
  constructor() {
    super("scale-not-found", msg("A escala não foi encontrada"));
  }
}

export class ErrorScaleFieldNotFound extends ApiError {
  constructor() {
    super("scale-field-not-found", msg("O campo da escala não foi encontrado"));
  }
}

export class ErrorScaleOptionNotFound extends ApiError {
  constructor() {
    super("scale-option-not-found", msg("A opção não foi encontrada"));
  }
}

export class ErrorPatientEvaluationNotFound extends ApiError {
  constructor() {
    super("patient-evaluation-not-found", msg("A avaliação do paciente não foi encontrada"));
  }
}

export class ErrorPatientEvaluationFieldNotFound extends ApiError {
  constructor() {
    super("patient-evaluation-field-not-found", msg("O campo da avaliação do paciente não existe"));
  }
}
