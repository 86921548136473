import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { LightElement } from "./components/component";

@customElement("app-root")
export class AppRoot extends LightElement {
  render() {
    return html`
      <auth-provider>
        <router-provider>
          <app-routes></app-routes>
        </router-provider>
      </auth-provider>
    `;
  }
}
