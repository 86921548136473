import { action, computed, makeObservable, observable, type IObservableArray } from "mobx";
import type {
  Evaluation,
  EvaluationField,
  EvaluationSection,
  EvaluationSummary,
  Category,
} from "../repository/report/report";

export class ObservableEvaluationSection {
  id: string;
  evaluation: string;
  @observable label: string;
  @observable position: number;
  @observable fields: IObservableArray<EvaluationField>;

  constructor(section: EvaluationSection) {
    this.id = section.id;
    this.evaluation = section.evaluation;
    this.label = section.label;
    this.position = section.position;
    this.fields = observable.array(section.fields);
    makeObservable(this);
  }

  @action merge(section: EvaluationSection) {
    this.label = section.label;
    this.position = section.position;
    return this;
  }
}

export class ObservableEvaluation {
  id: string;
  report: string;
  @observable name: string;
  @observable categories: IObservableArray<Category>;
  @observable sections: IObservableArray<ObservableEvaluationSection>;
  @observable isLoaded: boolean;

  constructor(evaluation: EvaluationSummary | Evaluation) {
    this.id = evaluation.id;
    this.report = evaluation.report;
    this.name = evaluation.name;
    this.categories = observable.array(evaluation.categories);
    this.isLoaded = false;
    if ("sections" in evaluation) {
      this.sections = observable.array(evaluation.sections.map((s) => new ObservableEvaluationSection(s)));
    } else {
      this.sections = observable.array([]);
    }
    makeObservable(this);
  }

  @computed get sortedSections() {
    return this.sections?.toSorted((a, b) => a.position - b.position);
  }

  @action merge(evaluation: EvaluationSummary) {
    this.name = evaluation.name;
    this.categories.replace(evaluation.categories);
    return this;
  }

  @action mergeSections(sections: EvaluationSection[]) {
    this.isLoaded = true;
    this.sections.replace(sections.map((s) => evaluationsStore.saveSection(s, this)));
  }

  @action saveSections(sections: EvaluationSection[]) {
    this.isLoaded = true;
    return sections.map((s) => evaluationsStore.saveSection(s, this));
  }

  @action cleanupSections(sections: EvaluationSection[]) {
    const sectionIds = new Set(sections.map((section) => section.id));
    const toDelete = this.sections.filter((section) => !sectionIds.has(section.id));
    for (const section of toDelete) {
      this.sections.remove(section);
    }
  }
}

class EvaluationsStore {
  @observable evaluations = observable.map<string, ObservableEvaluation>();

  constructor() {
    makeObservable(this);
  }

  find(id: string) {
    return this.evaluations.get(id);
  }

  exists(exercise: ObservableEvaluation) {
    return this.evaluations.has(exercise.id);
  }

  @action save(evaluation: EvaluationSummary) {
    const observable = new ObservableEvaluation(evaluation);
    this.evaluations.set(evaluation.id, observable);
    return observable;
  }

  @action saveSection(section: EvaluationSection, evaluation: ObservableEvaluation) {
    const observableSection = evaluation.sections.find((s) => s.id === section.id);
    if (observableSection) {
      return observableSection.merge(section);
    } else {
      const newSection = new ObservableEvaluationSection(section);
      evaluation.sections.push(newSection);
      return newSection;
    }
  }

  @action delete(evaluation: ObservableEvaluation) {
    return this.evaluations.delete(evaluation.id);
  }

  @action clearStore() {
    this.evaluations.clear();
  }

  @action subscribe(evaluation: EvaluationSummary) {
    const observable = this.find(evaluation.id);
    if (observable) {
      return observable.merge(evaluation);
    } else {
      return this.save(evaluation);
    }
  }

  /* @action cleanup(evaluations: (EvaluationSummary | ObservableEvaluation)[]) {
    runInAction(() => {
      const toDelete = evaluations.filter((e) => !this.find(e.id));
      for (const evaluation of toDelete) {
        this.evaluations.delete(evaluation.id);
      }
    });
  } */
}

export const evaluationsStore = new EvaluationsStore();
