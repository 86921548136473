import { action, makeObservable, observable, runInAction, type IObservableArray } from "mobx";
import { type CreatePatient, type Patient, type UpdatePatient } from "../repository/patient/patient";
import { patientRepository, type PatientFilters } from "../repository/patient/patient.repository";
import { groupStore, ObservableGroup, type ObservableSection } from "./groups.store";
import { ObservableDiagnostic, diagnosticsStore } from "./diagnostics.store";
import type { Pagination } from "./pagination.store";
import type { WithPage } from "../pocketbase";
import type { Category } from "../repository/group/category";

export class ObservablePatient {
  id: string;
  @observable section: string;
  @observable group: string;
  @observable name: string;
  @observable email: string;
  @observable phone: string;
  @observable gender: string;
  @observable birthdate: string;
  @observable address: string;
  @observable avatar: string | undefined;
  @observable clinicInfo: string | undefined;
  @observable isArchived: boolean;
  @observable categories: IObservableArray<Category>;
  @observable diagnostics: IObservableArray<ObservableDiagnostic>;
  @observable isLoaded: boolean;

  constructor(patient: Patient) {
    this.id = patient.id;
    this.section = patient.section;
    this.group = patient.group;
    this.name = patient.name;
    this.email = patient.email;
    this.phone = patient.phone;
    this.gender = patient.gender;
    this.birthdate = patient.birthdate;
    this.address = patient.address;
    this.avatar = patient.avatar;
    this.clinicInfo = patient.clinicInfo;
    this.isArchived = patient.isArchived;
    this.categories = observable.array(patient.categories);
    this.diagnostics = observable.array(patient.diagnostics.map((d) => diagnosticsStore.subscribe(d)));
    this.isLoaded = false;
    makeObservable(this);
  }

  @action merge(patient: Patient, isLoaded: boolean = false) {
    this.id = patient.id;
    this.section = patient.section;
    this.name = patient.name;
    this.email = patient.email;
    this.phone = patient.phone;
    this.gender = patient.gender;
    this.birthdate = patient.birthdate;
    this.address = patient.address;
    this.avatar = patient.avatar;
    this.clinicInfo = patient.clinicInfo;
    this.isArchived = patient.isArchived;
    this.categories = observable.array(patient.categories);
    this.diagnostics = observable.array(patient.diagnostics.map((d) => diagnosticsStore.subscribe(d)));
    this.isLoaded = isLoaded;
    return this;
  }

  @action update(patient: Patient) {
    this.id = patient.id;
    this.section = patient.section;
    this.name = patient.name;
    this.email = patient.email;
    this.phone = patient.phone;
    this.gender = patient.gender;
    this.birthdate = patient.birthdate;
    this.address = patient.address;
    this.avatar = patient.avatar;
    this.clinicInfo = patient.clinicInfo;
    this.isArchived = patient.isArchived;
    this.categories.replace(patient.categories);
    return this;
  }
}

class PatientsStore {
  @observable patients = observable.map<string, ObservablePatient>();

  constructor() {
    makeObservable(this);
  }

  find(id: string) {
    return this.patients.get(id);
  }

  exists(patient: ObservablePatient) {
    return this.patients.has(patient.id);
  }

  @action save(patient: ObservablePatient) {
    this.patients.set(patient.id, patient);
    return patient;
  }

  @action delete(patient: ObservablePatient) {
    if (!this.patients.delete(patient.id)) {
      throw new Error(`Patient with id ${patient.id} not found`);
    }
    return patient;
  }

  @action subscribe(patient: Patient) {
    const observable = this.find(patient.id);
    if (observable) {
      return observable.update(patient);
    } else {
      const newPatient = new ObservablePatient(patient);
      this.save(newPatient);
      return newPatient;
    }
  }

  @action clearStore() {
    runInAction(() => {
      this.patients.clear();
    });
  }

  @action
  async loadPatient(id: string, opts: { cache: boolean } = { cache: true }) {
    const cachedPatient = this.find(id);
    if (opts?.cache && cachedPatient?.isLoaded) {
      return cachedPatient;
    }

    const patient = await patientRepository.getPatient(id);
    const observable = new ObservablePatient(patient);

    runInAction(() => {
      if (cachedPatient) {
        cachedPatient.merge(patient, true);
      } else {
        observable.isLoaded = true;
        this.save(observable);
      }
    });

    if (cachedPatient) {
      return cachedPatient;
    }

    return observable;
  }

  @action
  async createPatient(section: ObservableSection, data: CreatePatient): Promise<ObservablePatient> {
    const newPatient = await patientRepository.createPatient(data);
    const observablePatient = new ObservablePatient(newPatient);

    runInAction(() => {
      this.save(observablePatient);
      section.patients?.push(observablePatient);
    });

    return observablePatient;
  }

  @action
  async updatePatient(patient: ObservablePatient, data: UpdatePatient) {
    const updatedPatient = await patientRepository.updatePatient(patient.id, data);
    runInAction(() => {
      const observablePatient = this.find(patient.id);
      if (observablePatient) {
        observablePatient.merge(updatedPatient);
      }
    });
    return updatedPatient;
  }

  @action
  async movePatientToSection(patient: ObservablePatient, section: ObservableSection) {
    if (patient.section === section.id) {
      return;
    }

    await patientRepository.moveToSection(patient.id, section.id, section.group);

    runInAction(() => {
      const oldSection = groupStore.findSection(patient.section);
      oldSection?.patients.remove(patient);
      patient.section = section.id;
      if (oldSection?.group !== section.group) {
        const oldGroup = groupStore.find(patient.group);
        if (oldGroup) {
          oldGroup.popularPatients = oldGroup.popularPatients.filter((p) => p.id !== patient.id);
        }
        patient.categories.clear();
      }
      section.patients.push(patient);
    });

    return patient;
  }

  @action async loadPatients(
    group: ObservableGroup | ObservableGroup[],
    pagination: Pagination,
    filters: PatientFilters = {},
  ): Promise<WithPage<ObservablePatient[]>> {
    let patients: WithPage<Patient[]>;
    if (Array.isArray(group)) {
      patients = await patientRepository.listPatients(
        group.map((g) => g.id),
        pagination,
        filters,
      );
    } else {
      patients = await patientRepository.listPatients(group.id, pagination, filters);
    }

    let observablePatients: ObservablePatient[] = [];

    runInAction(() => {
      patients.items.forEach((patient) => {
        const observable = this.find(patient.id);
        if (observable) {
          observablePatients.push(observable.merge(patient, true));
        } else {
          this.save(new ObservablePatient(patient));
        }
      });
    });

    return {
      ...patients,
      items: observablePatients,
    };
  }

  @action
  async activatePatient(patient: ObservablePatient) {
    const updatedPatient = await patientRepository.updatePatient(patient.id, { isArchived: false });

    runInAction(() => {
      patient.isArchived = updatedPatient.isArchived;
    });

    return patient;
  }

  @action
  async archivePatient(patient: ObservablePatient) {
    const updatedPatient = await patientRepository.updatePatient(patient.id, { isArchived: true });
    runInAction(() => {
      patient.isArchived = updatedPatient.isArchived;
    });

    return patient;
  }

  @action
  async deletePatient(patient: ObservablePatient) {
    await patientRepository.removePatient(patient.id);

    runInAction(() => {
      this.delete(patient);
      const section = groupStore.findSection(patient.section);
      if (section) {
        section.patients.remove(patient);
      }
    });
  }
}

export const patientsStore = new PatientsStore();
