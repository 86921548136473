import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";
import { repeat } from "lit/directives/repeat.js";

export enum COLOR_PICKER {
  GREEN = "#50E3C2",
  LIGHT_BLUE = "#AAFFEC",
  ORANGE = "#F5A729",
  LIGHT_ORANGE = "#FFEFCF",
  RED = "#FF3333",
  LIGHT_RED = "#F7D4D6",
  LIGHTER_RED = "#EAEAEA",
}

@customElement("xt-color-picker")
export class XTColorPicker extends LitElement {
  @property({ type: String }) color: string = COLOR_PICKER.GREEN;

  static styles = css`
    :host {
      --size: 24px;
      --border-radius: 99px;
      --border-width: 1.5px;
      --border-color: #000000;
    }

    div {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    button {
      width: var(--size);
      height: var(--size);
      border-radius: var(--border-radius);
      background-color: transparent;
      border: none;
    }

    button.selected {
      border: var(--border-width) solid var(--border-color);
    }
  `;

  render() {
    return html`
      <div>
        ${repeat(
          Object.values(COLOR_PICKER),
          (color) => color,
          (color) => html`
            <button
              id=${color}
              class="${this.color == color ? "selected" : ""}"
              style="background-color: ${color};"
              @click="${(e: Event) => this.toggleSelected(e)}"></button>
          `,
        )}
      </div>
    `;
  }

  toggleSelected(e: Event) {
    const id = (e.target as HTMLButtonElement).id;
    this.color = id;
    this.dispatchEvent(new CustomEvent("color-selected", { detail: { color: id }, bubbles: true, composed: true }));
  }
}
