import { provide } from "@lit/context";
import { customElement } from "lit/decorators.js";
import { LightElement } from "../components/component";
import { authContext, type AuthContext } from "./auth.context";
import { AuthStore } from "../stores/auth.store";
import { pb } from "../pocketbase";

@customElement("auth-provider")
export class AuthProvider extends LightElement {
  @provide({ context: authContext }) auth!: AuthContext;
  constructor() {
    super();
    this.auth = new AuthStore(pb);
  }
}
