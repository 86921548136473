import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { FinalFormController, zodValidator } from "../../controllers/final-form.controller";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import { UpdateScaleFieldSchema, type UpdateScaleField } from "../../repository/report/report";
import { alertController } from "@ionic/core";
import { reportsStore } from "../../stores/reports.store";
import type { ObservableScaleField } from "../../stores/scale.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../../error";

@customElement("modal-edit-scale-field")
@localized()
export class ModalEditScaleField extends Page {
  field!: ObservableScaleField;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  #form = new FinalFormController<UpdateScaleField>(this, {
    validate: zodValidator(UpdateScaleFieldSchema().pick({ label: true })),
    onSubmit: async (values) => {
      try {
        await reportsStore.updateScaleFieldLabel(values, this.field);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea;">
            <ion-buttons slot="start">
              <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Editar Campo")}</ion-title>
          </ion-toolbar>
        </ion-header>
        <form
          id="form-text-box"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none">
            <div>
              <span class="font-semibold font-display">${msg("Título do campo")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Título")}
                  enterkeyhint="send"
                  ${register("label", { initialValue: this.field.label })}>
                </ion-input>
              </ion-item>
              ${renderError("label")}
            </div>
          </ion-list>

          <ion-button
            form="form-text-box"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Guardar Alterações")}</ion-button
          >
        </form>
      </div>
    `;
  }
}
