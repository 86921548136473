import { customElement as litCustomElement } from "lit/decorators.js";

export function customElement(tagName: string) {
  return function (constructor: Function) {
    litCustomElement(tagName)(constructor as CustomElementConstructor);

    Object.defineProperty(constructor, "getName", {
      value: function () {
        return tagName;
      },
      writable: false,
    });
  };
}
