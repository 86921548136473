import { html } from "lit";
import { customElement } from "lit/decorators.js";
import type { PopoverButton } from "./popover.controller";
import { Page } from "./component";

@customElement("popover-content")
export class PopoverContent extends Page {
  buttons?: PopoverButton[];

  render() {
    return html`
      <div class="p-0">
        <ion-list>
          ${this.buttons?.map((button) => {
            const itemDefaultClass = "no-p no-detail font-semibold text-sm";

            const buttonClass = button.class ? button.class + " " + itemDefaultClass : itemDefaultClass;
            const labelClass = button.labelClass ? button.labelClass + " pl-3" : "pl-3";

            return html`
              <ion-item id=${button.id} @click=${button.handler} button .detail=${false} class=${buttonClass}>
                <ion-label class=${labelClass}>${button.text}</ion-label>
              </ion-item>
            `;
          })}
        </ion-list>
      </div>
    `;
  }
}
