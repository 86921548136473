import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { alertController, modalController } from "@ionic/core";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page, required } from "../components/component";
import type { UserAuth } from "../stores/auth.store";
// import { UserUpdateSchema } from "../repository/user/user";
import type { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { localized, msg } from "@lit/localize";
import { createUserUpdateSchema } from "../repository/user/user";

const formSchema = createUserUpdateSchema();

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-language-preferences")
@localized()
export class ModalLanguagePreferences extends Page {
  @required() user!: UserAuth;
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  connectedCallback(): void {
    super.connectedCallback();
    this.#controller.form.change("language", this.user.language);
  }

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(createUserUpdateSchema()),
    onSubmit: async (values) => {
      try {
        this.auth.updateProfile(values);
        window.dispatchEvent(new CustomEvent("locale-changed", { detail: values.language }));
        this.navigator.close();
      } catch (error) {
        const alert = await alertController.create({
          header: "Erro",
          message: "Ocorreu um erro ao criar mudar o idioma",
          buttons: ["OK"],
        });
        alert.present();
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click="${() => modalController.dismiss()}">
                ${msg("Cancelar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Escolher Idioma")}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="space-y-4" fullscreen>
        <form
          id="form-language-update"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}
          class="col space-y-4 pb-4">
          <ion-list class="mt-2">
            <ion-item
              id="en"
              button
              .detail=${formState.values.language === "en"}
              detail-icon="/assets/icons/check.svg"
              class="no-p no-inline-p"
              style="--detail-icon-color: var(--ion-color-success); --detail-icon-opacity: 1;"
              @click=${() => form.change("language", "en")}>
              <div class="py-4 w-full flex items-center justify-between">
                <ion-label class="font-semibold">English</ion-label>
              </div>
            </ion-item>
            <ion-item
              id="pt"
              button
              .detail=${formState.values.language === "pt"}
              detail-icon="/assets/icons/check.svg"
              class="no-p no-inline-p"
              style="--detail-icon-color: var(--ion-color-success); --detail-icon-opacity: 1;"
              @click=${() => form.change("language", "pt")}>
              <div class="py-4 w-full flex items-center justify-between">
                <ion-label class="font-semibold">Português</ion-label>
              </div>
            </ion-item>
          </ion-list>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar style="--background: transparent;">
          <ion-button
            form="form-language-update"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Guardar Alterações")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
