import { action, makeObservable, observable, runInAction, type IObservableArray, computed } from "mobx";
import {
  type CreateReport,
  type ReportSummary,
  type ReportSharedGroup,
  type ReportSharedPersonal,
  type EvaluationSummary,
  type ScaleSummary,
  type CreateScale,
  type CreateEvaluation,
  type CreateEvaluationSection,
  type UpdateEvaluationSection,
  type UpdateEvaluation,
  type CreateEvaluationFieldAttachment,
  type UpdateEvaluationFieldAttachment,
  type CreateEvaluationFieldNumericBox,
  type UpdateEvaluationFieldNumericBox,
  type CreateEvaluationFieldTextBox,
  type UpdateEvaluationFieldTextBox,
  type CreateEvaluationFieldResultInteger,
  type UpdateEvaluationFieldResultInteger,
  type CreateEvaluationFieldResultPercentage,
  type UpdateEvaluationFieldResultPercentage,
  type UpdateEvaluationFieldUrl,
  type CreateEvaluationFieldUrl,
  type CreateEvaluationFieldYesOrNo,
  type EvaluationFieldResultInteger,
  type EvaluationFieldResultPercentage,
  type UpdateEvaluationFieldYesOrNo,
  type EvaluationFieldYesOrNo,
  type CreateScaleField,
  type UpdateScale,
  type UpdateScaleField,
  type CreateScaleOption,
  type UpdateScaleOption,
  type EvaluationField,
  type EvaluationFieldNumericBox,
  type EvaluationFieldTextBox,
  type EvaluationFieldAttachment,
  type EvaluationFieldUrl,
  type CreateEvaluationFieldScale,
  type UpdateReport,
} from "../repository/report/report";
import type { User } from "../repository/user/user";
import type { UserAuth } from "./auth.store";
import { reportRepository } from "../repository/report/report.repository";
import { ObservableEvaluation, ObservableEvaluationSection, evaluationsStore } from "./evaluation.store";
import { ObservableScale, ObservableScaleField, scalesStore } from "./scale.store";
import type { UserShareAction } from "./userShare.store";
import { ErrorUnknown } from "../error";
import {
  ErrorEvaluationFieldNotFound,
  ErrorEvaluationNotFound,
  ErrorEvaluationSectionNotFound,
  ErrorReportNotFound,
  ErrorScaleFieldNotFound,
  ErrorScaleNotFound,
  ErrorScaleOptionNotFound,
} from "../repository/report/errors";

export class ObservableReport {
  @observable id: string;
  @observable name: string;
  @observable owner: User;
  @observable sharedGroup: ReportSharedGroup | null;
  @observable sharedPersonal: IObservableArray<ReportSharedPersonal>;
  @observable evaluations: IObservableArray<ObservableEvaluation>;
  @observable scales: IObservableArray<ObservableScale>;
  @observable initialTotalEvaluations: number;
  @observable initialTotalScales: number;
  @observable isLoaded = false;
  @observable areTemplatesLoaded = false;

  constructor(report: ReportSummary) {
    this.id = report.id;
    this.name = report.name;
    this.owner = report.owner;
    this.sharedGroup = report.sharedGroup?.[0] || null;
    this.sharedPersonal = observable.array(report.sharedPersonal);
    this.evaluations = observable.array([]);
    this.scales = observable.array([]);
    this.initialTotalEvaluations = report.totalEvaluations || 0;
    this.initialTotalScales = report.totalScales || 0;
    this.isLoaded = true;
    this.areTemplatesLoaded = false;
    makeObservable(this);
  }

  @computed get totalTemplates() {
    if (this.areTemplatesLoaded) {
      return this.evaluations.length + this.scales.length;
    }
    return this.initialTotalEvaluations + this.initialTotalScales;
  }

  @computed get totalEvaluations() {
    return this.evaluations.length;
  }

  @computed get allTemplates() {
    return [...this.evaluations, ...this.scales];
  }

  @action merge(report: ReportSummary) {
    this.name = report.name;
    this.owner = report.owner;
    if (report.sharedGroup) {
      this.sharedGroup = report.sharedGroup[0] || null;
    }
    if (report.sharedPersonal) {
      this.sharedPersonal.replace(report.sharedPersonal);
    }
  }

  @action mergeTemplates(templates: { evaluations?: EvaluationSummary[]; scales?: ScaleSummary[] }) {
    if (templates.evaluations) {
      this.evaluations.replace(templates.evaluations.map((e) => evaluationsStore.save(e)));
    }
    if (templates.scales) {
      this.scales.replace(templates.scales.map((s) => scalesStore.save(s)));
    }
  }

  isOwner(user: User | UserAuth) {
    return this.owner.id === user.id;
  }

  isAdmin(user: User | UserAuth) {
    return this.findUser(user)?.access.role === "admin";
  }

  isShared(user: User | UserAuth) {
    return this.findUser(user) !== undefined && !this.isOwner(user);
  }

  isSharedFromGroup() {
    return this.sharedGroup !== null;
  }

  canEdit(user: User | UserAuth) {
    return this.isOwner(user) || this.isAdmin(user) || this.findUser(user)?.access.role === "editor";
  }

  hasPrivileges(user: User | UserAuth) {
    return this.isOwner(user) || this.isAdmin(user);
  }

  private findUser(user: User | UserAuth) {
    return this.sharedPersonal.find((s) => s.user.id === user.id);
  }
}

class ReportsStore {
  @observable reports = observable.array<ObservableReport>([]);
  @observable isLoaded = false;

  public constructor() {
    makeObservable(this);
  }

  findReport(id: string) {
    return this.reports.find((r) => r.id === id);
  }

  @action save(report: ReportSummary) {
    const observable = new ObservableReport(report);
    this.reports.push(observable);
    return observable;
  }

  @action delete(report: ObservableReport) {
    this.reports.remove(report);
  }

  @action clearStore() {
    this.reports.clear();
  }

  @action cleanup(reports: (ReportSummary | ObservableReport)[]) {
    runInAction(() => {
      const toDelete = this.reports.filter((r) => !reports.some((ng) => ng.id === r.id));
      for (const report of toDelete) {
        this.delete(report);
      }
    });
  }

  @action async loadReports(opts = { cache: true }) {
    if (opts.cache && this.isLoaded) {
      return this.reports;
    }

    const reports = await reportRepository.listReports();

    runInAction(() => {
      this.cleanup(reports);

      //public report should come first
      reports.sort((a, b) => {
        if (a.id === "public") {
          return -1;
        }
        if (b.id === "public") {
          return 1;
        }
        return 0;
      });
      this.reports.replace(reports.map((r) => new ObservableReport(r)));
      this.isLoaded = true;
    });

    return this.reports;
  }

  @action async leaveReport(report: ObservableReport, user: UserAuth) {
    const shareId = report.sharedPersonal.find((s) => s.user.id === user.id)?.id;
    if (!shareId) {
      throw new Error("<leaveLibrary>: library not shared with user");
    }

    try {
      await reportRepository.leaveReport(shareId);
    } catch (error) {
      if (error instanceof ErrorReportNotFound) {
        runInAction(() => {
          this.delete(report);
        });
      }
      throw error;
    }

    runInAction(() => {
      this.delete(report);
    });

    return report;
  }

  @action async createReport(data: CreateReport) {
    const report = await reportRepository.createReport(data);
    let observable!: ObservableReport;

    runInAction(() => {
      observable = this.save(report);
    });

    return observable;
  }

  @action async loadReport(id: string, opts = { cache: true }) {
    const cachedReport = this.reports.find((r) => r.id === id);

    if (cachedReport && opts.cache && cachedReport.isLoaded) {
      return cachedReport;
    }

    const report = await reportRepository.getReport(id);

    let observable!: ObservableReport;

    runInAction(() => {
      if (cachedReport) {
        cachedReport.merge(report);
      } else {
        observable = this.save(report);
      }
    });

    if (cachedReport) {
      return cachedReport;
    }

    return observable;
  }

  @action async updateReport(report: ObservableReport, data: UpdateReport) {
    const result = await reportRepository.updateReport(report.id, data);
    runInAction(() => {
      report.merge(result);
    });

    return report;
  }

  @action async shareWithGroup(report: ObservableReport, groupId: string) {
    const sharedGroup = await reportRepository.shareReportWithGroup(report.id, groupId);

    runInAction(() => {
      report.sharedGroup = sharedGroup;
    });

    return report;
  }

  @action async shareWithUsers(report: ObservableReport, users: UserShareAction[]) {
    await reportRepository.shareReportWithUsers(report.id, users);

    runInAction(() => {
      // Remove deleted items
      const idsToDelete = users.filter((gs) => gs.action === "delete").map((gs) => gs.user.id);
      report.sharedPersonal.replace(report.sharedPersonal.filter((gs) => !idsToDelete.includes(gs.user.id)));

      // Apply updates
      users.forEach((u) => {
        if (u.action === "update") {
          const sp = report.sharedPersonal.find((item) => item.user.id === u.user.id);
          if (sp) {
            sp.access.id = u.access.id;
            sp.access.role = u.access.role;
          }
        }
      });
    });

    return report;
  }

  @action async deleteReport(report: ObservableReport) {
    try {
      await reportRepository.deleteReport(report.id);
      runInAction(() => {
        report.evaluations.map((e) => evaluationsStore.delete(e));
        report.scales.map((s) => scalesStore.delete(s));
        this.delete(report);
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorReportNotFound) {
        runInAction(() => {
          report.evaluations.map((e) => evaluationsStore.delete(e));
          report.scales.map((s) => scalesStore.delete(s));
          this.delete(report);
        });
        return true;
      }
      throw new ErrorUnknown(error);
    }
  }

  @action async loadReportTemplates(id: string, opts = { cache: true }) {
    const cachedReport = this.reports.find((r) => r.id === id);

    if (cachedReport && opts.cache && cachedReport.areTemplatesLoaded) {
      return cachedReport;
    }

    const [evaluations, scales] = await Promise.all([
      reportRepository.getEvaluations(id),
      reportRepository.getScales(id),
    ]);

    runInAction(() => {
      if (cachedReport) {
        cachedReport.mergeTemplates({ evaluations, scales });
        cachedReport.areTemplatesLoaded = true;
      }
    });

    return cachedReport;
  }

  @action async insertScale(data: CreateScale) {
    const scale = await reportRepository.createScale(data);
    let observable!: ObservableScale;

    const report = this.reports.find((r) => r.id === scale.report);

    runInAction(() => {
      observable = scalesStore.save(scale);
      if (report) {
        report.scales.push(observable);
      }
    });

    return observable;
  }

  @action async updateScale(data: UpdateScale, scale: ObservableScale) {
    const result = await reportRepository.updateScale(scale.id, data);
    runInAction(() => {
      scale.merge(result);
    });
  }

  @action async deleteScale(scale: ObservableScale) {
    try {
      await reportRepository.deleteScale(scale.id);
      runInAction(() => {
        scalesStore.delete(scale);
        const report = this.reports.find((r) => r.id === scale.report);
        if (report) {
          report.scales.remove(scale);
        }
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorScaleNotFound) {
        runInAction(() => {
          scalesStore.delete(scale);
          const report = this.reports.find((r) => r.id === scale.report);
          if (report) {
            report.scales.remove(scale);
          }
        });
        return true;
      }
      throw error;
    }
  }

  @action async loadScale(reportId: string, scaleId: string, opts = { cache: true }) {
    const cachedReport = this.reports.find((r) => r.id === reportId);
    if (!cachedReport) {
      await this.loadReport(reportId, opts);
    }
    const cachedScale = cachedReport?.scales.find((s) => s.id === scaleId);

    if (cachedScale && opts.cache && cachedScale.isLoaded) {
      return cachedScale;
    }

    const scale = await reportRepository.getScale(scaleId);
    let observable!: ObservableScale;

    runInAction(() => {
      if (cachedScale) {
        cachedScale.cleanupFields(scale.fields);
        cachedScale.merge(scale);
        cachedScale.mergeFields(scale.fields);
      } else if (cachedReport) {
        observable = scalesStore.save(scale);
        cachedReport.scales.push(observable);
      } else {
        observable = scalesStore.save(scale);
      }
    });

    if (cachedScale) {
      return cachedScale;
    }
    return observable;
  }

  @action async insertScaleField(data: CreateScaleField, scale: ObservableScale) {
    const field = await reportRepository.createScaleField(data);
    runInAction(() => {
      scale.saveFields([field]);
    });
  }

  @action async updateScaleFieldLabel(data: UpdateScaleField, field: ObservableScaleField) {
    const result = await reportRepository.updateScaleFieldLabel(field.id, data);

    runInAction(() => {
      field.label = result.label;
    });
  }

  @action async updateScaleFieldPosition(
    field1: ObservableScaleField,
    data1: UpdateScaleField,
    field2: ObservableScaleField,
    data2: UpdateScaleField,
  ) {
    const [result1, result2] = await reportRepository.updateScaleFieldPosition(field1.id, data1, field2.id, data2);

    runInAction(() => {
      field1.position = result1.position;
      field2.position = result2.position;
    });
  }

  @action async deleteScaleField(scale: ObservableScale, field: ObservableScaleField) {
    try {
      await reportRepository.deleteScaleField(field.id);
      runInAction(() => {
        const removedPosition = field.position;
        scale.fields.remove(field);
        scale.fields.forEach((s) => {
          if (s.position > removedPosition) {
            s.position--;
          }
        });
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorScaleFieldNotFound) {
        runInAction(() => {
          const removedPosition = field.position;
          scale.fields.remove(field);
          scale.fields.forEach((s) => {
            if (s.position > removedPosition) {
              s.position--;
            }
          });
        });
        return true;
      }
      throw error;
    }
  }

  @action async insertScaleOption(data: CreateScaleOption, field: ObservableScaleField) {
    const option = await reportRepository.createScaleOption(data);
    runInAction(() => {
      field.options.push(option);
    });
  }

  @action async updateScaleOption(id: string, data: UpdateScaleOption, field: ObservableScaleField) {
    const result = await reportRepository.updateScaleOption(id, data);

    runInAction(() => {
      const option = field.options.find((o) => o.id === result.id);
      option!.label = result.label;
      option!.value = result.value;
    });
  }

  @action async deleteScaleOption(id: string, field: ObservableScaleField) {
    try {
      await reportRepository.deleteScaleOption(id);
      runInAction(() => {
        const option = field.options.find((o) => o.id === id);
        if (option) {
          field.options.remove(option);
        }
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorScaleOptionNotFound) {
        runInAction(() => {
          const option = field.options.find((o) => o.id === id);
          if (option) {
            field.options.remove(option);
          }
        });
        return true;
      }
      throw error;
    }
  }

  @action async insertEvaluation(data: CreateEvaluation) {
    const evaluation = await reportRepository.createEvaluation(data);
    let observable!: ObservableEvaluation;

    const report = this.reports.find((r) => r.id === evaluation.report);

    runInAction(() => {
      observable = evaluationsStore.save(evaluation);
      if (report) {
        report.evaluations.push(observable);
      }
    });

    return observable;
  }

  @action async loadEvaluation(reportId: string, evaluationId: string, opts = { cache: true }) {
    const cachedReport = this.reports.find((r) => r.id === reportId);
    if (!cachedReport) {
      await this.loadReport(reportId, opts);
    }

    const cachedEvaluation = cachedReport?.evaluations.find((e) => e.id === evaluationId);
    if (cachedEvaluation && opts.cache && cachedEvaluation.isLoaded) {
      return cachedEvaluation;
    }

    const evaluation = await reportRepository.getEvaluation(evaluationId);
    let observable!: ObservableEvaluation;

    runInAction(() => {
      if (cachedEvaluation) {
        cachedEvaluation.cleanupSections(evaluation.sections);
        cachedEvaluation.merge(evaluation);
        cachedEvaluation.mergeSections(evaluation.sections);
      } else if (cachedReport) {
        observable = evaluationsStore.save(evaluation);
        cachedReport.evaluations.push(observable);
      } else {
        observable = evaluationsStore.save(evaluation);
      }
    });

    if (cachedEvaluation) {
      return cachedEvaluation;
    }
    return observable;
  }

  @action async updateEvaluation(data: UpdateEvaluation, evaluation: ObservableEvaluation) {
    const result = await reportRepository.updateEvaluation(evaluation.id, data);
    runInAction(() => {
      evaluation.merge(result);
    });
  }

  @action async deleteEvaluation(evaluation: ObservableEvaluation) {
    try {
      await reportRepository.deleteEvaluation(evaluation.id);
      runInAction(() => {
        evaluationsStore.delete(evaluation);
        const report = this.reports.find((r) => r.id === evaluation.report);
        if (report) {
          report.evaluations.remove(evaluation);
        }
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorEvaluationNotFound) {
        runInAction(() => {
          evaluationsStore.delete(evaluation);
          const report = this.reports.find((r) => r.id === evaluation.report);
          if (report) {
            report.evaluations.remove(evaluation);
          }
        });
        return true;
      }
      throw error;
    }
  }

  @action async insertEvaluationSection(data: CreateEvaluationSection, evaluation: ObservableEvaluation) {
    const section = await reportRepository.createEvaluationSection(data);
    runInAction(() => {
      evaluation.saveSections([section]);
    });
  }

  @action async updateEvaluationSectionLabel(
    id: string,
    data: UpdateEvaluationSection,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationSectionLabel(id, data);

    runInAction(() => {
      section.label = result.label;
    });
  }

  @action async updateEvaluationSectionPosition(
    section1: ObservableEvaluationSection,
    data1: UpdateEvaluationSection,
    section2: ObservableEvaluationSection,
    data2: UpdateEvaluationSection,
  ) {
    const [result1, result2] = await reportRepository.updateEvaluationSectionPosition(
      section1.id,
      data1,
      section2.id,
      data2,
    );

    runInAction(() => {
      section1.position = result1.position;
      section2.position = result2.position;
    });
  }

  @action async deleteEvaluationSection(evaluation: ObservableEvaluation, section: ObservableEvaluationSection) {
    try {
      await reportRepository.deleteEvaluationSection(section.id);
      runInAction(() => {
        const removedPosition = section.position;
        evaluation.sections.remove(section);
        evaluation.sections.forEach((s) => {
          if (s.position > removedPosition) {
            s.position--;
          }
        });
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorEvaluationSectionNotFound) {
        runInAction(() => {
          const removedPosition = section.position;
          evaluation.sections.remove(section);
          evaluation.sections.forEach((s) => {
            if (s.position > removedPosition) {
              s.position--;
            }
          });
        });
        return true;
      }
      throw error;
    }
  }

  @action async insertEvaluationFieldAttachment(
    data: CreateEvaluationFieldAttachment,
    section: ObservableEvaluationSection,
  ) {
    const attachment = await reportRepository.createEvaluationFieldAttachment(data);
    runInAction(() => {
      section.fields.push(attachment);
    });
  }

  @action async insertEvaluationFieldNumericBox(
    data: CreateEvaluationFieldNumericBox,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.createEvaluationFieldNumericBox(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async insertEvaluationFieldTextBox(data: CreateEvaluationFieldTextBox, section: ObservableEvaluationSection) {
    const result = await reportRepository.createEvaluationFieldTextBox(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async insertEvaluationFieldUrl(data: CreateEvaluationFieldUrl, section: ObservableEvaluationSection) {
    const result = await reportRepository.createEvaluationFieldUrl(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async insertEvaluationFieldYesOrNo(data: CreateEvaluationFieldYesOrNo, section: ObservableEvaluationSection) {
    const result = await reportRepository.createEvaluationFieldYesOrNo(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async insertEvaluationFieldResultInteger(
    data: CreateEvaluationFieldResultInteger,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.createEvaluationFieldResultInteger(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async insertEvaluationFieldResultPercentage(
    data: CreateEvaluationFieldResultPercentage,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.createEvaluationFieldResultPercentage(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async insertEvaluationFieldScale(data: CreateEvaluationFieldScale, section: ObservableEvaluationSection) {
    const result = await reportRepository.createEvaluationFieldScale(data);
    runInAction(() => {
      section.fields.push(result);
    });
  }

  @action async updateEvaluationFieldAttachment(
    id: string,
    data: UpdateEvaluationFieldAttachment,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldAttachment(id, data);
    runInAction(() => {
      const attachment = section.fields.find((f) => f.id === result.id) as EvaluationFieldAttachment | undefined;
      if (attachment) {
        attachment.label = result.label;
      }
    });
  }

  @action async updateEvaluationFieldNumericBox(
    id: string,
    data: UpdateEvaluationFieldNumericBox,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldNumericBox(id, data);
    runInAction(() => {
      const nb = section.fields.find((f) => f.id === result.id) as EvaluationFieldNumericBox | undefined;
      if (nb) {
        nb.label = result.label;
      }
    });
  }

  @action async updateEvaluationFieldTextBox(
    id: string,
    data: UpdateEvaluationFieldTextBox,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldTextBox(id, data);
    runInAction(() => {
      const nb = section.fields.find((f) => f.id === result.id) as EvaluationFieldTextBox | undefined;
      if (nb) {
        nb.label = result.label;
      }
    });
  }

  @action async updateEvaluationFieldResultInteger(
    id: string,
    data: UpdateEvaluationFieldResultInteger,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldResultInteger(id, data);
    runInAction(() => {
      const nb = section.fields.find((f) => f.id === result.id) as EvaluationFieldResultInteger | undefined;
      if (nb) {
        nb.label = result.label;
        nb.minimum = result.minimum;
        nb.maximum = result.maximum;
      }
    });
  }

  @action async updateEvaluationFieldResultPercentage(
    id: string,
    data: UpdateEvaluationFieldResultPercentage,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldResultPercentage(id, data);
    runInAction(() => {
      const nb = section.fields.find((f) => f.id === result.id) as EvaluationFieldResultPercentage | undefined;
      if (nb) {
        nb.label = result.label;
        nb.minimum = result.minimum;
        nb.maximum = result.maximum;
      }
    });
  }

  @action async updateEvaluationFieldUrl(
    id: string,
    data: UpdateEvaluationFieldUrl,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldUrl(id, data);
    runInAction(() => {
      const nb = section.fields.find((f) => f.id === result.id) as EvaluationFieldUrl | undefined;
      if (nb) {
        nb.label = result.label;
      }
    });
  }

  @action async updateEvaluationFieldYesOrNo(
    id: string,
    data: UpdateEvaluationFieldYesOrNo,
    section: ObservableEvaluationSection,
  ) {
    const result = await reportRepository.updateEvaluationFieldYesOrNo(id, data);
    runInAction(() => {
      const nb = section.fields.find((f) => f.id === result.id) as EvaluationFieldYesOrNo | undefined;
      if (nb) {
        nb.label = result.label;
        nb.option1 = result.option1;
        nb.option2 = result.option2;
        nb.option3 = result.option3;
        nb.option4 = result.option4;
        nb.option5 = result.option5;
      }
    });
  }

  @action async deleteEvaluationField(field: EvaluationField, section: ObservableEvaluationSection) {
    try {
      await reportRepository.deleteEvaluationField(field.id, field.collectionName);
      runInAction(() => {
        const cachedField = section.fields.find((f) => f.id === field.id);
        if (cachedField) {
          section.fields.remove(cachedField);
        }
      });
      return true;
    } catch (error) {
      if (error instanceof ErrorEvaluationFieldNotFound) {
        runInAction(() => {
          const cachedField = section.fields.find((f) => f.id === field.id);
          if (cachedField) {
            section.fields.remove(cachedField);
          }
        });
        return true;
      }
      throw error;
    }
  }
}

export const reportsStore = new ReportsStore();
