import { alertController, popoverController, toastController, type ItemReorderCustomEvent } from "@ionic/core";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import { format } from "date-fns";
import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { runInAction } from "mobx";
import { Page } from "../components/component";
import { authContext, type AuthContext } from "../context/auth.context";
import { routerContext, type RouterContext } from "../context/router.context";
import type { ModalNav } from "../modals/modal-nav";
import type { UpdateSession } from "../repository/patient/diagnostic";
import { diagnosticRepository } from "../repository/patient/diagnostic.repository";
import { ObservableDiagnostic, diagnosticsStore } from "../stores/diagnostics.store";
import { groupStore } from "../stores/groups.store";
import { ObservableExercisePlan, sessionsStore, type ObservableSession } from "../stores/sessions.store";
import { pb } from "../pocketbase";
import type { PublicExercise } from "../repository/library/exercises";
import { ObservableExercise } from "../stores/exercises.store";
import { localized, msg, str } from "@lit/localize";
import { getDateFnsLocale } from "../date-fns-locale";
import { ApiError } from "../error";
import { ErrorSessionNotFound } from "../repository/patient/errors";

@customElement("page-diagnostic")
@localized()
export class PageDiagnostic extends Page {
  @state() private _groupId: string = "";
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  #modalEditDiagnosticRef: Ref<ModalNav> = createRef();
  #modalNewSessionRef: Ref<ModalNav> = createRef();
  #modalEditSessionRef: Ref<ModalNav> = createRef();
  #modalCreatePlanExerciseRef: Ref<ModalNav> = createRef();
  #modalEditExercisePlanRef: Ref<ModalNav> = createRef();
  #modalChooseEvaluationRef: Ref<ModalNav> = createRef();
  #modalEvaluationRef: Ref<ModalNav> = createRef();
  #modalChooseTemplateRef: Ref<ModalNav> = createRef();

  #ionRefresher: Ref<IonRefresher> = createRef();

  connectedCallback(): void {
    super.connectedCallback();
    this._groupId = this.router.getParam("groupId");
    this.#group.run([true]);
    this.#diagnostic.run([true]);
  }

  #diagnostic = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return diagnosticsStore.load(this.router.getParam("diagnosticId"), { cache });
    },
  });

  #group = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return groupStore.loadGroup(this.router.getParam("groupId"), { cache });
    },
  });

  getDateChip(date: string, startTime: string, endTime: string) {
    const today = new Date();
    const time = today.getHours() + ":" + today.getMinutes();
    const formattedToday = [
      String(today.getDate()).padStart(2, "0"),
      String(today.getMonth() + 1).padStart(2, "0"),
      today.getFullYear(),
    ].join("-");
    if (date === formattedToday && startTime < time && time < endTime) {
      return html`<ion-chip style="--background: #79FFE1; --color: #000000">${msg("A decorrer")}</ion-chip> `;
    }
    return html` <ion-chip class="capitalize font-semibold" style="--background: #EAEAEA; --color: #111111"
      >${this.getDayMonth(date)}</ion-chip
    >`;
  }

  formatDate(isoDateString: string) {
    const date = format(new Date(isoDateString), "P", { locale: getDateFnsLocale(this.auth.user.language) });
    return date.replace(/\//g, "-");
  }

  getDayMonth(isoDateString: string) {
    return format(new Date(isoDateString), "dd MMMM", { locale: getDateFnsLocale(this.auth.user.language) });
  }

  getTime(isoDateString: string) {
    return format(new Date(isoDateString), "HH:mm");
  }

  async presentDeleteSessionAlert(diagnostic: ObservableDiagnostic, session: ObservableSession) {
    await popoverController.dismiss();
    const alert = await alertController.create({
      header: msg("Apagar Sessão"),
      message: msg("Tem a certeza que deseja apagar a sessão? Esta ação é irreversível."),
      buttons: [
        { text: msg("Cancelar"), role: "cancel" },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await sessionsStore.deleteSession(diagnostic, session);
              this.requestUpdate();
              alert.dismiss();
            } catch (error) {
              alert.dismiss();
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["OK"],
                });
                alert.present();
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async presentDeleteDiagnosticAlert(diagnostic: ObservableDiagnostic | undefined) {
    await popoverController.dismiss();
    if (!diagnostic) {
      return;
    }
    const alert = await alertController.create({
      header: msg("Apagar Diagnóstico"),
      message: msg("Tem a certeza que deseja apagar este diagnóstico? Esta ação é irreversível."),
      buttons: [
        { text: msg("Cancelar"), role: "cancel" },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await diagnosticsStore.deleteDiagnostic(diagnostic);
              alert.dismiss();
              this.router.back();
            } catch (error) {
              alert.dismiss();
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["OK"],
                });
                alert.present();
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async changeCompletedStatus(diagnostic: ObservableDiagnostic) {
    try {
      await diagnosticsStore.updateDiagnostic(diagnostic, {
        isCompleted: !diagnostic.isCompleted,
        name: diagnostic.name,
        description: diagnostic.description,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: error.message,
          buttons: ["OK"],
        });
        alert.present();
      }
    }
  }

  async editSessionTreatment(session: ObservableSession, treatment?: string) {
    const data: UpdateSession = {
      name: session.name,
      day: session.day,
      startTime: session.startTime,
      endTime: session.endTime,
      diagnostic: session.diagnostic,
      treatment: treatment,
    };
    try {
      await sessionsStore.updateSession(session, data);
      const toast = await toastController.create({
        message: msg("Tratamento atualizado com sucesso"),
        duration: 2000,
        color: "primary",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    } catch (error) {
      if (error instanceof ErrorSessionNotFound) {
        const toast = await toastController.create({
          message: msg("Falhou a guardar - a sessão não existe"),
          duration: 2000,
          color: "danger",
          icon: "/assets/icons/info.svg",
          position: "bottom",
        });
        await toast.present();
      } else {
        const toast = await toastController.create({
          message: msg("Não foi possível guardar as alterações"),
          duration: 2000,
          color: "danger",
          position: "bottom",
          icon: "/assets/icons/info.svg",
        });
        await toast.present();
      }
    }
  }

  async editSessionNotes(session: ObservableSession, notes?: string) {
    const data: UpdateSession = {
      name: session.name,
      day: session.day,
      startTime: session.startTime,
      endTime: session.endTime,
      diagnostic: session.diagnostic,
      notes: notes,
    };
    try {
      await sessionsStore.updateSession(session, data);
      const toast = await toastController.create({
        message: msg("Notas atualizadas com sucesso"),
        duration: 2000,
        color: "primary",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    } catch (error) {
      console.log(error);
      if (error instanceof ErrorSessionNotFound) {
        const toast = await toastController.create({
          message: msg("Falhou a guardar - a sessão não existe"),
          duration: 2000,
          color: "danger",
          icon: "/assets/icons/info.svg",
          position: "bottom",
        });
        await toast.present();
      } else {
        const toast = await toastController.create({
          message: msg("Não foi possível guardar as alterações"),
          duration: 2000,
          color: "danger",
          position: "bottom",
          icon: "/assets/icons/info.svg",
        });
        await toast.present();
      }
    }
  }

  onReorder = async (e: Event, session: ObservableSession, exercisePlan: ObservableExercisePlan[]) => {
    const detail = (e as ItemReorderCustomEvent).detail;

    const fromIdx = detail.from;
    const toIdx = detail.to;

    if (fromIdx === toIdx) {
      detail.complete();
      return;
    }

    runInAction(() => {
      // Move the item in the array
      const [movedItem] = exercisePlan.splice(fromIdx, 1);
      if (!movedItem) {
        detail.complete();
        return;
      }
      exercisePlan.splice(toIdx, 0, movedItem);

      // Update positions
      exercisePlan.forEach((item, idx) => {
        item.position = idx;
      });
    });

    diagnosticRepository.updateExercisePlanPositions(session.id, exercisePlan);

    detail.complete();
  };

  canEdit() {
    const group = groupStore.find(this._groupId);
    if (!group) return false;
    return group.canEdit(this.auth.user);
  }

  getSpecs(e: ObservableExercisePlan) {
    let specs = [];

    if (e.series) {
      specs.push(html`<span class="text-sm">${msg(str`${e.series} Séries`)}</span>`);
    }
    if (e.repetitions) {
      specs.push(html`<span class="text-sm">${msg(str`${e.repetitions} Repetições`)}</span>`);
    }
    if (e.weight) {
      specs.push(html`<span class="text-sm">${e.weight} ${e.weightUnit}</span>`);
    }
    if (e.duration) {
      const totalSeconds = e.duration;
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      if (minutes > 0) specs.push(html`<span class="text-sm">${msg(str`${minutes} Minutos`)}</span>`);
      if (seconds > 0) specs.push(html`<span class="text-sm">${msg(str`${seconds} Segundos`)}</span>`);
    }

    return specs.slice(0, 2);
  }

  render() {
    return html`
      <ion-content class="space-y-4 no-px" fullscreen>
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            await this.#diagnostic.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text="${msg("Puxe para atualizar")}"></ion-refresher-content>
        </ion-refresher>
        <!-- HEADER -->

        <div class="flex justify-between items-center px-4 mt-4">
          <ion-router-link href=${this.router.backUrl} router-direction="back">
            <ion-button fill="clear" class="font-semibold no-p no-inline-p no-m-inline no-m">
              <span class="flex items-center -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg("voltar")}
            </ion-button>
          </ion-router-link>
          ${when(
            this.canEdit(),
            () => html`
              <div class="flex items-center space-x-2">
                <ion-button
                  id="diagnostic-popover"
                  fill="clear"
                  size="chip"
                  @click="${(e: Event) => {
                    e.stopPropagation();
                  }}">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path
                      d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </ion-button>
                <ion-popover
                  arrow
                  alignment="center"
                  reference="trigger"
                  side="bottom"
                  style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                  trigger="diagnostic-popover">
                  <div class="p-0">
                    <ion-list>
                      <ion-item
                        button
                        .detail=${false}
                        class="no-p no-detail text-sm"
                        @click=${async () => {
                          await popoverController.dismiss();
                          this.#modalEditDiagnosticRef.value?.open({
                            diagnostic: this.#diagnostic.value,
                          });
                        }}>
                        <ion-label class="font-semibold pl-3">${msg("Editar")}</ion-label>
                      </ion-item>
                      <ion-item
                        button
                        .detail=${false}
                        class="no-p no-detail text-sm"
                        @click=${() => this.presentDeleteDiagnosticAlert(this.#diagnostic.value)}>
                        <ion-label class="font-semibold pl-3">${msg("Apagar")}</ion-label>
                      </ion-item>
                    </ion-list>
                  </div>
                </ion-popover>
              </div>
            `,
          )}
        </div>

        <!-- -------------- CONTENT --------------- -->

        ${this.#diagnostic.render({
          pending: () => {},
          error: (err) => {
            console.error(err);
            if (err instanceof ApiError) {
              return html`<div
                class="mx-4 mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${err.message}
                </span>
              </div>`;
            } else {
              return html`<div
                class="mx-4 mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${msg("Erro ao carregar diagnóstico")}
                </span>
              </div>`;
            }
          },
          complete: (diagnostic) => {
            return html`
              <div class="space-y-2.5 px-4 pb-1">
                <div class="space-x-0.5">
                  <h5>${diagnostic.name}</h5>
                  <div class="flex items-center space-x-1 font-medium">
                    <span>${this.formatDate(diagnostic.created)}</span>
                    <span class="text-accent-6">•</span>
                    ${when(
                      diagnostic.isCompleted,
                      () => html`
                        <ion-badge
                          style="--background: #F7B955;"
                          class="text-sm font-medium text-foreground px-2 py-0.5"
                          >${msg("Tratamento Concluído")}</ion-badge
                        >
                      `,
                      () => html`
                        <ion-badge style="--background: #79FFE1;" class="text-sm font-medium text-black px-2 py-0.5"
                          >${msg("Em tratamento")}</ion-badge
                        >
                      `,
                    )}
                  </div>
                </div>
                <p>${diagnostic.description}</p>
                ${when(
                  this.canEdit(),
                  () => html`
                    ${when(
                      diagnostic.isCompleted,
                      () => html`
                        <ion-button
                          @click=${() => this.changeCompletedStatus(diagnostic)}
                          shape="round"
                          expand="block"
                          color="primary"
                          size="small"
                          class="h-10 text-sm font-semibold"
                          >${msg("Desmarcar tratamento concluído")}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            class="ml-2">
                            <path
                              d="M12 12L8.00001 8.00001M8.00001 8.00001L4 4M8.00001 8.00001L12 4M8.00001 8.00001L4 12"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </ion-button>
                      `,
                      () => html`
                        <ion-button
                          @click=${() => this.changeCompletedStatus(diagnostic)}
                          shape="round"
                          expand="block"
                          color="light"
                          size="small"
                          class="h-10 text-sm font-semibold"
                          >${msg("Marcar tratamento como concluído")}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            class="ml-2 mb-[3px]">
                            <path
                              d="M6.66667 10.4045L10.2022 13.94L17.2725 6.8689M2.5 10.4045L6.03553 13.94M13.1066 6.8689L10.4167 9.58341"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </ion-button>
                      `,
                    )}
                  `,
                )}
              </div>

              <div class="w-full h-1 bg-accent-7"></div>

              <!-- -------------- SESSIONS --------------- -->

              ${when(
                diagnostic.sessions.length > 0,
                () => html`
                  <ion-accordion-group class="mt-4 space-y-4 mx-4" multiple>
                    ${repeat(
                      diagnostic.sessions,
                      (session) => session.id,

                      (session, index) => html`
                        <ion-accordion
                          value="${session.id}"
                          toggle-icon-slot="start"
                          toggle-icon="/assets/icons/chevron-down.svg">
                          <!-- --------------ACCORDION HEADER --------------- -->
                          <ion-item
                            lines="none"
                            class="no-active-bg no-border no-p-start no-inner-p items-start"
                            style="--min-height: none"
                            slot="header">
                            <div class="flex justify-between items-start w-full">
                              <div class="flex items-center gap-x-1 w-full flex-wrap">
                                <h6 class="line-clamp-1">${session.name}</h6>
                                ${this.getDateChip(session.day, session.startTime, session.endTime)}
                              </div>
                              ${when(
                                this.canEdit(),
                                () => html`
                                  <ion-button
                                    id="${index}-session-popover"
                                    fill="clear"
                                    size="chip"
                                    @click="${(e: Event) => {
                                      e.stopPropagation();
                                    }}">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none">
                                      <path
                                        d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                      <path
                                        d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                      <path
                                        d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </svg>
                                  </ion-button>
                                  <ion-popover
                                    arrow
                                    alignment="center"
                                    reference="trigger"
                                    side="bottom"
                                    style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                    trigger="${index}-session-popover">
                                    <div class="p-0">
                                      <ion-list>
                                        <ion-item
                                          button
                                          .detail=${false}
                                          class="no-p no-detail text-sm"
                                          @click=${async () => {
                                            await popoverController.dismiss();
                                            this.#modalEditSessionRef.value?.open({ session: session });
                                          }}>
                                          <ion-label class="font-semibold pl-3">${msg("Editar")}</ion-label>
                                        </ion-item>
                                        <ion-item
                                          button
                                          .detail=${false}
                                          class="no-p no-detail text-sm"
                                          @click=${() => this.presentDeleteSessionAlert(diagnostic, session)}>
                                          <ion-label class="font-semibold pl-3">${msg("Apagar")}</ion-label>
                                        </ion-item>
                                      </ion-list>
                                    </div>
                                  </ion-popover>
                                `,
                              )}
                            </div>
                          </ion-item>
                          <!-- --------------- ACCORDION CONTENT ------------------ -->
                          <div slot="content" class="mt-3.5 space-y-3">
                            <div class="space-y-0.5 text-sm col">
                              <span class="font-display font-semibold">${msg(html`Data & Horário`)}</span>
                              <span class="capitalize"
                                >${this.getDayMonth(session.day)}, ${this.getTime(session.startTime)} -
                                ${this.getTime(session.endTime)}</span
                              >
                            </div>
                            <div class="space-y-1.5">
                              <span class="font-display font-semibold text-sm">${msg("Avaliação")}</span>
                              ${when(
                                session.evaluations.length > 0,
                                () => html`
                                  <ion-list lines="none" class="space-y-2.5">
                                    ${repeat(
                                      session.evaluations,
                                      (evaluation) => evaluation.id,
                                      (evaluation) => {
                                        return html`
                                          <ion-item
                                            button
                                            detail-icon="/assets/icons/chevron-right.svg"
                                            class="no-p"
                                            @click=${() =>
                                              this.#modalEvaluationRef.value?.open({
                                                session: session,
                                                evaluation: evaluation,
                                              })}>
                                            <div class="w-full">
                                              <p class="font-semibold line-clamp-1">${evaluation.evaluation.name}</p>
                                              <div class="flex items-center space-x-[3px]">
                                                ${evaluation.evaluation.categories.slice(0, 2).map(
                                                  (category) => html`
                                                    <span class="text-sm">${category.category}</span>
                                                    <span class="text-accent-6 last:hidden">•</span>
                                                  `,
                                                )}
                                              </div>
                                            </div>
                                          </ion-item>
                                        `;
                                      },
                                    )}
                                  </ion-list>
                                `,
                                () => {
                                  if (!this.canEdit()) {
                                    return html`<p class="text-sm">${msg("Sem avaliações")}</p>`;
                                  }
                                  return nothing;
                                },
                              )}
                              ${when(
                                this.canEdit(),
                                () => html`
                                  <ion-button
                                    color="light"
                                    expand="block"
                                    shape="round"
                                    size="small"
                                    @click=${() => this.#modalChooseEvaluationRef.value?.open({ session: session })}
                                    class="h-10 text-sm font-semibold"
                                    >${msg("Adicionar avaliação")}</ion-button
                                  >
                                `,
                              )}
                            </div>
                            <div class="space-y-1.5">
                              <span class="font-display font-semibold text-sm">${msg("Tratamento")}</span>
                              <ion-item>
                                <ion-textarea
                                  auto-grow
                                  rows="4"
                                  .readonly=${!this.canEdit()}
                                  debounce=${2000}
                                  @ionInput=${(e: CustomEvent) => {
                                    this.editSessionTreatment(session, e.detail.value as string);
                                  }}
                                  value=${session.treatment}
                                  placeholder=${msg("Notas sobre o plano de exercícios")}></ion-textarea>
                              </ion-item>
                            </div>
                            <div class="space-y-1.5">
                              <div class="flex items-center justify-between">
                                <div class="font-display font-semibold text-sm">${msg("Plano de Exercícios")}</div>
                                ${when(
                                  this.canEdit(),
                                  () => html`
                                    <ion-button
                                      id="${index}-exercise-plan-popover"
                                      fill="clear"
                                      size="chip"
                                      @click="${(e: Event) => {
                                        e.stopPropagation();
                                      }}">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none">
                                        <path
                                          d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                      </svg>
                                    </ion-button>
                                    <ion-popover
                                      arrow
                                      alignment="center"
                                      reference="trigger"
                                      side="bottom"
                                      style="--max-width: 160px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                      trigger="${index}-exercise-plan-popover">
                                      <div class="p-0">
                                        <ion-list>
                                          <ion-item
                                            button
                                            .detail=${false}
                                            class="no-p no-detail text-sm"
                                            @click="${async () => {
                                              popoverController.dismiss();
                                              this.#modalCreatePlanExerciseRef.value?.open({
                                                session: session,
                                              });
                                            }}">
                                            <ion-label class="font-semibold pl-3"
                                              >${msg("Adicionar exercício")}</ion-label
                                            >
                                          </ion-item>
                                          <ion-item
                                            button
                                            .detail=${false}
                                            class="no-p no-detail text-sm"
                                            @click=${() => {
                                              popoverController.dismiss();
                                              this.#modalChooseTemplateRef.value?.open({
                                                session: session,
                                              });
                                            }}>
                                            <ion-label class="font-semibold pl-3"
                                              >${msg("Carregar template")}</ion-label
                                            >
                                          </ion-item>
                                        </ion-list>
                                      </div>
                                    </ion-popover>
                                  `,
                                )}
                              </div>
                              ${when(
                                session.exercisesPlan.length > 0,
                                () => html`
                                  <ion-list lines="none" class="pb-1">
                                    <ion-reorder-group
                                      class="space-y-2.5"
                                      .disabled=${false}
                                      @ionItemReorder=${(e: Event) =>
                                        this.onReorder(e, session, session.sortedExercisesPlan)}>
                                      ${repeat(
                                        session.sortedExercisesPlan,
                                        (ep) => ep.id,
                                        (ep) => html`
                                          <ion-item
                                            @click=${() =>
                                              this.#modalEditExercisePlanRef.value?.open({
                                                session: session,
                                                exercisePlan: ep,
                                              })}
                                            button
                                            .detail=${false}
                                            style="--inner-padding-end: 6px; --detail-icon-opacity: 1;"
                                            class="no-p">
                                            <div class="flex space-x-2 items-center">
                                              ${when(
                                                ep.exercise instanceof ObservableExercise,
                                                () => html`
                                                  <xt-avatar
                                                    style="--size: 52px; --border-width: 6px;"
                                                    src=${(ep.exercise as ObservableExercise).thumbnail({
                                                      width: 128,
                                                      height: 128,
                                                    })}
                                                    name=${ep.exercise?.name}></xt-avatar>
                                                `,
                                                () => {
                                                  if (ep.exercise?.thumbnail) {
                                                    const thumbnail = pb.files.getUrl(
                                                      ep.exercise!,
                                                      (ep.exercise as PublicExercise).thumbnail,
                                                      {
                                                        thumb: "128x128",
                                                      },
                                                    );
                                                    return html`
                                                      <xt-avatar
                                                        style="--size: 52px; --border-width: 6px;"
                                                        src=${thumbnail}
                                                        name=${ep.exercise?.name}></xt-avatar>
                                                    `;
                                                  } else {
                                                    return html`
                                                      <xt-avatar
                                                        style="--size: 52px; --border-width: 6px;"
                                                        name=${ep.exercise?.name}></xt-avatar>
                                                    `;
                                                  }
                                                },
                                              )}
                                              <div class="col max-w-[calc(100%-60px)]">
                                                <p class="font-semibold line-clamp-1">${ep.exercise?.name}</p>
                                                <div class="flex items-center space-x-[3px]">
                                                  ${this.getSpecs(ep).map((s) => {
                                                    return html`
                                                      <span class="text-sm">${s}</span>
                                                      <span class="text-sm last:hidden">•</span>
                                                    `;
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                            <ion-reorder slot="end">
                                              <ion-icon
                                                icon="/assets/icons/reorder.svg"
                                                class="w-6 h-6 text-accent-1"></ion-icon>
                                            </ion-reorder>
                                          </ion-item>
                                        `,
                                      )}
                                    </ion-reorder-group>
                                  </ion-list>
                                `,
                                () => {
                                  if (!this.canEdit()) {
                                    return html`<p class="text-sm">${msg("Sem planos de exercícios")}</p>`;
                                  }
                                  return nothing;
                                },
                              )}
                              ${when(
                                this.canEdit(),
                                () => html`
                                  <ion-button
                                    color="light"
                                    expand="block"
                                    shape="round"
                                    size="small"
                                    class="h-10 text-sm font-semibold"
                                    @click=${() =>
                                      this.#modalCreatePlanExerciseRef.value?.open({
                                        session: session,
                                      })}
                                    >${msg("Adicionar Exercício")}
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="17"
                                      height="16"
                                      viewBox="0 0 17 16"
                                      fill="none"
                                      class="ml-2 mb-0.5">
                                      <path
                                        d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </svg>
                                  </ion-button>
                                `,
                              )}
                            </div>
                            <div class="space-y-1.5">
                              <span class="font-display font-semibold text-sm">${msg("Observação do utente")}</span>
                              <ion-item>
                                <ion-textarea
                                  readonly
                                  value=${session.patientNotes}
                                  auto-grow
                                  rows="4"
                                  placeholder=${msg("O utente não tem observações")}></ion-textarea>
                              </ion-item>
                            </div>
                            <div class="space-y-1.5">
                              <span class="font-display font-semibold text-sm">${msg("Notas")}</span>
                              <ion-item>
                                <ion-textarea
                                  debounce=${2000}
                                  @ionInput=${(e: CustomEvent) => {
                                    this.editSessionNotes(session, e.detail.value as string);
                                  }}
                                  auto-grow
                                  .readonly=${!this.canEdit()}
                                  rows="4"
                                  value=${session.notes}
                                  placeholder=${msg("Notas adicionais")}></ion-textarea>
                              </ion-item>
                            </div>
                          </div>
                        </ion-accordion>
                        <div class="h-px w-full bg-accent-7"></div>
                      `,
                    )}
                  </ion-accordion-group>
                `,
              )}
              ${when(
                this.canEdit(),
                () => html`
                  <ion-button
                    shape="round"
                    expand="block"
                    size="small"
                    class="h-10 text-sm font-semibold mt-4 mx-4"
                    @click=${() => this.#modalNewSessionRef.value?.open({ diagnostic: diagnostic })}>
                    ${msg("Adicionar Sessão")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      class="ml-2 mb-0.5">
                      <path
                        d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </ion-button>
                `,
              )}
            `;
          },
        })}
      </ion-content>

      <modal-nav root="modal-create-session" ${ref(this.#modalNewSessionRef)}></modal-nav>
      <modal-nav root="modal-edit-session" ${ref(this.#modalEditSessionRef)}></modal-nav>
      <modal-nav root="modal-edit-diagnostic" ${ref(this.#modalEditDiagnosticRef)}></modal-nav>
      <modal-nav root="modal-choose-report" ${ref(this.#modalChooseEvaluationRef)}></modal-nav>
      <modal-nav root="modal-evaluation" ${ref(this.#modalEvaluationRef)}></modal-nav>
      <modal-nav root="modal-edit-plan-exercise" ${ref(this.#modalEditExercisePlanRef)}></modal-nav>
      <modal-nav root="modal-create-plan-exercise" ${ref(this.#modalCreatePlanExerciseRef)}></modal-nav>
      <modal-nav root="modal-choose-template" ${ref(this.#modalChooseTemplateRef)}></modal-nav>
    `;
  }
}
