import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { html } from "lit";
import { actionSheetController, alertController, modalController } from "@ionic/core";
import { z } from "zod";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { reportsStore } from "../stores/reports.store";
import { FilterStore } from "../stores/filter.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

const formSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("Nome é obrigatório") })
      .min(3, msg("O nome deve ter no mínimo 3 caracteres"))
      .max(128, msg("O nome deve ter no máximo 128 caracteres")),
    report: z.string(),
    type: z.string({ required_error: msg("Tipo é obrigatório") }),
  });

type FormValues = z.infer<ReturnType<typeof formSchema>>;

@customElement("modal-create-report")
@localized()
export class ModalNewReport extends Page {
  @state() private _selectedType: string = "";

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  filterStore = new FilterStore();

  connectedCallback(): void {
    super.connectedCallback();
    this.#controller.form.change("report", this.router.getParam("reportId"));
  }

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema()),
    onSubmit: async (values) => {
      try {
        if (values.type === "evaluation") {
          await reportsStore.insertEvaluation({
            report: values.report,
            name: values.name,
            categories: this.filterStore.filtersByKey("categories").map((category) => category.id),
          });
        } else {
          await reportsStore.insertScale({
            report: values.report,
            name: values.name,
            categories: this.filterStore.filtersByKey("categories").map((category) => category.id),
            expression: "",
          });
        }
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: [msg("OK")],
          });
          alert.present();
        }
      }
    },
  });

  changeTypeInputValue(value: string, formValue: string) {
    this._selectedType = value;
    this.#controller.form.change("type", formValue);
    actionSheetController.dismiss();
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">${msg("Nova Avaliação")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <form
          id="form-report-create"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list lines="none" class="space-y-4 mt-4 flex flex-col">
            <div>
              <span class="font-semibold font-display">${msg("Nome")}</span>
              <ion-item class="mt-1">
                <ion-input autocapitalize="on" type="text" placeholder=${msg("Nome da avaliação")} ${register("name")}>
                </ion-input>
              </ion-item>
              ${renderError("name")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Tipo")}</span>
              <ion-item class="mt-1">
                <ion-input
                  readonly
                  )
                  value=${this._selectedType}
                  placeholder=${msg("Clique para selecionar")}
                  id="open-action-sheet"
                  ${register("type")}>
                </ion-input>
              </ion-item>
              ${renderError("type")}
            </div>
          </ion-list>

          <div class="space-y-2.5 mt-4">
            <span class="font-display font-semibold">${msg("Categoria")}</span>
            ${when(this.filterStore.filtersByKey("categories").length > 0, () => {
              return html`
                <div class="my-1.5 flex items-center flex-wrap gap-1.5">
                  ${repeat(
                    this.filterStore.filtersByKey("categories"),
                    (category) => category.id,
                    (category) => {
                      return html`
                        <ion-chip
                          @click=${() => {
                            this.filterStore.remove(category);
                          }}
                          style="--background: var(--ion-color-success);"
                          class="font-semibold font-body text-sm px-2.5 py-1.5"
                          >${category.name}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            class="ml-1">
                            <rect width="12" height="12" rx="6" fill="black" />
                            <path
                              d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </ion-chip>
                      `;
                    },
                  )}
                </div>
              `;
            })}

            <ion-button
              @click=${() => {
                this.navigator.push("modal-report-categories", {
                  filterStore: this.filterStore,
                });
              }}
              class="font-bold"
              color="secondary"
              shape="round"
              size="small"
              expand="block"
              >${msg("Adicionar")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                class="ml-2">
                <path
                  d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </ion-button>
          </div>
        </form>

        <ion-action-sheet
          trigger="open-action-sheet"
          .buttons=${[
            {
              text: msg("Avaliação"),
              role: "selected",
              handler: () => {
                this.changeTypeInputValue(msg("Avaliação"), "evaluation");
              },
            },
            {
              text: msg("Escala"),
              role: "selected",
              handler: () => {
                this.changeTypeInputValue(msg("Escala"), "scale");
              },
            },
            {
              text: msg("Cancelar"),
              role: "cancel",
            },
          ]} />
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-report-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Adicionar Avaliação")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
