import type { ComponentProps, NavComponent, NavOptions, TransitionDoneFn } from "@ionic/core";
import type { IonNav } from "@ionic/core/components/ion-nav";
import { createContext } from "@lit/context";
import type { ReactiveController } from "lit";
import type { ReactiveHTMLControllerHost } from "./types";
import type { IonModal } from "@ionic/core/components/ion-modal";

export const navigatorContext = createContext<NavigatorController>(Symbol("navigator-controller"));

export type NavigatorContext = NavigatorController;

export class NavigatorController implements ReactiveController {
  #host: ReactiveHTMLControllerHost;
  modal: IonModal;
  ionNav: IonNav;

  private _onClose?: () => void;
  private _onOpen?: () => void;

  constructor(host: ReactiveHTMLControllerHost) {
    this.#host = host;

    this.modal = host.closest("ion-modal") as IonModal;
    this.ionNav = host.querySelector("ion-nav") as IonNav;
    if (!this.ionNav) {
      throw new Error("NavigatorController: <ion-nav> was not found");
    }

    this.#host.addController(this);
  }

  hostConnected() {
    // TODO: This is probably working, but only works on Capacitor env.
    // Uncomment when testing on device
    //
    // document.addEventListener("ionBackButton", (ev) => {
    //   ev.detail.register(101, async () => {
    //     const canGoBack = await this.router.canGoBack();
    //     if (canGoBack) {
    //       this.router.pop();
    //     } else {
    //       this.close();
    //     }
    //   });
    // });
  }
  hostDisconnected() {}

  set onClose(value: (() => void) | undefined) {
    this._onClose = value;
    this.#updateEventListener("ionModalWillDismiss", this.#onCloseCb);
  }

  set onOpen(value: (() => void) | undefined) {
    this._onOpen = value;
    this.#updateEventListener("ionModalWillPresent", this.#onOpenCb);
  }

  // Helper method to update event listeners
  #updateEventListener(eventType: string, callback: () => void) {
    this.modal.removeEventListener(eventType, callback);
    this.modal.addEventListener(eventType, callback);
  }

  #onOpenCb = () => {
    this._onOpen?.();
  };

  #onCloseCb = () => {
    this._onClose?.();
  };

  set canDismiss(value: boolean | ((data?: any, role?: string | undefined) => Promise<boolean>)) {
    this.modal.canDismiss = value;
  }

  get canDismiss() {
    return this.modal.canDismiss;
  }

  get modalNav() {
    return this.modal;
  }

  close = () => {
    return this.modal.dismiss();
  };

  goBack = async () => {
    const canGoBack = await this.ionNav.canGoBack();
    if (canGoBack) {
      this.pop();
      return;
    }
    this.close();
  };

  goToRoot = () => {
    this.ionNav.popToRoot();
  }

  public push<T extends NavComponent>(
    component: T,
    componentProps?: ComponentProps<T> | null,
    opts?: NavOptions | null,
    done?: TransitionDoneFn,
  ) {
    if (typeof component === "string") {
      return this.ionNav.push(component, componentProps, opts, done);
    }
    // @ts-ignore
    //
    // .getName() is a method that is injected by the decorator
    // @customElement("...")
    const tagName = component.getName();
    return this.ionNav.push(tagName, componentProps, opts, done);
  }

  public pop(opts?: NavOptions | null, done?: TransitionDoneFn) {
    return this.ionNav.pop(opts, done);
  }
}
