import { expand, expandAt, pb } from "../../pocketbase";
import { createCategory, type GroupCategoryRecord } from "../group/category";
import { createGroup } from "../group/group";
import { createExercise, createPublicExercise } from "../library/exercises";
import {
  createDiagnostic,
  createExercisePlan,
  createSession,
  type ExercisePlanRecord,
  type SessionRecord,
} from "../patient/diagnostic";
import { createPatient } from "../patient/patient";
import {
  type PatientEvaluationRecord,
  type EvaluationRecord,
  createEvaluationSummary,
  createReportCategory,
} from "../report/report";
import { createUser } from "../user/user";
import { createAppointmentDate, type AppointmentDate, createAppointment } from "./appointment";

class AppointmentRepository {
  async list(userId: string, day: string[] = []) {
    const result = await pb.collection<SessionRecord>("sessions").getFullList({
      expand:
        "diagnostic, diagnostic.patient, diagnostic.patient.patients_category_via_patient.category, diagnostic.professionals, diagnostic.patient.group, diagnostic.patient.group.owner",
      filter: `(${day.map((d) => `day = "${d}"`).join(" || ")}) && profissional = "${userId}"`,
      sort: "+startTime",
    });

    return result.map((r) => {
      const session = createSession(r);
      const diagnostic = createDiagnostic({
        ...r.expand?.diagnostic,
        professionals: expandAt(result, "diagnostic.professionals").map((p) => createUser(p)),
      });
      const categories = expandAt<GroupCategoryRecord>(
        r.expand?.diagnostic?.expand?.patient,
        "patients_category_via_patient",
      ).map((c) => createCategory(c.expand?.category));
      const patient = createPatient({ ...r.expand?.diagnostic?.expand?.patient, categories: categories });
      const group = createGroup({
        ...r.expand?.diagnostic?.expand?.patient?.expand?.group,
        owner: r.expand?.diagnostic?.expand?.patient?.expand?.group?.expand?.owner,
      });

      return createAppointment({
        session,
        diagnostic,
        patient,
        group,
      });
    });
  }

  async listAllDays() {
    const result = await pb.send<AppointmentDate[]>(`/api/v1/appointment/dates`, {});
    return result.map((d) => createAppointmentDate(d));
  }

  async get(id: string) {
    const result = await pb.collection<SessionRecord>("sessions").getOne(id, {
      expand: expand(
        "diagnostic",
        "diagnostic.patient",
        "diagnostic.professionals",
        "diagnostic.patient.group",
        "diagnostic.patient.group.owner",
        "exercises_plan_via_session",
        "exercises_plan_via_session.exercise",
        "exercises_plan_via_session.exercisePublic",
        "patient_evaluations_via_session.evaluation",
        "patient_evaluations_via_session.evaluation.evaluations_category_via_evaluation.category",
      ),
    });

    const exercisesPlan = expandAt<ExercisePlanRecord>(result, "exercises_plan_via_session").map((e) => {
      // we need to do this because zod schema will fail if we have categories,
      // equipments or muscles as string[] (we don't need this here)
      if (e.expand?.exercisePublic) {
        e.expand.exercisePublic.categories = [];
        e.expand.exercisePublic.equipments = [];
        e.expand.exercisePublic.muscles = [];
      }
      return createExercisePlan({
        ...e,
        exercise: e.expand?.exercise ? createExercise(e.expand?.exercise) : undefined,
        exercisePublic: e.expand?.exercisePublic ? createPublicExercise(e.expand?.exercisePublic) : undefined,
      });
    });
    const evaluations = expandAt<PatientEvaluationRecord>(result, "patient_evaluations_via_session").map((e) => {
      const evaluationArray = expandAt<EvaluationRecord>(e, "evaluation");

      if (!evaluationArray || evaluationArray.length === 0) return [];

      const evaluation = evaluationArray[0];
      return {
        ...e,
        evaluation: createEvaluationSummary({
          ...evaluation,
          categories: expandAt(evaluation, "evaluations_category_via_evaluation").map((c) =>
            createReportCategory(c.expand.category),
          ),
        }),
      };
    });
    const session = createSession({
      ...result,
      exercisesPlan,
      evaluations,
    });

    const diagnostic = createDiagnostic({
      ...result.expand?.diagnostic,
      professionals: expandAt(result, "diagnostic.professionals").map((p) => createUser(p)),
    });
    const patient = createPatient(result.expand?.diagnostic?.expand?.patient);
    const group = createGroup({
      ...result.expand?.diagnostic?.expand?.patient?.expand?.group,
      owner: result.expand?.diagnostic?.expand?.patient?.expand?.group?.expand?.owner,
    });

    return createAppointment({
      session,
      diagnostic,
      patient,
      group,
    });
  }
}

export const appointmentRepository = new AppointmentRepository();
