import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { Page, required } from "../components/component";
import { z } from "zod";
import { consume } from "@lit/context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { RouterContext, routerContext } from "../context/router.context";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { alertController } from "@ionic/core";
import type { Category } from "../repository/report/report";
import { reportRepository } from "../repository/report/report.repository";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

const formSchema = () =>
  z.object({
    category: z
      .string({ required_error: msg("Campo obrigatório") })
      .min(3, msg("A categoria deve ter no mínimo 3 caracteres"))
      .max(32, msg("A categoria deve ter no máximo 32 caracteres")),
  });

type FormValues = z.infer<ReturnType<typeof formSchema>>;

@customElement("modal-create-report-category")
@localized()
export class ModalCreateReportCategory extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  @required() category!: string;
  @required() onSubmit!: (category: Category) => void;

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema()),
    onSubmit: async (values) => {
      try {
        const reportId = this.router.getParam("reportId");
        const category = await reportRepository.createCategory({
          category: values.category,
          report: reportId,
        });
        this.onSubmit(category);
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      } finally {
        this.navigator.close();
      }
    },
  });

  render() {
    const { submit, register, renderError } = this.#controller;
    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea" class="!px-0">
            <ion-buttons slot="start">
              <ion-button @click="${this.navigator.close}" class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("fechar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Criar Categoria")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <div class="mt-4">
          <span class="font-semibold font-display">${msg("Categoria")}</span>
          <form id="form-create-category" @submit=${submit}>
            <ion-item class="mt-1.5">
              <ion-input
                type="text"
                placeholder=${msg("Categoria")}
                ${register("category", {
                  initialValue: this.category,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("category")}
          </form>
        </div>

        <ion-button form="form-create-category" type="submit" expand="block" shape="round" class="mt-6"
          >${msg("Criar Categoria")}</ion-button
        >
      </div>
    `;
  }
}
