import { enUS, pt, type Locale } from "date-fns/locale";

export const getDateFnsLocale = (locale?: string): Locale => {
  if (!locale) {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;
    switch (language) {
      case "pt-PT":
        return pt;
      case "pt-BR":
        return pt;
      case "en":
        return enUS;
      default:
        return enUS;
    }
  }
  switch (locale) {
    case "pt":
      return pt;
    case "en":
      return enUS;
    default:
      return pt;
  }
}