import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import { groupStore } from "../stores/groups.store";
import { ClientResponseError } from "pocketbase";
import { alertController } from "@ionic/core";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { localized, msg, str } from "@lit/localize";
import { when } from "lit/directives/when.js";
import { authContext, type AuthContext } from "../context/auth.context";
import { ApiError } from "../error";

@customElement("page-groups-archived-group")
@localized()
export class PageGroupsArchivedGroup extends Page {
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;
  @state() private _id: string = "";

  connectedCallback(): void {
    super.connectedCallback();
    this._id = this.router.getParam("groupId");
  }

  #group = new Task(this, {
    task: async () => {
      return groupStore.loadGroup(this._id);
    },
    args: () => [this._id],
  });

  async #presentReopenGroupAlert() {
    const alert = await alertController.create({
      header: msg("Reabrir grupo"),
      message: msg("Tem a certeza que pretende reabrir este grupo?"),
      buttons: [
        {
          role: "cancel",
          text: msg("Cancelar"),
        },
        {
          role: "confirm",
          text: msg("Reabrir"),
          handler: async () => {
            try {
              const group = this.#group.value;
              if (group) {
                await groupStore.reopenGroup(group).then(() => {
                  this.router.push("/groups", "back");
                });
              }
            } catch (error) {
              if (error instanceof ApiError) {
                const errAlert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["Ok"],
                });
                await errAlert.present();
              }
            }
          },
        },
      ],
    });

    await alert.present();
  }

  render() {
    return html`
      <ion-content>
        <!-- HEADER -->

        <div class="mt-4 flex justify-between items-center">
          <ion-router-link href=${this.router.backUrl} router-direction="back">
            <ion-button
              style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
              fill="clear"
              class="font-semibold">
              <span class="flex items-center -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg("voltar")}
            </ion-button>
          </ion-router-link>

          ${when(
            this.#group.value?.hasPrivileges(this.auth.user),
            () => html`
              <ion-button
                @click=${this.#presentReopenGroupAlert}
                style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                color="success"
                shape="round"
                size="xsmall">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class="mr-1 mb-0.5">
                  <path
                    d="M6.00033 6H4.80046C4.05372 6 3.68007 6 3.39486 6.14532C3.14397 6.27316 2.94015 6.47698 2.81232 6.72786C2.66699 7.01308 2.66699 7.38673 2.66699 8.13346V11.8668C2.66699 12.6135 2.66699 12.9867 2.81232 13.2719C2.94015 13.5228 3.14397 13.727 3.39486 13.8548C3.6798 14 4.053 14 4.79829 14L11.2024 14C11.9477 14 12.3203 14 12.6053 13.8548C12.8561 13.727 13.0606 13.5228 13.1885 13.2719C13.3337 12.987 13.3337 12.6143 13.3337 11.869V8.13127C13.3337 7.38599 13.3337 7.0128 13.1885 6.72786C13.0606 6.47698 12.8561 6.27316 12.6053 6.14532C12.32 6 11.9472 6 11.2005 6H6.00033ZM6.00033 6V4.08008C6.00033 2.93133 6.86701 2 7.93611 2C8.48544 2 8.98084 2.24586 9.33318 2.64076"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                ${msg("Reabrir")}
              </ion-button>
            `,
          )}
        </div>

        <!-- HEADER -->

        <!-- TITLE -->

        ${this.#group.render({
          pending: () => {
            return html`
              <div class="w-full flex justify-between mt-1.5">
                <ion-skeleton-text animated style="width: 92px; height: 36px;"></ion-skeleton-text>
                <div class="flex items-center space-x-1">
                  <ion-skeleton-text animated style="width: 20px; height: 20px;"></ion-skeleton-text>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17.2166 19.3323C15.9349 17.9008 14.0727 17 12 17C9.92734 17 8.06492 17.9008 6.7832 19.3323M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            `;
          },
          error: () => {
            return html`
              <div class="w-full flex justify-between mt-1.5">
                <ion-skeleton-text animated style="width: 92px; height: 36px;"></ion-skeleton-text>
                <div class="flex items-center space-x-1">
                  <ion-skeleton-text animated style="width: 20px; height: 20px;"></ion-skeleton-text>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17.2166 19.3323C15.9349 17.9008 14.0727 17 12 17C9.92734 17 8.06492 17.9008 6.7832 19.3323M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            `;
          },
          complete: (group) => {
            return html`
              <div class="w-full flex justify-between mt-1.5">
                <h3>${group.name}</h3>
                <div class="flex items-center space-x-1">
                  <span class="font-semibold font-display mt-px"> ${group.totalPatients} </span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M17.2166 19.3323C15.9349 17.9008 14.0727 17 12 17C9.92734 17 8.06492 17.9008 6.7832 19.3323M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </div>
              </div>
            `;
          },
        })}
        <!-- TITLE -->

        <!-- SEARCH BAR -->

        <!-- <ion-item class="mt-2.5">
          <span aria-hidden="true" slot="start" class="flex items-center mr-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path
                d="M10 10L14 14M6.66667 11.3333C4.08934 11.3333 2 9.244 2 6.66667C2 4.08934 4.08934 2 6.66667 2C9.244 2 11.3333 4.08934 11.3333 6.66667C11.3333 9.244 9.244 11.3333 6.66667 11.3333Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
          <ion-input clear-on-edit type="search" placeholder=${msg("Pesquisar utentes, grupos...")}></ion-input>
          <ion-button shape="round" size="chip" slot="end">
            <span class="flex items-center mr-0.5">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                <path
                  d="M11.6663 3.26672C11.6663 2.94002 11.6661 2.77655 11.6025 2.65177C11.5466 2.54201 11.4577 2.45283 11.348 2.39691C11.2232 2.33333 11.0595 2.33333 10.7328 2.33333H3.26611C2.93942 2.33333 2.77623 2.33333 2.65145 2.39691C2.54169 2.45283 2.45251 2.54201 2.39659 2.65177C2.33301 2.77655 2.33301 2.94002 2.33301 3.26672V3.69679C2.33301 3.83946 2.33301 3.91086 2.34913 3.97799C2.36342 4.03751 2.38704 4.09437 2.41903 4.14656C2.45509 4.20541 2.50562 4.25594 2.60645 4.35677L5.55971 7.31003C5.66059 7.41091 5.71076 7.46108 5.74684 7.51995C5.77882 7.57214 5.80277 7.62919 5.81706 7.68872C5.83301 7.75517 5.83301 7.82571 5.83301 7.9655V10.7397C5.83301 11.2398 5.83301 11.49 5.93832 11.6405C6.03029 11.772 6.17216 11.8597 6.33089 11.8832C6.51267 11.91 6.73642 11.7983 7.18368 11.5747L7.65034 11.3414C7.83763 11.2477 7.93104 11.2007 7.99946 11.1309C8.05996 11.0691 8.10625 10.9949 8.13444 10.9132C8.16632 10.8208 8.16634 10.7158 8.16634 10.5064V7.96984C8.16634 7.82717 8.16634 7.75585 8.18246 7.68872C8.19675 7.62919 8.22038 7.57214 8.25236 7.51995C8.28819 7.46148 8.33823 7.41144 8.43778 7.31189L8.43978 7.31003L11.393 4.35677C11.4939 4.25588 11.5441 4.20543 11.5802 4.14656C11.6122 4.09437 11.6361 4.03751 11.6504 3.97799C11.6663 3.91154 11.6663 3.84092 11.6663 3.70113V3.26672Z"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            ${msg("Filtrar")}
          </ion-button>
        </ion-item> -->

        <!-- SEARCH BAR -->

        <ion-accordion-group class="mt-4 space-y-2" multiple>
          <!-- SECTIONS -->

          ${this.#group.render({
            pending: () => {
              return html`
                <div class="space-y-2.5 mt-4">
                  ${Array.from({ length: 4 }).map(() => {
                    return html`
                      <div class="flex space-x-2">
                        <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                        <div class="flex-1">
                          <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                          <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                        </div>
                      </div>
                    `;
                  })}
                </div>
              `;
            },
            error: (error) => {
              if (error instanceof ApiError) {
                return html`<div
                  class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${error.message}
                  </span>
                </div>`;
              } else {
                return html`<div
                  class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#ee0000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round">
                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                    <path d="M12 9v4" />
                    <path d="M12 17h.01" />
                  </svg>
                  <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                    ${msg("Erro ao carregar o grupo")}
                  </span>
                </div>`;
              }
            },
            complete: (group) => {
              return html`
                ${group?.sortedSections?.map((section) => {
                  const patients = section.patients.filter((patient) => !patient.isArchived);
                  return html`
                    <ion-accordion
                      value="${section.id}"
                      toggle-icon-slot="start"
                      toggle-icon="/assets/icons/chevron-down.svg">
                      <ion-item lines="none" class="no-active-bg no-border no-p-start no-inner-p" slot="header">
                        <div class="flex justify-between items-center w-full">
                          <div class="flex items-center space-x-1 w-full">
                            <h6>${section.name}</h6>
                            <ion-chip style="--background: #EAEAEA; --color: #888888"
                              >${msg(str`${patients?.length} utentes`)}</ion-chip
                            >
                          </div>
                        </div>
                      </ion-item>
                      <div slot="content" class="mt-1.5">
                        ${patients.length > 0
                          ? html`
                              <ion-list class="space-y-2 no-border">
                                ${patients.map(
                                  (patient) => html`
                                    <ion-item
                                      button
                                      .detail=${false}
                                      @click=${() =>
                                        this.router.push(
                                          `groups/archived/${this._id}/patient/${patient.id}`,
                                          "forward",
                                        )}
                                      class="flex space-x-2 items-center no-px no-border">
                                      <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                                      <div class="col w-full">
                                        <p class="font-semibold">${patient.name}</p>
                                        ${patient.categories?.length > 0 && patient.categories?.[0]?.category
                                          ? html`
                                              <ion-chip
                                                class="px-2 w-fit"
                                                style="--background: ${patient.categories?.[0]?.color};"
                                                >${patient.categories?.[0]?.category}</ion-chip
                                              >
                                            `
                                          : nothing}
                                      </div>
                                    </ion-item>
                                  `,
                                )}
                              </ion-list>
                            `
                          : nothing}
                      </div>
                    </ion-accordion>
                  `;
                })}

                <!-- ARCHIVED ACCORDION -->
                <ion-accordion value="archived" toggle-icon-slot="start" toggle-icon="/assets/icons/chevron-down.svg">
                  <ion-item class="no-active-bg no-p-start no-inner-p no-border" slot="header">
                    <div class="flex items-center space-x-1 w-full">
                      <h6>${msg("Arquivados")}</h6>
                      <ion-chip style="--background: #EAEAEA; --color: #888888"
                        >${msg(str`${group.totalArchivedPatients} utentes`)}</ion-chip
                      >
                    </div>
                  </ion-item>
                  <div slot="content">
                    ${group.archivedPatients.length > 0
                      ? html`
                          <ion-list class="space-y-2 no-border">
                            ${group.archivedPatients.map(
                              (patient) => html`
                                <ion-item
                                  button
                                  .detail=${false}
                                  @click=${() =>
                                    this.router.push(`groups/archived/${this._id}/patient/${patient.id}`, "forward")}
                                  class="flex space-x-2 items-center no-px no-border">
                                  <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                                  <div class="col">
                                    <p class="font-semibold">${patient.name}</p>
                                    ${patient.categories?.length > 0 && patient.categories?.[0]?.category
                                      ? html`
                                          <ion-chip
                                            class="px-2 w-fit"
                                            style="--background: ${patient.categories?.[0]?.color};"
                                            >${patient.categories?.[0]?.category}</ion-chip
                                          >
                                        `
                                      : nothing}
                                  </div>
                                </ion-item>
                              `,
                            )}
                          </ion-list>
                        `
                      : nothing}
                  </div>
                </ion-accordion>
              `;
            },
          })}
        </ion-accordion-group>
      </ion-content>
    `;
  }
}
