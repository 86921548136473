import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { Page } from "../components/component";
import { consume } from "@lit/context";
import { routerContext, RouterContext } from "../context/router.context";
import { when } from "lit/directives/when.js";
import { alertController, popoverController, toastController, type ItemReorderCustomEvent } from "@ionic/core";
import { ClientResponseError } from "pocketbase";
import { repeat } from "lit/directives/repeat.js";
import { ObservableAppointment, appointmentStore } from "../stores/appointment.store";
import { Task } from "@lit/task";
import { format } from "date-fns";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import type { ObservableExercisePlan, ObservableSession } from "../stores/sessions.store";
import { runInAction } from "mobx";
import { diagnosticRepository } from "../repository/patient/diagnostic.repository";
import { pb } from "../pocketbase";
import type { PublicExercise } from "../repository/library/exercises";
import { ObservableExercise } from "../stores/exercises.store";
import { authContext, type AuthContext } from "../context/auth.context";
import { localized, msg, str } from "@lit/localize";
import { getDateFnsLocale } from "../date-fns-locale";

@customElement("page-appointment")
@localized()
export class PageAppointment extends Page {
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  #modalEditSessionRef: Ref<ModalNav> = createRef();
  #modalEditExercisePlanRef: Ref<ModalNav> = createRef();
  #modalCreatePlanExerciseRef: Ref<ModalNav> = createRef();
  #modalChooseTemplateRef: Ref<ModalNav> = createRef();
  #modalEvaluationRef: Ref<ModalNav> = createRef();
  #modalChooseReportRef: Ref<ModalNav> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this.#appointment.run();
  }

  #appointment = new Task(this, {
    task: async () => {
      const id = this.router.getParam("id");
      return await appointmentStore.load(id);
    },
  });

  hoursAndMinutes(date: string) {
    return format(new Date(date), "HH:mm", { locale: getDateFnsLocale(this.auth.user.language) });
  }

  dayAndMonth(date: string) {
    return format(new Date(date), "dd MMMM", { locale: getDateFnsLocale(this.auth.user.language) });
  }

  async presentDeleteSessionAlert(appointment: ObservableAppointment) {
    await popoverController.dismiss();
    const alert = await alertController.create({
      header: msg("Apagar Sessão"),
      message: msg("Tem a certeza que deseja apagar a sessão? Esta ação é irreversível."),
      buttons: [
        { text: msg("Cancelar"), role: "cancel" },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await appointmentStore.deleteAppointment(appointment);
              alert.dismiss();
              this.router.back();
            } catch (error) {
              alert.dismiss();
              if (error instanceof ClientResponseError) {
                if (error.status === 404) {
                  const alert = await alertController.create({
                    header: msg("Erro"),
                    message: msg("A sessão não existe."),
                    buttons: ["Ok"],
                  });
                  await alert.present();
                } else {
                  const alert = await alertController.create({
                    header: msg("Erro"),
                    message: msg("Não foi possível apagar a sessão."),
                    buttons: ["Ok"],
                  });
                  await alert.present();
                }
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async editSessionTreatment(appointment: ObservableAppointment, treatment: string) {
    try {
      await appointmentStore.updateAppointment(appointment, {
        treatment: treatment,
      });
      const toast = await toastController.create({
        message: msg("Tratamento atualizado com sucesso"),
        duration: 2000,
        color: "primary",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    } catch (error) {
      const toast = await toastController.create({
        message: msg("Não foi possível guardar as alterações"),
        duration: 2000,
        color: "danger",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    }
  }

  async editSessionNotes(appointment: ObservableAppointment, notes: string) {
    try {
      await appointmentStore.updateAppointment(appointment, {
        notes: notes,
      });
      const toast = await toastController.create({
        message: msg("Notas atualizadas com sucesso"),
        duration: 2000,
        color: "primary",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    } catch (error) {
      const toast = await toastController.create({
        message: msg("Não foi possível guardar as alterações"),
        duration: 2000,
        color: "danger",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    }
  }

  onReorder = async (e: Event, session: ObservableSession, exercisePlan: ObservableExercisePlan[]) => {
    const detail = (e as ItemReorderCustomEvent).detail;

    const fromIdx = detail.from;
    const toIdx = detail.to;

    if (fromIdx === toIdx) {
      detail.complete();
      return;
    }

    runInAction(() => {
      // Move the item in the array
      const [movedItem] = exercisePlan.splice(fromIdx, 1);
      if (!movedItem) {
        detail.complete();
        return;
      }
      exercisePlan.splice(toIdx, 0, movedItem);

      // Update positions
      exercisePlan.forEach((item, idx) => {
        item.position = idx;
      });
    });

    diagnosticRepository.updateExercisePlanPositions(session.id, exercisePlan);

    detail.complete();
  };

  getSpecs(e: ObservableExercisePlan) {
    let specs = [];

    if (e.series) {
      specs.push(html`<span class="text-sm">${msg(str`${e.series} Séries`)}</span>`);
    }
    if (e.repetitions) {
      specs.push(html`<span class="text-sm">${msg(str`${e.repetitions} Repetições`)}</span>`);
    }
    if (e.weight) {
      specs.push(html`<span class="text-sm">${e.weight} ${e.weightUnit}</span>`);
    }
    if (e.duration) {
      const totalSeconds = e.duration;
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      if (minutes > 0) specs.push(html`<span class="text-sm">${msg(str`${minutes} Minutos`)}</span>`);
      if (seconds > 0) specs.push(html`<span class="text-sm">${msg(str`${seconds} Segundos`)}</span>`);
    }

    return specs.slice(0, 2);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-router-link href=${this.router.backUrl} router-direction="back">
                <ion-button
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </ion-router-link>
            </div>
          </ion-buttons>
          <ion-title>${this.#appointment.value?.patient.name}</ion-title>
          <ion-buttons slot="end">
            <ion-button
              style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
              color="light"
              fill="solid"
              shape="round"
              size="xsmall"
              @click=${() => this.#modalEditSessionRef.value?.open({ session: this.#appointment.value?.session })}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                class="mr-1">
                <path
                  d="M8.00033 5.33341L2.66699 10.6667V13.3334L5.33366 13.3334L10.667 8.00007M8.00033 5.33341L9.91274 3.42098L9.91389 3.41984C10.1772 3.15659 10.309 3.02473 10.461 2.97534C10.5949 2.93183 10.7392 2.93183 10.873 2.97534C11.0249 3.02469 11.1567 3.1564 11.4195 3.41929L12.5794 4.57915C12.8434 4.84316 12.9755 4.97523 13.0249 5.12745C13.0684 5.26134 13.0684 5.40557 13.0249 5.53947C12.9755 5.69158 12.8436 5.82344 12.58 6.08708L12.5794 6.08764L10.667 8.00007M8.00033 5.33341L10.667 8.00007"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
              ${msg("Editar")}
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen class="no-p" style="--padding-bottom: 24px;">
        ${this.#appointment.render({
          pending: () => {},
          error: (e) => {
            console.error(e);
            return html`<span class="text-danger px-4">${msg("Ocorreu um erro ao ir buscar a sessão")}</span>`;
          },
          complete: (appointment) => {
            return html`
              <ion-header collapse="condense">
                <ion-toolbar>
                  <div class="px-4">
                    <xt-avatar
                      src=${appointment.patient.avatar}
                      name=${appointment.patient.name}
                      style="--size: 72px; --border-radius: 99px;"></xt-avatar>
                    <div class="mt-1.5 space-y-0.5">
                      <h5>${appointment.patient.name}</h5>
                      <div class="flex items-center space-x-1 font-medium">
                        <span class="font-medium capitalize"
                          >${this.dayAndMonth(appointment.session.startTime)},
                          ${this.hoursAndMinutes(appointment.session.startTime)}-${this.hoursAndMinutes(
                            appointment.session.endTime,
                          )}</span
                        >
                        <span class="text-accent-6">•</span>
                        ${when(
                          appointment.diagnostic.isCompleted,
                          () => html`
                            <ion-badge
                              style="--background: #F7B955;"
                              class="text-sm font-medium text-foreground px-2 py-0.5"
                              >${msg("Tratamento Concluído")}</ion-badge
                            >
                          `,
                          () => html`
                            <ion-badge style="--background: #79FFE1;" class="text-sm font-medium text-black px-2 py-0.5"
                              >${msg("Em tratamento")}</ion-badge
                            >
                          `,
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="bg-accent-7 w-full h-1 mt-4"></div>
                </ion-toolbar>
              </ion-header>

              <div class="px-4 mt-4">
                <div class="flex justify-between items-center">
                  <p class="font-display font-semibold">${appointment.session.name}</p>
                  <ion-button
                    id="session-popover"
                    fill="clear"
                    size="chip"
                    @click="${(e: Event) => {
                      e.stopPropagation();
                    }}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                      <path
                        d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </ion-button>
                  <ion-popover
                    arrow
                    alignment="center"
                    reference="trigger"
                    side="bottom"
                    style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                    trigger="session-popover">
                    <div class="p-0">
                      <ion-list>
                        <ion-item
                          button
                          .detail=${false}
                          class="no-p no-detail text-sm"
                          @click=${async () => {
                            await popoverController.dismiss();
                            this.#modalEditSessionRef.value?.open({ session: this.#appointment.value?.session });
                          }}>
                          <ion-label class="font-semibold pl-3">${msg("Editar")}</ion-label>
                        </ion-item>
                        <ion-item
                          button
                          .detail=${false}
                          class="no-p no-detail text-sm"
                          @click=${() => this.presentDeleteSessionAlert(appointment)}>
                          <ion-label class="font-semibold pl-3">${msg("Apagar")}</ion-label>
                        </ion-item>
                      </ion-list>
                    </div>
                  </ion-popover>
                </div>

                <div class="mt-3.5 space-y-3">
                  <div class="space-y-1.5">
                    <span class="font-display font-semibold text-sm">${msg("Avaliação")}</span>
                    ${when(
                      appointment.session.evaluations.length > 0,
                      () => html`
                        <ion-list lines="none" class="space-y-2.5">
                          ${repeat(
                            appointment.session.evaluations,
                            (evaluation) => evaluation.id,
                            (evaluation) => {
                              return html`
                                <ion-item
                                  button
                                  detail-icon="/assets/icons/chevron-right.svg"
                                  class="no-p no-inner-p space-x-2 items-center w-full"
                                  @click=${() =>
                                    this.#modalEvaluationRef.value?.open({
                                      evaluation: evaluation,
                                      session: appointment.session,
                                      group: appointment.group,
                                    })}>
                                  <div class="w-full">
                                    <p class="font-semibold">${evaluation.evaluation.name}</p>

                                    ${evaluation.evaluation.categories.slice(0, 2).map(
                                      (category) => html`
                                        <span class="text-sm">${category.category}</span>
                                        <span class="text-sm last:hidden"> & </span>
                                      `,
                                    )}
                                  </div>
                                </ion-item>
                              `;
                            },
                          )}
                        </ion-list>
                      `,
                    )}
                    <ion-button
                      color="light"
                      expand="block"
                      shape="round"
                      size="small"
                      @click=${() =>
                        this.#modalChooseReportRef.value?.open({
                          session: appointment.session,
                          patientId: appointment.patient.id,
                        })}
                      class="h-10 text-sm font-semibold"
                      >${msg("Adicionar avaliação")}</ion-button
                    >
                  </div>
                  <div class="space-y-1.5">
                    <span class="font-display font-semibold text-sm">${msg("Tratamento")}</span>
                    <ion-item>
                      <ion-textarea
                        auto-grow
                        rows="4"
                        debounce=${2000}
                        @ionInput=${(e: CustomEvent) => {
                          const treatment = e.detail.value as string;
                          this.editSessionTreatment(appointment, treatment);
                        }}
                        value=${appointment.session.treatment}
                        placeholder="${msg("Notas sobre o plano de exercícios")}"></ion-textarea>
                    </ion-item>
                  </div>
                  <div class="space-y-1.5">
                    <div class="flex items-center justify-between">
                      <div class="font-display font-semibold text-sm">${msg("Plano de Exercícios")}</div>
                      <ion-button
                        id="0-exercise-plan-popover"
                        fill="clear"
                        size="chip"
                        @click="${(e: Event) => {
                          e.stopPropagation();
                        }}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                          <path
                            d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </ion-button>
                      <ion-popover
                        arrow
                        alignment="center"
                        reference="trigger"
                        side="bottom"
                        style="--max-width: 160px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                        trigger="0-exercise-plan-popover">
                        <div class="p-0">
                          <ion-list>
                            <ion-item
                              button
                              .detail=${false}
                              class="no-p no-detail text-sm"
                              @click="${async () => {
                                popoverController.dismiss();
                                this.#modalCreatePlanExerciseRef.value?.open({
                                  session: appointment.session,
                                });
                              }}">
                              <ion-label class="font-semibold pl-3">${msg("Adicionar exercício")}</ion-label>
                            </ion-item>
                            <ion-item
                              button
                              .detail=${false}
                              class="no-p no-detail text-sm"
                              @click=${() => {
                                popoverController.dismiss();
                                this.#modalChooseTemplateRef.value?.open({
                                  session: appointment.session,
                                });
                              }}>
                              <ion-label class="font-semibold pl-3">${msg("Carregar template")}</ion-label>
                            </ion-item>
                          </ion-list>
                        </div>
                      </ion-popover>
                    </div>

                    ${when(
                      appointment.session.exercisesPlan.length > 0,
                      () => html`
                        <ion-list lines="none" class="pb-1">
                          <ion-reorder-group
                            class="space-y-2.5"
                            .disabled=${false}
                            @ionItemReorder=${(e: Event) =>
                              this.onReorder(e, appointment.session, appointment.session.sortedExercisesPlan)}>
                            ${repeat(
                              appointment.session.sortedExercisesPlan,
                              (ep) => ep.id,
                              (ep) => html`
                                <ion-item
                                  @click=${() =>
                                    this.#modalEditExercisePlanRef.value?.open({
                                      group: appointment.group,
                                      session: appointment.session,
                                      exercisePlan: ep,
                                    })}
                                  button
                                  .detail=${false}
                                  style="--inner-padding-end: 6px; --detail-icon-opacity: 1;"
                                  class="no-p">
                                  <div class="flex space-x-2 items-center">
                                    ${when(
                                      ep.exercise instanceof ObservableExercise,
                                      () => html`
                                        <xt-avatar
                                          style="--size: 52px; --border-width: 6px;"
                                          src=${(ep.exercise as ObservableExercise).thumbnail({
                                            width: 128,
                                            height: 128,
                                          })}
                                          name=${ep.exercise?.name}></xt-avatar>
                                      `,
                                      () => {
                                        const thumbnail = pb.files.getUrl(
                                          ep.exercise!,
                                          (ep.exercise as PublicExercise).thumbnail,
                                          {
                                            thumb: "128x128",
                                          },
                                        );
                                        return html`
                                          <xt-avatar
                                            style="--size: 52px; --border-width: 6px;"
                                            src=${thumbnail}
                                            name=${ep.exercise?.name}></xt-avatar>
                                        `;
                                      },
                                    )}
                                    <div class="col max-w-[calc(100%-60px)]">
                                      <p class="font-semibold line-clamp-1">${ep.exercise?.name}</p>
                                      <div class="flex items-center space-x-[3px]">
                                        ${this.getSpecs(ep).map((s) => {
                                          return html`${s}
                                            <span class="text-sm last:hidden">•</span> `;
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                  <ion-reorder slot="end">
                                    <ion-icon icon="/assets/icons/reorder.svg" class="w-6 h-6 text-accent-1"></ion-icon>
                                  </ion-reorder>
                                </ion-item>
                              `,
                            )}
                          </ion-reorder-group>
                        </ion-list>
                      `,
                    )}
                    <ion-button
                      color="light"
                      expand="block"
                      shape="round"
                      size="small"
                      class="h-10 text-sm font-semibold"
                      @click=${() => {
                        this.#modalCreatePlanExerciseRef.value?.open({
                          session: appointment.session,
                        });
                      }}
                      >${msg("Adicionar Exercício")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        height="16"
                        viewBox="0 0 17 16"
                        fill="none"
                        class="ml-2 mb-0.5">
                        <path
                          d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  </div>
                  <div class="space-y-1.5">
                    <span class="font-display font-semibold text-sm">${msg("Observação do utente")}</span>
                    <ion-item>
                      <ion-textarea
                        readonly
                        value=${appointment.session.patientNotes}
                        auto-grow
                        rows="4"
                        placeholder="${msg("O utente não tem observações")}"></ion-textarea>
                    </ion-item>
                  </div>
                  <div class="space-y-1.5">
                    <span class="font-display font-semibold text-sm">${msg("Notas")}</span>
                    <ion-item>
                      <ion-textarea
                        debounce=${2000}
                        @ionInput=${(e: CustomEvent) => {
                          this.editSessionNotes(appointment, e.detail.value as string);
                        }}
                        auto-grow
                        rows="4"
                        value=${appointment.session.notes}
                        placeholder="${msg("Notas adicionais")}"></ion-textarea>
                    </ion-item>
                  </div>
                </div>
              </div>
            `;
          },
        })}
      </ion-content>

      <modal-nav root="modal-edit-session" ${ref(this.#modalEditSessionRef)}></modal-nav>

      <modal-nav root="modal-edit-plan-exercise" ${ref(this.#modalEditExercisePlanRef)}></modal-nav>
      <modal-nav root="modal-create-plan-exercise" ${ref(this.#modalCreatePlanExerciseRef)}></modal-nav>
      <modal-nav root="modal-choose-template" ${ref(this.#modalChooseTemplateRef)}></modal-nav>
      <modal-nav root="modal-evaluation" ${ref(this.#modalEvaluationRef)}></modal-nav>
      <modal-nav root="modal-choose-report" ${ref(this.#modalChooseReportRef)}></modal-nav>
    `;
  }
}
