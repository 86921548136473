import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { Page, required } from "../components/component";
import { z } from "zod";
import { consume } from "@lit/context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { RouterContext, routerContext } from "../context/router.context";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { libraryRepository } from "../repository/library/library.repository";
import { alertController } from "@ionic/core";
import type { Muscle } from "../repository/library/exercises";
import { localized, msg } from "@lit/localize";
import { isApiError } from "../error";

const formSchema = () =>
  z.object({
    muscle: z
      .string({ required_error: msg("Campo obrigatório") })
      .min(3, msg("O músculo deve ter no mínimo 3 caracteres"))
      .max(32, msg("O músculo deve ter no máximo 32 caracteres")),
  });

type FormValues = z.infer<ReturnType<typeof formSchema>>;

@customElement("modal-create-exercise-muscle")
@localized()
export class ModalCreateExerciseMuscle extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  @required() muscle!: string;
  @required() onSubmit!: (muscle: Muscle) => void;

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema()),
    onSubmit: async (values) => {
      try {
        const libraryId = this.router.getParam("id");
        const muscle = await libraryRepository.createMuscle(values.muscle, libraryId);
        this.onSubmit(muscle);
      } catch (error) {
        if (isApiError(error)) {
          alertController
            .create({
              header: msg("Erro"),
              message: error.message,
              buttons: ["OK"],
            })
            .then((alert) => {
              alert.present();
              console.error(error);
            });
        }
      } finally {
        this.navigator.close();
      }
    },
  });

  render() {
    const { submit, register, renderError } = this.#controller;
    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea" class="!px-0">
            <ion-buttons slot="start">
              <ion-button @click="${this.navigator.close}" class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("fechar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Criar Músculo")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <div class="mt-4">
          <span class="font-semibold font-display">${msg("Músculo")}</span>
          <form id="form-create-muscle" @submit=${submit}>
            <ion-item class="mt-1.5">
              <ion-input
                autocapitalize="on"
                type="text"
                placeholder=${msg("Músculo")}
                ${register("muscle", {
                  initialValue: this.muscle,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("muscle")}
          </form>
        </div>

        <ion-button form="form-create-muscle" type="submit" expand="block" shape="round" class="mt-6"
          >${msg("Criar Músculo")}</ion-button
        >
      </div>
    `;
  }
}
