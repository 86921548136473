import { customElement, state } from "lit/decorators.js";
import { html, nothing } from "lit";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { alertController } from "@ionic/core";
import { consume } from "@lit/context";
import { navigatorContext, type NavigatorController } from "../controllers/navigator.controller";
import { authContext, type AuthContext } from "../context/auth.context";
import { when } from "lit/directives/when.js";
import { Task } from "@lit/task";
import { CreateLibrarySchema, type CreateLibrary } from "../repository/library/library";
import { choose } from "lit/directives/choose.js";
import { Page } from "../components/component";
import { groupStore } from "../stores/groups.store";
import { UserShareStore } from "../stores/userShare.store";
import { map } from "lit/directives/map.js";
import { librariesStore } from "../stores/libraries.store";
import { ModalShareEdit } from "./modal-share-edit";
import { ModalShareAdd } from "./modal-share-add";
import { localized, msg } from "@lit/localize";

@customElement("modal-create-library")
@localized()
export class ModalNewLibrary extends Page {
  @state() private _isShared: boolean = false;

  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) auth!: AuthContext;

  userShare = new UserShareStore();

  #loadGroups = new Task(this, {
    task: async () => {
      if (this._isShared) {
        return groupStore.loadGroupsByOwner(this.auth.user.id);
      }
      return [];
    },
    args: () => [this._isShared],
  });

  #controller = new FinalFormController<CreateLibrary>(this, {
    validate: zodValidator(CreateLibrarySchema()),
    onSubmit: async (values) => {
      try {
        const createLibrary: CreateLibrary = values;

        if (!this._isShared) {
          createLibrary.users = this.userShare.users.map((u) => ({
            userId: u.user.id,
            accessId: u.access.id,
          }));
        }

        await librariesStore.createLibrary(createLibrary);

        this.close();
      } catch (error) {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: msg("Ocorreu um erro ao criar a biblioteca. Por favor, tente novamente."),
          buttons: ["OK"],
        });
        await alert.present();
      }
    },
  });

  close() {
    this.userShare.reset();
    this.navigator.close();
  }

  setIsShared(e: CustomEvent) {
    if (e.detail.value === "shared") {
      this.#controller.form.change("users", []);
      this.userShare.reset();
      this._isShared = true;
    } else {
      this.#controller.form.change("group", undefined);
      this._isShared = false;
    }
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${() => this.close()}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Nova Bilioteca")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        <ion-segment
          value="personal"
          class="mt-3 w-fit rounded-full mx-auto p-1"
          @ionChange=${(e: CustomEvent) => this.setIsShared(e)}>
          <ion-segment-button
            value="personal"
            layout="icon-start"
            style="--border-radius: 99px; --color-checked: #ffffff; --indicator-color: #000000;">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M15 15.833C15 13.9921 12.7614 12.4997 10 12.4997C7.23858 12.4997 5 13.9921 5 15.833M10 9.99967C8.15905 9.99967 6.66667 8.50729 6.66667 6.66634C6.66667 4.82539 8.15905 3.33301 10 3.33301C11.8409 3.33301 13.3333 4.82539 13.3333 6.66634C13.3333 8.50729 11.8409 9.99967 10 9.99967Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <ion-label>${msg("Pessoal")}</ion-label>
          </ion-segment-button>
          <ion-segment-button
            value="shared"
            layout="icon-start"
            style="--border-radius: 99px; --color-checked: #ffffff; --indicator-color: #000000;">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path
                d="M17.5 16.6669C17.5 15.2156 16.1087 13.9809 14.1667 13.5233M12.5 16.667C12.5 14.826 10.2614 13.3337 7.5 13.3337C4.73858 13.3337 2.5 14.826 2.5 16.667M12.5 10.8337C14.3409 10.8337 15.8333 9.34127 15.8333 7.50033C15.8333 5.65938 14.3409 4.16699 12.5 4.16699M7.5 10.8337C5.65905 10.8337 4.16667 9.34127 4.16667 7.50033C4.16667 5.65938 5.65905 4.16699 7.5 4.16699C9.34095 4.16699 10.8333 5.65938 10.8333 7.50033C10.8333 9.34127 9.34095 10.8337 7.5 10.8337Z"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <ion-label>${msg("Partilhada")}</ion-label>
          </ion-segment-button>
        </ion-segment>

        <form
          class="mt-4"
          id="form-library-create"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <div>
            <span class="font-semibold font-display">${msg("Nome")}</span>
            <ion-item class="mt-1 has-input">
              <ion-input
                autocapitalize="on"
                type="text"
                placeholder=${msg("Nome da Biblioteca")}
                ${register("name")}></ion-input>
            </ion-item>
            ${renderError("name")}
          </div>
          ${when(
            this._isShared,
            () => {
              return this.#loadGroups.render({
                error: (error) => {
                  console.error(error);
                  return nothing;
                },
                complete: (groups) => {
                  return html`
                    <div class="mt-6 space-y-2">
                      <span class="font-semibold font-display">${msg("Grupos")}</span>
                      <ion-list>
                        <ion-radio-group allow-empty-selection ${register("group")} value="groups">
                          ${map(groups, (group) => {
                            if (group.isOwner(this.auth.user)) {
                              return html`
                                <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                                  <ion-radio value=${group.id} class="no-m-inline my-2">${group.name}</ion-radio>
                                </ion-item>
                              `;
                            }
                            return nothing;
                          })}
                        </ion-radio-group>
                      </ion-list>
                    </div>
                  `;
                },
              });
            },
            () => {
              return html`
                <div class="mt-6 space-y-2">
                  <span class="font-semibold font-display">${msg("Profissionais")}</span>
                  <ion-list class="space-y-2.5 no-p" lines="none">
                    <ion-item class="no-p">
                      <div class="flex items-center space-x-2">
                        <xt-avatar src=${this.auth.user?.avatar} name=${this.auth.user?.name}></xt-avatar>
                        <div class="col items-start">
                          <p class="font-semibold">${this.auth.user?.name}</p>
                          <span class="text-danger">Criador</span>
                        </div>
                      </div>
                    </ion-item>
                    ${this.userShare.users.map(
                      (share) => html`
                        <ion-item
                          class="no-p"
                          @click=${() =>
                            this.navigator.push(ModalShareEdit, {
                              shareStore: this.userShare,
                              share: share,
                            })}>
                          <div class="flex items-center space-x-2">
                            <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                            <div class="col items-start">
                              <p class="font-semibold">${share.user.name}</p>
                              ${choose(share.access.role, [
                                ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                                ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                                ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                              ])}
                            </div>
                          </div>
                        </ion-item>
                      `,
                    )}
                  </ion-list>
                </div>

                <div>
                  <ion-button
                    class="mt-3"
                    size="small"
                    color="secondary"
                    expand="block"
                    shape="round"
                    @click=${() => this.navigator.push(ModalShareAdd, { shareStore: this.userShare })}
                    >${msg("Adicionar Profissional")}
                    <span class="mt-0.5 ml-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                        <path
                          d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                  </ion-button>
                </div>
              `;
            },
          )}
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-library-create"
            type="submit"
            expand="block"
            shape="round"
            ?disabled=${formState.submitting}>
            ${msg("Criar Biblioteca")}
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
