import { customElement, property } from "lit/decorators.js";
import { html, type PropertyValueMap } from "lit";
import { provide } from "@lit/context";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { IonNav } from "@ionic/core/components/ion-nav";
import type { ComponentProps } from "@ionic/core";
import { LightElement } from "../components/component";

@customElement("ion-nav-context")
export class IonNavContext extends LightElement {
  @provide({ context: navigatorContext }) navigator!: NavigatorController;

  firstUpdated(props: PropertyValueMap<IonNavContext>) {
    super.firstUpdated(props);
    this.navigator = new NavigatorController(this);
  }
}

@customElement("modal-nav")
export class ModalNav extends LightElement {
  @property({ type: Boolean }) private _isOpen = false;
  @property({ type: String }) root = "page-empty";
  @property({ type: Function }) onClose?: () => void;
  @property({ type: Function }) onWillClose?: (e: Event) => void;
  @property({ type: Function }) onOpen?: () => void;
  @property({ type: Function }) onWillOpen?: (e: Event) => void;

  #navigator: Ref<IonNav> = createRef();

  async open(componentProps?: ComponentProps) {
    this._isOpen = true;
    await this.#navigator.value?.setRoot(this.root, componentProps);
  }

  async close() {
    if (this.onClose) {
      this.onClose();
    }
    this._isOpen = false;
  }

  render() {
    return html`
      <ion-modal
        id="modal-ion-nav"
        .isOpen=${this._isOpen}
        @ionModalDidDismiss=${this.close}
        @ionModalWillDismiss=${this.onWillClose}
        @ionModalDidPresent=${this.onOpen}
        @ionModalWillPresent=${this.onWillOpen}>
        <ion-nav-context>
          <ion-nav ${ref(this.#navigator)}></ion-nav>
        </ion-nav-context>
      </ion-modal>
    `;
  }
}
