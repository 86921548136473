import { alertController, toastController } from "@ionic/core";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { consume } from "@lit/context";
import { Task } from "@lit/task";
import { html, nothing } from "lit";
import { customElement } from "lit/decorators.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { Page } from "../components/component";
import { createPopover } from "../components/popover.controller";
import { authContext, type AuthContext } from "../context/auth.context";
import { routerContext, type RouterContext } from "../context/router.context";
import type { ModalNav } from "../modals/modal-nav";
import { ObservableLibrary, librariesStore } from "../stores/libraries.store";
import { templatesStore } from "../stores/templateExercisePlan.store";
import { pb } from "../pocketbase";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("page-exercises")
@localized()
export class PageExercises extends Page {
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  #modalNewLibraryRef: Ref<ModalNav> = createRef();
  #modalCreateTemplateRef: Ref<ModalNav> = createRef();
  #modalEditLibraryRef: Ref<ModalNav> = createRef();
  #modalEditExerciseRef: Ref<ModalNav> = createRef();
  #modalFiltersRef: Ref<ModalNav> = createRef();
  #ionRefresher: Ref<IonRefresher> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this.#loadlAll.run([true]);
  }

  #loadlAll = new Task(this, {
    task: ([cache]: [boolean]) => {
      return Promise.all([
        librariesStore.loadLibraries({ cache }),
        librariesStore.loadPublicLibrary({ cache }),
        templatesStore.loadTemplates({ cache }),
      ]);
    },
  });

  #openEditLibraryModal(library: unknown) {
    this.#modalEditLibraryRef.value?.open({ library });
  }

  async presentPopover(e: Event, library: ObservableLibrary) {
    if (library.isOwner(this.auth.user)) {
      const popover = await createPopover(
        [
          {
            text: msg("Editar"),
            handler: () => {
              this.#openEditLibraryModal(library);
            },
          },
          {
            text: msg("Apagar"),
            handler: async () => {
              const alert = await alertController.create({
                header: msg("Apagar biblioteca"),
                message: msg("Tem a certeza que deseja apagar esta biblioteca? A ação não é reversível."),
                buttons: [
                  {
                    text: msg("Cancelar"),
                    role: "cancel",
                  },
                  {
                    text: msg("Apagar"),
                    role: "destructive",
                    handler: async () => {
                      try {
                        await librariesStore.deleteLibrary(library).then(async () => {
                          const toast = await toastController.create({
                            message: msg("Biblioteca apagada com sucesso"),
                            color: "primary",
                            icon: "/assets/icons/info.svg",
                            duration: 2000,
                          });
                          await toast.present();
                        });
                      } catch (error) {
                        if (error instanceof ApiError) {
                          const alert = await alertController.create({
                            header: msg("Erro"),
                            message: error.message,
                            buttons: ["OK"],
                          });
                          await alert.present();
                        }
                      }
                    },
                  },
                ],
              });
              await alert.present();
            },
          },
        ],
        e,
      );
      await popover.present();
    }
    if (library.isAdmin(this.auth.user)) {
      const popover = await createPopover(
        [
          {
            text: msg("Editar"),
            handler: () => {
              this.#openEditLibraryModal(library);
            },
          },
          {
            text: msg("Sair"),
            handler: async () => {
              const leaveAlert = await alertController.create({
                header: msg("Sair da biblioteca"),
                message: msg("Tem a certeza que deseja sair desta biblioteca?"),
                buttons: [
                  {
                    text: msg("Cancelar"),
                    role: "cancel",
                  },
                  {
                    text: msg("Sair"),
                    role: "destructive",
                    handler: async () => {
                      try {
                        await librariesStore.leaveLibrary(library, this.auth.user);
                      } catch (error) {
                        // close the previous alert
                        await leaveAlert.dismiss();

                        const errorAlert = await alertController.create({
                          header: msg("Erro"),
                          message: msg("Erro ao sair da biblioteca. Por favor, tente novamente mais tarde."),
                          buttons: ["OK"],
                        });
                        await errorAlert.present();
                      }
                    },
                  },
                ],
              });
              await leaveAlert.present();
            },
          },
          {
            text: msg("Apagar"),
            handler: async () => {
              const alert = await alertController.create({
                header: msg("Apagar biblioteca"),
                message: msg("Tem a certeza que deseja apagar esta biblioteca? A ação não é reversível."),
                buttons: [
                  {
                    text: msg("Cancelar"),
                    role: "cancel",
                  },
                  {
                    text: msg("Apagar"),
                    role: "destructive",
                    handler: async () => {
                      await librariesStore.deleteLibrary(library).then(async () => {
                        const toast = await toastController.create({
                          message: msg("Biblioteca apagada com sucesso"),
                          color: "primary",
                          icon: "/assets/icons/info.svg",
                          duration: 2000,
                        });
                        await toast.present();
                      });
                    },
                  },
                ],
              });
              await alert.present();
            },
          },
        ],
        e,
      );
      await popover.present();
    } else if (library.isShared(this.auth.user)) {
      const popover = await createPopover(
        [
          {
            text: msg("Consultar"),
            handler: () => {
              this.#openEditLibraryModal(library);
            },
          },
          {
            text: msg("Sair"),
            handler: async () => {
              const leaveAlert = await alertController.create({
                header: msg("Sair da biblioteca"),
                message: msg("Tem a certeza que deseja sair desta biblioteca?"),
                buttons: [
                  {
                    text: msg("Cancelar"),
                    role: "cancel",
                  },
                  {
                    text: msg("Sair"),
                    role: "destructive",
                    handler: async () => {
                      try {
                        await librariesStore.leaveLibrary(library, this.auth.user);
                      } catch (error) {
                        // close the previous alert
                        await leaveAlert.dismiss();

                        const errorAlert = await alertController.create({
                          header: msg("Erro"),
                          message: msg("Erro ao sair da biblioteca. Por favor, tente novamente mais tarde."),
                          buttons: ["OK"],
                        });
                        await errorAlert.present();
                      }
                    },
                  },
                ],
              });
              await leaveAlert.present();
            },
          },
        ],
        e,
      );
      await popover.present();
    } else if (library.isSharedFromGroup() && !library.isOwner(this.auth.user)) {
      const popover = await createPopover(
        [
          {
            text: msg("Consultar"),
            handler: () => {
              this.#openEditLibraryModal(library);
            },
          },
        ],
        e,
      );
      await popover.present();
    }
  }

  render() {
    const currentUser = this.auth.user;

    return html`
      <ion-content fullscreen style="--padding-bottom: 24px">
        <!-- ----------- HEADER --------------- -->
        <div class="w-full mt-4 flex items-center">
          <h3>
            ${msg(html`Biblioteca <br />
              de Exercícios`)}
          </h3>
        </div>

        <!-- --------------- REFRESHER ---------------- -->
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            if (!this.auth.user) return;
            await this.#loadlAll.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        <div class="space-y-4 mt-4">
          <!-- --------------- TEMPLATES ---------------- -->
          <div class="space-y-2">
            <div class="flex justify-between items-center">
              <div class="flex items-center space-x-2">
                <h6>Templates</h6>
                <ion-chip class="font-semibold" style="--background: #EAEAEA; --color: #888888"
                  >${msg(str`${templatesStore.totalTemplates} templates`)}</ion-chip
                >
              </div>
            </div>
            <ion-list lines="none" class="space-y-2.5 flex flex-col">
              ${this.#loadlAll.render({
                pending: () => html`
                  <div class="space-y-4">
                    <div class="flex space-x-2">
                      <ion-skeleton-text animated style="width: 140px; height: 28px;"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 80px; height: 28px;"></ion-skeleton-text>
                    </div>
                    <div class="flex space-x-2">
                      <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                      <div class="flex-1">
                        <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                        <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                      </div>
                    </div>
                    <ion-skeleton-text animated style="width: 100%; height: 40px;"></ion-skeleton-text>
                  </div>
                `,
                error: (error) => {
                  console.error(error);
                  return html`
                    <ion-alert
                      header=${msg("Erro")}
                      message=${msg("Erro ao carregar templates. Por favor, tente novamente mais tarde.")}
                      .isOpen=${true}
                      .buttons=${["OK"]}></ion-alert>
                    <div class="space-y-4">
                      <div class="flex space-x-2">
                        <ion-skeleton-text animated style="width: 140px; height: 28px;"></ion-skeleton-text>
                        <ion-skeleton-text animated style="width: 80px; height: 28px;"></ion-skeleton-text>
                      </div>
                      <div class="flex space-x-2">
                        <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                        <div class="flex-1">
                          <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                          <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                        </div>
                      </div>
                      <ion-skeleton-text animated style="width: 100%; height: 40px;"></ion-skeleton-text>
                    </div>
                  `;
                },
                complete: ([_x, _y, templates]) => {
                  if (templates.length === 0) {
                    return html`
                      <ion-item
                        button
                        @click=${() => this.#modalCreateTemplateRef.value?.open()}
                        .detail=${false}
                        class="w-full h-[139px] rounded-md no-p no-inner-p mt-4">
                        <div
                          class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none">
                            <path
                              d="M6 12H12M12 12H18M12 12V18M12 12V6"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                          <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                            ${msg("Cria o teu primeiro template")}</span
                          >
                        </div>
                      </ion-item>
                    `;
                  }

                  return html`
                    ${templates.slice(0, 3).map(
                      (template) => html`
                        <ion-item
                          @click=${() => this.router.push(`/exercises/templates/popular/${template.id}`, "forward")}
                          button
                          .detail=${false}
                          class="no-p flex space-x-2 items-center">
                          <xt-avatar
                            style="--size: 52px; --border-width: 6px;"
                            src=${template.thumbnail}
                            name=${template.name}></xt-avatar>
                          <div class="col max-w-[calc(100%-60px)]">
                            <p class="font-semibold line-clamp-1">${template.name}</p>
                            <div class="flex items-center space-x-[3px]">
                              <span class="text-sm">${msg(str`${template._totalExercises} exercícios`)}</span>
                            </div>
                          </div>
                        </ion-item>
                      `,
                    )}
                    <div>
                      <ion-router-link href="/exercises/templates" router-direction="forward">
                        <ion-button color="secondary" expand="block" shape="round" size="small">
                          ${msg(str`Ver todos (${templatesStore.totalTemplates})`)}
                        </ion-button>
                      </ion-router-link>
                    </div>
                  `;
                },
              })}
            </ion-list>
          </div>

          <div class="h-px w-full bg-accent-7"></div>

          <!-- --------------- Public Library ---------------- -->

          ${this.#loadlAll.render({
            pending: () => html`
              <div class="space-y-4">
                <div class="flex space-x-2">
                  <ion-skeleton-text animated style="width: 140px; height: 28px;"></ion-skeleton-text>
                  <ion-skeleton-text animated style="width: 80px; height: 28px;"></ion-skeleton-text>
                </div>
                <div class="flex space-x-2">
                  <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                  <div class="flex-1">
                    <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                  </div>
                </div>
                <ion-skeleton-text animated style="width: 100%; height: 40px;"></ion-skeleton-text>
              </div>
            `,
            error: (error) => {
              console.error(error);
              return html`
                <ion-alert
                  header=${msg("Erro")}
                  message=${msg("Erro ao carregar biblioteca pública. Por favor, tente novamente mais tarde.")}
                  .isOpen=${true}
                  .buttons=${["OK"]}></ion-alert>
                <div class="space-y-4">
                  <div class="flex space-x-2">
                    <ion-skeleton-text animated style="width: 140px; height: 28px;"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 80px; height: 28px;"></ion-skeleton-text>
                  </div>
                  <div class="flex space-x-2">
                    <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                    <div class="flex-1">
                      <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <ion-skeleton-text animated style="width: 100%; height: 40px;"></ion-skeleton-text>
                </div>
              `;
            },
            complete: ([_, publicLibrary]) => {
              return html`
                <div class="space-y-2 mt-4">
                  <div class="flex justify-between items-center">
                    <div class="flex items-center space-x-2">
                      <h6>${msg("Biblioteca de Exercícios")}</h6>
                      <ion-chip class="font-semibold" style="--background: #EAEAEA; --color: #888888"
                        >${msg(str`${publicLibrary?.totalExercises} exercícios`)}</ion-chip
                      >
                    </div>
                  </div>
                  <ion-list lines="none" class="space-y-2.5 flex flex-col">
                    ${publicLibrary?.popularExercises.map((exercise) => {
                      return html`
                        <ion-item
                          @click=${() => this.router.push(`/exercises/${exercise.id}`, "forward")}
                          button
                          .detail=${false}
                          class="no-p flex space-x-2 items-center">
                          <img
                            loading="lazy"
                            src=${pb.files.getUrl(exercise, exercise.thumbnail, { thumb: "128x128" })}
                            class="w-[52px] h-[52px] object-cover rounded-md" />
                          <div class="col max-w-[calc(100%-60px)]">
                            <p class="font-semibold line-clamp-1">${exercise.name}</p>
                            <div class="flex items-center space-x-[3px]">
                              ${exercise.categories.map((tag, index) => {
                                if (index < 2) {
                                  return html`
                                    <span class="text-sm">${tag.category}</span>
                                    <span class="text-accent-6 last:hidden">•</span>
                                  `;
                                } else {
                                  return nothing;
                                }
                              })}
                            </div>
                          </div>
                        </ion-item>
                      `;
                    })}
                  </ion-list>
                  <div>
                    <ion-router-link href="/exercises/public" router-direction="forward">
                      <ion-button color="secondary" expand="block" shape="round" size="small">
                        ${msg(str`Ver todos (${publicLibrary?.totalExercises})`)}
                      </ion-button>
                    </ion-router-link>
                  </div>
                </div>
              `;
            },
          })}
          ${this.#loadlAll.render({
            pending: () => {
              return html`
                <div class="space-y-4 mt-4">
                  <div class="flex space-x-2">
                    <ion-skeleton-text animated style="width: 140px; height: 28px;"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 80px; height: 28px;"></ion-skeleton-text>
                  </div>
                  <div class="flex space-x-2">
                    <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                    <div class="flex-1">
                      <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <ion-skeleton-text animated style="width: 100%; height: 40px;"></ion-skeleton-text>
                </div>
              `;
            },
            error: (error) => {
              console.error(error);
              return html`
                <ion-alert
                  header=${msg("Erro")}
                  message=${msg("Erro ao carregar bibliotecas. Por favor, tente novamente mais tarde.")}
                  .isOpen=${true}
                  .buttons=${["OK"]}></ion-alert>
                <div class="space-y-4 mt-4">
                  <div class="flex space-x-2">
                    <ion-skeleton-text animated style="width: 140px; height: 28px;"></ion-skeleton-text>
                    <ion-skeleton-text animated style="width: 80px; height: 28px;"></ion-skeleton-text>
                  </div>
                  <div class="flex space-x-2">
                    <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                    <div class="flex-1">
                      <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                      <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                    </div>
                  </div>
                  <ion-skeleton-text animated style="width: 100%; height: 40px;"></ion-skeleton-text>
                </div>
              `;
            },
            complete: ([libraries]) => {
              return html`
                <div>
                  <div class="space-y-4">
                    <!-- --------------- LIBRARIES ---------------- -->

                    ${libraries.length > 0
                      ? html`
                          <div class="h-px w-full bg-accent-7"></div>
                          ${repeat(
                            libraries,
                            (library) => library.id,
                            (library) => html`
                              <div class="space-y-2">
                                <div class="flex justify-between items-start">
                                  <div class="flex items-start space-x-2">
                                    ${when(
                                      library.isShared(currentUser) ||
                                        (library.isSharedFromGroup() && !library.isOwner(currentUser)),
                                      () => html`
                                        <div class="w-6 h-6 bg-accent-7 rounded-full flex items-center justify-center">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none">
                                            <g clip-path="url(#clip0_1218_3360)">
                                              <path
                                                d="M5.83301 7.58334C6.08352 7.91825 6.40313 8.19536 6.77016 8.39589C7.13719 8.59641 7.54305 8.71566 7.96022 8.74553C8.37739 8.77541 8.7961 8.71522 9.18796 8.56904C9.57982 8.42287 9.93566 8.19413 10.2313 7.89834L11.9813 6.14834C12.5126 5.59825 12.8066 4.86149 12.8 4.09675C12.7933 3.33201 12.4866 2.60047 11.9458 2.0597C11.405 1.51893 10.6735 1.21218 9.90876 1.20554C9.14402 1.19889 8.40726 1.49288 7.85717 2.02417L6.85384 3.02167"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                              <path
                                                d="M8.1663 6.41667C7.91578 6.08176 7.59617 5.80465 7.22914 5.60412C6.86211 5.4036 6.45625 5.28435 6.03908 5.25448C5.62192 5.2246 5.2032 5.28479 4.81134 5.43096C4.41948 5.57714 4.06365 5.80588 3.76796 6.10167L2.01796 7.85167C1.48667 8.40176 1.19268 9.13852 1.19933 9.90326C1.20597 10.668 1.51272 11.3995 2.05349 11.9403C2.59426 12.4811 3.3258 12.7878 4.09054 12.7945C4.85528 12.8011 5.59204 12.5071 6.14213 11.9758L7.13963 10.9783"
                                                stroke="black"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1218_3360">
                                                <rect width="14" height="14" fill="white" />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      `,
                                    )}
                                    <h6>${library.name}</h6>
                                    <ion-chip class="shrink-0 mt-0.5" style="--background: #EAEAEA; --color: #888888"
                                      >${msg(str`${library.totalExercises} exercícios`)}</ion-chip
                                    >
                                  </div>
                                  <ion-button
                                    class="mt-0.5"
                                    @click=${(e: Event) => this.presentPopover(e, library)}
                                    fill="clear"
                                    size="chip">
                                    <ion-icon slot="icon-only" icon="/assets/icons/dots.svg"></ion-icon>
                                  </ion-button>
                                </div>
                                <ion-list lines="none" class="space-y-2.5 flex flex-col">
                                  ${library.popularExercises.map(
                                    (exercise) => html`
                                      <ion-item
                                        button
                                        .detail=${false}
                                        class="no-p flex space-x-2 items-center"
                                        @click=${() =>
                                          this.#modalEditExerciseRef.value?.open({
                                            library: library,
                                            exercise: exercise,
                                          })}>
                                        <img
                                          loading="lazy"
                                          src="${exercise.thumbnail({ width: 128, height: 128 })}"
                                          class="w-[52px] h-[52px] object-cover rounded-md" />
                                        <div class="col">
                                          <p class="font-semibold line-clamp-1">${exercise.name}</p>
                                          <div class="flex items-center space-x-1.5">
                                            ${exercise.categories.map(
                                              (tag) => html`
                                                <span class="text-sm">${tag.category}</span>
                                                <span class="w-1 h-1 bg-accent-6 rounded last:hidden"></span>
                                              `,
                                            )}
                                          </div>
                                        </div>
                                      </ion-item>
                                    `,
                                  )}
                                </ion-list>
                                <div>
                                  <ion-router-link href="/exercises/library/${library.id}" router-direction="forward">
                                    <ion-button color="secondary" expand="block" shape="round" size="small">
                                      ${msg(str`Ver todos (${library.totalExercises})`)}
                                    </ion-button>
                                  </ion-router-link>
                                </div>
                              </div>
                              <div class="h-px w-full bg-accent-7 last:hidden"></div>
                            `,
                          )}
                        `
                      : nothing}
                  </div>
                </div>
              `;
            },
          })}
        </div>

        <!-- ---------------- FAB ------------ -->
        <ion-fab slot="fixed" vertical="bottom" horizontal="end" @click=${() => this.#modalNewLibraryRef.value?.open()}>
          <ion-fab-button id="open-modal-create-group" label="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 12H12M12 12H18M12 12V18M12 12V6"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </ion-fab-button>
        </ion-fab>
      </ion-content>

      <modal-nav root="modal-create-template" ${ref(this.#modalCreateTemplateRef)}></modal-nav>
      <modal-nav root="modal-create-library" ${ref(this.#modalNewLibraryRef)}></modal-nav>
      <modal-nav root="modal-edit-library" ${ref(this.#modalEditLibraryRef)}></modal-nav>
      <modal-nav root="modal-edit-exercise" ${ref(this.#modalEditExerciseRef)}></modal-nav>
      <modal-nav root="modal-exercise-public-filters" ${ref(this.#modalFiltersRef)}></modal-nav>
    `;
  }
}
