import { type ReactiveController, type ReactiveControllerHost, nothing, noChange, html } from "lit";
import { Directive, directive, type ElementPart, type PartInfo, PartType } from "lit/directive.js";

import {
  createForm,
  type Config,
  type FormApi,
  formSubscriptionItems,
  type FieldConfig,
  type FormSubscription,
  type MutableState,
} from "final-form";
import { z } from "zod";
import { IonCheckbox } from "@ionic/core/components/ion-checkbox";
import { IonInput } from "@ionic/core/components/ion-input";
import { IonRadioGroup } from "@ionic/core/components/ion-radio-group";
import { IonTextarea } from "@ionic/core/components/ion-textarea";

export type { Config } from "final-form";

export const zodValidator =
  <T>(schema: z.ZodType<T, any>) =>
  (values: T) => {
    const errors: Partial<Record<keyof T, string>> = {};

    try {
      schema.parse(values);
    } catch (err) {
      if (err instanceof z.ZodError) {
        err.errors.forEach((error) => {
          if (error.path.length > 0) {
            const key = error.path[0];
            if (typeof key === "string" || typeof key === "number") {
              errors[key as keyof T] = error.message;
            }
          }
        });
      }
    }

    return errors;
  };

const allFormSubscriptionItems = formSubscriptionItems.reduce<FormSubscription>(
  (acc, item) => (((acc as any)[item] = true), acc),
  {},
);

export class FinalFormController<FormValues> implements ReactiveController {
  #host: ReactiveControllerHost;
  #subscription: FormSubscription = allFormSubscriptionItems;
  #unsubscribe = () => {};
  form: FormApi<FormValues>;

  // https://final-form.org/docs/final-form/types/Config
  constructor(host: ReactiveControllerHost, config: Config<FormValues>, subscription?: FormSubscription) {
    (this.#host = host).addController(this);
    if (subscription) {
      this.#subscription = subscription;
    }

    const defaultConfig: Config<FormValues> = {
      validateOnBlur: false,
      ...config,
      mutators: {
        ...config.mutators,
        setFieldData: (args: any[], state: MutableState<FormValues>) => {
          const [name, data] = args;
          const field = state.fields[name];
          if (field) {
            field.data = { ...field.data, ...data };
          }
        },
      },
    };

    this.form = createForm(defaultConfig);
  }

  hostConnected() {
    this.#unsubscribe = this.form.subscribe(async () => {
      // this is to remove the annoying warning about inefficient updates
      // https://github.com/lit/lit/issues/3597#issuecomment-1409012449
      await this.#host.updateComplete;
      this.#host.requestUpdate();
    }, this.#subscription);
  }

  hostDisconnected() {
    this.#unsubscribe();
  }

  setError = <K extends keyof FormValues>(field: K, error: string) => {
    this.form.mutators.setFieldData?.(field, { error });
  };

  clearError = <K extends keyof FormValues>(field: K) => {
    this.form.mutators.setFieldData?.(field, { error: "" });
  };

  clearErrors = () => {
    for (const field in this.form.getRegisteredFields()) {
      this.clearError(field as keyof FormValues);
    }
  };

  // https://final-form.org/docs/final-form/types/FieldConfig
  register = <K extends keyof FormValues>(name: K, fieldConfig?: FieldConfig<FormValues[K]>) => {
    return registerDirective(this.form, name, fieldConfig);
  };

  submit = (e: Event) => {
    e.preventDefault();
    this.form.submit();
  };

  renderError = <K extends keyof FormValues>(field: K) => {
    let { touched, error, data } = this.form.getFieldState(field) ?? {};
    if ((error && touched) || data?.error) {
      const e = error ?? data?.error ?? "";
      return html`<xt-error>${e}</xt-error>`;
    }

    return nothing;
  };
}

class RegisterDirective extends Directive {
  #registered = false;

  constructor(partInfo: PartInfo) {
    super(partInfo);
    if (partInfo.type !== PartType.ELEMENT) {
      throw new Error("The `register` directive must be used in the `element` attribute");
    }
  }

  update(part: ElementPart, [form, name, fieldConfig]: Parameters<this["render"]>) {
    if (!this.#registered) {
      form.registerField(
        name,
        (fieldState) => {
          let { blur, change, focus, value, error, touched, data } = fieldState;

          if (data?.error) {
            error = data.error;
          }

          const el = part.element as IonInput | IonTextarea | IonCheckbox | HTMLInputElement | IonRadioGroup;
          el.name = String(name);
          if (!this.#registered) {
            // if tagName contains ion
            if (el.tagName.toLowerCase().startsWith("ion")) {
              el.addEventListener("ionBlur", () => blur());
              el.addEventListener("ionChange", (event) => {
                if (el instanceof IonCheckbox) {
                  return change((event.target as IonCheckbox).checked);
                } else if (el instanceof IonInput) {
                  return change((event.target as IonInput).value);
                } else if (el instanceof IonTextarea) {
                  return change((event.target as IonTextarea).value);
                } else if (el instanceof IonRadioGroup) {
                  return change((event.target as IonRadioGroup).value);
                }
              });
              el.addEventListener("ionFocus", () => focus());
            } else {
              el.addEventListener("blur", () => blur());
              el.addEventListener("change", (event) => {
                if (el instanceof HTMLInputElement) {
                  if (el.type === "checkbox" || el.type === "radio") {
                    return change((event.target as HTMLInputElement).checked);
                  } else {
                    return change((event.target as HTMLInputElement).value);
                  }
                }
              });
              el.addEventListener("focus", () => focus());
            }
          }

          if (el instanceof IonCheckbox) {
            el.checked = value;
          } else if (el instanceof IonInput) {
            el.value = value === undefined ? "" : value;
          } else if (el instanceof IonTextarea) {
            el.value = value === undefined ? "" : value;
          } else if (el instanceof IonRadioGroup) {
            el.value = value === undefined ? "" : value;
          } else if (el instanceof HTMLInputElement) {
            if (el.type !== "file") {
              el.value = value === undefined ? "" : value;
            }
          }

          if ((touched || form.getState().submitFailed) && error) {
            el.setAttribute("data-error", "true");
            if (el.parentElement?.tagName.toLowerCase() === "ion-item") {
              el.parentElement.setAttribute("data-error", "true");
            }
          } else {
            el.removeAttribute("data-error");
            if (el.parentElement?.tagName.toLowerCase() === "ion-item") {
              el.parentElement.removeAttribute("data-error");
            }
          }
        },
        { value: true, touched: true, error: true, data: true },
        fieldConfig,
      );
      this.#registered = true;
    }

    return noChange;
  }

  // Can't get generics carried over from directive call
  render(_form: FormApi<any>, _name: PropertyKey, _fieldConfig?: FieldConfig<any>) {
    return nothing;
  }
}

const registerDirective = directive(RegisterDirective);
