import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { Page } from "../components/component";

@customElement("page-empty")
export class PageExercises extends Page {
  render() {
    return html`<ion-content fullscreen>empty page</ion-content>`;
  }
}
