import { html, type PropertyValues } from "lit";
import { customElement, state } from "lit/decorators.js";
import { alertController, modalController } from "@ionic/core";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page, required } from "../components/component";
import { choose } from "lit/directives/choose.js";
import { UserShareStore } from "../stores/userShare.store";
import { ModalShareEdit } from "./modal-share-edit";
import { ModalShareAdd } from "./modal-share-add";
import { when } from "lit/directives/when.js";
import { ObservableTemplateExercisePlan, templatesStore } from "../stores/templateExercisePlan.store";
import { RouterContext, routerContext } from "../context/router.context";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalNav } from "./modal-nav";
import { localized, msg, str } from "@lit/localize";
import { type UpdateTemplateExercisePlan, UpdateTemplateExercisePlanSchema } from "../repository/templates/templates";
import { ApiError } from "../error";

@customElement("modal-edit-template")
@localized()
export class ModalEditTemplate extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: routerContext }) router!: RouterContext;

  @required() template!: ObservableTemplateExercisePlan;
  @state() users: UserShareStore["users"] = [];

  shareStore = new UserShareStore();

  #modalCropPhotoRef: Ref<ModalNav> = createRef();

  async connectedCallback(): Promise<void> {
    super.connectedCallback();
    this.shareStore.set(
      this.template.sharedPersonal.map((share) => ({
        user: share.user,
        access: share.access,
        action: "update",
      })),
    );

    this.users = this.shareStore.updatedUsers.slice(0, 2);

    if (this.template.thumbnail) {
      try {
        this.fetchAndSetThumbnail(this.template.thumbnail);
      } catch (error) {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: msg("Erro ao buscar a imagem do template"),
          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    //so that new users stay in the same order in the list depending when they were added
    this.shareStore.on("create", () => {
      this.users.push(this.shareStore.newUsers[this.shareStore.newUsers.length - 1]!);
      this.requestUpdate();
    });

    this.shareStore.on("delete", () => {
      this.users = this.users.filter((user) => user.action !== "delete");
      // if its a new user that was deleted, remove it from the new users list
      this.users.map((user) => {
        if (user.action === "create" && !this.shareStore.newUsers.includes(user)) {
          this.users = this.users.filter((u) => u !== user);
        }
      });
      this.requestUpdate();
    });
  }

  #controller = new FinalFormController<UpdateTemplateExercisePlan>(this, {
    validate: zodValidator(UpdateTemplateExercisePlanSchema()),
    onSubmit: async (data) => {
      try {
        await templatesStore.updateTemplate(this.template, data);
        await templatesStore.shareWithUsers(this.template, this.shareStore.users);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  updateCroppedImage(canvas: HTMLCanvasElement) {
    const image = canvas.toDataURL("image/jpeg");
    const button = document.querySelector("#thumbnail-button") as HTMLButtonElement;
    button.style.backgroundImage = `url(${image})`;
    button.style.backgroundSize = "cover";
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const fileName = `cropped-image-${Date.now()}.jpeg`;
          const file = new File([blob], fileName, { type: "image/jpeg" });
          this.#controller.form.change("thumbnail", file);
        } else {
          console.error("Canvas to Blob conversion failed");
        }
      },
      "image/jpeg",
      0.8,
    );
  }

  // Helper function to fetch and set thumbnail
  async fetchAndSetThumbnail(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split("/").pop()?.split("?")[0] || "file";
      const file = new File([blob], fileName, { type: blob.type });

      // Update the button's background image
      const button = this.renderRoot.querySelector("#thumbnail-button") as HTMLButtonElement;
      const imageUrl = URL.createObjectURL(file);
      button.style.backgroundImage = `url(${imageUrl})`;
      button.style.backgroundSize = "cover";
      button.style.backgroundPosition = "center";
      button.style.backgroundRepeat = "no-repeat";

      this.#controller.form.change("thumbnail", file);
    } catch (error) {
      throw new ApiError("thumbnail-fetch-error", msg("Erro ao buscar a imagem do template"));
    }
  }

  private deleteTemplate() {
    alertController
      .create({
        header: msg("Apagar template"),
        message: msg("Tem a certeza que quer apagar este template?"),
        buttons: [
          {
            text: msg("Cancelar"),
            role: "cancel",
          },
          {
            text: msg("Apagar"),
            role: "destructive",
            handler: async () => {
              try {
                await templatesStore.deleteTemplate(this.template);
                this.navigator.close();
                this.router.back();
              } catch (error) {
                if (error instanceof ApiError) {
                  const alert = await alertController.create({
                    header: msg("Erro"),
                    message: error.message,
                    buttons: ["OK"],
                  });
                  await alert.present();
                }
              }
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }

  render() {
    const { form, register, submit, renderError } = this.#controller;
    const formState = form.getState();

    return html`
      <ion-content class="space-y-4" fullscreen>
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => modalController.dismiss()}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">${this.template.name}</ion-title>
            <ion-buttons slot="end">
              <div class="flex items-center">
                <ion-button
                  @click=${() => this.deleteTemplate()}
                  color="danger"
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    class="mr-1">
                    <path
                      d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                  ${msg("Apagar")}
                </ion-button>
              </div>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form id="form-template-update" @submit=${submit}>
          <div class="flex flex-col justify-center items-center w-full">
            <button
              id="thumbnail-button"
              type="button"
              class="w-36 h-36 rounded-full bg-accent-8 border border-solid border-accent-7"
              aria-label="thumbnail"
              @click=${() => {
                const thumbnailFile = this.renderRoot.querySelector("#thumbnail-file") as HTMLInputElement;
                thumbnailFile.click();
              }}>
              ${when(
                !formState.values.thumbnail,
                () => html`
                  <div slot="icon-only">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                      <path
                        d="M8.5 12H12.5M12.5 12H16.5M12.5 12V16M12.5 12V8M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                `,
              )}
            </button>
            ${renderError("thumbnail")}
            <input
              ${register("thumbnail")}
              id="thumbnail-file"
              hidden
              accept="image/*"
              type="file"
              @change=${(event: Event) => {
                const input = event.target as HTMLInputElement;
                if (input.files && input.files[0]) {
                  const file = input.files[0];

                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const imageUrl = e.target?.result as string;
                    this.#modalCropPhotoRef.value?.open({
                      image: imageUrl,
                      updateImage: this.updateCroppedImage.bind(this),
                    });
                  };
                  reader.readAsDataURL(file);
                  input.value = "";
                }
              }} />
          </div>

          <div>
            <span class="font-semibold font-display">${msg("Nome")}</span>
            <ion-item class="mt-1">
              <ion-input
                autocapitalize="on"
                type="text"
                placeholder=${msg("Nome do template")}
                ${register("name", {
                  initialValue: this.template.name,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("name")}
          </div>
          <div class="mt-6">
            <div class="flex justify-between items-center">
              <span class="font-semibold font-display">${msg("Profissionais")}</span>
              ${when(
                this.template.hasPrivileges(this.auth.user),
                () => html`
                  <ion-button
                    class="h-7 text-sm"
                    style="--padding-start: 8px; --padding-end: 8px;"
                    color="secondary"
                    shape="round"
                    @click=${() => {
                      this.navigator.push("modal-share-add", {
                        shareStore: this.shareStore,
                      });
                    }}>
                    <svg
                      class="mr-1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M4 8H8M8 8H12M8 8V12M8 8V4"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    ${msg("Adicionar")}
                  </ion-button>
                `,
              )}
            </div>
            <ion-list lines="none" class="space-y-2.5 mt-2 flex flex-col">
              <ion-item
                class="no-p"
                ${register("owner", {
                  initialValue: this.template.owner.id,
                })}>
                <div class="flex items-center space-x-2">
                  <xt-avatar src=${this.template.owner?.avatar} name=${this.template.owner?.name}></xt-avatar>
                  <div class="col items-start">
                    <p class="font-semibold">${this.template.owner?.name}</p>
                    <span class="text-danger">${msg("Criador")}</span>
                  </div>
                </div>
                ${renderError("owner")}
              </ion-item>
              ${this.users.map(
                (share) => html`
                  <ion-item
                    class="no-p"
                    @click=${() => {
                      if (this.template.hasPrivileges(this.auth.user))
                        this.navigator.push("modal-share-edit", {
                          share: share,
                          shareStore: this.shareStore,
                        });
                    }}>
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${share.user.name}</p>
                        ${choose(share.access.role, [
                          ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                          ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                          ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                        ])}
                      </div>
                    </div>
                  </ion-item>
                `,
              )}
              ${when(
                this.shareStore.updatedUsers?.length > 2 &&
                  this.users.filter((user) => user.action === "update").length === 2,
                () => {
                  return html`
                    <ion-button
                      class="mt-3"
                      size="small"
                      color="secondary"
                      expand="block"
                      shape="round"
                      @click=${() => (this.users.push(...this.shareStore.updatedUsers.slice(2)), this.requestUpdate())}
                      >${msg(str`Ver Todos (${this.shareStore.updatedUsers.length + 1})`)}
                    </ion-button>
                  `;
                },
              )}
              <!-- ${this.shareStore.availableUsers.map(
                (share) => html`
                  <ion-item
                    class="no-p"
                    @click=${() =>
                      this.navigator.push(ModalShareEdit, {
                        shareStore: this.shareStore,
                        share: share,
                      })}>
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${share.user.avatar} name=${share.user.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${share.user.name}</p>
                        ${choose(share.access.role, [
                          ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                          ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                          ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                        ])}
                      </div>
                    </div>
                  </ion-item>
                `,
              )} -->
            </ion-list>
          </div>
          <!-- <div class="mt-3">
            <ion-button
              size="small"
              color="secondary"
              expand="block"
              shape="round"
              @click=${() => this.navigator.push(ModalShareAdd, { shareStore: this.shareStore })}
              >${msg("Adicionar Profissional")}
              <span class="mt-0.5 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path
                    d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
            </ion-button>
          </div> -->
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-template-update"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Guardar Alterações")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>
    `;
  }
}
