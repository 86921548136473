import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { consume } from "@lit/context";
import { pb } from "./pocketbase";
import { notificationsStore } from "./stores/notifications.store";
import { groupStore } from "./stores/groups.store";
import { LightElement } from "./components/component";
import { librariesStore } from "./stores/libraries.store";
import { authContext, type AuthContext } from "./context/auth.context";
import { routerContext, RouterContext } from "./context/router.context";
import { templatesStore } from "./stores/templateExercisePlan.store";
import { reportsStore } from "./stores/reports.store";
import { configureLocalization } from "@lit/localize";
import { sourceLocale, targetLocales } from "./generated/locale-codes.js";

@customElement("app-routes")
export class AppRoutes extends LightElement {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;

  firstUpdated() {
    if (this.auth.isAuthenticated) {
      this.#subscribeToNotifications();

      const { setLocale } = configureLocalization({
        sourceLocale,
        targetLocales,
        loadLocale: (locale) => import(`./generated/locales/${locale}.ts`),
      });

      setLocale(this.auth.user.language);

      window.addEventListener("locale-changed", (e) => {
        setLocale((e as CustomEvent).detail);
      });
    } else {
      const language = navigator.languages ? navigator.languages[0] : navigator.language;

      const { setLocale } = configureLocalization({
        sourceLocale,
        targetLocales,
        loadLocale: (locale) => import(`./generated/locales/${locale}.ts`),
      });

      switch (language) {
        case "pt-PT":
          setLocale("pt");
          break;
        case "pt-BR":
          setLocale("pt");
          break;
        case "en":
          setLocale("en");
          break;
        default:
          setLocale("en");
          break;
      }

      window.addEventListener("locale-changed", (e) => {
        setLocale((e as CustomEvent).detail);
      });
    }
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("locale-changed", () => {
      this.requestUpdate();
    });
  }

  // INFO: don't let prettier format the route so it's easier to read and edit
  // prettier-ignore
  render() {
    return html`
      <ion-app>
        ${this.router.render()}
        <!--
        I've setted this as true because of animations even though it could
        cause issues. It's preferred to set it false
        -->
        <ion-router-outlet .animated=${true} ></ion-router-outlet>

        <ion-toast
          id="update-sw-toast"
          duration="10000"
          message="Nova atualização disponível"
          position="bottom"
          icon="/assets/icons/info.svg"
          keyboard-close
        ></ion-toast>
      </ion-app>
    `
  }

  #subscribeToNotifications() {
    pb.collection("notification_group")
      .subscribe("*", (e) => {
        //NOTIFICATION CREATION
        if (e.action === "create") {
          if (this.auth.user.id === e.record["user_receiver"]) {
            notificationsStore.newGroupInvite(e.record.id);
            if (e.record.type === "removal") {
              const group = groupStore.find(e.record.group);
              if (group) {
                groupStore.delete(group); // OK
              }
            }
          }
        } else if (e.action === "update" && e.record.type === "invite") {
          if (this.auth.user.id === e.record["user_sender"]) {
            if (e.record.accepted && !e.record.rejected) {
              notificationsStore.newGroupInviteAccepted(e.record.id); // OK
              groupStore.loadGroups({ cache: false }); // OK
            } else if (e.record.rejected && !e.record.accepted) {
              notificationsStore.newGroupInviteRejected(e.record.id); // OK
            }
          } else if (this.auth.user.id === e.record["user_receiver"]) {
            if (e.record.accepted && !e.record.rejected) {
              groupStore.loadGroups({ cache: false }); // OK
            }
          }
        }
      })
      .catch((e) => {
        console.log("error subscribing to notification_group");
        console.log(e);
      });

    pb.collection("notification_library")
      .subscribe("*", (e) => {
        //NOTIFICATION CREATION
        if (e.action === "create") {
          if (this.auth.user.id === e.record["user_receiver"]) {
            notificationsStore.newLibraryInvite(e.record.id); // OK
            if (e.record.type === "removal") {
              const library = librariesStore.find(e.record.library);
              if (library) {
                librariesStore.delete(library); // OK
              }
            }
          }
        } else if (e.action === "update" && e.record.type === "invite") {
          if (this.auth.user.id === e.record["user_sender"]) {
            if (e.record.accepted && !e.record.rejected) {
              notificationsStore.newLibraryInviteAccepted(e.record.id); // OK
              librariesStore.loadLibrary(e.record.library, { cache: false }); // OK
            } else if (e.record.rejected && !e.record.accepted) {
              notificationsStore.newLibraryInviteRejected(e.record.id); // OK
            }
          } else if (this.auth.user.id === e.record["user_receiver"]) {
            if (e.record.accepted && !e.record.rejected) {
              librariesStore.loadLibrary(e.record.library, { cache: false }); // OK
            }
          }
        }
      })
      .catch((e) => {
        console.log("error subscribing to notification_library");
        console.log(e);
      });

    pb.collection("notification_template_exercise_plan")
      .subscribe("*", (e) => {
        //NOTIFICATION CREATION
        if (e.action === "create") {
          if (this.auth.user.id === e.record["user_receiver"]) {
            notificationsStore.newTemplateExercisePlanInvite(e.record.id);
            if (e.record.type === "removal") {
              const id = e.record.template;
              const template = templatesStore.find(id);
              if (template) {
                templatesStore.delete(template);
              }
            }
          }
        } else if (e.action === "update" && e.record.type === "invite") {
          if (this.auth.user.id === e.record["user_sender"]) {
            if (e.record.accepted && !e.record.rejected) {
              notificationsStore.newTemplateExercisePlanInviteAccepted(e.record.id);
              templatesStore.loadTemplate(e.record.template, { cache: false });
            } else if (e.record.rejected && !e.record.accepted) {
              notificationsStore.newTemplateExercisePlanInviteRejected(e.record.id);
            }
          } else if (this.auth.user.id === e.record["user_receiver"]) {
            if (e.record.accepted && !e.record.rejected) {
              templatesStore.loadTemplate(e.record.template, { cache: false });
            }
          }
        }
      })
      .catch((e) => {
        console.log("error subscribing to notification_library");
        console.log(e);
      });

    pb.collection("notification_report")
      .subscribe("*", (e) => {
        //NOTIFICATION CREATION
        if (e.action === "create") {
          if (this.auth.user.id === e.record["user_receiver"]) {
            notificationsStore.newReportInvite(e.record.id);
            if (e.record.type === "removal") {
              const id = e.record.report;
              const report = reportsStore.findReport(id);
              if (report) {
                reportsStore.delete(report);
              }
            }
          }
        } else if (e.action === "update" && e.record.type === "invite") {
          if (this.auth.user.id === e.record["user_sender"]) {
            if (e.record.accepted && !e.record.rejected) {
              notificationsStore.newReportInviteAccepted(e.record.id);
              reportsStore.loadReport(e.record.report, { cache: false });
            } else if (e.record.rejected && !e.record.accepted) {
              notificationsStore.newReportInviteRejected(e.record.id);
            }
          } else if (this.auth.user.id === e.record["user_receiver"]) {
            if (e.record.accepted && !e.record.rejected) {
              reportsStore.loadReport(e.record.report, { cache: false });
            }
          }
        }
      })
      .catch((e) => {
        console.log("error subscribing to notification_report");
        console.log(e);
      });
  }
}
