import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { when } from "lit/directives/when.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { repeat } from "lit/directives/repeat.js";
import { navigatorContext, type NavigatorController } from "../controllers/navigator.controller";
import { Task } from "@lit/task";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { ObservableTemplateExercise, templatesStore } from "../stores/templateExercisePlan.store";
import { ObservableExercise } from "../stores/exercises.store";
import { pb } from "../pocketbase";
import type { PublicExercise } from "../repository/library/exercises";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("page-template-exercise-plan-template")
@localized()
export class PageTemplateExercisePlanExercise extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #modalCreateTemplateRef: Ref<ModalNav> = createRef();
  #modalEditTemplateExerciseRef: Ref<ModalNav> = createRef();
  #modalEditTemplateRef: Ref<ModalNav> = createRef();

  #ionRefresher: Ref<IonRefresher> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this.#template.run([true]);
  }

  #template = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return await templatesStore.loadTemplate(this.router.getParam("templateId"), { cache: cache });
    },
  });

  getSpecs(e: ObservableTemplateExercise) {
    let specs = [];

    if (e.series) {
      specs.push(html`<span class="text-sm">${msg(str`${e.series} Séries`)}</span>`);
    }
    if (e.repetitions) {
      specs.push(html`<span class="text-sm">${msg(str`${e.repetitions} Repetições`)}</span>`);
    }
    if (e.weight) {
      specs.push(html`<span class="text-sm">${e.weight} ${e.weightUnit}</span>`);
    }
    if (e.duration) {
      const totalSeconds = e.duration;
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);
      if (minutes > 0) specs.push(html`<span class="text-sm">${msg(str`${minutes} Minutos`)}</span>`);
      if (seconds > 0) specs.push(html`<span class="text-sm">${msg(str`${seconds} Segundos`)}</span>`);
    }

    return specs.slice(0, 2);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-router-link href=${this.router.backUrl} router-direction="back">
                <ion-button
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </ion-router-link>
            </div>
          </ion-buttons>
          <ion-title>${this.#template.value?.name}</ion-title>
          ${when(
            this.#template.value?.hasPrivileges(this.auth.user),
            () => html`
              <ion-button
                style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                color="secondary"
                shape="round"
                size="xsmall"
                slot="end"
                @click=${() => {
                  this.#modalEditTemplateRef.value?.open({
                    template: this.#template.value,
                  });
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class="mr-1">
                  <path
                    d="M8.00033 5.33341L2.66699 10.6667V13.3334L5.33366 13.3334L10.667 8.00007M8.00033 5.33341L9.91274 3.42098L9.91389 3.41984C10.1772 3.15659 10.309 3.02473 10.461 2.97534C10.5949 2.93183 10.7392 2.93183 10.873 2.97534C11.0249 3.02469 11.1567 3.1564 11.4195 3.41929L12.5794 4.57915C12.8434 4.84316 12.9755 4.97523 13.0249 5.12745C13.0684 5.26134 13.0684 5.40557 13.0249 5.53947C12.9755 5.69158 12.8436 5.82344 12.58 6.08708L12.5794 6.08764L10.667 8.00007M8.00033 5.33341L10.667 8.00007"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
                ${msg("Editar")}
              </ion-button>
            `,
          )}
        </ion-toolbar>
      </ion-header>

      <ion-content fullscreen style="--padding-bottom: 24px" class="no-p">
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            await this.#template.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>

        <ion-header collapse="condense">
          <ion-toolbar style="--padding-top: 0">
            <div class="flex justify-between items-start mx-4 gap-x-1.5">
              ${this.#template.render({
                pending: () => html`
                  <ion-skeleton-text animated style="width: 120px; height: 38px;"></ion-skeleton-text>
                  <div class="flex items-center space-x-1 mt-2">
                    <ion-skeleton-text animated style="width: 24px; height: 20px;"></ion-skeleton-text>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                `,
                error: () => html`
                  <ion-skeleton-text animated style="width: 120px; height: 38px;"></ion-skeleton-text>
                  <div class="flex items-center space-x-1 mt-2">
                    <ion-skeleton-text animated style="width: 24px; height: 20px;"></ion-skeleton-text>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                `,
                complete: (template) => html`
                  <h2>${template.name}</h2>
                  <div class="flex items-center space-x-1 mt-2">
                    <span class="font-display font-semibold">${template.totalExercises}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </div>
                `,
              })}
            </div>
            <div class="bg-accent-7 h-1 w-full mt-3"></div>
          </ion-toolbar>
        </ion-header>

        <!-- --------------- CONTENT ----------------- -->
        ${this.#template.render({
          pending: () => {
            return html`
              <div class="space-y-2.5 mt-4 px-4">
                ${Array.from({ length: 4 }).map(() => {
                  return html`
                    <div class="flex space-x-2">
                      <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                      <div class="flex-1">
                        <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                        <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                      </div>
                    </div>
                  `;
                })}
              </div>
            `;
          },
          error: (err) => {
            if (err instanceof ApiError) {
              return html`<div
                class="mt-4 mx-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${err.message}
                </span>
              </div>`;
            } else {
              return html`<div
                class="mt-4 mx-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${msg("Erro ao carregar a biblioteca")}
                </span>
              </div>`;
            }
          },
          complete: (template) => {
            return html`
              ${when(
                template._totalExercises > 0,
                () => {
                  return html`
                    <ion-list lines="none" class="mt-4 space-y-2.5 mb-1 px-4 flex flex-col">
                      ${repeat(
                        template.exercises,
                        (e) => e.id,
                        (e) => html`
                          <ion-item
                            @click=${() => {
                              this.#modalEditTemplateExerciseRef.value?.open({
                                exercise: e,
                                template: template,
                              });
                            }}
                            button
                            detail-icon="/assets/icons/itemArrow.svg"
                            style="--inner-padding-end: 0px; --detail-icon-opacity: 1;"
                            class="no-p no-inner-p flex space-x-2 items-center">
                            ${when(
                              e.exercise instanceof ObservableExercise,
                              () => html`
                                <xt-avatar
                                  style="--size: 52px; --border-width: 6px;"
                                  src=${(e.exercise as ObservableExercise).thumbnail({ width: 128, height: 128 })}
                                  name=${e.exercise?.name}></xt-avatar>
                              `,
                              () => {
                                const thumbnail = pb.files.getUrl(
                                  e.exercise!,
                                  (e.exercise as PublicExercise).thumbnail,
                                  {
                                    thumb: "128x128",
                                  },
                                );
                                return html`
                                  <xt-avatar
                                    style="--size: 52px; --border-width: 6px;"
                                    src=${thumbnail}
                                    name=${e.exercise?.name}></xt-avatar>
                                `;
                              },
                            )}
                            <div class="col max-w-[calc(100%-60px)]">
                              <p class="font-semibold line-clamp-1">${e.exercise?.name}</p>
                              <div class="flex items-center space-x-[3px]">
                                ${this.getSpecs(e).map((s) => {
                                  return html`${s}
                                    <span class="text-sm last:hidden">•</span> `;
                                })}
                              </div>
                            </div>
                          </ion-item>
                        `,
                      )}
                    </ion-list>
                  `;
                },
                () => {
                  return html`
                    ${when(
                      template.canEdit(this.auth.user),
                      () =>
                        html`
                          <ion-item
                            button
                            @click=${() =>
                              this.#modalCreateTemplateRef.value?.open({
                                template: template,
                              })}
                            .detail=${false}
                            class="w-full h-[139px] rounded-md no-p no-inner-p mt-4 px-4">
                            <div
                              class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none">
                                <path
                                  d="M6 12H12M12 12H18M12 12V18M12 12V6"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round" />
                              </svg>
                              <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                                ${msg("Cria o teu primeiro template")}</span
                              >
                            </div>
                          </ion-item>
                        `,
                      () =>
                        html`
                          <ion-item class="w-full h-[139px] rounded-md no-p no-inner-p mt-4 px-4">
                            <div
                              class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                              <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                                ${msg("Ainda não existem templates")}</span
                              >
                            </div>
                          </ion-item>
                        `,
                    )}
                  `;
                },
              )}
            `;
          },
        })}

        <!-- FAB -->

        ${when(
          this.#template.value?.hasPrivileges(this.auth.user),
          () => html`
            <ion-fab
              slot="fixed"
              vertical="bottom"
              horizontal="end"
              @click=${() =>
                this.#modalCreateTemplateRef.value?.open({
                  template: this.#template.value,
                })}>
              <ion-fab-button id="open-modal-create-template" label="button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6 12H12M12 12H18M12 12V18M12 12V6"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </ion-fab-button>
            </ion-fab>
          `,
        )}
      </ion-content>

      <modal-nav root="modal-create-template-exercise" ${ref(this.#modalCreateTemplateRef)}></modal-nav>
      <modal-nav root="modal-edit-template-exercise" ${ref(this.#modalEditTemplateExerciseRef)}></modal-nav>
      <modal-nav root="modal-edit-template" ${ref(this.#modalEditTemplateRef)}></modal-nav>
    `;
  }
}
