import { z } from "zod";
import { pb, withThumb } from "../../pocketbase";
import { file } from "../shared/file";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";
import { msg } from "@lit/localize";

export const LibraryCategoryRecordSchema = record({
  library: z.string().optional(),
  category: z.string(),
  count: z.number().optional().default(0),
});
export interface LibraryCategoryRecord extends z.infer<typeof LibraryCategoryRecordSchema> {}

export const CategorySchema = LibraryCategoryRecordSchema.omit({ expand: true });
export interface Category extends z.infer<typeof LibraryCategoryRecordSchema> {}
export function createCategory(data: unknown) {
  return unsafeParse(LibraryCategoryRecordSchema, data);
}

export const MuscleRecordSchema = record({
  library: z.string().optional(),
  muscle: z.string(),
  count: z.number().optional().default(0),
});
export interface MuscleRecord extends z.infer<typeof MuscleRecordSchema> {}

export const MuscleSchema = MuscleRecordSchema.omit({ expand: true });
export interface Muscle extends z.infer<typeof MuscleRecordSchema> {}
export function createMuscle(data: unknown) {
  return unsafeParse(MuscleRecordSchema, data);
}

export const EquipmentRecordSchema = record({
  library: z.string().optional(),
  equipment: z.string(),
  count: z.number().optional().default(0),
});
export interface EquipmentRecord extends z.infer<typeof EquipmentRecordSchema> {}

export const EquipmentSchema = EquipmentRecordSchema.omit({ expand: true });
export interface Equipment extends z.infer<typeof EquipmentRecordSchema> {}
export function createEquipment(data: unknown) {
  return unsafeParse(EquipmentRecordSchema, data);
}

export const ExercisesFiltersSchema = z.object({
  muscles: z.array(MuscleSchema).default([]),
  categories: z.array(CategorySchema).default([]),
  equipments: z.array(EquipmentSchema).default([]),
});
export interface ExercisesFilters extends z.infer<typeof ExercisesFiltersSchema> {}
export function createExercisesFilters(data: unknown) {
  return unsafeParse(ExercisesFiltersSchema, data);
}

export const ExerciseRecordSchema = record({
  name: z.string(),
  exercisesLibrary: z.string(),
  content: z.array(z.string()),
  thumbnail: z.string(),
  categories: z.array(z.string()),
  muscles: z.array(z.string()),
  equipments: z.array(z.string()),
});
export interface ExerciseRecord extends z.infer<typeof ExerciseRecordSchema> {}

export const ExerciseSchema = ExerciseRecordSchema.extend({
  exercisesLibrary: z.string(),
  categories: z.array(CategorySchema).default([]),
  muscles: z.array(MuscleSchema).default([]),
  equipments: z.array(EquipmentSchema).default([]),
}).omit({ expand: true, exercisesLibrary: true });
export interface Exercise extends z.infer<typeof ExerciseSchema> {}
export function createExercise(data: Record<string, unknown>) {
  data["content"] = (data["content"] as string[]).map((filename) => {
    return pb.getFileUrl(data, filename, {
      thumb: "256x256",
    });
  });
  // return unsafeParse(ExerciseSchema, withThumb(data, "thumbnail", "256x256"));
  return unsafeParse(ExerciseSchema, data);
}

export const ExerciseSearchSchema = record({
  id: z.string(),
  name: z.string(),
  thumbnail: z.string(),
  categories: z.array(CategorySchema).optional(),
});
export interface ExerciseSearch extends z.infer<typeof ExerciseSearchSchema> {}
export function createExerciseSearch(data: Record<string, unknown>) {
  return unsafeParse(ExerciseSearchSchema, withThumb(data, "thumbnail"));
}

export const CreateExerciseSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("Nome é obrigatório") })
      .min(2, msg("O nome deve ter pelo menos 2 caracteres"))
      .max(50, msg("O nome deve ter no máximo 50 caracteres")),
    exercisesLibrary: z.string(),
    content: z.array(
      file(
        {
          size: 10485760,
          allowedFileTypes: [
            "image/jpeg",
            "image/jpg",
            "image/avif",
            "image/png",
            "image/webp",
            "image/heic",
            "image/heif",
            "image/gif",
            "video/mp4",
            "video/webm",
          ],
        },
        msg("O conteúdo deve ser menor que 10MB e ter o formato jpeg, avif, png, webp, heic, heif, gif, mp4 ou webm"),
      ),
      { required_error: msg("Conteúdo é obrigatório") },
    ),
    thumbnail: file(
      {
        size: 10485760,
        allowedFileTypes: [
          "image/jpeg",
          "image/jpg",
          "image/avif",
          "image/png",
          "image/webp",
          "image/heic",
          "image/heif",
        ],
      },
      msg("O thumbnail deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif"),
    ),
    categories: z.array(z.string()).optional(),
    muscles: z.array(z.string()).optional(),
    equipments: z.array(z.string()).optional(),
  });
export interface CreateExercise extends z.infer<ReturnType<typeof CreateExerciseSchema>> {}

export const UpdateExerciseSchema = () =>
  z.object({
    name: z
      .string({ required_error: msg("Nome é obrigatório") })
      .min(2, msg("O nome deve ter pelo menos 2 caracteres"))
      .max(50, msg("O nome deve ter no máximo 50 caracteres")),
    exercisesLibrary: z.string().optional(),
    content: z
      .array(
        file(
          {
            size: 10485760,
            allowedFileTypes: [
              "image/jpeg",
              "image/jpg",
              "image/avif",
              "image/png",
              "image/webp",
              "image/heic",
              "image/heif",
              "image/gif",
              "video/mp4",
              "video/webm",
            ],
          },
          msg("O conteúdo deve ser menor que 10MB e ter o formato jpeg, avif, png, webp, heic, heif, gif, mp4 ou webm"),
        ),
      )
      .optional(),
    "content-": z.array(z.string()).optional(),
    thumbnail: file(
      {
        size: 10485760,
        allowedFileTypes: ["image/jpeg", "image/jpg", "image/png", "image/webp", "image/heic", "image/heif"],
      },
      msg("O thumbnail deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif"),
    ).optional(),
    categories: z.array(z.string()).optional(),
    "categories-": z.array(z.string()).optional(),
    muscles: z.array(z.string()).optional(),
    "muscles-": z.array(z.string()).optional(),
    equipment: z.array(z.string()).optional(),
    "equipment-": z.array(z.string()).optional(),
  });
export interface UpdateExercise extends z.infer<ReturnType<typeof UpdateExerciseSchema>> {}

/* *************** PUBLIC LIBRARY ****************** */

export const PublicExerciseRecordSchema = ExerciseRecordSchema.omit({
  exercisesLibrary: true,
});
export interface PublicExerciseRecord extends z.infer<typeof PublicExerciseRecordSchema> {}

export const PublicExerciseSchema = PublicExerciseRecordSchema.extend({
  categories: z.array(CategorySchema).default([]),
  muscles: z.array(MuscleSchema).default([]),
  equipments: z.array(EquipmentSchema).default([]),
}).omit({ expand: true });
export interface PublicExercise extends z.infer<typeof PublicExerciseSchema> {}
export function createPublicExercise(data: Record<string, unknown>) {
  data["content"] = (data["content"] as string[]).map((filename) => {
    return pb.getFileUrl(data, filename, {
      thumb: "128x128",
    });
  });
  // return unsafeParse(PublicExerciseSchema, withThumb(data, "thumbnail"));
  return unsafeParse(PublicExerciseSchema, data);
}

export const LibraryPublicSchema = z.object({
  popularExercises: z.array(PublicExerciseSchema).default([]),
  totalExercises: z.number(),
});
export interface LibraryPublic extends z.infer<typeof LibraryPublicSchema> {}
export function createPublicLibrary(data: unknown) {
  return unsafeParse(LibraryPublicSchema, data);
}
