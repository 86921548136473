import { customElement } from "lit/decorators.js";
import { LightElement } from "../components/component";
import { html } from "lit";
import type { IonDatetime } from "@ionic/core/components/ion-datetime";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { formatISO, roundToNearestMinutes } from "date-fns";
import { localized, msg } from "@lit/localize";

@customElement("modal-time-picker")
@localized()
export class ModalTimePicker extends LightElement {
  public date?: string;
  public header?: string = msg("Horas");
  //@ts-ignore
  public formatOptions?: IonDatetime["formatOptions"] = { hour: "numeric", minute: "2-digit" };
  public minuteValues?: number[] = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
  public hourCycle?: IonDatetime["hourCycle"] = "h23";
  public setDate?: (date: string) => void;

  public ionDateTime: Ref<IonDatetime> = createRef();

  connectedCallback() {
    super.connectedCallback();
    if (!this.setDate) {
      throw new Error("<modal-time-picker>: setDate is required");
    } else if (!this.date) {
      const now = new Date();
      const nearestMinute = roundToNearestMinutes(now, { nearestTo: 5 });
      this.date = formatISO(nearestMinute);
    }
  }

  async confirm() {
    await this.ionDateTime.value?.confirm(true);
    const date = this.ionDateTime.value?.value!;
    if (Array.isArray(date)) return;
    else {
      this.setDate?.(date);
    }
  }

  async cancel() {
    this.ionDateTime.value?.cancel(true);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click=${this.cancel}>${msg("Cancelar")}</ion-button>
          </ion-buttons>
          <ion-title>${this.header}</ion-title>
          <ion-buttons slot="end">
            <ion-button @click=${this.confirm}>${msg("Confirmar")}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <div class="inner-content">
        <div class="flex justify-center w-full">
          <ion-datetime
            .formatOptions=${this.formatOptions}
            .minuteValues=${this.minuteValues}
            .hourCycle=${this.hourCycle}
            value=${this.date}
            class="w-full"
            prefer-wheel="true"
            presentation="time"
            ${ref(this.ionDateTime)}></ion-datetime>
        </div>
      </div>
    `;
  }
}
