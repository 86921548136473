import { initialize } from "@ionic/core/components";

import { IonAccordionGroup } from "@ionic/core/components/ion-accordion-group";
import { IonAccordion } from "@ionic/core/components/ion-accordion";
import { IonActionSheet } from "@ionic/core/components/ion-action-sheet";
import { IonAlert } from "@ionic/core/components/ion-alert";
import { IonApp } from "@ionic/core/components/ion-app";
// import { IonAvatar } from '@ionic/core/components/ion-avatar';
import { IonBackButton } from "@ionic/core/components/ion-back-button";
import { IonBackdrop } from "@ionic/core/components/ion-backdrop";
import { IonBadge } from "@ionic/core/components/ion-badge";
// import { IonBreadcrumb } from '@ionic/core/components/ion-breadcrumb';
// import { IonBreadcrumbs } from '@ionic/core/components/ion-breadcrumbs';
import { IonButton } from "@ionic/core/components/ion-button";
import { IonButtons } from "@ionic/core/components/ion-buttons";
// import { IonCardContent } from '@ionic/core/components/ion-card-content';
// import { IonCardHeader } from '@ionic/core/components/ion-card-header';
// import { IonCard } from '@ionic/core/components/ion-card';
// import { IonCardSubtitle } from '@ionic/core/components/ion-card-subtitle';
// import { IonCardTitle } from '@ionic/core/components/ion-card-title';
import { IonCheckbox } from "@ionic/core/components/ion-checkbox";
import { IonChip } from "@ionic/core/components/ion-chip";
// import { IonCol } from '@ionic/core/components/ion-col';
import { IonContent } from "@ionic/core/components/ion-content";
import { IonDatetime } from "@ionic/core/components/ion-datetime";
import { IonDatetimeButton } from "@ionic/core/components/ion-datetime-button";
import { IonFabButton } from "@ionic/core/components/ion-fab-button";
import { IonFab } from "@ionic/core/components/ion-fab";
// import { IonFabList } from '@ionic/core/components/ion-fab-list';
// import { IonFooter } from '@ionic/core/components/ion-footer';
// import { IonGrid } from '@ionic/core/components/ion-grid';
import { IonHeader } from "@ionic/core/components/ion-header";
// import { IonImg } from '@ionic/core/components/ion-img';
import { IonInfiniteScrollContent } from "@ionic/core/components/ion-infinite-scroll-content";
import { IonInfiniteScroll } from "@ionic/core/components/ion-infinite-scroll";
import { IonInput } from "@ionic/core/components/ion-input";

// import { IonItemDivider } from '@ionic/core/components/ion-item-divider';
// import { IonItemGroup } from '@ionic/core/components/ion-item-group';
import { IonItem } from "@ionic/core/components/ion-item";
import { IonItemOption } from "@ionic/core/components/ion-item-option";
import { IonItemOptions } from "@ionic/core/components/ion-item-options";
import { IonItemSliding } from "@ionic/core/components/ion-item-sliding";
import { IonLabel } from "@ionic/core/components/ion-label";
import { IonListHeader } from "@ionic/core/components/ion-list-header";
import { IonList } from "@ionic/core/components/ion-list";
// import { IonLoading } from '@ionic/core/components/ion-loading';
// import { IonMenuButton } from '@ionic/core/components/ion-menu-button';
import { IonMenu } from "@ionic/core/components/ion-menu";
import { IonMenuToggle } from "@ionic/core/components/ion-menu-toggle";
import { IonModal } from "@ionic/core/components/ion-modal";
import { IonNav } from "@ionic/core/components/ion-nav";
import { IonNavLink } from "@ionic/core/components/ion-nav-link";
// import { IonNote } from '@ionic/core/components/ion-note';
import { IonPickerColumnInternal } from "@ionic/core/components/ion-picker-column-internal";
import { IonPickerColumn } from "@ionic/core/components/ion-picker-column";
import { IonPickerInternal } from "@ionic/core/components/ion-picker-internal";
import { IonPicker } from "@ionic/core/components/ion-picker";
import { IonPopover } from "@ionic/core/components/ion-popover";
// import { IonProgressBar } from '@ionic/core/components/ion-progress-bar';
import { IonRadioGroup } from "@ionic/core/components/ion-radio-group";
import { IonRadio } from "@ionic/core/components/ion-radio";
// import { IonRange } from '@ionic/core/components/ion-range';
import { IonRefresherContent } from "@ionic/core/components/ion-refresher-content";
import { IonRefresher } from "@ionic/core/components/ion-refresher";
import { IonReorderGroup } from "@ionic/core/components/ion-reorder-group";
import { IonReorder } from "@ionic/core/components/ion-reorder";
import { IonRippleEffect } from "@ionic/core/components/ion-ripple-effect";
import { IonRoute } from "@ionic/core/components/ion-route";
import { IonRouteRedirect } from "@ionic/core/components/ion-route-redirect";
import { IonRouter } from "@ionic/core/components/ion-router";
import { IonRouterLink } from "@ionic/core/components/ion-router-link";
import { IonRouterOutlet } from "@ionic/core/components/ion-router-outlet";
// import { IonRow } from '@ionic/core/components/ion-row';
// import { IonSearchbar } from '@ionic/core/components/ion-searchbar';
import { IonSegmentButton } from "@ionic/core/components/ion-segment-button";
import { IonSegment } from "@ionic/core/components/ion-segment";
import { IonSelect } from "@ionic/core/components/ion-select";
import { IonSelectOption } from "@ionic/core/components/ion-select-option";
// import { IonSelectPopover } from '@ionic/core/components/ion-select-popover';
import { IonSkeletonText } from "@ionic/core/components/ion-skeleton-text";
// import { IonSlide } from '@ionic/core/components/ion-slide';
// import { IonSlides } from '@ionic/core/components/ion-slides';
import { IonSpinner } from "@ionic/core/components/ion-spinner";
// import { IonSplitPane } from '@ionic/core/components/ion-split-pane';
import { IonTabBar } from "@ionic/core/components/ion-tab-bar";
import { IonTabButton } from "@ionic/core/components/ion-tab-button";
import { IonTab } from "@ionic/core/components/ion-tab";
import { IonTabs } from "@ionic/core/components/ion-tabs";
import { IonTextarea } from "@ionic/core/components/ion-textarea";
// import { IonText } from '@ionic/core/components/ion-text';
// import { IonThumbnail } from '@ionic/core/components/ion-thumbnail';
import { IonTitle } from "@ionic/core/components/ion-title";
import { IonToast } from "@ionic/core/components/ion-toast";
// import { IonToggle } from '@ionic/core/components/ion-toggle';
import { IonToolbar } from "@ionic/core/components/ion-toolbar";
// import { IonVirtualScroll } from '@ionic/core/components/ion-virtual-scroll';

// @ts-ignore - no types for this?
import { IonIcon } from "@ionic/core/components/ion-icon";

document.documentElement.classList.add("ion-ce");

initialize({
  mode: "ios",
  swipeBackEnabled: false,
  hardwareBackButton: true,
});

function define(impl: any, tag: string) {
  try {
    customElements.define(tag, impl);
  } catch (error) {}
}

define(IonAccordionGroup, "ion-accordion-group");
define(IonAccordion, "ion-accordion");
define(IonActionSheet, "ion-action-sheet");
define(IonAlert, "ion-alert");
define(IonApp, "ion-app");
// define(IonAvatar, 'ion-avatar');
define(IonBackButton, "ion-back-button");
define(IonBackdrop, "ion-backdrop");
define(IonBadge, "ion-badge");
// define(IonBreadcrumb, 'ion-breadcrumb');
// define(IonBreadcrumbs, 'ion-breadcrumbs');
define(IonButton, "ion-button");
define(IonButtons, "ion-buttons");
// define(IonCardContent, 'ion-card-content');
// define(IonCardHeader, 'ion-card-header');
// define(IonCard, 'ion-card');
// define(IonCardSubtitle, 'ion-card-subtitle');
// define(IonCardTitle, 'ion-card-title');
define(IonCheckbox, "ion-checkbox");
define(IonChip, "ion-chip");
// define(IonCol, 'ion-col');
define(IonContent, "ion-content");
define(IonDatetime, "ion-datetime");
define(IonDatetimeButton, "ion-datetime-button");
define(IonFabButton, "ion-fab-button");
define(IonFab, "ion-fab");
// define(IonFabList, 'ion-fab-list');
// define(IonFooter, 'ion-footer');
// define(IonGrid, 'ion-grid');
define(IonHeader, "ion-header");
// define(IonImg, 'ion-img');
define(IonInfiniteScrollContent, "ion-infinite-scroll-content");
define(IonInfiniteScroll, "ion-infinite-scroll");
define(IonInput, "ion-input");

// define(IonItemDivider, 'ion-item-divider');
// define(IonItemGroup, 'ion-item-group');

define(IonItem, "ion-item");
define(IonItemOption, "ion-item-option");
define(IonItemOptions, "ion-item-options");
define(IonItemSliding, "ion-item-sliding");
define(IonLabel, "ion-label");
define(IonListHeader, "ion-list-header");
define(IonList, "ion-list");
// define(IonLoading, 'ion-loading');
// define(IonMenuButton, 'ion-menu-button');
define(IonMenu, "ion-menu");
define(IonMenuToggle, "ion-menu-toggle");
define(IonModal, "ion-modal");
define(IonNav, "ion-nav");
define(IonNavLink, "ion-nav-link");
// define(IonNote, 'ion-note');
define(IonPickerColumnInternal, "ion-picker-column-internal");
define(IonPickerColumn, "ion-picker-column");
define(IonPickerInternal, "ion-picker-internal");
define(IonPicker, "ion-picker");
define(IonPopover, "ion-popover");
// define(IonProgressBar, 'ion-progress-bar');
define(IonRadioGroup, "ion-radio-group");
define(IonRadio, "ion-radio");
// define(IonRange, 'ion-range');
define(IonRefresherContent, "ion-refresher-content");
define(IonRefresher, "ion-refresher");
define(IonReorderGroup, "ion-reorder-group");
define(IonReorder, "ion-reorder");
define(IonRippleEffect, "ion-ripple-effect");
define(IonRoute, "ion-route");
define(IonRouteRedirect, "ion-route-redirect");
define(IonRouter, "ion-router");
define(IonRouterLink, "ion-router-link");
define(IonRouterOutlet, "ion-router-outlet");
// define(IonRow, 'ion-row');
// define(IonSearchbar, 'ion-searchbar');
define(IonSegmentButton, "ion-segment-button");
define(IonSegment, "ion-segment");
define(IonSelect, "ion-select");
define(IonSelectOption, "ion-select-option");
// define(IonSelectPopover, 'ion-select-popover');
define(IonSkeletonText, "ion-skeleton-text");
// define(IonSlide, 'ion-slide');
// define(IonSlides, 'ion-slides');
define(IonSpinner, "ion-spinner");
// define(IonSplitPane, 'ion-split-pane');
define(IonTabBar, "ion-tab-bar");
define(IonTabButton, "ion-tab-button");
define(IonTab, "ion-tab");
define(IonTabs, "ion-tabs");
define(IonTextarea, "ion-textarea");
// define(IonText, 'ion-text');
// define(IonThumbnail, 'ion-thumbnail');
define(IonTitle, "ion-title");
define(IonToast, "ion-toast");
// define(IonToggle, 'ion-toggle');
define(IonToolbar, "ion-toolbar");
// define(IonVirtualScroll, 'ion-virtual-scroll');
define(IonIcon, "ion-icon");
