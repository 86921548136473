import { alertController, isPlatform, modalController, popoverController } from "@ionic/core";
import { consume } from "@lit/context";
import { html, nothing } from "lit";
import { state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { Page, required } from "../components/component";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import { customElement } from "../element";
import type { Content } from "../repository/patient/diagnostic";
import type { ObservableExercise } from "../stores/exercises.store";
import { FilterStore, type Filter } from "../stores/filter.store";
import { ObservableLibrary, librariesStore } from "../stores/libraries.store";
import { ModalLibraryCategories } from "./modal-library-categories";
import { ModalLibraryEquipments } from "./modal-library-equipments";
import { ModalLibraryMuscles } from "./modal-library-muscles";
import { authContext, type AuthContext } from "../context/auth.context";
import { UpdateExerciseSchema, type UpdateExercise } from "../repository/library/exercises";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { IonActionSheet } from "@ionic/core/components/ion-action-sheet";
import type { ModalNav } from "./modal-nav";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";
import {
  ErrorExerciseInvalidThumbnail,
  ErrorExerciseInvalidContent,
  ErrorExerciseContentNotFound,
  ErrorExerciseThumbnailNotFound,
} from "../repository/library/errors";

interface NewContent {
  file: File;
  content: string;
  action: "create" | "none" | "delete";
}

@customElement("modal-edit-exercise")
@localized()
export class ModalEditExercise extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) auth!: AuthContext;

  @required() library!: ObservableLibrary;
  @required() exercise!: ObservableExercise;
  onSuccess?: () => void;

  @state() _content: NewContent[] = [];
  @state() _initialContent: NewContent[] = [];

  #contentActionSheet: Ref<IonActionSheet> = createRef();
  #thumbnailActionSheet: Ref<IonActionSheet> = createRef();

  filterStore!: FilterStore;

  #modalCropPhotoRef: Ref<ModalNav> = createRef();

  connectedCallback(): void {
    super.connectedCallback();
    this.filterStore = new FilterStore([
      ["categories", this.exercise.categories.map((c) => ({ id: c.id, name: c.category }))],
      ["muscles", this.exercise.muscles.map((m) => ({ id: m.id, name: m.muscle }))],
      ["equipments", this.exercise.equipments.map((e) => ({ id: e.id, name: e.equipment }))],
    ]);
  }

  async firstUpdated(_changedProperties: Map<string, any>) {
    super.firstUpdated(_changedProperties);

    try {
      // Fetch and set thumbnail
      if (this.exercise.thumbnail()) {
        this.fetchAndSetThumbnail(this.exercise.thumbnail());
      }

      // Fetch and set content
      this.exercise.content.forEach((url) => {
        this.fetchAndSetContent(url);
      });
      this._initialContent = this._content;
    } catch (error) {
      if (error instanceof ApiError) {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: error.message,
          buttons: ["OK"],
        });
        await alert.present();
      }
    }

    const fileInput = this.renderRoot.querySelector("#thumbnail-file") as HTMLInputElement;
    const fileInputPhoto = this.renderRoot.querySelector("#thumbnail-file-photo") as HTMLInputElement;
    [fileInput, fileInputPhoto].forEach((input) => {
      input.addEventListener("change", (event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
          const file = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target?.result as string;
            this.#modalCropPhotoRef.value?.open({ image: imageUrl, updateImage: this.updateCroppedImage });
          };
          reader.readAsDataURL(file);
          input.value = "";
        }
      });
    });

    const contentInputPhoto = this.renderRoot.querySelector("#content-file-photo") as HTMLInputElement;
    const contentInputVideo = this.renderRoot.querySelector("#content-file-video") as HTMLInputElement;
    const contentInputGeneral = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
    [contentInputPhoto, contentInputVideo, contentInputGeneral].forEach((input) => {
      input.addEventListener("change", (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files && files.length > 0) {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file) {
              const reader = new FileReader();
              reader.onload = (e) => {
                if (e.target) {
                  const hasFile = this._content.some((c) => c.file.name === file.name);
                  if (!hasFile) {
                    this._content.push({
                      file: file,
                      content: e.target?.result as string,
                      action: "create",
                    });
                    this.requestUpdate();
                  }
                }
              };
              reader.readAsDataURL(file);
            }
          }
          this.#controller.form.change(
            "content",
            this._content.map((c) => c.file),
          );
        }
      });
    });
  }

  updateCroppedImage = (canvas: HTMLCanvasElement) => {
    const image = canvas.toDataURL("image/jpeg");
    const button = document.querySelector("#thumbnail-button") as HTMLButtonElement;
    button.style.backgroundImage = `url(${image})`;
    button.style.backgroundSize = "contain";
    button.style.backgroundPosition = "center";
    button.style.backgroundRepeat = "no-repeat";
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const fileName = `cropped-image-${Date.now()}.jpeg`;
          const file = new File([blob], fileName, { type: "image/jpeg" });
          this.#controller.form.change("thumbnail", file);
        } else {
          console.error("Canvas to Blob conversion failed");
        }
      },
      "image/jpeg",
      0.8,
    );
  };

  // Helper function to fetch and set thumbnail
  async fetchAndSetThumbnail(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split("/").pop()?.split("?")[0] || "file";
      const file = new File([blob], fileName, { type: blob.type });

      // Update the button's background image
      const button = this.renderRoot.querySelector("#thumbnail-button") as HTMLButtonElement;
      const imageUrl = URL.createObjectURL(file);
      button.style.backgroundImage = `url(${imageUrl})`;
      button.style.backgroundSize = "contain";
      button.style.backgroundPosition = "center";
      button.style.backgroundRepeat = "no-repeat";
    } catch (error) {
      throw new ErrorExerciseThumbnailNotFound();
    }
  }

  // Helper function to fetch and set content
  async fetchAndSetContent(url: string): Promise<void> {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const fileName = url.split("/").pop()?.split("?")[0] || "file";
      const file = new File([blob], fileName, { type: blob.type });

      this._content.push({
        file: file as Content,
        content: URL.createObjectURL(file),
        action: "none",
      });
      this.requestUpdate();
    } catch (error) {
      throw new ErrorExerciseContentNotFound();
    }
  }

  #controller = new FinalFormController<UpdateExercise>(this, {
    validate: zodValidator(UpdateExerciseSchema().omit({ exercisesLibrary: true })),
    onSubmit: async (values) => {
      const data: UpdateExercise = {
        name: values.name,
        exercisesLibrary: this.library.id,

        // categories that doesn't exist in the exercise
        categories: this.filterStore
          .filtersByKey("categories")
          .filter((c) => !this.exercise.categories.some((ec) => ec.id === c.id))
          .map((c) => c.id),
        "categories-": this.exercise.categories
          .filter((c) => !this.filterStore.filtersByKey("categories").some((f) => f.id === c.id))
          .map((c) => c.id),

        muscles: this.filterStore
          .filtersByKey("muscles")
          .filter((m) => !this.exercise.muscles.some((em) => em.id === m.id))
          .map((m) => m.id),
        "muscles-": this.exercise.muscles
          .filter((m) => !this.filterStore.filtersByKey("muscles").some((f) => f.id === m.id))
          .map((m) => m.id),

        equipment: this.filterStore
          .filtersByKey("equipments")
          .filter((e) => !this.exercise.equipments.some((ee) => ee.id === e.id))
          .map((e) => e.id),
        "equipment-": this.exercise.equipments
          .filter((e) => !this.filterStore.filtersByKey("equipments").some((f) => f.id === e.id))
          .map((e) => e.id),
        thumbnail: values.thumbnail,
        content: this._content.filter((c) => c.action === "create").map((c) => c.file),
        "content-": this._initialContent.filter((ic) => !this._content.includes(ic)).map((c) => c.file.name),
      };

      try {
        await librariesStore.updateExercise(this.library, this.exercise, data);
        this.onSuccess?.();
        this.navigator.close();
      } catch (error) {
        if (error instanceof ErrorExerciseInvalidThumbnail) {
          this.#controller.setError("thumbnail", msg("Thumbnail inválido"));
        }
        if (error instanceof ErrorExerciseInvalidContent) {
          this.#controller.setError("content", msg("Conteúdo inválido"));
        }
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  deleteExercise() {
    alertController
      .create({
        header: msg("Apagar exercício"),
        message: msg("Tem a certeza que deseja apagar o exercício?"),
        buttons: [
          {
            text: msg("Cancelar"),
            role: "cancel",
          },
          {
            text: msg("Apagar"),
            role: "destructive",
            handler: async () => {
              try {
                await librariesStore.deleteExercise(this.library, this.exercise);
                this.onSuccess?.();
                this.navigator.close();
              } catch (error) {
                if (error instanceof ApiError) {
                  const alert = await alertController.create({
                    header: msg("Erro"),
                    message: error.message,
                    buttons: ["OK"],
                  });
                  await alert.present();
                }
              }
            },
          },
        ],
      })
      .then((alert) => {
        alert.present();
      });
  }

  removeNewContent(data: NewContent) {
    popoverController.dismiss();
    this._content = this._content.filter((c) => c.file.name !== data.file.name);
    const contentInput = this.renderRoot.querySelector("#content-file-general");
    if (contentInput instanceof HTMLInputElement) {
      contentInput.value = "";
    }

    const contentInputPhoto = this.renderRoot.querySelector("#content-file-photo");
    if (contentInputPhoto instanceof HTMLInputElement) {
      contentInputPhoto.value = "";
    }

    const contentInputVideo = this.renderRoot.querySelector("#content-file-video");
    if (contentInputVideo instanceof HTMLInputElement) {
      contentInputVideo.value = "";
    }
    this.requestUpdate();
  }

  removeFilter(filter: Filter) {
    if (!this.library.canEdit(this.auth.user)) return;
    this.filterStore.remove(filter);
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    const categories = this.filterStore.filtersByKey("categories");
    const muscles = this.filterStore.filtersByKey("muscles");
    const equipments = this.filterStore.filtersByKey("equipments");

    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${modalController.dismiss}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${this.exercise.name}</ion-title>
          ${when(
            this.library.canEdit(this.auth.user),
            () => html`
              <ion-buttons slot="end">
                <ion-button
                  @click=${this.deleteExercise}
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  color="danger"
                  class="font-semibold">
                  <span class="flex items-center mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path
                        d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("Apagar")}
                </ion-button>
              </ion-buttons>
            `,
          )}
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        <div class="mt-4">
          <form
            id="form-exercise-update"
            @submit=${(e: Event) => {
              e.preventDefault();
              form.submit();
            }}
            class="space-y-4">
            <div class="space-y-4">
              <div>
                <span class="font-semibold font-display mb-1.5">${msg("Thumbnail")}</span>
                <button
                  .disabled=${!this.library.canEdit(this.auth.user)}
                  id="thumbnail-button"
                  type="button"
                  class="w-full h-56 rounded-lg bg-accent-8 border border-solid border-accent-7"
                  aria-label="thumbnail"
                  @click=${() => {
                    if (isPlatform("android")) {
                      this.#thumbnailActionSheet.value?.present();
                    } else {
                      const input = this.renderRoot.querySelector("#thumbnail-file") as HTMLInputElement;
                      input.click();
                    }
                  }}>
                  ${when(
                    !formState.values.thumbnail,
                    () => html`
                      <div slot="icon-only">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M8 12H12M12 12H16M12 12V16M12 12V8M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                            stroke="#999999"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </div>
                    `,
                  )}
                </button>
                ${renderError("thumbnail")}
                <input id="thumbnail-file" class="hidden" accept="image/*" type="file" ${register("thumbnail")} />
                <input
                  id="thumbnail-file-photo"
                  capture="user"
                  class="hidden"
                  accept="image/*"
                  type="file"
                  ${register("thumbnail")} />
              </div>
              <div>
                <span class="font-semibold font-display mb-1.5">${msg("Nome")}</span>
                <ion-item>
                  <ion-input
                    autocapitalize="on"
                    type="text"
                    .readonly=${!this.library.canEdit(this.auth.user)}
                    placeholder=${msg("Nome do exercício")}
                    ${register("name", {
                      initialValue: this.exercise.name,
                    })}>
                  </ion-input>
                </ion-item>
                ${renderError("name")}
              </div>
              <div class="space-y-1.5">
                <span class="font-semibold font-display">${msg("Categoria")}</span>
                ${when(
                  categories.length > 0,
                  () => html`
                    <div class="flex flex-wrap gap-x-1 gap-y-1.5">
                      ${categories.map(
                        (c) => html`
                          <ion-chip
                            @click=${() => {
                              this.removeFilter(c);
                            }}
                            style="--background: var(--ion-color-success);"
                            class="font-semibold font-body text-sm px-2.5 py-1.5"
                            >${c.name}
                            ${when(
                              this.library.canEdit(this.auth.user),
                              () => html`
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  class="ml-1">
                                  <rect width="12" height="12" rx="6" fill="black" />
                                  <path
                                    d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              `,
                            )}
                          </ion-chip>
                        `,
                      )}
                    </div>
                  `,
                  () => {
                    if (!this.library.canEdit(this.auth.user)) {
                      return html`<p class="text-sm">${msg("Sem categorias")}</p>`;
                    }
                    return nothing;
                  },
                )}
                ${when(
                  this.library.canEdit(this.auth.user),
                  () => html`
                    <ion-button
                      @click=${() =>
                        this.navigator.push(ModalLibraryCategories, {
                          filterStore: this.filterStore,
                          library: this.library,
                        })}
                      size="small"
                      color="secondary"
                      shape="round"
                      expand="block"
                      class="h-10"
                      >${msg("Adicionar")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="ml-2 mb-0.5">
                        <path
                          d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  `,
                )}
              </div>
              <div class="space-y-1.5">
                <span class="font-semibold font-display">${msg("Músculos")}</span>
                ${when(
                  muscles.length > 0,
                  () => html`
                    <div class="flex flex-wrap gap-x-1 gap-y-1.5">
                      ${muscles.map(
                        (m) => html`
                          <ion-chip
                            @click="${() => this.removeFilter(m)}"
                            style="--background: var(--ion-color-success);"
                            class="font-semibold font-body text-sm px-2.5 py-1.5"
                            >${m.name}
                            ${when(
                              this.library.canEdit(this.auth.user),
                              () => html`
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  class="ml-1">
                                  <rect width="12" height="12" rx="6" fill="black" />
                                  <path
                                    d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              `,
                            )}
                          </ion-chip>
                        `,
                      )}
                    </div>
                  `,
                  () => {
                    if (!this.library.canEdit(this.auth.user)) {
                      return html`<p class="text-sm">${msg("Sem músculos")}</p>`;
                    }
                    return nothing;
                  },
                )}
                ${when(
                  this.library.canEdit(this.auth.user),
                  () => html`
                    <ion-button
                      @click=${() =>
                        this.navigator.push(ModalLibraryMuscles, {
                          filterStore: this.filterStore,
                          library: this.library,
                        })}
                      size="small"
                      color="secondary"
                      shape="round"
                      expand="block"
                      class="h-10"
                      >${msg("Adicionar")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="ml-2 mb-0.5">
                        <path
                          d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  `,
                )}
              </div>
              <div class="space-y-1.5">
                <span class="font-semibold font-display">${msg("Equipamentos")}</span>
                ${when(
                  equipments.length > 0,
                  () => html`
                    <div class="flex flex-wrap gap-x-1 gap-y-1.5">
                      ${equipments.map(
                        (e) => html`
                          <ion-chip
                            @click="${() => this.removeFilter(e)}"
                            style="--background: var(--ion-color-success);"
                            class="font-semibold font-body text-sm px-2.5 py-1.5"
                            >${e.name}
                            ${when(
                              this.library.canEdit(this.auth.user),
                              () => html`
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 12 12"
                                  fill="none"
                                  class="ml-1">
                                  <rect width="12" height="12" rx="6" fill="black" />
                                  <path
                                    d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                                    stroke="white"
                                    stroke-linecap="round"
                                    stroke-linejoin="round" />
                                </svg>
                              `,
                            )}
                          </ion-chip>
                        `,
                      )}
                    </div>
                  `,
                  () => {
                    if (!this.library.canEdit(this.auth.user)) {
                      return html`<p class="text-sm">${msg("Sem equipamentos")}</p>`;
                    }
                    return nothing;
                  },
                )}
                ${when(
                  this.library.canEdit(this.auth.user),
                  () => html`
                    <ion-button
                      @click=${() =>
                        this.navigator.push(ModalLibraryEquipments, {
                          filterStore: this.filterStore,
                          library: this.library,
                        })}
                      size="small"
                      color="secondary"
                      shape="round"
                      expand="block"
                      class="h-10"
                      >${msg("Adicionar")}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="ml-2 mb-0.5">
                        <path
                          d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </ion-button>
                  `,
                )}
              </div>
              <div>
                <span class="font-semibold font-display mb-1.5">${msg("Conteúdo")}</span>
                <div class="flex flex-col space-y-3">
                  ${this._content.filter((c) => c.action !== "delete").length > 0
                    ? this._content
                        .filter((c) => c.action !== "delete")
                        .map((c, index) => {
                          if (c.file.type.startsWith("video/")) {
                            return html`
                              <div class="w-full relative">
                                <video
                                  controls
                                  class="w-full max-w-lg h-[180px] rounded-lg border border-solid border-accent-7">
                                  <source src=${c.content} type=${c.file.type} />
                                </video>
                                ${when(
                                  this.library.canEdit(this.auth.user),
                                  () => html`
                                    <button
                                      @click="${(e: Event) => {
                                        e.stopPropagation();
                                      }}"
                                      type="button"
                                      id="${index}-newcontent-popover"
                                      class="w-5 h-5 absolute top-2.5 right-2.5 bg-white rounded-full ">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none">
                                        <path
                                          d="M14.1667 10.0001C14.1667 10.4603 14.5398 10.8334 15.0001 10.8334C15.4603 10.8334 15.8334 10.4603 15.8334 10.0001C15.8334 9.53984 15.4603 9.16675 15.0001 9.16675C14.5398 9.16675 14.1667 9.53984 14.1667 10.0001Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M9.16675 10.0001C9.16675 10.4603 9.53984 10.8334 10.0001 10.8334C10.4603 10.8334 10.8334 10.4603 10.8334 10.0001C10.8334 9.53984 10.4603 9.16675 10.0001 9.16675C9.53984 9.16675 9.16675 9.53984 9.16675 10.0001Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M4.16675 10.0001C4.16675 10.4603 4.53984 10.8334 5.00008 10.8334C5.46032 10.8334 5.83341 10.4603 5.83341 10.0001C5.83341 9.53984 5.46032 9.16675 5.00008 9.16675C4.53984 9.16675 4.16675 9.53984 4.16675 10.0001Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                      </svg>
                                    </button>
                                    <ion-popover
                                      arrow
                                      alignment="center"
                                      reference="trigger"
                                      side="bottom"
                                      style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                      trigger="${index}-newcontent-popover">
                                      <div class="p-0">
                                        <ion-list>
                                          <ion-item
                                            @click=${() => this.removeNewContent(c)}
                                            button
                                            .detail=${false}
                                            class="no-p popover text-sm">
                                            <ion-label class="font-semibold pl-3">${msg("Remover")}</ion-label>
                                          </ion-item>
                                        </ion-list>
                                      </div>
                                    </ion-popover>
                                  `,
                                )}
                              </div>
                            `;
                          } else {
                            return html`
                              <div class="w-full relative">
                                <img
                                  loading="lazy"
                                  class="w-full h-auto max-h-96 rounded-lg object-contain border border-solid border-accent-7"
                                  src=${c.content} />
                                ${when(
                                  this.library.canEdit(this.auth.user),
                                  () => html`
                                    <button
                                      @click="${(e: Event) => {
                                        e.stopPropagation();
                                      }}"
                                      type="button"
                                      id="${index}-newcontent-popover"
                                      class="w-5 h-5 absolute top-2.5 right-2.5 bg-white rounded-full">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none">
                                        <path
                                          d="M14.1667 10.0001C14.1667 10.4603 14.5398 10.8334 15.0001 10.8334C15.4603 10.8334 15.8334 10.4603 15.8334 10.0001C15.8334 9.53984 15.4603 9.16675 15.0001 9.16675C14.5398 9.16675 14.1667 9.53984 14.1667 10.0001Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M9.16675 10.0001C9.16675 10.4603 9.53984 10.8334 10.0001 10.8334C10.4603 10.8334 10.8334 10.4603 10.8334 10.0001C10.8334 9.53984 10.4603 9.16675 10.0001 9.16675C9.53984 9.16675 9.16675 9.53984 9.16675 10.0001Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                        <path
                                          d="M4.16675 10.0001C4.16675 10.4603 4.53984 10.8334 5.00008 10.8334C5.46032 10.8334 5.83341 10.4603 5.83341 10.0001C5.83341 9.53984 5.46032 9.16675 5.00008 9.16675C4.53984 9.16675 4.16675 9.53984 4.16675 10.0001Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round" />
                                      </svg>
                                    </button>
                                    <ion-popover
                                      arrow
                                      alignment="center"
                                      reference="trigger"
                                      side="bottom"
                                      style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                      trigger="${index}-newcontent-popover">
                                      <div class="p-0">
                                        <ion-list>
                                          <ion-item
                                            @click=${() => this.removeNewContent(c)}
                                            button
                                            .detail=${false}
                                            class="no-p popover text-sm">
                                            <ion-label class="font-semibold pl-3">${msg("Remover")}</ion-label>
                                          </ion-item>
                                        </ion-list>
                                      </div>
                                    </ion-popover>
                                  `,
                                )}
                              </div>
                            `;
                          }
                        })
                    : html`
                        <button
                          type="button"
                          class="w-full h-[180px] rounded-lg bg-accent-8 border border-solid border-accent-7"
                          aria-label="content"
                          @click=${() => {
                            if (isPlatform("android")) {
                              this.#contentActionSheet.value?.present();
                            } else {
                              const input = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
                              input.click();
                            }
                          }}>
                          <div slot="icon-only">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none">
                              <path
                                d="M8 12H12M12 12H16M12 12V16M12 12V8M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z"
                                stroke="#999999"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round" />
                            </svg>
                          </div>
                        </button>
                      `}
                  ${renderError("content")}
                  ${when(
                    this._content.length > 0 && this.library.canEdit(this.auth.user),
                    () => html`
                      <div class="relative w-full h-4 mt-2">
                        <div class="w-full h-px bg-accent-7 inset-y-center"></div>
                        <button
                          type="button"
                          class="rounded-full bg-accent-7 w-4 h-4 inset-x-center flex items-center"
                          @click=${() => {
                            if (isPlatform("android")) {
                              this.#contentActionSheet.value?.present();
                            } else {
                              const input = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
                              input.click();
                            }
                          }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            class="mx-auto">
                            <path
                              d="M3 6H6M6 6H9M6 6V9M6 6V3"
                              stroke="#111111"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </button>
                      </div>
                    `,
                  )}
                </div>
              </div>
            </div>
            ${when(
              this.library.canEdit(this.auth.user),
              () => html`
                <ion-button
                  form="form-exercise-update"
                  type="submit"
                  color="primary"
                  expand="block"
                  shape="round"
                  class="font-semibold"
                  ?disabled=${formState.submitting}
                  >${msg("Guardar Alterações")}</ion-button
                >
              `,
            )}
          </form>
        </div>
        <input
          id="content-file-photo"
          ${register("content")}
          class="hidden"
          accept="image/*"
          type="file"
          capture="user" />
        <input
          id="content-file-video"
          ${register("content")}
          class="hidden"
          accept="video/*"
          type="file"
          capture="environment" />
        <input id="content-file-general" ${register("content")} class="hidden" accept="video/*, image/*" type="file" />

        <ion-action-sheet
          ${ref(this.#contentActionSheet)}
          .buttons=${[
            {
              text: msg("Fotografia"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#content-file-photo") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Vídeo"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#content-file-video") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Biblioteca de Ficheiros"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#content-file-general") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Cancelar"),
              role: "cancel",
            },
          ]}></ion-action-sheet>
        <ion-action-sheet
          ${ref(this.#thumbnailActionSheet)}
          .buttons=${[
            {
              text: msg("Fotografia"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#thumbnail-file-photo") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Biblioteca de Ficheiros"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#thumbnail-file") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Cancelar"),
              role: "cancel",
            },
          ]}></ion-action-sheet>
        <ion-action-sheet
          ${ref(this.#thumbnailActionSheet)}
          .buttons=${[
            {
              text: msg("Fotografia"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#thumbnail-file-photo") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Biblioteca de Ficheiros"),
              role: "selected",
              handler: () => {
                const contentFile = this.renderRoot.querySelector("#thumbnail-file") as HTMLInputElement;
                contentFile.click();
              },
            },
            {
              text: msg("Cancelar"),
              role: "cancel",
            },
          ]}></ion-action-sheet>
      </ion-content>

      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>
    `;
  }
}
