import { pb } from "../../pocketbase";
import { LibraryRecordSchema } from "../library/library";
import { GroupRecordSchema } from "../group/group";
import { TemplateExercisePlanRecordSchema } from "../templates/templates";
import { createUser } from "../user/user";
import type {
  GroupNotification,
  GroupNotificationRecord,
  LibraryNotification,
  LibraryNotificationRecord,
  ReportNotification,
  ReportNotificationRecord,
  TemplateExercisePlanNotification,
  TemplateExercisePlanNotificationRecord,
} from "./notification";
import { ReportRecordSchema } from "../report/report";

class NotificationRepository {
  async getGroupNotifications(userId: string): Promise<GroupNotification[]> {
    const result = await pb.collection<GroupNotificationRecord>("notification_group").getFullList({
      filter: `user_receiver='${userId}' && accepted=false && rejected=false || user_sender='${userId}' && (accepted=true || rejected=true)`,
      expand: "group, user_receiver, user_sender",
      sort: "-created",
    });

    return result.map((r) => {
      return {
        ...r,
        userReceiver: createUser(r.expand?.user_receiver),
        userSender: createUser(r.expand?.user_sender),
        group: GroupRecordSchema.parse(r.expand?.group),
      };
    });
  }

  async getGroupNotification(id: string): Promise<GroupNotification> {
    const result = await pb.collection<GroupNotificationRecord>("notification_group").getOne(id, {
      expand: "group, user_receiver, user_sender",
    });

    return {
      ...result,
      userReceiver: createUser(result.expand?.user_receiver),
      userSender: createUser(result.expand?.user_sender),
      group: GroupRecordSchema.parse(result.expand?.group),
    };
  }

  async getLibraryNotifications(userId: string): Promise<LibraryNotification[]> {
    const result = await pb.collection<LibraryNotificationRecord>("notification_library").getFullList({
      filter: `user_receiver='${userId}' && accepted=false && rejected=false || user_sender='${userId}' && (accepted=true || rejected=true)`,
      expand: "library, user_receiver, user_sender",
      sort: "-created",
    });

    return result.map((r) => {
      return {
        ...r,
        userReceiver: createUser(r.expand?.user_receiver),
        userSender: createUser(r.expand?.user_sender),
        library: LibraryRecordSchema.parse(r.expand?.library),
      };
    });
  }

  async getLibraryNotification(id: string): Promise<LibraryNotification> {
    const result = await pb.collection<LibraryNotificationRecord>("notification_library").getOne(id, {
      expand: "library, user_receiver, user_sender",
    });

    return {
      ...result,
      userReceiver: createUser(result.expand?.user_receiver),
      userSender: createUser(result.expand?.user_sender),
      library: LibraryRecordSchema.parse(result.expand?.library),
    };
  }

  async getTemplateExerciseNotifications(userId: string): Promise<TemplateExercisePlanNotification[]> {
    const result = await pb
      .collection<TemplateExercisePlanNotificationRecord>("notification_template_exercise_plan")
      .getFullList({
        filter: `user_receiver='${userId}' && accepted=false && rejected=false || user_sender='${userId}' && (accepted=true || rejected=true)`,
        sort: "-created",
        expand: "template, user_receiver, user_sender",
      });

    return result.map((r) => {
      return {
        ...r,
        userReceiver: createUser(r.expand?.user_receiver),
        userSender: createUser(r.expand?.user_sender),
        template: TemplateExercisePlanRecordSchema.parse(r.expand?.template),
      };
    });
  }

  async getTemplateExerciseNotification(id: string): Promise<TemplateExercisePlanNotification> {
    const result = await pb
      .collection<TemplateExercisePlanNotificationRecord>("notification_template_exercise_plan")
      .getOne(id, {
        expand: "template, user_receiver, user_sender",
      });

    return {
      ...result,
      userReceiver: createUser(result.expand?.user_receiver),
      userSender: createUser(result.expand?.user_sender),
      template: TemplateExercisePlanRecordSchema.parse(result.expand?.template),
    };
  }

  async getReportNotifications(id: string): Promise<ReportNotification[]> {
    const result = await pb.collection<ReportNotificationRecord>("notification_report").getFullList({
      filter: `user_receiver='${id}' && accepted=false && rejected=false || user_sender='${id}' && (accepted=true || rejected=true)`,
      sort: "-created",
      expand: "report,user_receiver,user_sender",
    });

    return result.map((r) => {
      return {
        ...r,
        userReceiver: createUser(r.expand?.user_receiver),
        userSender: createUser(r.expand?.user_sender),
        report: ReportRecordSchema.parse(r.expand?.report),
      };
    });
  }

  async getReportNotification(id: string): Promise<ReportNotification> {
    const result = await pb.collection<ReportNotificationRecord>("notification_report").getOne(id, {
      expand: "report,user_receiver,user_sender",
    });

    return {
      ...result,
      userReceiver: createUser(result.expand?.user_receiver),
      userSender: createUser(result.expand?.user_sender),
      report: ReportRecordSchema.parse(result.expand?.report),
    };
  }

  async rejectGroupNotification(id: string) {
    return pb.collection("notification_group").update(id, {
      accepted: false,
      rejected: true,
    });
  }

  async rejectLibraryNotification(id: string) {
    return pb.collection("notification_library").update(id, {
      accepted: false,
      rejected: true,
    });
  }

  async rejectTemplateExerciseNotification(id: string) {
    return pb.collection("notification_template_exercise_plan").update(id, {
      accepted: false,
      rejected: true,
    });
  }

  async rejectReportNotification(id: string) {
    return pb.collection("notification_report").update(id, {
      accepted: false,
      rejected: true,
    });
  }

  async acceptGroupNotification(id: string) {
    return pb.collection("notification_group").update(id, {
      accepted: true,
      rejected: false,
    });
  }

  async acceptLibraryNotification(id: string) {
    return pb.collection("notification_library").update(id, {
      accepted: true,
      rejected: false,
    });
  }

  async acceptTemplateExerciseNotification(id: string) {
    return pb.collection("notification_template_exercise_plan").update(id, {
      accepted: true,
      rejected: false,
    });
  }

  async acceptReportNotification(id: string) {
    return pb.collection("notification_report").update(id, {
      accepted: true,
      rejected: false,
    });
  }

  async markGroupNotificationAsSeen(id: string) {
    return pb.collection("notification_group").delete(id);
  }

  async markLibraryNotificationAsSeen(id: string) {
    return pb.collection("notification_library").delete(id);
  }

  async markTemplateExerciseNotificationAsSeen(id: string) {
    return pb.collection("notification_template_exercise_plan").delete(id);
  }

  async markReportNotificationAsSeen(id: string) {
    return pb.collection("notification_report").delete(id);
  }
}

export const notificationRepository = new NotificationRepository();
