import { customElement } from "lit/decorators.js";
import { Page } from "../components/component";
import { Task } from "@lit/task";
import { html, nothing } from "lit";
import { consume } from "@lit/context";
import { RouterContext, routerContext } from "../context/router.context";
import { authContext, type AuthContext } from "../context/auth.context";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import type { ObservablePatientEvaluation } from "../stores/sessions.store";
import type {
  CreateValueScale,
  FieldValueScale,
  ScaleFieldSummary,
  UpdateValueScale,
  ValueScale,
} from "../repository/report/report";
import { reportRepository } from "../repository/report/report.repository";
import { localized, msg, str } from "@lit/localize";
import { alertController } from "@ionic/core";
import { ApiError } from "../error";

@customElement("modal-scale-values")
@localized()
export class ModalScaleValues extends Page {
  evaluation!: ObservablePatientEvaluation;
  fieldValue!: FieldValueScale;
  readonly!: boolean;
  onChange?: (value: ValueScale) => void;
  calculateScore?: () => number;
  score?: number;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) auth!: AuthContext;

  #scale = new Task(this, {
    task: async () => {
      return reportRepository.getScale(this.fieldValue.scale.id);
    },
    args: () => [],
  });

  getValue(fieldId: string) {
    return this.fieldValue.value?.find((value) => value.field.id === fieldId)?.value.id ?? -1;
  }

  async updateScale(e: any, field: ScaleFieldSummary) {
    const newValue = e.target.value;
    const valueId = this.fieldValue.value?.find((value) => value.field.id === field.id)?.id;
    if (!valueId) {
      try {
        const data: CreateValueScale = {
          value: newValue,
          patient_evaluation: this.evaluation.id,
          field: field.id,
        };
        const value = await reportRepository.createValueScale(data);
        this.onChange?.(value);
        this.requestUpdate();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    } else {
      try {
        const data: UpdateValueScale = {
          value: newValue,
        };
        const value = await reportRepository.updateValueScale(valueId, data);
        this.onChange?.(value);
        this.requestUpdate();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    }
  }

  render() {
    return html`
      <ion-content fullscreen class="no-px" style="--padding-bottom: 24px;">
        <ion-header>
          <ion-toolbar style="--border-color:#eaeaea; --padding-start:16px; --padding-end:16px">
            <ion-buttons slot="start">
              <ion-button
                @click=${() => this.navigator.pop()}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </ion-buttons>
            <ion-title>${this.#scale.value?.name}</ion-title>
            <div slot="end" class="bg-accent-7 rounded-full h-5 px-1.5 flex items-center">
              <span class="text-xs text-accent-1 font-semibold leading-4"
                >Score: ${this.score || this.calculateScore?.()}</span
              >
            </div>
          </ion-toolbar>
        </ion-header>

        ${this.#scale.render({
          pending: () => {},
          error: (err) => {
            console.error(err);
            if (err instanceof ApiError) {
              return html`<div
                class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${err.message}
                </span>
              </div>`;
            } else {
              return html`<div
                class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${msg("Erro ao carregar a escala")}
                </span>
              </div>`;
            }
          },
          complete: (scale) => {
            const sortedFields = scale.fields.sort((a, b) => a.position - b.position);
            return html`
              ${when(
                scale.fields.length > 0,
                () => html`
                  <ion-accordion-group class="mt-4 mb-1.5 space-y-3 px-4" multiple>
                    ${sortedFields.map(
                      (field, index) => html`
                        <ion-accordion toggle-icon-slot="start" toggle-icon="/assets/icons/chevron-down.svg">
                          <ion-item lines="none" class="no-active-bg no-border no-p-start no-inner-p" slot="header">
                            <div class="flex justify-between items-center w-full">
                              <div class="flex items-start space-x-1 w-full">
                                <h6>${index + 1}. ${field.label}</h6>
                                <ion-chip class="flex-shrink-0" style="--background: #EAEAEA; --color: #888888"
                                  >${msg(str`${field.options.length || 0} Campos`)}</ion-chip
                                >
                              </div>
                            </div>
                          </ion-item>
                          <div slot="content" class="px-1.5">
                            <ion-list lines="none">
                              <ion-radio-group
                                @ionChange=${(e: any) => this.updateScale(e, field)}
                                value="${this.getValue(field.id)}">
                                ${repeat(
                                  field.options,
                                  (option) => option.id,
                                  (option, index) => html`
                                    <ion-item
                                      class="text-accent-5 no-p no-inner-p pointer-events-auto h-12"
                                      style="--min-height: 48px;">
                                      <ion-radio
                                        disabled=${this.readonly}
                                        value=${option.id}
                                        class="no-m-inline text-sm opacity-100 text-accent-1"
                                        >${option.label}</ion-radio
                                      >
                                    </ion-item>
                                    ${index === field.options.length - 1
                                      ? nothing
                                      : html`<div class="h-px w-full bg-[#e5e5e5] last:hidden"></div>`}
                                  `,
                                )}
                              </ion-radio-group>
                            </ion-list>
                          </div>
                        </ion-accordion>
                      `,
                    )}
                  </ion-accordion-group>
                `,
                () => {
                  return html`
                    <div
                      class="mx-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                          stroke="#111111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                        ${msg("Esta escala ainda não tem conteúdo")}
                      </span>
                    </div>
                  `;
                },
              )}
            `;
          },
        })}
      </ion-content>
    `;
  }
}
