import { expandAt, pb, expand, ERROR_NOT_UNIQUE } from "../../pocketbase";
import { createUser } from "../user/user";
import { createPatient, type PatientRecord } from "../patient/patient";
import {
  createGroup,
  type Group,
  type GroupRecord,
  type CreateGroup,
  type UpdateGroup,
  type GroupSummary,
  createGroupShared,
  type GroupSharedRecord,
} from "./group";
import type { UserShareAction } from "../../stores/userShare.store";
import {
  createSection,
  type CreateSection,
  type Section,
  type UpdateSection,
  type SectionRecord,
  type UpdateSectionWithId,
} from "./section";
import { createAccess } from "./access";
import { type GroupCategoryRecord, createCategory, type Category, type CreateCategory } from "./category";
import { ErrorUnknown } from "../../error";
import { ClientResponseError } from "pocketbase";
import {
  ErrorGroupCategoryExists,
  ErrorGroupCategoryLimitReached,
  ErrorGroupInviteExists,
  ErrorGroupNameInvalid,
  ErrorGroupNotFound,
  ErrorGroupNotUnique,
  ErrorGroupSectionNotFound,
  ErrorGroupSectionNotUnique,
} from "./errors";

export class GroupRepository {
  async get(id: string): Promise<Group> {
    try {
      const result = await pb.collection<GroupRecord>("groups").getFirstListItem(`id = "${id}"`, {
        expand: expand(
          "owner",
          "group_shared_via_group.user",
          "group_shared_via_group.access",
          "sections_via_group",
          "sections_via_group.patients_via_section",
          // get the categories for each patient
          "sections_via_group.patients_via_section.patients_category_via_patient.category",
        ),
      });

      const owner = createUser(result.expand?.owner);

      const shared = expandAt<GroupSharedRecord>(result, "group_shared_via_group").map((item) => {
        return createGroupShared({
          ...item,
          user: createUser(item.expand?.user),
          access: createAccess(item.expand?.access),
        });
      });

      const sections = expandAt<SectionRecord>(result, "sections_via_group").map((section) => {
        return createSection({
          ...section,
          patients: expandAt<PatientRecord>(section, "patients_via_section").map((patient) => {
            return createPatient({
              ...patient,
              categories: expandAt<GroupCategoryRecord>(patient, "patients_category_via_patient").map((c) =>
                createCategory(c.expand?.category),
              ),
            });
          }),
        });
      });

      return createGroup({
        ...result,
        shared,
        sections,
        owner,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorGroupNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async groupsSummary(): Promise<GroupSummary[]> {
    const result = await pb.send<
      Array<{
        group: GroupRecord;
        popularPatients: PatientRecord[];
        totalPatients: number;
        archivedPatients: number;
      }>
    >(`/api/v1/group/summary`, {
      method: "GET",
    });

    if (!result) {
      return [];
    }

    const groups = result.map(({ group, popularPatients, totalPatients, archivedPatients }) => {
      const owner = createUser(group.expand?.owner);

      const shared = expandAt<GroupSharedRecord>(group, "group_shared_via_group").map((item) => {
        const user = createUser(item.expand?.user);
        const access = createAccess(item.expand?.access);
        return createGroupShared({
          ...item,
          user,
          access,
        });
      });

      const sections = expandAt<SectionRecord>(group, "sections_via_group").map((sectionRecord) => {
        return createSection(sectionRecord);
      });

      const patients = popularPatients.map((patient) => {
        return createPatient({
          ...patient,
          categories: expandAt<GroupCategoryRecord>(patient, "patients_category_via_patient").map((c) =>
            createCategory(c.expand?.category),
          ),
        });
      });

      return {
        ...group,
        shared,
        sections,
        owner,
        popularPatients: patients,
        totalPatients,
        archivedPatients,
      };
    });

    return groups;
  }

  async listGroupsByOwner(owner: string): Promise<Group[]> {
    const result = await pb.collection<GroupRecord>("groups").getFullList({
      filter: `owner = "${owner}" && isArchived = false`,
      expand: "owner",
    });

    return result.map((g) => createGroup({ ...g, owner: createUser(g.expand?.owner) }));
  }

  async createGroup(group: CreateGroup): Promise<Group> {
    try {
      const result = await pb.collection<GroupRecord>("groups").create(
        {
          name: group.name,
          owner: group.owner,
          isArchived: false,
        },
        {
          expand: "owner",
        },
      );

      return createGroup({
        ...result,
        owner: createUser(result.expand?.owner),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.name) {
            if (error.response.data.name.code === ERROR_NOT_UNIQUE) {
              throw new ErrorGroupNotUnique();
            } else {
              throw new ErrorGroupNameInvalid();
            }
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateGroup(groupId: string, data: UpdateGroup): Promise<Group> {
    try {
      const result = await pb.collection<GroupRecord>("groups").update(
        groupId,
        {
          isArchived: data.isArchived,
          name: data.name,
        },
        {
          expand: "owner",
        },
      );

      return createGroup({
        ...result,
        owner: createUser(result.expand?.owner),
        isArchived: result.isArchived,
        name: result.name,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.name) {
            if (error.response.data.name.code === ERROR_NOT_UNIQUE) {
              throw new ErrorGroupNotUnique();
            } else {
              throw new ErrorGroupNameInvalid();
            }
          }
        } else if (error.status === 404) {
          throw new ErrorGroupNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async shareGroupWithUsers(groupId: string, users: UserShareAction[]): Promise<void> {
    try {
      await pb.send(`/api/v1/group/${groupId}/share`, {
        method: "POST",
        body: JSON.stringify({ users }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 409) {
          //extract user name from error message
          const pattern = /^(.+?) already has an invitation to join this group$/;
          const match = error.response.message.match(pattern);
          if (match) {
            throw new ErrorGroupInviteExists(match[1].trim());
          }
          throw new ErrorGroupInviteExists();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateSections(groupId: string, sections: UpdateSectionWithId[]) {
    await pb.send(`/api/v1/group/${groupId}/section`, {
      method: "PATCH",
      body: JSON.stringify({ sections }),
    });
  }

  async getGroupCategories(groupId: string): Promise<Category[]> {
    try {
      const records = await pb.collection<GroupCategoryRecord>("group_category").getFullList({
        filter: `group = "${groupId}"`,
        sort: "+category",
        expand: "patients_category_via_category",
      });

      const categoriesFiltered = records
        .map((r) => {
          const amount = expandAt<GroupCategoryRecord>(r, "patients_category_via_category").length;
          return createCategory({
            ...r,
            count: amount,
          });
        })
        .toSorted((a, b) => (b.count || 0) - (a.count || 0));

      return categoriesFiltered;
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorGroupNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createCategory(data: CreateCategory): Promise<Category> {
    try {
      const result = await pb.collection<GroupCategoryRecord>("group_category").create(data);
      return createCategory(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.category?.code === ERROR_NOT_UNIQUE) {
            throw new ErrorGroupCategoryExists();
          }
          if (error.response.data?.title?.code === "limit_reached") {
            throw new ErrorGroupCategoryLimitReached();
          }
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async removeCategory(categoryId: string) {
    return pb.collection("group_category").delete(categoryId);
  }

  async createSection(section: CreateSection): Promise<Section> {
    try {
      const result = await pb.collection<SectionRecord>("sections").create({
        name: section.name,
        group: section.group,
        position: section.position,
      });

      return createSection({
        ...result,
        patients: [],
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.name?.code === ERROR_NOT_UNIQUE) {
            throw new ErrorGroupSectionNotUnique();
          }
        }
        if (error.status === 404) {
          throw new ErrorGroupNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateSection(sectionId: string, data: UpdateSection): Promise<Section> {
    try {
      const result = await pb.collection<SectionRecord>("sections").update(sectionId, {
        name: data.name,
        position: data.position,
      });
      return createSection(result);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.name?.code === ERROR_NOT_UNIQUE) {
            throw new ErrorGroupSectionNotUnique();
          }
        }
        if (error.status === 404) {
          throw new ErrorGroupSectionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteSection(id: string): Promise<boolean> {
    try {
      return await pb.collection("sections").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorGroupSectionNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteGroup(id: string): Promise<boolean> {
    try {
      return pb.collection("groups").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorGroupNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async leaveGroup(id: string) {
    return await pb.collection<GroupSharedRecord>("group_shared").delete(id);
  }
}

export const groupRepository = new GroupRepository();
