import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { ObservableSection, type ObservableGroup } from "../stores/groups.store";
import { when } from "lit/directives/when.js";
import { repeat } from "lit/directives/repeat.js";
import { patientsStore, type ObservablePatient } from "../stores/patients.store";
import { Page } from "../components/component";
import { Task, TaskStatus } from "@lit/task";
import { alertController } from "@ionic/core";
import { RouterContext, routerContext } from "../context/router.context";
import { localized, msg, str } from "@lit/localize";

@customElement("modal-choose-section")
@localized()
export class ModalChooseSection extends Page {
  group!: ObservableGroup;
  patient!: ObservablePatient;

  @consume({ context: navigatorContext }) private navigator!: NavigatorController;
  @consume({ context: routerContext }) private router!: RouterContext;

  connectedCallback() {
    super.connectedCallback();
    if (!this.group) {
      throw new Error("<modal-choose-section> requires a group");
    } else if (!this.patient) {
      throw new Error("<modal-choose-section> requires a patient");
    }
  }

  #movePatient = new Task(this, {
    task: async ([patient, section]: [ObservablePatient, ObservableSection]) => {
      await patientsStore.movePatientToSection(patient, section);
      if (section.group !== patient.group) {
        this.router.back();
      }
    },
    onError: (error) => {
      console.error(error);
      alertController
        .create({ header: msg("Erro"), message: msg("Não foi possível mover o paciente"), buttons: ["OK"] })
        .then((alert) => alert.present());
    },
  });

  private _movePatient(patient: ObservablePatient, section: ObservableSection) {
    alertController
      .create({
        header: msg("Mover paciente"),
        message: msg(str`Deseja mover o paciente ${patient.name} para a secção ${section.name}? Todas as categorias do paciente serão removidas.`),
        buttons: [
          {
            text: msg("Cancelar"),
            role: "cancel",
          },
          {
            text: msg("Mover"),
            handler: () => {
              this.#movePatient.run([patient, section]);
              this.navigator.close();
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar style="--border-color: #eaeaea">
          <ion-buttons slot="start">
            <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
              <span class="flex items-center -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg("voltar")}</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">${msg("Escolhe a secção")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        ${when(
          this.group,
          () => html`
            <ion-list>
              ${repeat(
                this.group!.sections,
                (section) => section.id,
                (section) => html`
                  <ion-item
                    button
                    .detail=${false}
                    disabled=${this.#movePatient.status === TaskStatus.PENDING}
                    @click=${() => this._movePatient(this.patient, section)}
                    class="no-p no-inline-p no-inner-p">
                    <ion-label class="py-2 font-semibold">${section.name}</ion-label>
                  </ion-item>
                `,
              )}
            </ion-list>
          `,
        )}
      </ion-content>
    `;
  }
}
