import { z } from "zod";
import {
  SessionSchema,
  DiagnosticSchema,
} from "../patient/diagnostic";
import { PatientSchema } from "../patient/patient";
import { GroupSchema } from "../group/group";
import { unsafeParse } from "../shared/unsafeParse";

export const AppointmentDateSchema = z.object({
  date: z.string(),
  count: z.number(),
});
export interface AppointmentDate extends z.infer<typeof AppointmentDateSchema> {}
export function createAppointmentDate(data: Record<string, unknown>) {
  return unsafeParse(AppointmentDateSchema, data);
}

export const AppointmentSummarySchema = z.object({
  session: SessionSchema,
  diagnostic: DiagnosticSchema,
  patient: PatientSchema,
  group: GroupSchema,
});
export interface Appointment extends z.infer<typeof AppointmentSummarySchema> {}
export function createAppointment(data: Record<string, unknown>) {
  return unsafeParse(AppointmentSummarySchema, data);
}
