import { ClientResponseError } from "pocketbase";
import { ErrorInsufficientPermissions, ErrorUnknown } from "../../error";
import { ERROR_MISSING_REL, expand, expandAt, pb } from "../../pocketbase";
import type { UserShareAction } from "../../stores/userShare.store";
import { createAccess } from "../group/access";
import { createExercise, createPublicExercise } from "../library/exercises";
import { createUser } from "../user/user";
import {
  createTemplateExercise,
  createTemplateExercisePlan,
  createTemplateExerciseShare,
  type CreateTemplateExercisePlan,
  type TemplateExercisePlan,
  type TemplateExercisePlanRecord,
  type CreateTemplateExercisePlanExercise,
  type TemplateExercisePlanExercise,
  type UpdateTemplateExercisePlan,
  type UpdateTemplateExercisePlanExercise,
} from "./templates";
import { ErrorTemplateInviteExists, ErrorTemplateLibraryNotFound, ErrorTemplateNotFound } from "./errors";

class TemplatesRepository {
  async getOne(id: string): Promise<TemplateExercisePlan> {
    try {
      const result = await pb.collection<TemplateExercisePlanRecord>("template_exercise_plan").getOne(id, {
        expand: expand(
          "owner",
          "template_exercise_plan_share_via_template.user",
          "template_exercise_plan_share_via_template.access",
          "template_exercise_plan_exercises_via_template",
          "template_exercise_plan_exercises_via_template.exercise",
          "template_exercise_plan_exercises_via_template.exercisePublic",
        ),
      });

      const shared = expandAt(result, "template_exercise_plan_share_via_template").map((share) =>
        createTemplateExerciseShare({
          ...share,
          user: createUser(share.expand?.user),
          access: createAccess(share.expand?.access),
        }),
      );

      const exercises = expandAt(result, "template_exercise_plan_exercises_via_template").map((exerciseTemplate) => {
        // we need to do this because zod schema will fail if we have categories,
        // equipments or muscles as string[] (we don't need this here)
        if (exerciseTemplate.expand?.exercisePublic) {
          exerciseTemplate.expand.exercisePublic.categories = [];
          exerciseTemplate.expand.exercisePublic.equipments = [];
          exerciseTemplate.expand.exercisePublic.muscles = [];
        }
        return createTemplateExercise({
          ...exerciseTemplate,
          exercise: exerciseTemplate.expand?.exercise ? createExercise(exerciseTemplate.expand?.exercise) : undefined,
          exercisePublic: exerciseTemplate.expand?.exercisePublic
            ? createPublicExercise(exerciseTemplate.expand?.exercisePublic)
            : undefined,
        });
      });

      return createTemplateExercisePlan({
        ...result,
        owner: createUser(result.expand?.owner),
        shared,
        exercises,
        totalExercises: exercises.length,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 404) {
          throw new ErrorTemplateLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async listTemplates(): Promise<TemplateExercisePlan[]> {
    try {
      const results = await pb.send<TemplateExercisePlanRecord[]>("/api/v1/template_exercise_plan", {
        method: "GET",
      });
      return results.map((result) => {
        const shared = expandAt(result, "template_exercise_plan_share_via_template").map((share) =>
          createTemplateExerciseShare({
            ...share,
            user: createUser(share.expand?.user),
            access: createAccess(share.expand?.access),
          }),
        );
        const owner = createUser(result.expand?.owner);
        return createTemplateExercisePlan({
          ...result,
          owner,
          shared,
        });
      });
    } catch (error) {
      throw new ErrorUnknown(error);
    }
  }

  async createTemplate(data: CreateTemplateExercisePlan): Promise<TemplateExercisePlan> {
    const form = new FormData();
    form.append("name", data.name);
    if (data.thumbnail) form.append("thumbnail", data.thumbnail);
    form.append("owner", data.owner);

    try {
      const result = await pb.collection("template_exercise_plan").create(form, {
        expand: expand("owner"),
      });
      const owner = createUser(result.expand?.owner);
      return createTemplateExercisePlan({
        ...result,
        owner,
        totalExercises: 0,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateTemplate(id: string, data: UpdateTemplateExercisePlan): Promise<TemplateExercisePlan> {
    const form = new FormData();
    if (data.name) {
      form.append("name", data.name);
    }
    if (data.owner) {
      form.append("owner", data.owner);
    }
    if (data.thumbnail) {
      form.append("thumbnail", data.thumbnail);
    }

    try {
      const result = await pb.collection("template_exercise_plan").update(id, form, {
        expand: expand("owner"),
      });

      return createTemplateExercisePlan({
        ...result,
        owner: createUser(result.expand?.owner),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorTemplateLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteTemplate(id: string) {
    try {
      return await pb.collection("template_exercise_plan").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorTemplateLibraryNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async shareTemplateWithUsers(templateId: string, users: UserShareAction[]): Promise<void> {
    try {
      await pb.send(`/api/v1/template_exercise_plan/${templateId}/share`, {
        method: "POST",
        body: JSON.stringify({ users }),
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 409) {
          //extract user name from error message
          const pattern = /^(.+?) already has an invitation to join this template library$/;
          const match = error.response.message.match(pattern);
          if (match) {
            throw new ErrorTemplateInviteExists(match[1].trim());
          }
          throw new ErrorTemplateInviteExists();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async createExercise(
    templateId: string,
    data: CreateTemplateExercisePlanExercise,
  ): Promise<TemplateExercisePlanExercise> {
    try {
      const result = await pb.collection("template_exercise_plan_exercises").create(
        {
          ...data,
          template: templateId,
        },
        {
          expand: expand("exercise", "exercisePublic"),
        },
      );

      // we need to do this because zod schema will fail if we have categories,
      // equipments or muscles as string[] (we don't need this here)
      if (result.expand?.exercisePublic) {
        result.expand.exercisePublic.categories = [];
        result.expand.exercisePublic.equipments = [];
        result.expand.exercisePublic.muscles = [];
      }

      return createTemplateExercise({
        ...result,
        exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
        exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.template?.code === ERROR_MISSING_REL) {
            throw new ErrorTemplateLibraryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async updateExercise(id: string, data: UpdateTemplateExercisePlanExercise): Promise<TemplateExercisePlanExercise> {
    try {
      const result = await pb.collection("template_exercise_plan_exercises").update(id, data, {
        expand: expand("exercise", "exercisePublic"),
      });

      // we need to do this because zod schema will fail if we have categories,
      // equipments or muscles as string[] (we don't need this here)
      if (result.expand?.exercisePublic) {
        result.expand.exercisePublic.categories = [];
        result.expand.exercisePublic.equipments = [];
        result.expand.exercisePublic.muscles = [];
      }

      return createTemplateExercise({
        ...result,
        exercise: result.expand?.exercise ? createExercise(result.expand?.exercise) : undefined,
        exercisePublic: result.expand?.exercisePublic ? createPublicExercise(result.expand?.exercisePublic) : undefined,
      });
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 400) {
          if (error.response.data?.template?.code === ERROR_MISSING_REL) {
            throw new ErrorTemplateLibraryNotFound();
          }
        }
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorTemplateNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }

  async deleteExercise(id: string) {
    try {
      return await pb.collection("template_exercise_plan_exercises").delete(id);
    } catch (error) {
      if (error instanceof ClientResponseError) {
        if (error.status === 403) {
          throw new ErrorInsufficientPermissions();
        }
        if (error.status === 404) {
          throw new ErrorTemplateNotFound();
        }
      }
      throw new ErrorUnknown(error);
    }
  }
}

export const templatesRepository = new TemplatesRepository();
