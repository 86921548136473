import { z } from "zod";
import { withThumb } from "../../pocketbase";
import { file } from "../shared/file";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";
import { msg } from "@lit/localize";

export const UserRecordSchema = record({
  name: z.string(),
  username: z.string(),
  email: z.string().optional(), // email might be private
  avatar: z.string().optional(),
  isProfileComplete: z.boolean(),
  role: z.string().optional(),
  language: z.string().optional().default("pt"),
})
export interface UserRecord extends z.infer<typeof UserRecordSchema> {}

export const UserSchema = UserRecordSchema.omit({ expand: true, created: true, updated: true});
export interface User extends z.infer<typeof UserSchema> {}
export function createUser(data: Record<string, unknown>): User {
  return unsafeParse(UserSchema, withThumb(data, "avatar", "128x128"));
}

// export const UserUpdateSchema = z.object({
//   name: z.string().min(3, msg("O nome tem de ter pelo menos 3 caracteres")).optional(),
//   username: z
//     .string()
//     .min(3, "O nome de utilizador tem de ter pelo menos 3 caracteres")
//     .max(32, "Nome de utilizador deve ter no máximo 32 caracteres")
//     .regex(/^[A-Za-z0-9._-]+$/, "Nome de utilizador deve conter apenas letras, números e os caracteres . _ -")
//     .optional(),
//   role: z.string().optional(),
//   avatar: file(
//     {
//       size: 5 * 1024 * 1024,
//       allowedFileTypes: ["image/jpeg", "image/png", "image/webp", "image/heic", "image/heif"],
//     },
//     "O thumbnail deve ser menor que 5MB e ter o formato jpeg, png, webp, heic ou heif",
//   ).optional(),
//   language: z.string().optional(),
// });
// export interface UserUpdate extends z.infer<typeof UserUpdateSchema> {}

export function userCreateSchema() {
  return z.object({
    email: z
      .string({
        required_error: msg("O email é obrigatório"),
      })
      .email("Email inválido"),
    password: z.string({
      required_error: msg("A password é obrigatória"),
    }),
  });
}
export type UserCreate = z.infer<ReturnType<typeof userCreateSchema>>;

export const UserRegisterSchema = () => z.object({
  email: z
    .string({
      required_error: msg("O email é obrigatório"),
    })
    .email(msg("Email inválido")),
  password: z
    .string({
      required_error: msg("A password é obrigatória"),
    })
    .min(8, msg("Password deve ter pelo menos 8 caracteres")),
  terms: z.boolean().refine((v) => v),
});
export type UserRegister = z.infer<ReturnType<typeof UserRegisterSchema>>;

export function createUserUpdateSchema() {
  return z.object({
    name: z.string().min(3, msg("O nome tem de ter pelo menos 3 caracteres")).optional(),
    username: z
      .string()
      .min(3, msg("O nome de utilizador tem de ter pelo menos 3 caracteres"))
      .max(32, msg("Nome de utilizador deve ter no máximo 32 caracteres"))
      .regex(/^[A-Za-z0-9._-]+$/, msg("Nome de utilizador deve conter apenas letras, números e os caracteres . _ -"))
      .optional(),
    role: z.string().optional(),
    avatar: file(
      {
        size: 5 * 1024 * 1024,
        allowedFileTypes: ["image/jpeg", "image/png", "image/webp", "image/heic", "image/heif"],
      },
      msg("O thumbnail deve ser menor que 5MB e ter o formato jpeg, png, webp, heic ou heif"),
    ).optional(),
    language: z.string().optional(),
  });
}

export type UserUpdate = z.infer<ReturnType<typeof createUserUpdateSchema>>;
