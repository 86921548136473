import { observable, makeObservable, computed, action } from "mobx";

export type Filter = { id: string; name: string } & Record<string, any>;
export type Filters = Filter[];

type StagedFilter = Filter & {
  action: "create" | "remove";
};
type StagedFilters = StagedFilter[];

export class FilterStore {
  @observable private _filters: Map<string, Filters>;
  @observable private _stagedFilters: Map<string, StagedFilters>;

  constructor(initial?: [string, Filters][]) {
    this._filters = new Map();
    this._stagedFilters = new Map();

    if (initial) {
      initial.forEach(([key, filters]) => {
        this._filters.set(key, filters);
      });
    }

    makeObservable(this);
  }

  @computed get filters(): Filter[] {
    return Array.from(this._filters.values()).flat();
  }

  @computed get stagedFilters(): Filter[] {
    return Array.from(this._stagedFilters.values()).flat();
  }

  filtersByKey(key: string): Filter[] {
    const filters = this._filters.get(key);
    if (!filters) {
      return [];
    }
    return filters;
  }

  stagedFiltersByKey(key: string): Filter[] {
    const filters = this._stagedFilters.get(key);
    if (!filters) {
      return [];
    }
    return filters;
  }

  matches(key: string, filter: Filter[]): boolean {
    const filters = this._filters.get(key);
    if (!filters || filters.length !== filter.length) {
      return false;
    }
    return filters.every((f) => filter.some((ff) => this.isEqual(f, ff)));
  }

  @computed get hasFilters(): boolean {
    const values = this._filters.values();
    for (const value of values) {
      if (value.length > 0) {
        return true;
      }
    }
    return false;
  }

  @action stage(key: string, filter: Filter) {
    const filters = this._stagedFilters.get(key) || [];

    const existing = filters.find((f) => this.isEqual(f, filter));
    const isActive = this.isActive(filter);

    if (existing) {
      this._stagedFilters.set(
        key,
        filters.map((f) => (this.isEqual(f, filter) ? { ...f, action: isActive ? "remove" : "create" } : f)),
      );
    } else {
      this._stagedFilters.set(key, [...filters, { ...filter, action: isActive ? "remove" : "create" }]);
    }
  }

  @action stageSingle(key: string, filter: Filter) {
    const filters = this._filters.get(key) || [];
    const activeFilters = filters.filter((f) => this.isActive(f));
    const stagedFilters: StagedFilters = activeFilters.map((f) => ({ ...f, action: "remove" }));
    this._stagedFilters.set(key, stagedFilters.concat({ ...filter, action: "create" }));
  }

  @action commit() {
    this._stagedFilters.forEach((stagedFilters, key) => {
      const filters = this._filters.get(key) || [];
      const filtersToAdd = stagedFilters.filter(
        (f) => f.action === "create" && !filters.some((ef) => this.isEqual(ef, f)),
      );
      const filtersToRemove = stagedFilters.filter((f) => f.action === "remove");

      const newFilters = filters.filter((f) => !filtersToRemove.some((rf) => this.isEqual(rf, f))).concat(filtersToAdd);

      this._filters.set(key, newFilters);
    });
    this.rollback();
  }

  @action rollback() {
    for (const key of this._stagedFilters.keys()) {
      this._stagedFilters.set(key, []);
    }
  }

  @action clear() {
    for (const key of this._filters.keys()) {
      this._filters.set(key, []);
    }
    for (const key of this._stagedFilters.keys()) {
      this._stagedFilters.set(key, []);
    }
  }

  @action add(key: string, filter: Filter) {
    const filters = this._filters.get(key) || [];
    if (!filters.some((f) => this.isEqual(f, filter))) {
      this._filters.set(key, [...filters, filter]);
    }
  }

  @action remove(filter: Filter) {
    const filterKeys = this._filters.keys();
    for (const key of filterKeys) {
      const filters = this._filters.get(key) || [];
      const exists = filters.some((f) => this.isEqual(f, filter));
      if (exists) {
        this._filters.set(
          key,
          filters.filter((v) => !this.isEqual(v, filter)),
        );
      }
    }
  }

  isActive(filter: Filter): boolean {
    for (const stagedFilters of this._stagedFilters.values()) {
      const found = stagedFilters.find((v) => this.isEqual(v, filter));
      if (found) {
        return found.action === "create";
      }
    }

    for (const filters of this._filters.values()) {
      if (filters.some((f) => this.isEqual(f, filter))) {
        return true;
      }
    }

    return false;
  }

  private isEqual(filter1: Filter, filter2: Filter): boolean {
    return filter1.id === filter2.id && filter1.name === filter2.name;
  }
}
