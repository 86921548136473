import { customElement, state } from "lit/decorators.js";
import { Page } from "../components/component";
import { Task, TaskStatus } from "@lit/task";
import { html, nothing } from "lit";
import { consume } from "@lit/context";
import { RouterContext, routerContext } from "../context/router.context";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import type { ModalSheetNav } from "../modals/modal-sheet-nav";
import { authContext, type AuthContext } from "../context/auth.context";
import { actionSheetController, alertController, toastController } from "@ionic/core";
import { repeat } from "lit/directives/repeat.js";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { reportsStore } from "../stores/reports.store";
import type { ObservableScale, ObservableScaleField } from "../stores/scale.store";
import { type ScaleOption, type UpdateScale } from "../repository/report/report";
import { when } from "lit/directives/when.js";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("page-scale")
@localized()
export class PageScale extends Page {
  @state() private _reportId: string = "";
  @state() private _scaleId: string = "";
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: authContext }) auth!: AuthContext;

  #ionRefresher: Ref<IonRefresher> = createRef();
  #modalCreateScaleFieldRef: Ref<ModalSheetNav> = createRef();
  #modalEditScaleFieldRef: Ref<ModalSheetNav> = createRef();
  #modalScaleOptionRef: Ref<ModalSheetNav> = createRef();
  #modalEditOptionRef: Ref<ModalSheetNav> = createRef();
  #modalEditReportRef: Ref<ModalNav> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this._reportId = this.router.getParam("reportId");
    this._scaleId = this.router.getParam("scaleId");
    this.#scale.run([true]);
  }

  #scale = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return reportsStore.loadScale(this._reportId, this._scaleId, { cache });
    },
  });

  async presentFieldModal(scale: ObservableScale) {
    await this.#modalCreateScaleFieldRef.value?.open({ scale: scale });
  }

  async presentEditFieldModal(field: ObservableScaleField) {
    await this.#modalEditScaleFieldRef.value?.open({ field: field });
  }

  async presentEditScaleModal(scale: ObservableScale) {
    await this.#modalEditReportRef.value?.open({ scale: scale });
  }

  async presentAddOptionBottomSheet(field: ObservableScaleField) {
    await this.#modalScaleOptionRef.value?.open({ field: field });
  }

  async deleteScale() {
    const alert = await alertController.create({
      header: msg("Apagar Escala"),
      message: msg("Tem a certeza que deseja apagar esta escala? A ação não é reversível."),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await reportsStore.deleteScale(this.#scale.value!).then(() => {
                this.router.back();
              });
            } catch (error) {
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async deleteField(field: ObservableScaleField) {
    const alert = await alertController.create({
      header: msg("Apagar Campo"),
      message: msg("Tem a certeza que deseja apagar este campo? A ação não é reversível."),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await reportsStore.deleteScaleField(this.#scale.value!, field).then(async () => {
                const toast = await toastController.create({
                  message: msg("Campo apagado com sucesso"),
                  color: "primary",
                  icon: "/assets/icons/info.svg",
                  duration: 2000,
                });
                await toast.present();
                console.log(this.#scale.value);
              });
            } catch (error) {
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async showDeleteOptionAlert(field: ObservableScaleField, id: string) {
    const alert = await alertController.create({
      header: msg("Apagar Campo"),
      message: msg("Tem a certeza que deseja apagar esta opção? A ação não é reversível."),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Apagar"),
          role: "destructive",
          handler: async () => {
            try {
              await reportsStore.deleteScaleOption(id, field);
            } catch (error) {
              if (error instanceof ApiError) {
                const alert = await alertController.create({
                  header: msg("Erro"),
                  message: error.message,
                  buttons: ["OK"],
                });
                await alert.present();
              }
            }
          },
        },
      ],
    });
    await alert.present();
  }

  async editExpression(scale: ObservableScale, expression: string) {
    const data: UpdateScale = {
      expression: expression,
    };
    try {
      await reportsStore.updateScale(data, scale);
      const toast = await toastController.create({
        message: msg("Expressão atualizada com sucesso"),
        duration: 2000,
        color: "primary",
        icon: "/assets/icons/info.svg",
        position: "bottom",
      });
      await toast.present();
    } catch (error) {
      if (error instanceof ApiError) {
        const alert = await alertController.create({
          header: msg("Erro"),
          message: error.message,
          buttons: ["OK"],
        });
        await alert.present();
      }
    }
  }

  async moveSectionUp(scale: ObservableScale, field: ObservableScaleField) {
    const previousField = scale.fields.find((s) => s.position === field.position - 1);
    if (previousField) {
      try {
        await reportsStore.updateScaleFieldPosition(field, { position: field.position - 1 }, previousField, {
          position: previousField.position + 1,
        });
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    }
  }

  async moveSectionDown(scale: ObservableScale, field: ObservableScaleField) {
    const nextField = scale.fields.find((s) => s.position === field.position + 1);
    if (nextField) {
      try {
        await reportsStore.updateScaleFieldPosition(field, { position: field.position + 1 }, nextField, {
          position: nextField.position - 1,
        });
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    }
  }

  async presentActionSheet(field: ObservableScaleField, option: ScaleOption) {
    const actionSheet = await actionSheetController.create({
      header: msg("Ações"),
      buttons: [
        {
          text: msg("Editar"),
          handler: () => {
            this.#modalEditOptionRef.value?.open({ field, option });
          },
        },
        {
          text: msg("Remover"),
          role: "destructive",
          handler: async () => {
            await this.showDeleteOptionAlert(field, option.id);
          },
        },
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
      ],
    });
    await actionSheet.present();
  }

  canEdit() {
    const report = reportsStore.findReport(this._reportId);
    if (!report) return false;
    return report.canEdit(this.auth.user);
  }

  render() {
    return html`
      <ion-header>
        <ion-toolbar class=${this.#scale.value?.fields.length === 0 ? "no-border" : ""}>
          <!-- remove the class above 'no-border' when there are sections -->
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-router-link href=${this.router.backUrl} router-direction="back">
                <ion-button
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </ion-router-link>
            </div>
          </ion-buttons>
          ${when(
            this.#scale.status === TaskStatus.ERROR,
            () =>
              html`
                <ion-title class="flex"
                  ><ion-skeleton-text animated class="h-10 w-4/5 mx-auto"></ion-skeleton-text
                ></ion-title>
              `,
          )}
          <ion-title>${this.#scale.value?.name}</ion-title>
          ${when(
            this.canEdit(),
            () => html`
              <ion-buttons slot="end">
                <div class="flex items-center">
                  <ion-button
                    id="action-popover"
                    fill="solid"
                    style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
                    color="secondary"
                    shape="round"
                    size="xsmall">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      class="mr-1 mb-0.5">
                      <path
                        d="M11.6667 12.4998H17.5M2.5 12.4998H4.16667M4.16667 12.4998C4.16667 13.6504 5.09941 14.5832 6.25 14.5832C7.40059 14.5832 8.33333 13.6504 8.33333 12.4998C8.33333 11.3492 7.40059 10.4165 6.25 10.4165C5.09941 10.4165 4.16667 11.3492 4.16667 12.4998ZM16.6667 7.49984H17.5M2.5 7.49984H8.33333M13.75 9.58317C12.5994 9.58317 11.6667 8.65043 11.6667 7.49984C11.6667 6.34924 12.5994 5.4165 13.75 5.4165C14.9006 5.4165 15.8333 6.34924 15.8333 7.49984C15.8333 8.65043 14.9006 9.58317 13.75 9.58317Z"
                        stroke="#111111"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                    ${msg("Ações")}
                  </ion-button>
                </div>
              </ion-buttons>
              <ion-popover
                arrow
                alignment="center"
                dismiss-on-select="true"
                reference="trigger"
                side="bottom"
                style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                trigger="action-popover">
                <div class="p-0">
                  <ion-list>
                    <ion-item
                      button
                      .detail=${false}
                      class="no-p popover text-sm"
                      @click=${() => this.presentEditScaleModal(this.#scale.value!)}>
                      <ion-label class="font-semibold pl-3">${msg("Editar")}</ion-label>
                    </ion-item>
                    <ion-item button class="no-p no-detail text-sm" @click=${() => this.deleteScale()}>
                      <ion-label class="font-semibold pl-3">${msg("Apagar")}</ion-label>
                    </ion-item>
                  </ion-list>
                </div>
              </ion-popover>
            `,
          )}
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen class="no-px" style="--padding-bottom: 24px;">
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            if (!this.auth.user) return;
            await this.#scale.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        ${this.#scale.render({
          pending: () => {},
          error: (err) => {
            console.log(err);
            if (err instanceof ApiError) {
              return html`<div
                class="mx-4 mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${err.message}
                </span>
              </div>`;
            } else {
              return html`<div
                class="mx-4 mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${msg("Erro ao carregar a escala")}
                </span>
              </div>`;
            }
          },
          complete: (scale) => {
            return html`
              ${when(
                scale.fields.length > 0,
                () => html`
                  <ion-accordion-group class="mt-2.5 mb-1.5 space-y-2.5" multiple>
                    ${scale.sortedFields.map(
                      (field, index) => html`
                        <ion-accordion
                          class="px-4"
                          value="${field.id}"
                          toggle-icon-slot="start"
                          toggle-icon="/assets/icons/chevron-down.svg">
                          <ion-item lines="none" class="no-active-bg no-border no-p-start no-inner-p" slot="header">
                            <div class="flex justify-between items-center w-full">
                              <div class="flex items-start space-x-1 w-full">
                                <h6>${index + 1}. ${field.label}</h6>
                                <ion-chip class="flex-shrink-0" style="--background: #EAEAEA; --color: #888888"
                                  >${msg(str`${field.options.length || 0} Opções`)}</ion-chip
                                >
                              </div>
                              ${when(
                                this.canEdit(),
                                () => html`
                                  <ion-button
                                    id="${index}-section-popover"
                                    fill="clear"
                                    size="chip"
                                    @click="${(e: Event) => {
                                      e.stopPropagation();
                                    }}">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none">
                                      <path
                                        d="M17 12C17 12.5523 17.4477 13 18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11C17.4477 11 17 11.4477 17 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                      <path
                                        d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                      <path
                                        d="M5 12C5 12.5523 5.44772 13 6 13C6.55228 13 7 12.5523 7 12C7 11.4477 6.55228 11 6 11C5.44772 11 5 11.4477 5 12Z"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </svg>
                                  </ion-button>
                                  <ion-popover
                                    arrow
                                    alignment="center"
                                    dismiss-on-select="true"
                                    reference="trigger"
                                    side="bottom"
                                    style="--max-width: 150px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                                    trigger="${index}-section-popover">
                                    <div class="p-0">
                                      <ion-list>
                                        <ion-item
                                          button
                                          .detail=${false}
                                          class="no-p popover text-sm"
                                          @click=${() => this.presentAddOptionBottomSheet(field)}>
                                          <ion-label class="font-semibold pl-3">${msg("Adicionar opção")}</ion-label>
                                        </ion-item>
                                        <ion-item
                                          button
                                          .detail=${false}
                                          class="no-p popover text-sm"
                                          @click=${() => this.presentEditFieldModal(field)}>
                                          <ion-label class="font-semibold pl-3">${msg("Editar")}</ion-label>
                                        </ion-item>
                                        ${when(
                                          index !== 0,
                                          () => html`
                                            <ion-item
                                              button
                                              .detail=${false}
                                              class="no-p popover text-sm"
                                              @click=${() => this.moveSectionUp(scale, field)}>
                                              <ion-label class="font-semibold pl-3"
                                                >${msg("Mover para Cima")}</ion-label
                                              >
                                            </ion-item>
                                          `,
                                        )}
                                        ${when(
                                          index !== scale.fields.length - 1,
                                          () => html`
                                            <ion-item
                                              button
                                              .detail=${false}
                                              class="no-p popover text-sm"
                                              @click=${() => this.moveSectionDown(scale, field)}>
                                              <ion-label class="font-semibold pl-3"
                                                >${msg("Mover para Baixo")}</ion-label
                                              >
                                            </ion-item>
                                          `,
                                        )}
                                        <ion-item
                                          button
                                          class="no-p no-detail text-sm"
                                          @click=${() => this.deleteField(field)}>
                                          <ion-label class="font-semibold pl-3">${msg("Apagar")}</ion-label>
                                        </ion-item>
                                      </ion-list>
                                    </div>
                                  </ion-popover>
                                `,
                              )}
                            </div>
                          </ion-item>
                          <div slot="content" class="mt-1.5">
                            <ion-list lines="none">
                              <ion-radio-group>
                                ${repeat(
                                  field.options,
                                  (option) => option.id,
                                  (option, index) => html`
                                    <ion-item
                                      .disabled=${!this.canEdit()}
                                      @click=${() => this.presentActionSheet(field, option)}
                                      class="text-accent-5 no-p no-inner-p pointer-events-auto"
                                      style="--min-height: 48px;">
                                      <ion-radio
                                        disabled
                                        value=${index}
                                        class="no-m-inline my-2 text-sm opacity-100 text-accent-1"
                                        >${option.label}</ion-radio
                                      >
                                    </ion-item>
                                    ${index === field.options.length - 1
                                      ? nothing
                                      : html`<div class="h-px w-full bg-[#e5e5e5] last:hidden"></div>`}
                                  `,
                                )}
                              </ion-radio-group>
                            </ion-list>
                          </div>
                        </ion-accordion>
                      `,
                    )}
                  </ion-accordion-group>
                  <div class="space-y-3 mb-3">
                    <div class="h-1 w-full bg-[#e5e5e5]"></div>

                    <div class="px-4">
                      <div class="col gap-y-1">
                        <span class="font-semibold font-display">${msg("Expressão Final")}</span>
                        <span class="text-sm"
                          >${msg(html`Para representar o valor de um campo deverá colocar um cifrão antes do campo. Por
                          exemplo, uma soma entre o campo 1 e 2 seria representada por $1+$2. Para dividir usar '/' e
                          para multiplicar usar '*'.`)}</span
                        >
                      </div>
                      <ion-item class="mt-2">
                        <ion-input
                          type="text"
                          .readonly=${!this.canEdit()}
                          placeholder="($1+$2)/$3"
                          value=${scale.expression}
                          debounce=${2000}
                          @ionInput=${(e: CustomEvent) =>
                            this.editExpression(scale, e.detail.value as string)}></ion-input>
                      </ion-item>
                    </div>
                    <div class="h-1 w-full bg-[#e5e5e5]"></div>
                  </div>
                `,
                () => {
                  if (!this.canEdit()) {
                    return html`
                      <div
                        class="mx-4 h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z"
                            stroke="#111111"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                          ${msg("Esta escala ainda não tem conteúdo")}
                        </span>
                      </div>
                    `;
                  }
                  return nothing;
                },
              )}
              ${when(
                this.canEdit(),
                () => html`
                  <ion-button
                    class="px-4"
                    color="secondary"
                    expand="block"
                    shape="round"
                    size="small"
                    @click=${() => this.presentFieldModal(scale)}
                    >${msg("Adicionar Campo")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      class="ml-1.5">
                      <path
                        d="M4 8H8M8 8H12M8 8V12M8 8V4"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </ion-button>
                `,
              )}
            `;
          },
        })}
      </ion-content>

      <modal-sheet-nav
        auto-height
        root="modal-create-scale-field"
        ${ref(this.#modalCreateScaleFieldRef)}></modal-sheet-nav>
      <modal-sheet-nav auto-height root="modal-edit-scale-field" ${ref(this.#modalEditScaleFieldRef)}></modal-sheet-nav>
      <modal-sheet-nav auto-height root="modal-scale-option" ${ref(this.#modalScaleOptionRef)}></modal-sheet-nav>
      <modal-sheet-nav auto-height root="modal-edit-scale-option" ${ref(this.#modalEditOptionRef)}></modal-sheet-nav>
      <modal-nav root="modal-edit-report" ${ref(this.#modalEditReportRef)}></modal-nav>
    `;
  }
}
