import { html, type PropertyValues } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Page } from "../components/component";
import Cropper from "cropperjs";
import { localized, msg } from "@lit/localize";

@customElement("modal-crop-photo")
@localized()
export class ModalCropPhoto extends Page {
  image!: string;
  updateImage!: (image: HTMLCanvasElement) => void;
  onCancel?: () => void;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @state() private cropper: Cropper | null = null;
  @query("#image-crop") private imageElement!: HTMLImageElement;

  async firstUpdated(changedProperties: PropertyValues) {
    super.firstUpdated(changedProperties);
    await this.updateComplete;
    this.image = await this.resizeImage(this.image);
    this.waitForImageAndInitializeCropper();
  }

  private async waitForImageAndInitializeCropper() {
    this.imageElement.src = this.image;
    if (this.imageElement.complete) {
      this.initializeCropper();
    } else {
      this.imageElement.onload = () => {
        this.initializeCropper();
      };
    }
  }

  private async initializeCropper() {
    if (this.cropper) {
      // this.cropper.destroy();
      return;
    }
    setTimeout(() => {
      this.cropper = new Cropper(this.imageElement, {
        dragMode: "move",
        aspectRatio: 1,
        autoCropArea: 0.65,
        restore: false,
        guides: false,
        center: false,
        highlight: false,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
      });
    }, 1);
  }

  private resizeImage(imageUrl: string | File, maxWidth: number = 1024): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        let width = img.width;
        let height = img.height;

        if (width > height && width > maxWidth) {
          height = (height * maxWidth) / width;
          width = maxWidth;
        } else if (height > maxWidth) {
          width = (width * maxWidth) / height;
          height = maxWidth;
        }

        canvas.width = width;
        canvas.height = height;

        ctx?.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL("image/jpeg"));
      };
      // img.src = imageUrl;
      img.onerror = reject;

      if (typeof imageUrl === "string") {
        img.src = imageUrl;
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          img.src = e.target?.result as string;
        };
        reader.onerror = reject;
        reader.readAsDataURL(imageUrl);
      }
    });
  }

  cropImage() {
    const canvas = this.cropper?.getCroppedCanvas();
    if (canvas) this.updateImage(canvas);
    this.navigator.close();
  }

  cancel() {
    if (this.onCancel) this.onCancel();
    this.navigator.close();
  }

  render() {
    return html`
      <ion-content>
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea">
            <ion-buttons slot="start">
              <ion-button @click=${() => this.cancel()} class="font-semibold">${msg("Cancelar")}</ion-button>
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Cortar Foto")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <div id="img-container" class="w-full h-auto">
          <img id="image-crop" src=${this.image} style="display: block; max-width: 100%; height: auto;" />
        </div>
      </ion-content>
      <ion-footer>
        <ion-toolbar>
          <ion-button
            @click=${() => this.cropImage()}
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            >${msg("Cortar")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
