import { html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { when } from "lit/directives/when.js";
import { alertController } from "@ionic/core";
import { UpdatePatientSchema, type UpdatePatient } from "../repository/patient/patient";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { ObservablePatient, patientsStore } from "../stores/patients.store";
import { repeat } from "lit/directives/repeat.js";
import { Page, required } from "../components/component";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalSheetNav } from "./modal-sheet-nav";
import { format, formatISO } from "date-fns";
import { pt } from "date-fns/locale";
import { routerContext, RouterContext } from "../context/router.context";
import type { IonActionSheet } from "@ionic/core/components/ion-action-sheet";
import { FilterStore } from "../stores/filter.store";
import type { ModalNav } from "./modal-nav";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-edit-patient")
@localized()
export class ModalEditPatient extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  @state() private _gender: string = "";
  @state() private _birthdate: string = "";
  @state() private _hasAvatar: boolean = false;

  filterStore!: FilterStore;

  @required() patient!: ObservablePatient;

  #sheetDatePickerRef: Ref<ModalSheetNav> = createRef();
  #modalCropPhotoRef: Ref<ModalNav> = createRef();
  #sheetGenderRef: Ref<IonActionSheet> = createRef();
  #fileInputRef: Ref<HTMLInputElement> = createRef();

  connectedCallback() {
    super.connectedCallback();
    if (!this.patient) {
      throw new Error("<modal-edit-patient> requires patient");
    }
    this._birthdate = this.patient.birthdate;
    this.filterStore = new FilterStore([
      ["categories", this.patient.categories.map((c) => ({ id: c.id, name: c.category, color: c.color }))],
    ]);
  }

  #controller = new FinalFormController<UpdatePatient>(this, {
    validate: zodValidator(UpdatePatientSchema()),
    onSubmit: async (values) => {
      try {
        const data: UpdatePatient = {
          ...values,
          gender: this.getGenderKey(values.gender!),
          birthdate: formatISO(this._birthdate),
          categories: this.filterStore
            .filtersByKey("categories")
            .filter((c) => !this.patient.categories.some((pc) => pc.id === c.id))
            .map((c) => c.id),
          "categories-": this.patient.categories
            .filter((c) => !this.filterStore.filtersByKey("categories").some((f) => f.id === c.id))
            .map((c) => c.id),
        };

        await patientsStore.updatePatient(this.patient, data);

        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: [msg("OK")],
          });
          alert.present();
        }
      }
    },
  });

  async firstUpdated(_changedProperties: Map<string, any>) {
    super.firstUpdated(_changedProperties);

    const fileInput = this.#fileInputRef.value;
    if (fileInput) {
      fileInput.addEventListener("change", (event) => {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files[0]) {
          const file = input.files[0];

          const reader = new FileReader();
          reader.onload = (e) => {
            const imageUrl = e.target?.result as string;
            this.#modalCropPhotoRef.value?.open({ image: imageUrl, updateImage: this.updateCroppedImage.bind(this) });
          };
          reader.readAsDataURL(file);
          input.value = "";
        }
      });
    }
  }

  updateCroppedImage(canvas: HTMLCanvasElement) {
    const image = canvas.toDataURL("image/jpeg");
    const button = document.querySelector("#avatar-button") as HTMLButtonElement;
    button.style.backgroundImage = `url(${image})`;
    button.style.backgroundSize = "cover";
    this._hasAvatar = true;
    canvas.toBlob(
      (blob) => {
        if (blob) {
          const fileName = `cropped-image-${Date.now()}.jpeg`;
          const file = new File([blob], fileName, { type: "image/jpeg" });
          this.#controller.form.change("avatar", file);
        } else {
          console.error("Canvas to Blob conversion failed");
        }
      },
      "image/jpeg",
      0.8,
    );
  }

  getGenderName(gender: string) {
    if (gender === "male") {
      return msg("Masculino");
    } else if (gender === "female") {
      return msg("Feminino");
    }
    return msg("Outro");
  }

  getGenderKey(name: string) {
    if (name === "Masculino") {
      return "male";
    } else if (name === "Feminino") return "female";
    return "other";
  }

  changeGenderInputValue(value: string) {
    this._gender = value;
    this.#controller.form.change("gender", this.getGenderName(value));
    this.#sheetGenderRef.value?.dismiss();
  }

  getDay(date: string) {
    if (!date) return;
    const time = format(date, "dd-MM-yyyy", { locale: pt });
    return time;
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    if (this._birthdate) {
      form.change("birthdate", this.getDay(this._birthdate));
    }

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click=${() => this.navigator.goBack()}>
                ${msg("Cancelar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Editar Utente")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4" fullscreen>
        ${this.patient &&
        html`
          <form
            id="form-patient-edit"
            @submit=${(e: Event) => {
              e.preventDefault();
              form.submit();
            }}
            class="col space-y-4 pb-4 pt-6">
            <div class="mx-auto">
              <button
                id="avatar-button"
                type="button"
                class="w-36 h-36 rounded-full bg-accent-8 border border-solid border-accent-7"
                aria-label="avatar"
                @click=${() => {
                  const input = this.#fileInputRef.value;
                  if (input) input.click();
                }}>
                ${when(
                  this.patient.avatar && !this._hasAvatar,
                  () => html`
                    <img loading="lazy" src=${this.patient?.avatar} class="w-36 h-36 rounded-full object-cover" />
                  `,
                  () =>
                    when(
                      !this._hasAvatar,
                      () => html`
                        <div slot="icon-only">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 25 24"
                            fill="none">
                            <path
                              d="M8.5 12H12.5M12.5 12H16.5M12.5 12V16M12.5 12V8M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21Z"
                              stroke="black"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </div>
                      `,
                    ),
                )}
              </button>
              ${renderError("avatar")}
              <input ${ref(this.#fileInputRef)} class="hidden" accept="image/*" type="file" ${register("avatar")} />
            </div>

            <div class="space-y-2.5">
              <span class="font-display font-semibold">${msg("Categoria")}</span>
              ${when(this.filterStore.hasFilters, () => {
                return html`
                  <div class="my-1.5 flex items-center flex-wrap gap-1.5">
                    ${repeat(
                      this.filterStore.filtersByKey("categories"),
                      (category) => category.id,
                      (category) => {
                        return html` <ion-chip
                          style="--background: ${category.color}"
                          class="font-semibold font-body text-sm px-2.5 py-1.5"
                          @click=${() => this.filterStore.remove(category)}
                          >${category.name}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                            class="ml-1">
                            <rect width="12" height="12" rx="6" fill="black" />
                            <path
                              d="M7.99999 7.99999L6.00001 6.00001M6.00001 6.00001L4 4M6.00001 6.00001L8.00001 4M6.00001 6.00001L4 8.00001"
                              stroke="white"
                              stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </ion-chip>`;
                      },
                    )}
                  </div>
                `;
              })}
              <ion-button
                @click=${() => {
                  this.navigator.push("modal-choose-patient-category", {
                    filterStore: this.filterStore,
                  });
                }}
                class="font-bold"
                color="secondary"
                shape="round"
                size="small"
                expand="block"
                >${msg("Adicionar")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  class="ml-2">
                  <path
                    d="M5.33333 8H8M8 8H10.6667M8 8V10.6667M8 8V5.33333M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </ion-button>
            </div>
            <ion-list lines="none" class="space-y-4 flex flex-col">
              <div>
                <span class="font-semibold font-display">${msg("Nome")}</span>
                <ion-item class="mt-1">
                  <ion-input
                    autocapitalize="on"
                    type="text"
                    placeholder=${msg("Nome do paciente")}
                    ${register("name", {
                      initialValue: this.patient.name,
                    })}>
                  </ion-input>
                </ion-item>
                ${renderError("name")}
              </div>
              <div>
                <span class="font-semibold font-display">${msg("Email")}</span>
                <ion-item class="mt-1">
                  <ion-input
                    id="input-email"
                    type="email"
                    placeholder="email@gmail.com"
                    ${register("email", {
                      initialValue: this.patient.email,
                    })}>
                  </ion-input>
                </ion-item>
                ${renderError("email")}
              </div>
              <div>
                <span class="font-semibold font-display">${msg("Telemóvel")}</span>
                <ion-item class="mt-1">
                  <ion-input
                    type="tel"
                    placeholder="912345678"
                    ${register("phone", {
                      initialValue: this.patient.phone,
                    })}>
                  </ion-input>
                </ion-item>
                ${renderError("phone")}
              </div>
              <div>
                <span class="font-semibold font-display">${msg("Género")}</span>
                <ion-item class="mt-1">
                  <ion-input
                    readonly
                    ${register("gender", {
                      initialValue: this.getGenderName(this._gender || this.patient.gender),
                    })}
                    @click=${() => this.#sheetGenderRef.value?.present()}
                    placeholder=${msg("Clique para selecionar")}>
                  </ion-input>
                </ion-item>
                ${renderError("gender")}
              </div>
              <div>
                <span class="font-semibold font-display">${msg("Data de Nascimento")}</span>
                <ion-item class="mt-1">
                  <ion-input
                    readonly
                    ${register("birthdate")}
                    placeholder="01-01-2000"
                    @click=${() =>
                      this.#sheetDatePickerRef.value?.open({
                        date: formatISO(this._birthdate),
                        setDate: (date: string) => {
                          this._birthdate = date;
                        },
                      })}>
                  </ion-input>
                </ion-item>
                ${renderError("birthdate")}
              </div>
              <div>
                <span class="font-semibold font-display">${msg("Residência")}</span>
                <ion-item class="mt-1">
                  <ion-input
                    ${register("address", {
                      initialValue: this.patient.address,
                    })}
                    type="text"
                    placeholder=${msg("Localidade")}>
                  </ion-input>
                </ion-item>
                ${renderError("address")}
              </div>
              <div>
                <span class="font-semibold font-display">${msg("Informação Clínica")}</span>
                <ion-item class="mt-1">
                  <ion-textarea
                    ${register("clinicInfo", {
                      initialValue: this.patient.clinicInfo,
                    })}
                    auto-grow
                    rows="4"
                    placeholder=${msg("Digite aqui a informação necessária")}>
                  </ion-textarea>
                </ion-item>
                ${renderError("clinicInfo")}
              </div>
            </ion-list>
          </form>
        `}
      </ion-content>

      <ion-footer>
        <ion-toolbar style="--background: transparent">
          <ion-button
            form="form-patient-edit"
            type="submit"
            shape="round"
            expand="block"
            ?disabled=${formState.submitting}
            >${msg("Guardar Alterações")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>

      <modal-sheet-nav
        auto-height
        root="modal-date-picker"
        border-radius=${0}
        .handle=${false}
        ${ref(this.#sheetDatePickerRef)}></modal-sheet-nav>
      <modal-nav root="modal-crop-photo" ${ref(this.#modalCropPhotoRef)}></modal-nav>

      <ion-action-sheet
        ${ref(this.#sheetGenderRef)}
        .buttons=${[
          {
            text: msg("Masculino"),
            role: "selected",
            handler: () => {
              this.changeGenderInputValue("male");
            },
          },
          {
            text: msg("Feminino"),
            role: "selected",
            handler: () => {
              this.changeGenderInputValue("female");
            },
          },
          {
            text: msg("Outro"),
            role: "selected",
            handler: () => {
              this.changeGenderInputValue("other");
            },
          },
          {
            text: msg("Cancelar"),
            role: "cancel",
          },
        ]}></ion-action-sheet>
    `;
  }
}
