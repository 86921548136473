import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { consume } from "@lit/context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { choose } from "lit/directives/choose.js";
import { authContext, type AuthContext } from "../context/auth.context";
import { groupStore } from "../stores/groups.store";
import { CreateGroupSchema, type CreateGroup } from "../repository/group/group";
import { alertController } from "@ionic/core";
import { Page } from "../components/component";
import { UserShareStore } from "../stores/userShare.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-create-group")
@localized()
export class ModalGroupCreate extends Page {
  @consume({ context: navigatorContext }) navigator!: NavigatorController;
  @consume({ context: authContext }) auth!: AuthContext;

  private shareStore = new UserShareStore();

  #controller = new FinalFormController<CreateGroup>(this, {
    validate: zodValidator(CreateGroupSchema()),
    onSubmit: async (values) => {
      try {
        const group = await groupStore.createGroup({
          name: values.name,
          owner: values.owner,
        });

        await groupStore.shareWithUsers(group, this.shareStore.users);

        this.navigator.goBack();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: [msg("OK")],
          });
          await alert.present();
        }
      }
    },
  });

  render() {
    const { form, register, renderError, submit } = this.#controller;
    const formState = form.getState();

    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${this.navigator.goBack}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Novo grupo")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen class="space-y-6">
        <form id="form-group-create" @submit=${submit} class="mt-6">
          <div>
            <span class="font-semibold font-display">${msg("Nome")}</span>
            <ion-item class="mt-1">
              <ion-input
                autocapitalize="on"
                type="text"
                placeholder=${msg("Nome do grupo")}
                @input=${() => {}}
                ${register("name")}>
              </ion-input>
            </ion-item>
            ${renderError("name")}
          </div>

          <div class="mt-6">
            <span class="font-semibold font-display">${msg("Profissionais")}</span>
            <ion-list lines="none" class="space-y-2.5 mt-2">
              <ion-item class="no-p">
                <div
                  class="flex items-center space-x-2"
                  ${register("owner", {
                    initialValue: this.auth.user?.id,
                  })}>
                  <xt-avatar src=${this.auth.user?.avatar} name=${this.auth.user?.name}></xt-avatar>
                  <div class="col items-start">
                    <p class="font-semibold">${this.auth.user?.name}</p>
                    <span class="text-danger">${msg("Criador")}</span>
                  </div>
                </div>
                ${renderError("owner")}
              </ion-item>
              ${this.shareStore.availableUsers?.map(
                (groupShared) => html`
                  <ion-item
                    class="no-p"
                    @click=${() =>
                      this.navigator.push("modal-share-edit", {
                        share: groupShared,
                        shareStore: this.shareStore,
                      })}>
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${groupShared.user.avatar} name=${groupShared.user.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${groupShared.user.name}</p>
                        ${choose(groupShared.access.role, [
                          ["admin", () => html` <span class="text-successDark">${msg("Administrador")}</span> `],
                          ["editor", () => html` <span class="text-warning">${msg("Editor")}</span> `],
                          ["viewer", () => html` <span class="text-accent-2">${msg("Visualizador")}</span> `],
                        ])}
                      </div>
                    </div>
                  </ion-item>
                `,
              )}
            </ion-list>
          </div>
          <div class="mt-3">
            <ion-button
              size="small"
              color="secondary"
              expand="block"
              shape="round"
              @click=${() => {
                this.navigator.push("modal-share-add", {
                  shareStore: this.shareStore,
                });
              }}
              >${msg("Adicionar Profissional")}
              <span class="mt-0.5 ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path
                    d="M5.83333 8H8.5M8.5 8H11.1667M8.5 8V10.6667M8.5 8V5.33333M8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8C14.5 11.3137 11.8137 14 8.5 14Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
            </ion-button>
          </div>
        </form>
        <!-- spacer -->
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-group-create"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}>
            ${msg("Criar grupo")}
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
