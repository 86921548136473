import { z } from "zod";
import { DiagnosticSchema, SessionSchema } from "./diagnostic";
import { withThumb } from "../../pocketbase";
import { file } from "../shared/file";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";
import { CategorySchema } from "../group/category";
import { UserSchema } from "../user/user";
import { msg } from "@lit/localize";

export const PatientRecordSchema = record({
  section: z.string(),
  group: z.string(),
  name: z.string(),
  email: z.string(),
  phone: z.string(),
  gender: z.string(),
  address: z.string(),
  birthdate: z.string(),
  avatar: z.string().optional(),
  clinicInfo: z.string().optional(),
  isArchived: z.boolean(),
});
export interface PatientRecord extends z.infer<typeof PatientRecordSchema> {}

export const PatientSchema = PatientRecordSchema.extend({
  categories: z.array(CategorySchema).default([]),
  diagnostics: z.array(DiagnosticSchema).default([]),
});
export interface Patient extends z.infer<typeof PatientSchema> {}
export function createPatient(data: Record<string, unknown>) {
  return unsafeParse(PatientSchema, withThumb(data, "avatar", "128x128"));
}

export const PatientPublicSchema = z.object({
  patient: PatientSchema,
  sessions: z.array(SessionSchema).default([]),
  professionals: z.array(UserSchema).default([]),
});
export interface PatientPublic extends z.infer<typeof PatientPublicSchema> {}
export function createPatientPublic(data: Record<string, unknown>) {
  return unsafeParse(PatientPublicSchema, data);
}

export const CreatePatientSchema = () =>
  z.object({
    group: z.string(),
    section: z.string(),
    name: z
      .string({
        required_error: msg("Nome é obrigatório"),
      })
      .min(3, msg("Nome tem de ter pelo menos 3 caracteres")),
    email: z.string().email(msg("Email inválido")).optional().or(z.literal("")),
    phone: z
      .string({
        required_error: msg("Telemóvel é obrigatório"),
      })
      .min(9, msg("Telemóvel inválido")),
    gender: z.string({
      required_error: msg("Género é obrigatório"),
    }),
    birthdate: z.string({
      required_error: msg("Data de Nascimento é obrigatória"),
    }),
    address: z.string({ required_error: msg("Morada é obrigatória") }).min(1, msg("Morada é obrigatória")),
    clinicInfo: z.string().optional(),
    avatar: file(
      {
        size: 10 * 1024 * 1024,
        allowedFileTypes: [
          "image/jpeg",
          "image/jpg",
          "image/avif",
          "image/png",
          "image/webp",
          "image/heic",
          "image/heif",
        ],
      },
      msg("O avatar deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif"),
    ).optional(),
    categories: z.array(z.string()).optional(),
  });
export interface CreatePatient extends z.infer<ReturnType<typeof CreatePatientSchema>> {}

export const UpdatePatientSchema = () =>
  z.object({
    name: z.string().min(3, msg("Nome tem de ter pelo menos 3 caracteres")).optional(),
    email: z.string().email(msg("Email inválido")).optional().or(z.literal("")),
    phone: z.string().min(9, msg("Telemóvel inválido")).optional(),
    gender: z.string().optional(),
    birthdate: z.string().optional(),
    address: z.string().min(1, msg("Morada é obrigatória")).optional(),
    clinicInfo: z.string().optional(),
    isArchived: z.boolean().optional(),
    avatar: file(
      {
        size: 10 * 1024 * 1024,
        allowedFileTypes: [
          "image/jpeg",
          "image/jpg",
          "image/avif",
          "image/png",
          "image/webp",
          "image/heic",
          "image/heif",
        ],
      },
      msg("O avatar deve ser menor que 10MB e ter o formato jpeg, png, webp, heic ou heif"),
    ).optional(),
    categories: z.array(z.string()).optional(),
    "categories-": z.array(z.string()).optional(),
  });
export interface UpdatePatient extends z.infer<ReturnType<typeof UpdatePatientSchema>> {}
