import { action, computed, makeObservable, observable, runInAction, type IObservableArray, ObservableMap } from "mobx";
import { groupRepository } from "../repository/group/group.repository";
import type { User } from "../repository/user/user";
import { ObservablePatient, patientsStore } from "./patients.store";
import type { CreateGroup, Group, GroupShared, GroupSummary, UpdateGroup } from "../repository/group/group";
import type { UserAuth } from "./auth.store";
import type { UserShareAction } from "./userShare.store";
import type { Section, UpdateSectionWithId, CreateSection, UpdateSection } from "../repository/group/section";
import { ErrorGroupSectionNotFound } from "../repository/group/errors";

export class ObservableSection {
  id: string;
  group: string;
  @observable name: string;
  @observable position: number;
  @observable patients: IObservableArray<ObservablePatient>;

  constructor(section: Section) {
    this.id = section.id;
    this.name = section.name;
    this.position = section.position;
    this.patients = observable.array(section.patients.map((p) => patientsStore.subscribe(p)));
    this.group = section.group;
    makeObservable(this);
  }

  @computed get totalActivePatients() {
    return this.patients.filter((p) => !p.isArchived).length;
  }
}

export class ObservableGroup {
  id: string;
  @observable name: string;
  @observable owner: User;
  @observable isArchived: boolean;
  @observable sharedPersonal: IObservableArray<GroupShared>;
  @observable sections: IObservableArray<ObservableSection>;
  @observable isLoaded: boolean;

  // data that is loaded by the group summary
  @observable popularPatients: ObservablePatient[] = [];
  @observable private _totalPatients: number = 0;
  @observable private _archivedPatients: number = 0;

  constructor(group: Group | GroupSummary) {
    this.id = group.id;
    this.name = group.name;
    this.owner = group.owner;
    this.isArchived = group.isArchived;
    this.sharedPersonal = observable.array(group.shared);
    this.isLoaded = false;
    this.sections = observable.array(group.sections.map((s) => new ObservableSection(s)));
    if ("totalPatients" in group) this._totalPatients = group.totalPatients;
    if ("popularPatients" in group) this.popularPatients = group.popularPatients.map((p) => patientsStore.subscribe(p));
    if ("archivedPatients" in group) this._archivedPatients = group.archivedPatients;
    makeObservable(this);
  }

  @computed get totalPatients() {
    if (this.isLoaded) return this.sections.reduce((acc, s) => acc + s.patients.length, 0);
    return this._totalPatients;
  }

  @computed get archivedPatients() {
    return this.sections.flatMap((s) => s.patients.filter((p) => p.isArchived));
  }

  @computed get totalArchivedPatients() {
    if (this.isLoaded) {
      return this.sections.reduce((acc, s) => acc + s.patients.filter((p) => p.isArchived).length, 0);
    }
    return this._archivedPatients;
  }

  @computed get sortedSections() {
    return this.sections?.toSorted((a, b) => a.position - b.position);
  }

  @action mutate(group: Group | GroupSummary, isValid: boolean) {
    this.id = group.id;
    this.name = group.name;
    this.owner = group.owner;
    this.isArchived = group.isArchived;
    this.sharedPersonal.replace(group.shared);
    this.isLoaded = isValid;
    this.sections = observable.array(group.sections.map((s) => new ObservableSection(s)));
    if ("totalPatients" in group) this._totalPatients = group.totalPatients;
    if ("popularPatients" in group) this.popularPatients = group.popularPatients.map((p) => patientsStore.subscribe(p));
    if ("archivedPatients" in group) this._archivedPatients = group.archivedPatients;
    return this;
  }

  @action update(data: Group) {
    this.name = data.name;
    this.owner = data.owner;
    this.isArchived = data.isArchived;
    return this;
  }

  isOwner(user: User | UserAuth) {
    return this.owner.id === user.id;
  }

  isAdmin(user: User | UserAuth) {
    return this.findUser(user)?.access.role === "admin";
  }

  isShared(user: User | UserAuth) {
    return this.findUser(user) !== undefined && !this.isOwner(user);
  }

  hasPrivileges(user: User | UserAuth) {
    return this.isOwner(user) || this.isAdmin(user);
  }

  canEdit(user: User | UserAuth) {
    return this.isOwner(user) || this.isAdmin(user) || this.findUser(user)?.access.role === "editor";
  }

  private findUser(user: User | UserAuth) {
    return this.sharedPersonal.find((s) => s.user.id === user.id);
  }
}

class GroupsStore {
  @observable _groups: ObservableMap<string, ObservableGroup>;
  @observable isLoaded: boolean = false;

  constructor() {
    this._groups = observable.map<string, ObservableGroup>();
    makeObservable(this);
  }

  find(id: string) {
    return this._groups.get(id);
  }

  findBySection(sectionId: string): ObservableGroup | null {
    for (const group of this.groups) {
      const section = group.sections.find((s) => s.id === sectionId);
      if (section) return group;
    }
    return null;
  }

  findSection(sectionId: string): ObservableSection | null {
    for (const group of this.groups) {
      const section = group.sections.find((s) => s.id === sectionId);
      if (section) return section;
    }
    return null;
  }

  // WARNING: does this need to be a computed property?
  get groups() {
    return Array.from(this._groups.values());
  }

  @computed get archivedGroups() {
    return this.groups.filter((g) => g.isArchived);
  }

  @computed get activeGroups() {
    return this.groups.filter((g) => !g.isArchived);
  }

  @action save(group: ObservableGroup) {
    this._groups.set(group.id, group);
    return group;
  }

  @action delete(group: ObservableGroup) {
    return this._groups.delete(group.id);
  }

  @action clearStore() {
    this._groups.clear();
    this.isLoaded = false;
  }

  @action subscribe(group: Group) {
    const cachedGroup = this.find(group.id);
    if (cachedGroup) {
      return cachedGroup.update(group);
    }
    return this.save(new ObservableGroup(group));
  }

  @action async loadGroup(
    groupId: string,
    opts: {
      cache: boolean;
    } = { cache: true },
  ): Promise<ObservableGroup> {
    const cachedGroup = this.find(groupId);
    if (opts?.cache && cachedGroup?.isLoaded) {
      return cachedGroup;
    }

    const groupResult = await groupRepository.get(groupId);
    const newGroup = new ObservableGroup(groupResult);

    runInAction(() => {
      if (cachedGroup) {
        cachedGroup.mutate(groupResult, true);
      } else {
        newGroup.isLoaded = true;
        this.save(newGroup);
      }
    });

    if (cachedGroup) {
      return cachedGroup;
    }

    return newGroup;
  }

  @action async loadGroups(
    opts: {
      cache: boolean;
    } = { cache: true },
  ): Promise<Array<ObservableGroup>> {
    const cachedGroups = this.groups;
    if (opts?.cache && this.isLoaded) {
      return cachedGroups;
    }

    const groups = await groupRepository.groupsSummary();

    runInAction(() => {
      // const toDelete = this.groups.filter((g) => !groups.some((ng) => ng.id === g.id));
      // for (const group of toDelete) {
      //   this.delete(group);
      // }

      this._groups.clear();
      for (const group of groups) {
        this.save(new ObservableGroup(group));
      }

      // for (const group of groups) {
      //   const cachedGroup = this.find(group.id);
      //   if (cachedGroup) {
      //     cachedGroup.mutate(group, false);
      //   } else {
      //     this.save(new ObservableGroup(group));
      //   }
      // }

      this.isLoaded = true;
    });

    return this.groups;
  }

  @action async loadGroupsByOwner(ownerId: string): Promise<Array<ObservableGroup>> {
    const groups = await groupRepository.listGroupsByOwner(ownerId);

    const observableGroups: ObservableGroup[] = [];

    runInAction(() => {
      for (const group of groups) {
        const cachedGroup = this.find(group.id);
        if (cachedGroup) {
          observableGroups.push(cachedGroup.update(group));
        } else {
          observableGroups.push(this.save(new ObservableGroup(group)));
        }
      }
    });

    return this.activeGroups;
  }

  @action
  async createGroup(group: CreateGroup) {
    const newGroup = await groupRepository.createGroup(group);
    const obsGroup = new ObservableGroup(newGroup);

    runInAction(() => {
      this.save(obsGroup);
    });

    return obsGroup;
  }

  @action
  async updateGroup(group: ObservableGroup, data: UpdateGroup) {
    const updatedGroup = await groupRepository.updateGroup(group.id, data);

    runInAction(() => {
      group.update(updatedGroup);
    });

    return group;
  }

  @action
  async shareWithUsers(group: ObservableGroup, users: UserShareAction[]) {
    await groupRepository.shareGroupWithUsers(group.id, users);

    runInAction(() => {
      // Remove deleted items
      const idsToDelete = users.filter((gs) => gs.action === "delete").map((gs) => gs.user.id);
      group.sharedPersonal.replace(group.sharedPersonal.filter((gs) => !idsToDelete.includes(gs.user.id)));

      // Apply updates
      users.forEach((u) => {
        if (u.action === "update") {
          const sp = group.sharedPersonal.find((item) => item.user.id === u.user.id);
          if (sp) {
            sp.access.id = u.access.id;
            sp.access.role = u.access.role;
          }
        }
      });
    });

    return group;
  }

  @action async updateSections(group: ObservableGroup, sections: UpdateSectionWithId[]) {
    await groupRepository.updateSections(group.id, sections);

    runInAction(() => {
      sections.forEach((sectionUpdate) => {
        const section = group.sections.find((s) => s.id === sectionUpdate.id);
        if (section) {
          section.position = sectionUpdate.position;
        }
      });
    });

    return group;
  }

  @action
  async reopenGroup(group: ObservableGroup) {
    return this.updateGroup(group, { isArchived: false });
  }

  @action
  async removeGroup(group: ObservableGroup) {
    try {
      await groupRepository.deleteGroup(group.id);
      runInAction(() => {
        this.delete(group);
      });
    } catch (error) {
      runInAction(() => {
        this.delete(group);
      });
    }

    return group;
  }

  //
  // Sections
  //

  @action
  async createSection(group: ObservableGroup, section: CreateSection) {
    const newSection = await groupRepository.createSection(section);

    runInAction(() => {
      group.sections.push(new ObservableSection(newSection));
    });

    return newSection;
  }

  @action
  async updateSection(section: ObservableSection, data: UpdateSection) {
    const updatedSection = await groupRepository.updateSection(section.id, data);

    runInAction(() => {
      if (section) {
        section.name = updatedSection.name;
        section.position = updatedSection.position;
      }
    });

    return updatedSection;
  }

  @action
  async deleteSection(group: ObservableGroup, sectionId: string) {
    try {
      const result = await groupRepository.deleteSection(sectionId);

      const section = group.sections.find((s) => s.id === sectionId);
      if (section) {
        runInAction(() => group.sections.remove(section));
      }

      return result;
    } catch (error) {
      if (error instanceof ErrorGroupSectionNotFound) {
        const section = group.sections.find((s) => s.id === sectionId);
        if (section) {
          runInAction(() => group.sections.remove(section));
        }
      }
      throw error;
    }
  }

  //
  // Group Shared
  //

  @action
  async leaveGroup(group: ObservableGroup, user: UserAuth) {
    const access = group.sharedPersonal.find((gs) => gs.user.id === user.id);
    if (!access) {
      throw new Error("<leaveGroup> user does not have access to this group");
    }

    const ok = await groupRepository.leaveGroup(access.id);
    if (!ok) {
      throw new Error("<leaveGroup> failed to leave group");
    }

    runInAction(() => {
      this.delete(group);
    });

    return group;
  }
}

export const groupStore = new GroupsStore();
