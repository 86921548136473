import type { Schema, z } from "zod";
import { IS_DEV } from "../../constants";

export const createUnsafeParse = (condition: boolean) => {
  const fn = <T extends Schema>(schema: T, value: any): z.infer<T> => {
    // We are going to remove unsafeParse later on...
    // if (!condition) return value;
    return schema.parse(value);
  };
  return fn;
};

// Parses on DEV, returns the value on PROD
export const unsafeParse = createUnsafeParse(IS_DEV);
