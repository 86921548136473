import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { ObservableGroup, groupStore } from "../stores/groups.store";
import { Task } from "@lit/task";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { when } from "lit/directives/when.js";
import { alertController, popoverController } from "@ionic/core";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("page-groups-archived")
@localized()
export class PageGroupsArchived extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;

  #groups = new Task(this, {
    task: () => {
      return groupStore.loadGroups();
    },
    args: () => [],
  });

  #ionRefresher: Ref<IonRefresher> = createRef();

  async #alertReopenGroup(group: ObservableGroup) {
    // close all popovers
    await popoverController.dismiss();

    // open alert
    const alert = await alertController.create({
      header: msg("Reabrir grupo"),
      message: msg("Tem a certeza que pretende reabrir este grupo?"),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Reabrir"),
          handler: async () => {
            try {
              await groupStore.updateGroup(group, {
                isArchived: false,
              });
            } catch (error) {
              if (error instanceof ApiError) {
                await alertController
                  .create({
                    header: msg("Erro"),
                    message: error.message,
                    buttons: ["Ok"],
                  })
                  .then(async (alert) => await alert.present());
              }
            }
          },
        },
      ],
    });

    await alert.present();
  }

  async #alertRemoveGroup(group: ObservableGroup) {
    // close all popovers
    await popoverController.dismiss();

    // open alert
    const alert = await alertController.create({
      header: msg("Remover grupo"),
      message: msg("Tem a certeza que pretende remover este grupo?"),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Remover"),
          role: "destructive",
          handler: async () => {
            await groupStore.removeGroup(group);
          },
        },
      ],
    });

    await alert.present();
  }

  async #alertLeaveGroup(group: ObservableGroup) {
    // close all popovers
    await popoverController.dismiss();

    // open alert
    const alert = await alertController.create({
      header: msg("Sair do grupo"),
      message: msg("Tem a certeza que pretende sair deste grupo?"),
      buttons: [
        {
          text: msg("Cancelar"),
          role: "cancel",
        },
        {
          text: msg("Sair"),
          role: "destructive",
          handler: async () => {
            return groupStore.leaveGroup(group, this.auth.user);
          },
        },
      ],
    });

    await alert.present();
  }

  render() {
    return html`
      <!-- HEADER -->
      <ion-header>
        <ion-toolbar>
          <div class="w-full flex mb-4">
            <ion-button
              @click=${() => this.router.back()}
              style="--padding-start: 10px; --padding-end: 10px; --padding-top: 6px; --padding-bottom: 6px;"
              color="primary"
              shape="round"
              class="mx-auto"
              size="xsmall">
              <span class="mr-1 mt-px">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path
                    d="M7.99967 10L5.99967 8.00002M5.99967 8.00002L7.99967 6.00002M5.99967 8.00002H13.333M9.99967 4.83241V4.80015C9.99967 4.05341 9.99967 3.67977 9.85435 3.39455C9.72652 3.14367 9.52269 2.93984 9.27181 2.81201C8.98659 2.66669 8.61295 2.66669 7.86621 2.66669H4.79954C4.05281 2.66669 3.67996 2.66669 3.39475 2.81201C3.14386 2.93984 2.93936 3.14367 2.81152 3.39455C2.66634 3.67949 2.66634 4.05268 2.66634 4.79796V11.2024C2.66634 11.9477 2.66634 12.3203 2.81152 12.6053C2.93936 12.8562 3.14386 13.0603 3.39475 13.1882C3.67968 13.3334 4.05234 13.3334 4.79761 13.3334H7.8684C8.61368 13.3334 8.98687 13.3334 9.27181 13.1882C9.52269 13.0603 9.72652 12.856 9.85435 12.6051C9.99967 12.3199 9.99967 11.9468 9.99967 11.2V11.1667"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg(str`Grupos Arquivados (${groupStore.archivedGroups.length})`)}
            </ion-button>
          </div>
        </ion-toolbar>
      </ion-header>
      <!-- HEADER -->
      <ion-content fullscreen style="--padding-bottom: 24px">
        <!-- REFRESHER -->
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            await groupStore.loadGroups({ cache: false });
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        <!-- CONTENT -->

        <div class="mt-4">
          <!-- GROUPS -->

          ${this.#groups.render({
            pending: () => html`
              <div class="flex justify-center items-center py-8">
                <ion-spinner name="lines-sharp-small"></ion-spinner>
              </div>
            `,
            error: (error) => {
              console.error(error);
              return html`<div
                class="mt-4 h-[139px] bg-dangerLighter rounded-md col justify-center items-center space-y-1 text-accent-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#ee0000"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round">
                  <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3" />
                  <path d="M12 9v4" />
                  <path d="M12 17h.01" />
                </svg>
                <span class="font-display font-semibold text-center text-danger" style="max-inline-size: 20ch">
                  ${msg("Erro ao carregar os grupos arquivados")}
                </span>
              </div>`;
            },
            complete: () => {
              const groups = groupStore.archivedGroups;
              return when(
                groups.length === 0,
                () => html`
                  <div
                    class="col space-y-1 items-center justify-center font-display font-semibold h-36 w-full whitespace-normal rounded-md bg-accent-7 text-accent-1">
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path
                          d="M12.5 11V16M12.5 21C7.52944 21 3.5 16.9706 3.5 12C3.5 7.02944 7.52944 3 12.5 3C17.4706 3 21.5 7.02944 21.5 12C21.5 16.9706 17.4706 21 12.5 21ZM12.5498 8V8.1L12.4502 8.1002V8H12.5498Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                    </span>
                    <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                      ${msg("Não existem grupos arquivados")}</span
                    >
                  </div>
                `,
                () => html`
                  <div class="space-y-4">
                    ${groups.map(
                      (group, index) => html`
                        <div class="space-y-2">
                          <div class="flex justify-between items-center">
                            <div class="flex items-center space-x-2">
                              <h6 class="line-through">${group.name}</h6>
                              <ion-chip style="--background: #EAEAEA; --color: #888888"
                                >${msg(str`${group.totalPatients} utentes`)}</ion-chip
                              >
                            </div>
                            <ion-button
                              @click="${(e: Event) => (e.stopPropagation(), e.preventDefault())}"
                              id="${index}-archived-popover"
                              fill="clear"
                              size="chip">
                              <ion-icon slot="icon-only" icon="/assets/icons/dots.svg"></ion-icon>
                            </ion-button>
                            <ion-popover
                              arrow
                              alignment="center"
                              reference="trigger"
                              side="bottom"
                              style="--max-width: 140px; --offset-x: -8px; --backdrop-opacity: 0.3;"
                              trigger="${index}-archived-popover">
                              <div class="p-0">
                                <ion-list>
                                  ${when(
                                    group.hasPrivileges(this.auth.user),
                                    () => html`
                                      <ion-item
                                        button
                                        class="no-p no-detail text-sm"
                                        @click=${() => this.#alertReopenGroup(group)}>
                                        <ion-label class="font-semibold pl-3">${msg("Reabrir")}</ion-label>
                                      </ion-item>
                                      <ion-item
                                        button
                                        lines="none"
                                        class="no-p no-detail text-sm"
                                        @click=${() => this.#alertRemoveGroup(group)}>
                                        <ion-label class="font-semibold pl-3">${msg("Remover")}</ion-label>
                                      </ion-item>
                                    `,
                                    () => html`
                                      <ion-item
                                        button
                                        class="no-p no-detail text-sm"
                                        @click=${() => this.#alertLeaveGroup(group)}>
                                        <ion-label class="font-semibold pl-3">${msg("Sair")}</ion-label>
                                      </ion-item>
                                    `,
                                  )}
                                </ion-list>
                              </div>
                            </ion-popover>
                          </div>
                          <ion-list lines="none" class="space-y-2.5 flex flex-col">
                            ${group.popularPatients?.map(
                              (patient) => html`
                                <ion-item
                                  @click=${() => this.router.goto(`groups/archived/patient/${patient.id}`)}
                                  button
                                  .detail=${false}
                                  class="no-p flex space-x-2 items-center">
                                  <xt-avatar src=${patient.avatar} name=${patient.name}></xt-avatar>
                                  <div class="col">
                                    <p class="font-semibold">${patient.name}</p>
                                    <div class="flex items-center space-x-1">
                                      ${patient?.categories?.map(
                                        (category) => html`
                                          <ion-chip class="px-2 w-fit" style="--background: ${category.color};"
                                            >${category?.category}</ion-chip
                                          >
                                        `,
                                      )}
                                    </div>
                                  </div>
                                </ion-item>
                              `,
                            )}
                          </ion-list>
                          <div>
                            <ion-router-link href="/groups/archived/${group.id}" router-direction="forward">
                              <ion-button color="secondary" expand="block" shape="round" size="small">
                                ${msg("Ver todos")}
                              </ion-button>
                            </ion-router-link>
                          </div>
                        </div>
                        <div class="h-px w-full bg-accent-7 last:hidden"></div>
                      `,
                    )}
                  </div>
                `,
              );
            },
          })}

          <!-- GROUPS -->
        </div>

        <!-- CONTENT -->
      </ion-content>
    `;
  }

  getDivider(index: number, array: any[]) {
    return index !== array.length - 1 ? html` <div class="h-px w-full bg-accent-7"></div> ` : html``;
  }
}
