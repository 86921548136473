import { customElement, property } from "lit/decorators.js";
import { html, type PropertyValues } from "lit";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { IonNav } from "@ionic/core/components/ion-nav";
import type { ComponentProps } from "@ionic/core";
import type { IonModal } from "@ionic/core/components/ion-modal";
import { LightElement } from "../components/component";

@customElement("modal-sheet-nav")
export class ModalSheetNav extends LightElement {
  @property({ type: Boolean }) private _isOpen = false;
  @property({ type: String }) root = "page-empty";
  @property({ type: Boolean, attribute: "handle" }) handle = true;
  @property({ type: Boolean, attribute: "auto-height" }) autoHeight = false;
  @property({ type: Number, attribute: "border-radius" }) borderRadius = 16;
  @property({ type: Number, reflect: true, attribute: "initial-breakpoint" }) initialBreakpoint = 1;
  @property({ type: Array, reflect: true, attribute: "breakpoints" }) breakpoints: Array<number> = [0, 1];

  @property({ type: Function }) onClose?: () => void;
  @property({ type: Function }) onWillClose?: (e: Event) => void;
  @property({ type: Function }) onOpen?: () => void;
  @property({ type: Function }) onWillOpen?: (e: Event) => void;

  #navigator: Ref<IonNav> = createRef();
  #modal: Ref<IonModal> = createRef();

  async firstUpdated(props: PropertyValues) {
    super.firstUpdated(props);

    // This is currently only for browser
    //
    // On device I can access the ionKeyboardWillShow event, thus making it more
    // instant and without the need to wait for the animation to finish to move
    // the modal
    //
    // Ref: <https://github.com/ionic-team/ionic-framework/issues/20922#issuecomment-610465908>
    //
    const modal = this.#modal.value;
    window.addEventListener("ionKeyboardDidShow", async (e) => {
      const { keyboardHeight } = (e as any).detail;
      if (modal) {
        const bp = await modal.getCurrentBreakpoint();
        if (bp !== 1) {
          modal.style.transform = `translate3d(0, -${keyboardHeight}px, 0)`;
        }
      }
    });

    window.addEventListener("ionKeyboardDidHide", () => {
      if (modal) {
        modal.style.transform = `translate3d(0, 0, 0)`;
      }
    });
  }

  async open(componentProps?: ComponentProps) {
    this._isOpen = true;
    await this.#navigator.value?.setRoot(this.root, componentProps);
  }

  async close() {
    if (this.onClose) {
      this.onClose();
    }
    this._isOpen = false;
  }

  render() {
    if (this.autoHeight) {
      return html`
        <ion-modal
          class="auto-height"
          handle=${this.handle}
          initial-breakpoint="1"
          .breakpoints=${[0, 1]}
          .isOpen="${this._isOpen}"
          @ionModalDidDismiss=${this.close}
          @ionModalWillDismiss=${this.onWillClose}
          @ionModalDidPresent=${this.onOpen}
          @ionModalWillPresent=${this.onWillOpen}
          style="--border-radius: ${this.borderRadius}px"
          ${ref(this.#modal)}>
          <ion-nav-context>
            <ion-nav ${ref(this.#navigator)}></ion-nav>
          </ion-nav-context>
        </ion-modal>
      `;
    }

    return html`
      <ion-modal
        initial-breakpoint=${this.initialBreakpoint}
        .breakpoints=${this.breakpoints}
        .isOpen="${this._isOpen}"
        handle=${this.handle}
        @ionModalDidDismiss=${this.close}
        @ionModalWillDismiss=${this.onWillClose}
        @ionModalDidPresent=${this.onOpen}
        @ionModalWillPresent=${this.onWillOpen}
        style="--border-radius: ${this.borderRadius}px"
        ${ref(this.#modal)}>
        <ion-nav-context>
          <ion-nav ${ref(this.#navigator)}></ion-nav>
        </ion-nav-context>
      </ion-modal>
    `;
  }
}
