import { z, type ZodRawShape } from "zod";

export const RecordSchema = z.object({
  id: z.string(),
  collectionName: z.string(),
  collectionId: z.string(),
  created: z.string(),
  updated: z.string(),
  expand: z.record(z.any()).optional(),
});

export function record<T extends ZodRawShape>(schemaShape: T) {
  return RecordSchema.extend(schemaShape);
}
