import PocketBase, { ClientResponseError } from "pocketbase";

export const isUrl = (url: string) => {
  if (!url) {
    return false;
  }
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

const pocketbaseUrl: string = (() => {
  const localUrl = import.meta.env.VITE_API_URL;
  const tunnelUrl = import.meta.env.VITE_API_URL_CLOUDFLARED;

  // check if we're running in localhost from the browser
  const isLocalhost = window.location.hostname === "localhost";

  if (!isLocalhost && tunnelUrl) {
    if (!isUrl(tunnelUrl)) {
      throw new Error("VITE_API_URL_CLOUDFLARED must be a valid URL");
    }
    return tunnelUrl;
  }

  if (!isUrl(localUrl)) {
    throw new Error("VITE_API_URL must be a valid URL");
  }
  return localUrl;
})();

export const pb = new PocketBase(pocketbaseUrl);

// disable auto cancellation since it's just slowing us down, we'll handle
// it ourselves with AbortController and signal
pb.autoCancellation(false);

export type Result<A, B> = Promise<
  | {
      data: A;
      error: null;
    }
  | {
      data: null;
      error: B;
    }
>;

export const defer = async <A, B = ClientResponseError>(promise: Promise<A> | (() => Promise<A>)): Result<A, B> => {
  try {
    const result = await (typeof promise === "function" ? promise() : promise);
    return {
      data: result,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: e as B,
    };
  }
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const isOk = <T, E>(result: { data: T | null; error: E | null }): result is { data: T; error: null } => {
  return result.data !== null && result.error == null;
};

export const expand = (...args: string[]): string => {
  return args.join(",");
};

export const expandAt = <T = any>(item: any, key: string): T[] => {
  if (!Array.isArray(item.expand?.[key])) {
    if (item.expand?.[key] === undefined) {
      return [];
    }

    return [item.expand?.[key]];
  }

  return item.expand?.[key];
};

export const withThumb = <T extends Record<string, unknown>>(
  record: T,
  key: keyof T,
  thumb: `${number}x${number}` = "128x128", // maybe we should use a default of 64x64?
): T => {
  const value = record[key];

  if (!record.collectionId || !record.id) {
    throw new Error("withThumb requires a valid record with collectionId and id");
  }

  if (typeof value !== "string") {
    throw new Error("withThumb requires a valid record with a string value");
  }

  if (isUrl(value)) {
    return record; // the avatar is already a url
  }

  // @ts-ignore
  record[key] = pb.files.getUrl(record, value, {
    thumb: thumb,
  });

  return record;
};

export const ERROR_NOT_UNIQUE = "validation_not_unique";
export const ERROR_MISSING_REL = "validation_missing_rel_records";

// omit collectioName, expand, collectionId, create, update
export type NonRecord<T> = Omit<T, "collectionName" | "expand" | "collectionId" | "created" | "updated">;

export type WithPage<T> = {
  items: T;
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
}
