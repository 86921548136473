import { customElement, state } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { FinalFormController, zodValidator } from "../../controllers/final-form.controller";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import { CreateEvaluationFieldScaleSchema, type CreateEvaluationFieldScale } from "../../repository/report/report";
import { alertController } from "@ionic/core";
import type { ObservableEvaluationSection } from "../../stores/evaluation.store";
import { reportsStore } from "../../stores/reports.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../../error";

@customElement("modal-scale")
@localized()
export class ModalScale extends Page {
  section!: ObservableEvaluationSection;
  @state() private selectedValue: string = "";
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  connectedCallback(): void {
    super.connectedCallback();
    document.addEventListener("choose-scale", (event: Event) => {
      const scale = (event as CustomEvent).detail;
      this.changeScaleInputValue(scale.name, scale.id);
    });
  }

  #form = new FinalFormController<CreateEvaluationFieldScale>(this, {
    validate: zodValidator(CreateEvaluationFieldScaleSchema().pick({ scale: true })),
    onSubmit: async (values) => {
      try {
        const data: CreateEvaluationFieldScale = {
          scale: values.scale,
          section: this.section.id,
          evaluation: this.section.evaluation,
        };
        await reportsStore.insertEvaluationFieldScale(data, this.section);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  changeScaleInputValue(value: string, formValue: string) {
    this.selectedValue = value;
    this.#form.form.change("scale", formValue);
  }

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
              <span class="mt-[3px] -ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M14 16L10 12L14 8"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </span>
              ${msg("voltar")}</ion-button
            >
          </ion-buttons>
          <ion-title class="font-display text-lg">${msg("Escala")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen>
        <form
          id="form-url"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list class="space-y-4" lines="none">
            <div>
              <span class="font-semibold font-display">${msg("Escala a escolher")}</span>
              <ion-item class="mt-1">
                <ion-input
                  type="text"
                  placeholder=${msg("Clique aqui para selecionar")}
                  readonly
                  value=${this.selectedValue}
                  @click=${() =>
                    this.navigator.push("modal-choose-scale", { registerScale: this.changeScaleInputValue })}
                  ${register("scale")}>
                </ion-input>
              </ion-item>
              ${renderError("scale")}
            </div>
          </ion-list>

          <ion-button
            form="form-url"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Adicionar")}</ion-button
          >
        </form>
      </ion-content>
    `;
  }
}
