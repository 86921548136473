import { html } from "lit";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, NavigatorController } from "../controllers/navigator.controller";
import { Task } from "@lit/task";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { type Ref, createRef, ref } from "lit/directives/ref.js";
import { Page, required } from "../components/component";
import { customElement } from "../element";
import { templatesStore } from "../stores/templateExercisePlan.store";
import { sessionsStore, type ObservableSession } from "../stores/sessions.store";
import { alertController } from "@ionic/core";
import { localized, msg, str } from "@lit/localize";
import { ApiError } from "../error";

@customElement("modal-choose-template")
@localized()
export class ModalChooseTemplate extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #ionRefresher: Ref<IonRefresher> = createRef();

  @required() session!: ObservableSession;

  connectedCallback() {
    super.connectedCallback();
    this.#loadTemplates.run([true]);
  }

  #loadTemplates = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return await templatesStore.loadTemplates({ cache: cache });
    },
  });

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                fill="clear"
                class="font-semibold no-p no-inline-p no-m-inline no-m"
                @click="${() => {
                  this.navigator.goBack();
                }}">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title class="font-display font-semibold text-lg">${msg("Escolher Template")}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="space-y-4" fullscreen>
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            if (!this.auth.user) return;
            await this.#loadTemplates.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        <!-- -------------- CONTENT -------------- -->
        ${this.#loadTemplates.render({
          pending: () => {
            return html`
              <div class="space-y-4">
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
                <ion-skeleton-text animated style="width: 100%; height: 140px"></ion-skeleton-text>
              </div>
            `;
          },
          error: () => {
            return html` <span class="text-danger">${msg("Erro ao carregar as templates")}</span> `;
          },
          complete: (templates) => {
            return html`
              <ion-list lines="none" class="space-y-4">
                ${templates.map((template) => {
                  return html`
                    <ion-item
                      button
                      .detail=${false}
                      style="--border-width: 1px; --border-color: #EAEAEA; --border-radius: 6px"
                      class="no-p no-inner-p"
                      @click=${async () => {
                        alertController
                          .create({
                            header: msg("Inserir Template"),
                            message: msg(
                              "Deseja realmente inserir este template na sessão? (todos os exercícios serão substituídos)",
                            ),
                            buttons: [
                              {
                                text: msg("Cancelar"),
                                role: "cancel",
                              },
                              {
                                text: msg("Inserir"),
                                role: "confirm",
                                handler: async () => {
                                  try {
                                    await sessionsStore.injectTemplate(this.session, template);
                                    this.navigator.goBack();
                                  } catch (error) {
                                    if (error instanceof ApiError) {
                                      alertController
                                        .create({
                                          header: msg("Erro"),
                                          message: error.message,
                                          buttons: [
                                            {
                                              text: msg("Ok"),
                                              role: "cancel",
                                            },
                                          ],
                                        })
                                        .then((alert) => alert.present());
                                    }
                                  }
                                },
                              },
                            ],
                          })
                          .then((alert) => alert.present());
                      }}>
                      <div class="w-full h-[140px] bg-accent-8 flex col justify-center items-center space-y-0.5">
                        <p class="font-display font-semibold text-accent-1">${template.name}</p>
                        <ion-badge class="px-1.5 py-0.5 text-xs font-semibold"
                          >${msg(str`${template.totalExercises} exercícios`)}</ion-badge
                        >
                      </div>
                    </ion-item>
                  `;
                })}
              </ion-list>
            `;
          },
        })}
      </ion-content>
    `;
  }
}
