import { msg, str } from "@lit/localize";
import { ApiError } from "../../error";


export class ErrorGroupNotFound extends ApiError {
  constructor() {
    super("group-not-found", msg("Grupo não encontrado"));
  }
}

export class ErrorGroupNotUnique extends ApiError {
  constructor() {
    super("group-not-unique", msg("Já existe um grupo com o mesmo nome"));
  }
}

export class ErrorGroupNameInvalid extends ApiError {
  constructor() {
    super("group-name-invalid", msg("O nome do grupo é inválido"));
  }
}

export class ErrorGroupSectionNotFound extends ApiError {
  constructor() {
    super("group-section-not-found", msg("Secção não encontrada"));
  }
}

export class ErrorGroupSectionNotUnique extends ApiError {
  constructor() {
    super("group-section-not-unique", msg("Já existe uma secção com o mesmo nome"));
  }
}

export class ErrorGroupCategoryExists extends ApiError {
  constructor() {
    super("group-category-exists", msg("A categoria já existe neste grupo"));
  }
}

export class ErrorGroupCategoryLimitReached extends ApiError {
  constructor() {
    super("group-category-limit-reached", msg("O limite de categorias foi atingido"));
  }
}

export class ErrorGroupInviteExists extends ApiError {
  constructor(name?: string) {
    super("group-invite-exists", msg(str`O utilizador ${name} já foi convidado para o grupo`));
  }
}