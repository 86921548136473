import { msg, str } from "@lit/localize";
import { ApiError } from "../../error";

export class ErrorTemplateLibraryNotFound extends ApiError {
  public constructor() {
    super("template-library-not-found", msg("A biblioteca de templates não foi encontrada"));
  }
}

export class ErrorTemplateInviteExists extends ApiError {
  constructor(name?: string) {
    super("template-invite-exists", msg(str`O utilizador ${name} já foi convidado para a biblioteca de templates`));
  }
}

export class ErrorTemplateNotFound extends ApiError {
  constructor() {
    super("template-not-found", msg("Template não encontrado"));
  }
}