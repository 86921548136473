import { action, observable, runInAction } from "mobx";

interface Record {
  id: string;
}

export class SelectStore<T extends Record> {
  @observable private _selected = observable.array<T>([]);
  @observable private _staged = observable.array<T>([]);
  private isMultiple: boolean;

  constructor(initial: T[] = [], isMultiple: boolean = false) {
    this._selected.replace(initial);
    this.isMultiple = isMultiple;
  }

  get selected(): T[] {
    return this._selected;
  }

  get staged(): T[] {
    return this._staged;
  }

  isSelected(item: T): boolean {
    return this._selected.some((selected) => selected.id === item.id) || this.isStaged(item);
  }

  isStaged(item: T): boolean {
    return this._staged.some((staged) => staged.id === item.id);
  }

  @action add(item: T): void {
    runInAction(() => {
      if (!this.isMultiple) {
        this._selected.replace([item]);
      } else {
        this._selected.push(item);
      }
    });
  }

  @action remove(item: T): void {
    this._selected.remove(item);
  }

  @action toggle(item: T): void {
    if (this.isSelected(item)) {
      this.remove(item);
    } else {
      this.add(item);
    }
  }

  @action clear(): void {
    this._selected.clear();
    this._staged.clear();
  }

  @action stage(item: T): void {
    runInAction(() => {
      if (!this.isMultiple) {
        this._staged.replace([item]);
      } else {
        if (this.isStaged(item)) {
          this._staged.remove(item);
        } else {
          this._staged.push(item);
        }
      }
    });
  }

  @action commit(): void {
    runInAction(() => {
      if (!this.isMultiple) {
        if (this._staged.length > 0) {
          const firstStaged = this._staged[0];
          if (firstStaged) {
            this._selected.replace([firstStaged]);
          }
        }
      } else {
        const itemsToAdd = this._staged.filter(
          (staged) => !this._selected.some((selected) => selected.id === staged.id),
        );
        this._selected.push(...itemsToAdd);
      }
      this._staged.clear();
    });
  }

  @action rollback(): void {
    this._staged.clear();
  }

}
