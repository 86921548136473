import { state } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { NavigatorController, navigatorContext } from "../controllers/navigator.controller";
import type { ChangeEvent } from "../type";
import { Task } from "@lit/task";
import { pb } from "../pocketbase";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { z } from "zod";
import { when } from "lit/directives/when.js";
import { authContext, type AuthContext } from "../context/auth.context";
import { classMap } from "lit/directives/class-map.js";
import { Page, required } from "../components/component";
import { UserSchema, createUser, type User } from "../repository/user/user";
import type { UserShareStore } from "../stores/userShare.store";
import { customElement } from "../element";
import { AccessRoleSchema, createAccessFromRole } from "../repository/group/access";
import { localized, msg } from "@lit/localize";

const formSchema = z.object({
  user: UserSchema,
  access: AccessRoleSchema,
});

type FormValues = z.infer<typeof formSchema>;

@customElement("modal-share-add")
@localized()
export class ModalShareAdd extends Page {
  @state() private query = "";

  @consume({ context: navigatorContext }) private navigator!: NavigatorController;
  @consume({ context: authContext }) private auth!: AuthContext;

  @required({ type: Object }) shareStore!: UserShareStore;

  #controller = new FinalFormController<FormValues>(this, {
    validate: zodValidator(formSchema),
    onSubmit: async (values) => {
      if (values.user) {
        this.shareStore.insert({
          user: values.user,
          access: createAccessFromRole(values.access),
        });
        this.navigator.goBack();
      }
    },
  });

  #users = new Task(this, {
    task: async ([query], { signal }) => {
      if (query === "") {
        return [];
      }

      const data = await pb.collection("users_by_username").getList(1, 5, {
        filter: `(username ~ "${query}" || name ~ "${query}") && id != "${this.auth.user?.id}"`,
        signal,
      });

      return data.items.map((item) => createUser(item));
    },
    args: () => [this.query],
  });

  selectUser(user: User) {
    this.#controller.form.change("user", user);
    this.query = "";
  }

  removeUser() {
    this.#controller.form.change("user", undefined);
  }

  #hasResults() {
    if (!this.#users.value) {
      return false;
    }
    return this.#users.value?.length > 0;
  }

  render() {
    const { form, register, renderError, submit } = this.#controller;
    const formState = form.getState();

    const selectedUser = formState.values.user;

    return html`
      <ion-content fullscreen>
        <!-- HEADER -->
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  @click=${() => this.navigator.goBack()}
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">${msg("Adicionar Profissional")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <!-- HEADER -->

        <form class="mt-4" id="form-add-share" @submit=${submit}>
          ${when(
            selectedUser,
            () => {
              return html`
                <div>
                  <span class="font-semibold font-display">${msg("Utilizador")}</span>
                  <ion-item class="no-p mt-1 no-p no-inline-p no-inner-p" lines="none">
                    <div class="flex items-center space-x-2">
                      <xt-avatar src=${selectedUser?.avatar} name=${selectedUser?.name}></xt-avatar>
                      <div class="col items-start">
                        <p class="font-semibold">${selectedUser?.name}</p>
                        <span class="text-accent-5">@${selectedUser?.username}</span>
                      </div>
                    </div>
                    <ion-button @click=${this.removeUser} slot="end" shape="round" size="chip">
                      <span class="flex items-center mr-0.5">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 12L8.00001 8.00001M8.00001 8.00001L4 4M8.00001 8.00001L12 4M8.00001 8.00001L4 12"
                            stroke="white"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                      </span>
                      ${msg("Remover")}
                    </ion-button>
                  </ion-item>
                </div>
              `;
            },

            () => html`
              <div>
                <span class="font-semibold font-display">${msg("Utilizador")}</span>
                <ion-item class="mt-1" ${register("user")}>
                  <ion-input
                    type="text"
                    name="username"
                    debounce="150"
                    placeholder="@joao.pedro"
                    @ionInput="${(e: ChangeEvent<HTMLInputElement>) => (this.query = e.target.value)}">
                  </ion-input>
                </ion-item>
              </div>

              <div
                class=${classMap({
                  "mt-2.5": this.#hasResults(),
                })}>
                ${this.#users.render({
                  pending: () => html`<ion-spinner name="lines-sharp-small"></ion-spinner>`,
                  error: () => html`<span class="text-danger">${msg("Erro ao procurar")}<span></span></span>`,
                  complete: (data) => html`
                    <ion-list class="space-y-2.5 no-p" lines="none">
                      ${data.map(
                        (user) => html`
                          <ion-item class="no-p" @click=${() => this.selectUser(user)}>
                            <div class="flex items-center space-x-2">
                              <xt-avatar src=${user.avatar} name=${user.name}></xt-avatar>
                              <div class="col items-start">
                                <p class="font-semibold">${user.name}</p>
                                <span class="text-accent-5">@${user.username}</span>
                              </div>
                            </div>
                          </ion-item>
                        `,
                      )}
                    </ion-list>
                  `,
                })}
                ${renderError("user")}
              </div>
            `,
          )}

          <div class="space-y-1.5 mt-6">
            <span class="font-semibold font-display">${msg("Acesso")}</span>
            <ion-list>
              <ion-radio-group value="editor" ${register("access")}>
                <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                  <ion-radio value="admin" class="no-m-inline my-2">${msg("Administrador")}</ion-radio>
                </ion-item>
                <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                  <ion-radio value="editor" class="no-m-inline my-2">${msg("Editor")}</ion-radio>
                </ion-item>
                <ion-item class="text-accent-5 no-p no-inner-p" style="--min-height: 48px;">
                  <ion-radio value="viewer" class="no-m-inline my-2">${msg("Visualizador")}</ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            ${renderError("access")}
          </div>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar>
          <ion-button
            form="form-add-share"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Adicionar")}</ion-button
          >
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
