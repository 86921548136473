import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { when } from "lit/directives/when.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import type { ModalNav } from "../modals/modal-nav";
import { repeat } from "lit/directives/repeat.js";
import { navigatorContext, type NavigatorController } from "../controllers/navigator.controller";
import { Task } from "@lit/task";
import type { IonRefresher } from "@ionic/core/components/ion-refresher";
import { routerContext, type RouterContext } from "../context/router.context";
import { Page } from "../components/component";
import { templatesStore } from "../stores/templateExercisePlan.store";
import { localized, msg, str } from "@lit/localize";

@customElement("page-template-exercise-plan")
@localized()
export class PageTemplateExercisePlan extends Page {
  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorController;

  #modalCreateTemplateRef: Ref<ModalNav> = createRef();
  #ionRefresher: Ref<IonRefresher> = createRef();

  connectedCallback() {
    super.connectedCallback();
    this.#templates.run([true]);
  }

  #templates = new Task(this, {
    task: async ([cache]: [boolean]) => {
      return templatesStore.loadTemplates({ cache: cache });
    },
  });

  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-router-link href=${this.router.backUrl} router-direction="back">
                <ion-button
                  style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                  fill="clear"
                  class="font-semibold">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </ion-router-link>
            </div>
          </ion-buttons>
          <ion-title>${msg("Templates")}</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content fullscreen style="--padding-bottom: 24px" class="no-p">
        <ion-refresher
          slot="fixed"
          @ionRefresh=${async () => {
            if (!this.auth.user) return;
            await this.#templates.run([false]);
            await this.#ionRefresher.value?.complete();
          }}
          ${ref(this.#ionRefresher)}>
          <ion-refresher-content class="mt-4" pulling-text=${msg("Puxe para atualizar")}></ion-refresher-content>
        </ion-refresher>

        <ion-header collapse="condense">
          <ion-toolbar>
            <div class="flex justify-between items-start mx-4">
              <h2>${msg("Templates")}</h2>
              <div class="flex items-center space-x-1 mt-2">
                <span class="font-display font-semibold">${templatesStore.totalTemplates}</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M3.98691 2.28614C2.78603 6.0889 1.70525 15.2556 6.98909 21.5001C8.79038 20.2992 14.1943 21.5001 17.1965 20.2992C19.5983 19.3385 21.3996 17.4972 22 16.6966M9.99125 2.88657C9.19066 4.28759 8.06985 7.32981 9.99125 8.29051C11.9126 9.25121 11.9927 11.4928 11.7926 12.4936C12.393 10.292 15.2751 6.4892 22 8.89094M14.5714 13.6944C14.9024 12.5936 16.4913 10.6922 20.1987 11.8931"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round" />
                </svg>
              </div>
            </div>
            <div class="bg-accent-7 h-1 w-full mt-3"></div>
          </ion-toolbar>
        </ion-header>

        <!-- --------------- CONTENT ----------------- -->
        ${this.#templates.render({
          pending: () => {
            return html`
              <div class="space-y-2.5 mt-4">
                ${Array.from({ length: 4 }).map(() => {
                  return html`
                    <div class="flex space-x-2">
                      <ion-skeleton-text animated style="width: 52px; height: 52px;"></ion-skeleton-text>
                      <div class="flex-1">
                        <ion-skeleton-text animated style="width: 100%; height: 24px;"></ion-skeleton-text>
                        <ion-skeleton-text animated style="width: 100%; height: 16px;"></ion-skeleton-text>
                      </div>
                    </div>
                  `;
                })}
              </div>
            `;
          },
          error: () => {
            return html` <span class="mt-3 text-danger">${msg("Erro ao carregar os templates")}</span> `;
          },
          complete: (templates) => {
            return html`
              ${when(
                templates.length > 0,
                () => {
                  return html`
                    <ion-list lines="none" class="mt-4 space-y-2.5 mb-1 px-4 flex flex-col">
                      ${repeat(
                        templatesStore.templates,
                        (template) => template.id,
                        (template) => html`
                          <ion-item
                            @click=${() => this.router.push(`/exercises/templates/${template.id}`, "forward")}
                            button
                            detail-icon="/assets/icons/itemArrow.svg"
                            style="--inner-padding-end: 0px; --detail-icon-opacity: 1;"
                            class="no-p no-inner-p flex space-x-2 items-center">
                            <xt-avatar
                              style="--size: 52px; --border-width: 6px;"
                              src=${template.thumbnail}
                              name=${template.name}></xt-avatar>
                            <div class="col max-w-[calc(100%-60px)]">
                              <p class="font-semibold line-clamp-1">${template.name}</p>
                              <div class="flex items-center space-x-[3px]">
                                <span class="text-sm">${msg(str`${template._totalExercises} exercícios`)}</span>
                              </div>
                            </div>
                          </ion-item>
                        `,
                      )}
                    </ion-list>
                  `;
                },
                () => {
                  return html`
                    <ion-item
                      button
                      @click=${() => this.#modalCreateTemplateRef.value?.open()}
                      .detail=${false}
                      class="w-full h-[139px] rounded-md no-p no-inner-p mt-4 px-4">
                      <div
                        class="w-full h-[139px] bg-accent-7 rounded-md col justify-center items-center space-y-1 text-accent-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path
                            d="M6 12H12M12 12H18M12 12V18M12 12V6"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round" />
                        </svg>
                        <span class="font-display font-semibold text-center" style="max-inline-size: 20ch">
                          ${msg("Cria o teu primeiro template")}</span
                        >
                      </div>
                    </ion-item>
                  `;
                },
              )}
            `;
          },
        })}

        <!-- FAB -->
        <ion-fab
          slot="fixed"
          vertical="bottom"
          horizontal="end"
          @click=${() => this.#modalCreateTemplateRef.value?.open()}>
          <ion-fab-button id="open-modal-create-template" label="button">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6 12H12M12 12H18M12 12V18M12 12V6"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </ion-fab-button>
        </ion-fab>
      </ion-content>

      <modal-nav root="modal-create-template" ${ref(this.#modalCreateTemplateRef)}></modal-nav>
    `;
  }
}
