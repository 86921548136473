import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { consume } from "@lit/context";
import { Page } from "../../components/component";
import { FinalFormController, zodValidator } from "../../controllers/final-form.controller";
import { navigatorContext, NavigatorController } from "../../controllers/navigator.controller";
import {
  UpdateEvaluationFieldResultIntegerSchema,
  type EvaluationFieldResultInteger,
  type UpdateEvaluationFieldResultInteger,
} from "../../repository/report/report";
import type { ObservableEvaluationSection } from "../../stores/evaluation.store";
import { alertController } from "@ionic/core";
import { reportsStore } from "../../stores/reports.store";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../../error";

@customElement("modal-edit-result-integer")
@localized()
export class ModalEditResultInteger extends Page {
  field!: EvaluationFieldResultInteger;
  section!: ObservableEvaluationSection;
  @consume({ context: navigatorContext }) private navigator!: NavigatorController;

  #form = new FinalFormController<UpdateEvaluationFieldResultInteger>(this, {
    validate: zodValidator(
      UpdateEvaluationFieldResultIntegerSchema().pick({ label: true, minimum: true, maximum: true }),
    ),
    onSubmit: async (values) => {
      try {
        const minimum = Number(values.minimum);
        const maximum = Number(values.maximum);
        if (minimum >= maximum) {
          this.#form.setError("minimum", msg("O valor mínimo deve ser menor que o valor máximo"));
          return;
        }
        await reportsStore.updateEvaluationFieldResultInteger(this.field.id, values, this.section);
        this.navigator.close();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  render() {
    const { form, register, renderError } = this.#form;
    const formState = form.getState();

    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar>
            <ion-buttons slot="start">
              <ion-button @click=${() => this.navigator.goBack()} class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Resultado por inteiro")}</ion-title>
          </ion-toolbar>
        </ion-header>
        <form
          id="form-result-integer"
          class="space-y-4 mt-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <ion-list class="space-y-4" lines="none">
            <div>
              <span class="font-semibold font-display">${msg("Legenda")}</span>
              <ion-item class="mt-1">
                <ion-input
                  autocapitalize="on"
                  type="text"
                  placeholder=${msg("Legenda")}
                  enterkeyhint="next"
                  ${register("label", { initialValue: this.field.label })}>
                </ion-input>
              </ion-item>
              ${renderError("label")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Valor mínimo")}</span>
              <ion-item class="mt-1">
                <ion-input
                  type="number"
                  placeholder="00"
                  enterkeyhint="next"
                  ${register("minimum", { initialValue: this.field.minimum })}>
                </ion-input>
              </ion-item>
              ${renderError("minimum")}
            </div>
            <div>
              <span class="font-semibold font-display">${msg("Valor máximo")}</span>
              <ion-item class="mt-1">
                <ion-input
                  type="number"
                  placeholder="00"
                  enterkeyhint="send"
                  ${register("maximum", { initialValue: this.field.maximum })}>
                </ion-input>
              </ion-item>
              ${renderError("maximum")}
            </div>
          </ion-list>

          <ion-button
            form="form-result-integer"
            type="submit"
            color="primary"
            expand="block"
            shape="round"
            class="font-semibold"
            ?disabled=${formState.submitting}
            >${msg("Guardar Alterações")}</ion-button
          >
        </form>
      </div>
    `;
  }
}
