import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { modalController } from "@ionic/core";
import { Page } from "../components/component";
import { localized, msg } from "@lit/localize";

@customElement("modal-terms-and-conditions")
@localized()
export class ModalTermsAndConditions extends Page {
  render() {
    return html`
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <div class="flex items-center">
              <ion-button
                @click=${() => modalController.dismiss()}
                style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                fill="clear"
                class="font-semibold">
                <span class="flex items-center -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M14 16L10 12L14 8"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("voltar")}
              </ion-button>
            </div>
          </ion-buttons>
          <ion-title>${msg(html`Termos & Condições`)}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content fullscreen style="--padding-bottom: 24px;">
        <ion-header collapse="condense">
          <ion-toolbar>
            <h3 class="w-1/2">${msg(html`Termos & Condições`)}</h3>
          </ion-toolbar>
        </ion-header>

        <p class="mt-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ullamcorper sit amet risus nullam eget. Posuere urna nec tincidunt praesent semper feugiat nibh
          sed.

          <br />
          <br />
          Tristique magna sit amet purus gravida. In metus vulputate eu scelerisque felis imperdiet. Massa enim nec dui
          nunc mattis enim ut. Libero enim sed faucibus turpis.
          <br />
          <br />

          Est placerat in egestas erat. Porttitor massa id neque aliquam vestibulum morbi blandit cursus. Risus
          ultricies tristique nulla aliquet. Bibendum at varius vel pharetra vel. Lacus suspendisse faucibus interdum
          posuere. Lectus mauris ultrices eros in cursus turpis massa tincidunt.
          <br />
          <br />

          Gravida quis blandit turpis cursus in hac habitasse. Duis ultricies lacus sed turpis tincidunt id aliquet.
          Cursus mattis molestie a iaculis at erat. Pretium lectus quam id leo in vitae.
          <br />
          <br />

          Ac auctor augue mauris augue neque gravida. Imperdiet nulla malesuada pellentesque elit eget. Cursus in hac
          habitasse platea dictumst quisque sagittis purus sit. Augue neque gravida in fermentum et sollicitudin. Sit
          amet nisl suscipit adipiscing bibendum. In tellus integer feugiat scelerisque varius morbi enim nunc. Laoreet
          sit amet cursus sit amet dictum. Maecenas volutpat blandit aliquam etiam erat velit scelerisque. Ullamcorper
          velit sed ullamcorper morbi tincidunt. Odio pellentesque diam volutpat commodo sed egestas egestas fringilla
          phasellus. Mauris pharetra et ultrices neque ornare aenean euismod. At erat pellentesque adipiscing commodo
          elit at imperdiet. Pulvinar neque laoreet suspendisse interdum consectetur libero id. Sapien pellentesque
          habitant morbi tristique senectus et netus.
          <br />
          <br />
          Quisque sagittis purus sit amet volutpat consequat mauris nunc congue. Aenean euismod elementum nisi quis
          eleifend quam adipiscing vitae proin. Eget magna fermentum iaculis eu non. Habitasse platea dictumst
          vestibulum rhoncus est. Scelerisque eu ultrices vitae auctor eu augue ut lectus arcu. Tempor nec feugiat nisl
          pretium fusce id. Convallis tellus id interdum velit. Integer vitae justo eget magna fermentum iaculis eu non.
          Non arcu risus quis varius. Fringilla ut morbi tincidunt augue interdum. Pretium quam vulputate dignissim
          suspendisse in. Eget egestas purus viverra accumsan in nisl nisi scelerisque. Amet consectetur adipiscing elit
          duis tristique sollicitudin nibh sit amet. In hendrerit gravida rutrum quisque non.

          <br />
          <br />
          Varius duis at consectetur lorem donec massa sapien faucibus. Id eu nisl nunc mi ipsum. Mattis nunc sed
          blandit libero volutpat. Velit euismod in pellentesque massa placerat duis ultricies lacus. Semper quis lectus
          nulla at volutpat diam. Dui accumsan sit amet nulla. Aliquam sem et tortor consequat id porta. Nisi porta
          lorem mollis aliquam. Arcu risus quis varius quam
        </p>
      </ion-content>
    `;
  }
}
