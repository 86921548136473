import { html, nothing } from "lit";
import { customElement, state } from "lit/decorators.js";
import { consume } from "@lit/context";
import { authContext, type AuthContext } from "../context/auth.context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { Page, required } from "../components/component";
import { classMap } from "lit/directives/class-map.js";
import { ModalChooseLibrary } from "./modal-choose-library";
import { SelectStore } from "../stores/select.store";
import { ObservableExercise } from "../stores/exercises.store";
import { when } from "lit/directives/when.js";
import { RouterContext, routerContext } from "../context/router.context";
import { alertController, pickerController } from "@ionic/core";
import type { IonInput } from "@ionic/core/components/ion-input";
import { ClientResponseError } from "pocketbase";
import { sessionsStore, ObservableExercisePlan, ObservableSession } from "../stores/sessions.store";
import { UpdateExercisePlanSchema, type UpdateExercisePlan } from "../repository/patient/diagnostic";
import type { PublicExercise } from "../repository/library/exercises";
import { Task } from "@lit/task";
import { ObservableGroup, groupStore } from "../stores/groups.store";
import { pb } from "../pocketbase";
import { localized, msg } from "@lit/localize";
import { ApiError } from "../error";

const seriesIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M6.66675 11.3334H13.3334M2.66675 10.457V10.3334C2.66675 9.78113 3.11446 9.33341 3.66675 9.33341H3.69377C4.23113 9.33341 4.66689 9.76904 4.66689 10.3064C4.66689 10.5403 4.59088 10.768 4.45053 10.9552L2.66675 13.3335L4.66675 13.3334M6.66675 8.00008H13.3334M6.66675 4.66675H13.3334M2.66675 3.33341L4.00008 2.66675V6.66675"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const repetitionsIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M6.66675 10.6667H3.33341V14M9.33341 5.33333H12.6667V2M3.05542 6.00227C3.42921 5.07711 4.05504 4.27536 4.86178 3.68815C5.66852 3.10094 6.62465 2.75168 7.61992 2.68034C8.61518 2.609 9.6099 2.81827 10.4921 3.28441C11.3744 3.75055 12.1076 4.45503 12.6095 5.31742M12.9451 9.99805C12.5713 10.9232 11.9455 11.725 11.1388 12.3122C10.332 12.8994 9.37686 13.2482 8.38159 13.3195C7.38633 13.3909 6.39079 13.1816 5.50854 12.7155C4.6263 12.2494 3.89263 11.545 3.39071 10.6826"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const durationIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M8.00008 10.6666V8.66659M8.00008 8.66659V6.66659M8.00008 8.66659H6.00008M8.00008 8.66659H10.0001M14.0001 3.99992L12.6667 2.66659M6.66675 1.33325H9.33341M8.00008 13.9999C5.05456 13.9999 2.66675 11.6121 2.66675 8.66659C2.66675 5.72107 5.05456 3.33325 8.00008 3.33325C10.9456 3.33325 13.3334 5.72107 13.3334 8.66659C13.3334 11.6121 10.9456 13.9999 8.00008 13.9999Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const weightIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <g clip-path="url(#clip0_348_6931)">
      <path
        d="M4.33325 4.33325L11.6666 11.6666"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M13.9999 13.9999L13.3333 13.3333"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2 2L2.66667 2.66667"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M12 14.6667L14.6667 12"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M1.33325 3.99992L3.99992 1.33325"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M2 6.66667L6.66667 2"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
      <path
        d="M9.33325 13.9999L13.9999 9.33325"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_348_6931">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
`;
const restIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M9.33333 6V10M6.66667 6V10M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

const descriptionIcon = html`
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" class="mr-1 mb-px">
    <path
      d="M8 7.33333V10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14ZM8.0332 5.33333V5.4L7.9668 5.40013V5.33333H8.0332Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round" />
  </svg>
`;

@customElement("modal-edit-plan-exercise")
@localized()
export class ModalEditPlanExercise extends Page {
  @state() private _fields = new Map([
    ["series", { label: msg("Séries"), selected: false, icon: seriesIcon }],
    ["repetitions", { label: msg("Repetições"), selected: false, icon: repetitionsIcon }],
    ["duration", { label: msg("Duração"), selected: false, icon: durationIcon }],
    ["rest", { label: msg("Repouso"), selected: false, icon: restIcon }],
    ["weight", { label: msg("Peso/Carga"), selected: false, icon: weightIcon }],
    ["description", { label: msg("Descrição"), selected: false, icon: descriptionIcon }],
  ]);

  @consume({ context: authContext }) auth!: AuthContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;
  @consume({ context: routerContext }) router!: RouterContext;

  @required() session!: ObservableSession;
  @required() exercisePlan!: ObservableExercisePlan;

  group!: ObservableGroup;

  exercises = new SelectStore<PublicExercise | ObservableExercise>();

  #group = new Task(this, {
    task: async () => {
      if (this.group) return this.group;
      return groupStore.loadGroup(this.router.getParam("groupId"), { cache: true });
    },
    args: () => [],
  });

  connectedCallback(): void {
    super.connectedCallback();
    if (this.exercisePlan.exercise) {
      this.exercises.add(this.exercisePlan.exercise);
    }
    if (this.exercisePlan.series) {
      this.#controller.form.change("series", this.exercisePlan.series);
      this._fields.get("series")!.selected = true;
    }
    if (this.exercisePlan.repetitions) {
      this.#controller.form.change("repetitions", this.exercisePlan.repetitions);
      this._fields.get("repetitions")!.selected = true;
    }
    if (this.exercisePlan.weight) {
      this.#controller.form.change("weight", this.exercisePlan.weight);
      this._fields.get("weight")!.selected = true;
    }
    if (this.exercisePlan.weightUnit) {
      this.#controller.form.change("weightUnit", this.exercisePlan.weightUnit);
      this._fields.get("weight")!.selected = true;
      const weightUnitInput = this.querySelector("#weightUnit") as IonInput;
      if (weightUnitInput) {
        if (this.exercisePlan.weightUnit === "kg") {
          weightUnitInput.value = msg("Kilograma (kg)");
        } else if (this.exercisePlan.weightUnit === "lb") {
          weightUnitInput.value = msg("Libra (lb)");
        } else if (this.exercisePlan.weightUnit === "g") {
          weightUnitInput.value = msg("Gramas (g)");
        } else if (this.exercisePlan.weightUnit === "km/h") {
          weightUnitInput.value = msg("Kms por hora (km/h)");
        }
      }
    }
    if (this.exercisePlan.rest) {
      this.#controller.form.change("rest", this.exercisePlan.rest);
      this._fields.get("rest")!.selected = true;
      const restInput = this.querySelector("#rest") as IonInput;
      if (restInput) {
        const minutes = Math.floor(this.exercisePlan.rest / 60);
        const seconds = this.exercisePlan.rest % 60;
        restInput.value = `${minutes.toString().padStart(2, "0")}m:${seconds.toString().padStart(2, "0")}s`;
      }
    }
    if (this.exercisePlan.duration) {
      this.#controller.form.change("duration", this.exercisePlan.duration);
      this._fields.get("duration")!.selected = true;
      const durationInput = this.querySelector("#duration") as IonInput;
      if (durationInput) {
        const minutes = Math.floor(this.exercisePlan.duration / 60);
        const seconds = this.exercisePlan.duration % 60;
        durationInput.value = `${minutes.toString().padStart(2, "0")}m:${seconds.toString().padStart(2, "0")}s`;
      }
    }
    if (this.exercisePlan.description) {
      this.#controller.form.change("description", this.exercisePlan.description);
      this._fields.get("description")!.selected = true;
    }
  }

  #controller = new FinalFormController<UpdateExercisePlan>(this, {
    validate: zodValidator(UpdateExercisePlanSchema()),
    onSubmit: async (values) => {
      try {
        if (!this._fields.get("series")?.selected) {
          values.series = 0;
        }
        if (!this._fields.get("repetitions")?.selected) {
          values.repetitions = "";
        }
        if (!this._fields.get("duration")?.selected) {
          values.duration = 0;
        }
        if (!this._fields.get("rest")?.selected) {
          values.rest = 0;
        }
        if (!this._fields.get("weight")?.selected) {
          values.weight = 0;
          values.weightUnit = "";
        }
        if (!this._fields.get("description")?.selected) {
          values.description = "";
        }
        await sessionsStore.updateExercisePlan(this.exercisePlan, values);
        this.navigator.goBack();
      } catch (error) {
        if (error instanceof ApiError) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["Ok"],
          });
          await alert.present();
        }
      }
    },
  });

  toggleSelected(key: string) {
    const field = this._fields.get(key);
    if (field) {
      field.selected = !field.selected;
      this._fields.set(key, field); // Ensure the map is updated
    }
    this.requestUpdate();
  }

  field(key: string) {
    return this._fields.get(key);
  }

  isSelected(key: string) {
    const field = this.field(key);
    if (!field) {
      throw new Error(`Field ${key} not found`);
    }
    return field.selected;
  }

  deleteExercise() {
    alertController
      .create({
        header: msg("Apagar Exercício"),
        message: msg("Tem a certeza que pretende apagar este exercício?"),
        buttons: [
          {
            text: msg("Cancelar"),
            role: "cancel",
          },
          {
            text: msg("Apagar"),
            role: "destructive",
            handler: async () => {
              try {
                await sessionsStore.deleteExercisePlan(this.session, this.exercisePlan);
                this.navigator.goBack();
              } catch (error) {
                if (error instanceof ApiError) {
                  const alert = await alertController.create({
                    header: msg("Erro"),
                    message: error.message,
                    buttons: ["Ok"],
                  });
                  await alert.present();
                }
              }
            },
          },
        ],
      })
      .then((alert) => alert.present());
  }

  render() {
    const { form, register, renderError } = this.#controller;
    const formState = form.getState();

    const selectedExercise = this.exercises.selected[0];
    if (selectedExercise) {
      if (selectedExercise instanceof ObservableExercise) {
        this.#controller.form.change("exercise", selectedExercise.id);
        this.#controller.form.change("exercisePublic", null);
      } else {
        this.#controller.form.change("exercisePublic", selectedExercise.id);
        this.#controller.form.change("exercise", null);
      }
    }

    return html`
      <ion-content class="space-y-4 no-px" fullscreen>
        <ion-header class="px-4">
          <ion-toolbar>
            <ion-buttons slot="start">
              <div class="flex items-center">
                <ion-button
                  fill="clear"
                  class="font-semibold no-p no-inline-p no-m-inline no-m"
                  @click="${() => this.navigator.goBack()}">
                  <span class="flex items-center -ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M14 16L10 12L14 8"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                  ${msg("voltar")}
                </ion-button>
              </div>
            </ion-buttons>
            <ion-title class="font-display font-semibold text-lg">${msg("Exercício")}</ion-title>
            ${when(
              this.#group.value?.canEdit(this.auth.user),
              () => html`
                <ion-buttons slot="end">
                  <div class="flex items-center">
                    <ion-button
                      @click=${() => this.deleteExercise()}
                      color="danger"
                      style="--padding-inline-start: 0px; --padding-start: 0px; margin-inline-start: 0px; margin-start: 0px;"
                      fill="clear"
                      class="font-semibold">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        class="mr-1">
                        <path
                          d="M5.33333 8H10.6667M8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14Z"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round" />
                      </svg>
                      ${msg("Apagar")}
                    </ion-button>
                  </div>
                </ion-buttons>
              `,
            )}
          </ion-toolbar>
        </ion-header>

        <!-- -------------- FILTERS -------------- -->

        <div class="space-y-3 !mt-0">
          <div class="w-full h-1 bg-accent-7"></div>
          <div class="px-4 pb-1 space-y-2">
            <p class="font-display font-semibold">${msg("Escolha os campos")}</p>
            <div class="flex flex-wrap gap-2 items-center">
              ${Array.from(this._fields.entries()).map(
                ([key, field]) => html`
                  <ion-chip
                    color=${field.selected ? "primary" : "light"}
                    @click=${() => {
                      if (this.#group?.value?.canEdit(this.auth.user)) this.toggleSelected(key);
                    }}
                    class="px-2.5 py-1.5 text-sm font-medium">
                    ${field.icon} ${field.label}
                  </ion-chip>
                `,
              )}
            </div>
          </div>
          <div class="w-full h-1 bg-accent-7"></div>
        </div>

        <!-- ------------------- CONTENT ----------------- -->

        <form
          id="form-create-template-exercise"
          class="px-4 space-y-4"
          @submit=${(e: Event) => {
            e.preventDefault();
            form.submit();
          }}>
          <div class="mt-4">
            <span class="font-semibold font-display">${msg("Exercício")}</span>
            <div ${register("exercise")}>
              ${when(this.exercises.selected.length > 0, () => {
                const exercise = this.exercises.selected[0];
                if (!exercise) {
                  return nothing;
                }
                return html`
                  <ion-item .detail=${false} class="no-p mt-1.5" style="--border-color: transparent;">
                    <div class="flex items-center justify-between w-full">
                      <div class="space-x-2 flex items-center">
                        ${when(
                          exercise instanceof ObservableExercise,
                          () => html`
                            <xt-avatar
                              src=${(exercise as ObservableExercise).thumbnail({ width: 128, height: 128 })}
                              name=${exercise.name}></xt-avatar>
                          `,
                          () => {
                            const thumbnail = pb.files.getUrl(exercise, (exercise as PublicExercise).thumbnail, {
                              thumb: "128x128",
                            });
                            return html` <xt-avatar src=${thumbnail} name=${exercise.name}></xt-avatar> `;
                          },
                        )}
                        <div class="col items-start">
                          <p class="font-semibold line-clamp-1">${exercise?.name}</p>
                        </div>
                      </div>
                    </div>
                  </ion-item>
                `;
              })}
            </div>
            <div class="mt-1.5">
              <ion-button
                size="small"
                color="secondary"
                expand="block"
                shape="round"
                @click=${() =>
                  this.navigator.push(ModalChooseLibrary, {
                    exercises: this.exercises,
                  })}>
                ${msg("Escolher Exercício")}
              </ion-button>
            </div>
            ${renderError("exercise")}
          </div>

          <div ?hidden=${!this.isSelected("series") && !this.isSelected("repetitions")}>
            <div class="grid grid-cols-2 gap-x-4">
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("series") && this.isSelected("repetitions"),
                  "col-span-2": !this.isSelected("series") || !this.isSelected("repetitions"),
                })}>
                <div ?hidden=${!this.isSelected("series")}>
                  <span class="font-semibold font-display text-sm">${msg("Séries")}</span>
                  <ion-item class="mt-1">
                    <ion-input ${register("series")} type="number" placeholder="4"></ion-input>
                  </ion-item>
                  ${renderError("series")}
                </div>
              </div>
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("series") && this.isSelected("repetitions"),
                  "col-span-2": !this.isSelected("series") || !this.isSelected("repetitions"),
                })}>
                <div ?hidden=${!this.isSelected("repetitions")}>
                  <span class="font-semibold font-display text-sm">${msg("Repetições")}</span>
                  <ion-item class="mt-1">
                    <ion-input ${register("repetitions")} type="text" inputmode="numeric" placeholder="12"></ion-input>
                  </ion-item>
                  ${renderError("repetitions")}
                </div>
              </div>
            </div>
          </div>

          <div ?hidden=${!this.isSelected("duration") && !this.isSelected("rest")}>
            <div class="grid grid-cols-2 gap-x-4">
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("duration") && this.isSelected("rest"),
                  "col-span-2": !this.isSelected("duration") || !this.isSelected("rest"),
                })}>
                <div ?hidden=${!this.isSelected("duration")}>
                  <span class="font-semibold font-display text-sm">${msg("Duração")}</span>
                  <ion-item
                    class="mt-1"
                    @click=${() => {
                      const currentDuration = formState.values.duration || 0;

                      const minutes = Math.floor(currentDuration / 60);
                      const seconds = currentDuration % 60;

                      pickerController
                        .create({
                          columns: [
                            {
                              name: "minutes",
                              options: Array.from({ length: 60 }, (_, i) => ({
                                text: String(i).padStart(2, "0"),
                                value: i,
                              })),
                              selectedIndex: minutes,
                            },
                            {
                              name: "seconds",
                              options: Array.from({ length: 60 }, (_, i) => ({
                                text: String(i).padStart(2, "0"),
                                value: i,
                              })),
                              selectedIndex: seconds,
                            },
                          ],
                          buttons: [
                            {
                              text: msg("Cancelar"),
                              role: "cancel",
                            },
                            {
                              text: msg("Confirmar"),
                              handler: (value) => {
                                const { minutes, seconds } = value;
                                form.change("duration", minutes.value * 60 + seconds.value);
                                const restInput = document.getElementById("duration") as IonInput;
                                restInput.value = `${minutes.text}m:${seconds.text}s`;
                              },
                            },
                          ],
                        })
                        .then((picker) => {
                          picker.present();
                        });
                    }}>
                    <ion-input id="duration" type="text" placeholder="60" inert></ion-input>
                    <ion-input hidden ${register("duration")} type="number"></ion-input>
                  </ion-item>
                  ${renderError("duration")}
                </div>
              </div>
              <div
                class=${classMap({
                  "col-span-1": this.isSelected("duration") && this.isSelected("rest"),
                  "col-span-2": !this.isSelected("duration") || !this.isSelected("rest"),
                })}>
                <div ?hidden=${!this.isSelected("rest")}>
                  <span class="font-semibold font-display text-sm">${msg("Repouso")}</span>
                  <ion-item
                    class="mt-1"
                    @click=${() => {
                      const currentRest = formState.values.rest || 0;

                      const minutes = Math.floor(currentRest / 60);
                      const seconds = currentRest % 60;

                      pickerController
                        .create({
                          columns: [
                            {
                              name: "minutes",
                              options: Array.from({ length: 60 }, (_, i) => ({
                                text: String(i).padStart(2, "0"),
                                value: i,
                              })),
                              selectedIndex: minutes,
                            },
                            {
                              name: "seconds",
                              options: Array.from({ length: 60 }, (_, i) => ({
                                text: String(i).padStart(2, "0"),
                                value: i,
                              })),
                              selectedIndex: seconds,
                            },
                          ],
                          buttons: [
                            {
                              text: msg("Cancelar"),
                              role: "cancel",
                            },
                            {
                              text: msg("Confirmar"),
                              handler: (value) => {
                                const { minutes, seconds } = value;
                                form.change("rest", minutes.value * 60 + seconds.value);
                                const restInput = document.getElementById("rest") as IonInput;
                                restInput.value = `${minutes.text}m:${seconds.text}s`;
                              },
                            },
                          ],
                        })
                        .then((picker) => {
                          picker.present();
                        });
                    }}>
                    <ion-input id="rest" type="text" placeholder="45" inert></ion-input>
                    <ion-input hidden ${register("rest")} type="number"></ion-input>
                  </ion-item>
                  ${renderError("rest")}
                </div>
              </div>
            </div>
          </div>

          <div ?hidden=${!this.isSelected("weight")}>
            <div class="grid grid-cols-2 gap-x-4">
              <div class="col-span-1">
                <span class="font-semibold font-display text-sm">${msg("Peso/Carga")}</span>
                <ion-item class="mt-1">
                  <ion-input ${register("weight")} type="number" step=".01" placeholder="10"></ion-input>
                </ion-item>
                ${renderError("weight")}
              </div>
              <div class="col-span-1">
                <span class="font-semibold font-display text-sm">${msg("Unidade")}</span>
                <ion-item
                  class="mt-1"
                  @click=${() => {
                    pickerController
                      .create({
                        columns: [
                          {
                            name: "unit",
                            options: [
                              { text: msg("Kilograma (kg)"), value: "kg" },
                              { text: msg("Libra (lb)"), value: "lb" },
                              { text: msg("Gramas (g)"), value: "g" },
                              { text: msg("Kms por hora (km/h)"), value: "km/h" },
                            ],
                          },
                        ],
                        buttons: [
                          {
                            text: msg("Cancelar"),
                            role: "cancel",
                          },
                          {
                            text: msg("Confirmar"),
                            handler: (value) => {
                              form.change("weightUnit", value.unit.value);
                              const weightUnitInput = document.getElementById("weightUnit") as IonInput;
                              weightUnitInput.value = value.unit.text;
                            },
                          },
                        ],
                      })
                      .then((picker) => {
                        picker.present();
                      });
                  }}>
                  <ion-input id="weightUnit" type="text" placeholder="Kg" inert></ion-input>
                  <ion-input hidden ${register("weightUnit")} type="text"></ion-input>
                </ion-item>
                ${renderError("weight")}
              </div>
            </div>
          </div>

          <div ?hidden=${!this.isSelected("description")}>
            <span class="font-semibold font-display text-sm">${msg("Descrição")}</span>
            <ion-item class="mt-1">
              <ion-textarea
                ${register("description")}
                auto-grow
                rows="4"
                placeholder=${msg("Escreva aqui os detalhes necessários")}></ion-textarea>
            </ion-item>
            ${renderError("description")}
          </div>
        </form>
      </ion-content>

      <ion-footer>
        <ion-toolbar class="px-4">
          <ion-button
            form="form-create-template-exercise"
            type="submit"
            ?disabled=${formState.submitting}
            expand="block"
            shape="round"
            class="font-semibold">
            ${msg("Guardar Alterações")}
          </ion-button>
        </ion-toolbar>
      </ion-footer>
    `;
  }
}
