import { action, computed, makeObservable, observable, runInAction } from "mobx";
import type { Appointment, AppointmentDate } from "../repository/appointment/appointment";
import { appointmentRepository } from "../repository/appointment/appointment.repository";
import type { UpdateSession } from "../repository/patient/diagnostic";
import { groupStore, ObservableGroup } from "./groups.store";
import { patientsStore, type ObservablePatient } from "./patients.store";
import { ObservableSession, sessionsStore } from "./sessions.store";
import { diagnosticRepository } from "../repository/patient/diagnostic.repository";
import { diagnosticsStore, type ObservableDiagnostic } from "./diagnostics.store";
import { format } from "date-fns";

export class ObservableAppointment {
  @observable session: ObservableSession;
  @observable patient: ObservablePatient;
  @observable group: ObservableGroup;
  @observable diagnostic: ObservableDiagnostic;
  @observable isLoaded = false;

  constructor(data: Appointment) {
    this.session = sessionsStore.subscribe(data.session);
    this.patient = patientsStore.subscribe(data.patient);
    this.group = groupStore.subscribe(data.group);
    this.diagnostic = diagnosticsStore.subscribe(data.diagnostic);
    makeObservable(this);
  }

  @action merge(data: Appointment) {
    this.session = sessionsStore.subscribe(data.session);
    this.patient = patientsStore.subscribe(data.patient);
    this.group = groupStore.subscribe(data.group);
    this.diagnostic = diagnosticsStore.subscribe(data.diagnostic);
  }

  @action update(data: Appointment) {
    this.session.update(data.session);
    this.patient.update(data.patient);
    this.group.update(data.group);
  }
}

export class ObservableAppointmentDay {
  date: string;
  @observable textColor: string;
  @observable count: number;

  constructor(data: AppointmentDate) {
    this.date = data.date;
    this.count = data.count;
    this.textColor = "#50E3C2";
    makeObservable(this);
  }
}

class AppointmentStore {
  @observable _appointments = observable.map<string, ObservableAppointment>();
  @observable _days = observable.map<string, ObservableAppointmentDay>();

  @computed get days() {
    return Array.from(this._days.values());
  }

  @computed get appointments() {
    return Array.from(this._appointments.values());
  }

  @action save(appointment: ObservableAppointment) {
    this._appointments.set(appointment.session.id, appointment);
    return appointment;
  }

  @action delete(appointment: ObservableAppointment) {
    this._appointments.delete(appointment.session.id);

    // decrement the count?
    const date = this.findDay(appointment.session.day);
    if (date) {
      date.count -= 1;
    }
  }

  @action
  clearStore() {
    this._appointments.clear();
    this._days.clear();
  }

  findDay(date: string) {
    return this._days.get(date);
  }

  appointmentsByDay(day: string) {
    return this.appointments.filter((appointment) => appointment.session.day === day);
  }

  @action async load(id: string, options: { cache: boolean } = { cache: true }) {
    const cachedAppointment = this._appointments.get(id);
    if (options.cache && cachedAppointment?.isLoaded) {
      return cachedAppointment;
    }

    const appointment = await appointmentRepository.get(id);
    const observableAppointment = new ObservableAppointment(appointment);

    runInAction(() => {
      observableAppointment.isLoaded = true;
      this.save(observableAppointment);
    });

    return observableAppointment;
  }

  @action async loadAppointments(userId: string, days: string[]) {
    const appointments = await appointmentRepository.list(userId, days);

    runInAction(() => {
      this._appointments.clear();
      for (const appointment of appointments) {
        this.save(new ObservableAppointment(appointment));
      }
    });

    return this.appointments;
  }

  @action async loadDates() {
    const dates = await appointmentRepository.listAllDays();

    runInAction(() => {
      for (const date of dates) {
        this._days.set(date.date, new ObservableAppointmentDay(date));
      }
    });

    return this.days;
  }

  @action async updateAppointment(appointment: ObservableAppointment, data: UpdateSession) {
    const result = await diagnosticRepository.updateSession(appointment.session.id, data);

    runInAction(() => {
      if (data.startTime) {
        const day = format(new Date(data.startTime), "yyyy-MM-dd");
        const hasDayChanged = appointment?.session.day !== day;
        if (hasDayChanged) {
          const oldDay = this.findDay(appointment.session.day);
          if (oldDay) {
            oldDay.count -= 1;
          }
          const newDay = this.findDay(day);
          if (newDay) {
            newDay.count += 1;
          }
        }
      }
      appointment.session.update(result);
    });

    return appointment;
  }

  @action
  async deleteAppointment(appointment: ObservableAppointment) {
    await diagnosticRepository.deleteSession(appointment.session.id);

    runInAction(() => {
      this.delete(appointment);
    });

    return appointment;
  }
}

export const appointmentStore = new AppointmentStore();
