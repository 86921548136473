import { popoverController, type PopoverOptions as IonPopoverOptions } from "@ionic/core";

export interface PopoverButton {
  class?: string;
  labelClass?: string;
  id?: string;
  text: string;
  handler?: (e: Event) => void | Promise<void>;
}

export type PopoverOptions = Omit<IonPopoverOptions, "component" | "event" | "componentProps">

export const createPopover = (data: PopoverButton[], event: Event, options?: PopoverOptions) => {
  return popoverController.create({
    dismissOnSelect: true,
    ...options,
    component: "popover-content",
    componentProps: {
      buttons: data,
    },
    event: event,
  });
};

export { popoverController }