import { z } from "zod";
import { unsafeParse } from "../shared/unsafeParse";
import { record } from "../shared/record";

export const AccessRoleSchema = z.enum(["editor", "viewer", "admin"]);
export type AccessRole = z.infer<typeof AccessRoleSchema>;

export const AccessRecordSchema = record({
  id: z.string(),
  role: AccessRoleSchema,
});
export interface AccessRecord extends z.infer<typeof AccessRecordSchema> {}

export const AccessSchema = AccessRecordSchema.omit({ expand: true });
export interface Access extends z.infer<typeof AccessSchema> {}
export function createAccess(data: unknown) {
  return unsafeParse(AccessSchema, data);
}

export const createAccessFromRole = (name: AccessRole) => {
  switch (name) {
    case "viewer":
      return { id: "ks89pfutqzmjj09", role: "viewer" } as const;
    case "editor":
      return { id: "r90nw84blkmt66b", role: "editor" } as const;
    case "admin":
      return { id: "vo4zc29pag3sovl", role: "admin" } as const;
  }
};
