import { createContext } from "@lit/context";

export const routerContext = createContext<RouterContext>(Symbol("router-context"));

import {
  type AnimationBuilder,
  type RouterDirection,
  type IonRouterCustomEvent,
  type RouterEventDetail,
} from "@ionic/core";
import type { IonRouter } from "@ionic/core/components/ion-router";
import { html, type TemplateResult } from "lit";
import { when } from "lit/directives/when.js";
import { createRef, ref, type Ref } from "lit/directives/ref.js";
import { computed, makeObservable, observable, runInAction } from "mobx";

export type HistoryEntry = {
  to: string;
  from: string | null;
  timestamp: Date;
  props?: Record<string, unknown>;
};

export type MiddlewareResult = boolean | { redirect: string };

export type Middleware = () => MiddlewareResult | Promise<MiddlewareResult>;

export type Route = {
  component: string;
  props?: Record<string, unknown>;
  beforeEnter?: Middleware[];
  beforeLeave?: Middleware[];
  children?: Routes;
};

export type Routes = {
  [path: string]: Route;
};

export type TabRoutes = {
  component: string;
  routes: Routes;
};

export type RoutesConfig = {
  useHash?: boolean;
  routes: Routes;
  tabs?: TabRoutes;
};

export class RouterContext {
  private ionRouter: Ref<IonRouter> = createRef();
  private currentPath: string = "";
  private pathProps: Record<string, unknown> = {};
  private pathParams: Record<string, string> = {};
  private paramMaps: Map<string, URLPattern> = new Map();
  private propMaps: Map<string, Record<string, unknown>> = new Map();

  @observable private _backTemplate: string | null = null;

  constructor(public config: RoutesConfig) {
    this.buildRoutesMap(this.config.routes);

    if (this.config.tabs) {
      this.buildRoutesMap(this.config.tabs.routes);
    }

    makeObservable(this);
  }

  public getParam(key: string): string {
    const param = this.pathParams[key];
    if (!param) {
      throw new Error(`Param ${key} not found in path`);
    }
    return param;
  }

  public getProp<T>(key: string): T {
    const prop = this.pathProps[key];
    if (!prop) {
      throw new Error(`Prop ${key} not found in path`);
    }
    return prop as T;
  }

  public render(): TemplateResult {
    return html`
      <ion-router
        .useHash="${this.config.useHash || false}"
        @ionRouteWillChange="${this._onRouteWillChange}"
        @ionRouteDidChange="${this._onRouteDidChange}"
        ${ref(this.ionRouter)}>
        ${when(
          this.config.tabs,
          () => html`
            <ion-route component="${this.config.tabs!.component}">
              ${this.renderRoutes(this.config.tabs!.routes)}
            </ion-route>
          `,
        )}
        ${this.renderRoutes(this.config.routes)}
      </ion-router>
    `;
  }

  private renderRoutes(routes: Routes): TemplateResult {
    return html`${Object.entries(routes).map(([path, route]) => {
      const beforeEnter = route.beforeEnter ? () => this.executeMiddlewares(route.beforeEnter) : undefined;
      const beforeLeave = route.beforeLeave ? () => this.executeMiddlewares(route.beforeLeave) : undefined;
      return html`
        <ion-route
          url="${path}"
          component="${route.component}"
          .componentProps="${route.props}"
          .beforeEnter="${beforeEnter}"
          .beforeLeave="${beforeLeave}">
          ${route.children ? this.renderRoutes(route.children) : ""}
        </ion-route>
      `;
    })}`;
  }

  // Adjust your route and parameter mapping to use URLPattern
  private buildRoutesMap(routes: Routes, basePath: string = "", parentMiddleware: Middleware[] = []) {
    Object.entries(routes).forEach(([path, route]) => {
      const fullPath = `${basePath}${path}`;

      if (!route.beforeEnter && parentMiddleware.length > 0) {
        route.beforeEnter = [...parentMiddleware];
      }

      // Construct a URLPattern for each route
      const pattern = new URLPattern({ pathname: fullPath });
      this.paramMaps.set(fullPath, pattern);
      this.propMaps.set(fullPath, route.props || {});

      if (route.children) {
        this.buildRoutesMap(route.children, fullPath, route.beforeEnter || parentMiddleware);
      }
    });
  }

  private matchAndParseParams(currentPath: string): { params: Record<string, string>; matchedRoutePath?: string } {
    for (const [path, pattern] of this.paramMaps) {
      const result = pattern.exec({ pathname: currentPath });
      if (result) {
        const params: Record<string, string> = {};
        Object.entries(result.pathname.groups).forEach(([key, value]) => {
          if (typeof value === "string") {
            params[key] = value;
          }
        });
        return { params, matchedRoutePath: path };
      }
    }
    return { params: {} };
  }

  private async executeMiddlewares(middlewares: Middleware[] | undefined): Promise<MiddlewareResult> {
    if (!middlewares) {
      return true;
    }

    for (let middleware of middlewares) {
      const result = await middleware();
      if (typeof result === "object" && result.redirect) {
        return result; // Redirect directive from middleware
      } else if (!result) {
        return false; // Middleware failed; halt navigation
      }
      // Continue to next middleware if result is true
    }
    return true; // All middleware passed
  }

  private _onRouteWillChange = (e: Event) => {
    const { to, from } = (e as IonRouterCustomEvent<RouterEventDetail>).detail;
    this.currentPath = to;

    const { params, matchedRoutePath } = this.matchAndParseParams(this.currentPath);
    this.pathParams = params;
    if (matchedRoutePath) {
      this.pathProps = this.propMaps.get(matchedRoutePath) || {};
    } else {
      this.pathProps = {};
    }

    // Store the original back template
    const backTemplate = (this.pathProps.back || from) as string | undefined | null;
    if (backTemplate) {
      runInAction(() => {
        // Store the template, not the constructed URL
        this._backTemplate = backTemplate;
      });
    }

    // Insert history entry
    this.insertHistoryEntry({ to, from, timestamp: new Date(), props: this.pathProps });

    if (import.meta.env.DEV) {
      // console.log("Matched route path:", matchedRoutePath, "Path params:", this.pathParams, "Props:", this.pathProps);
    }
  };

  private constructUrlFromTemplate(template: string | undefined | null, params: Record<string, string>): string | null {
     if (!template) {
      console.error("No template provided to construct URL from");
      return null;
    }

    // If the template doesn't contain any parameters, return it as is
    if (!template.includes(':')) {
      return template;
    }

    let url = template;

    Object.entries(params).forEach(([key, value]) => {
      url = url.replace(`:${key}`, value);
    });

    return url;
  }

  private _onRouteDidChange = (_: Event) => {};

  public get pathname() {
    return window.location.pathname;
  }

  public goto(path: string, animation?: AnimationBuilder | undefined) {
    return this.push(path, "forward", animation);
  }

  public push(path: string, direction?: RouterDirection | undefined, animation?: AnimationBuilder | undefined) {
    return this.ionRouter.value?.push(path, direction, animation);
  }

  @computed
  get backUrl(): string {
     if (this._backTemplate) {
      // Construct the URL when it's actually needed
      const constructedBackUrl = this.constructUrlFromTemplate(this._backTemplate, this.pathParams);
      if(constructedBackUrl) return constructedBackUrl;
    }

    const lastEntry = this.getLastHistoryEntry();
    if (lastEntry) {
      const backTemplate = (lastEntry.props?.back || lastEntry.from) as string | undefined | null;
      if (backTemplate) {
        const constructedBackUrl = this.constructUrlFromTemplate(backTemplate, this.pathParams);
        if(constructedBackUrl) return constructedBackUrl;
      }
    }

    return "";
  }

  public back(animation?: AnimationBuilder | undefined) {
    const backUrl = this.backUrl;
    if (backUrl) {
      return this.ionRouter.value?.push(backUrl, "back", animation);
    }

    return this.ionRouter.value?.back();
  }

  private insertHistoryEntry(entry: HistoryEntry) {
    const history = localStorage.getItem("routeHistory");
    if (!history) {
      const newHistory = JSON.stringify([entry]);
      localStorage.setItem("routeHistory", newHistory);
      return true;
    }

    try {
      const routeHistory = JSON.parse(history) as HistoryEntry[];
      if (routeHistory.length > 0 && routeHistory[routeHistory.length - 1]?.to === entry.to) {
        return false;
      }
      if (routeHistory.length > 5) {
        routeHistory.shift();
      }
      routeHistory.push(entry);
      localStorage.setItem("routeHistory", JSON.stringify(routeHistory));
      return true;
    } catch (error) {
      console.error("Error parsing route history from localStorage:", error);
      return false;
    }
  }

  private getLastHistoryEntry(): HistoryEntry | null {
    const history = localStorage.getItem("routeHistory");
    if (!history) {
      return null;
    }
    try {
      const routeHistory = JSON.parse(history) as HistoryEntry[];
      if (routeHistory.length === 0) {
        return null;
      }
      return routeHistory[routeHistory.length - 1] || null;
    } catch (error) {
      console.error("Error parsing route history from localStorage:", error);
      return null;
    }
  }

  // private popLastHistoryEntry() {
  //   const history = localStorage.getItem("routeHistory");
  //   if (!history) {
  //     return;
  //   }
  //   try {
  //     const routeHistory = JSON.parse(history) as HistoryEntry[];
  //     if (routeHistory.length === 0) {
  //       return;
  //     }
  //     routeHistory.pop();
  //     localStorage.setItem("routeHistory", JSON.stringify(routeHistory));
  //   } catch (error) {
  //     console.error("Error parsing route history from localStorage:", error);
  //   }
  // }
}
