/**
 * Creates a new CSSStyleSheet and replaces its contents with the provided CSS.
 *
 * @param css - The CSS to insert into the stylesheet.
 * @returns The CSSStyleSheet object containing the CSS.
 */
export function sheet(...css: string[]) {
  const styles = css.join("\n");
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(styles);
  return sheet;
}