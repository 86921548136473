import { observable, makeObservable, type IObservableArray, action, runInAction, computed } from "mobx";
import type {
  CreateExercisePlan,
  CreateSession,
  ExercisePlan,
  Session,
  UpdateExercisePlan,
  UpdateSession,
} from "../repository/patient/diagnostic";
import { diagnosticRepository } from "../repository/patient/diagnostic.repository";
import { type ObservableDiagnostic } from "./diagnostics.store";
import {
  type CreatePatientEvaluation,
  type EvaluationSummary,
  type PatientEvaluation,
} from "../repository/report/report";
import { exercisesStore, type ObservableExercise } from "./exercises.store";
import type { PublicExercise } from "../repository/library/exercises";
import type { ObservableTemplateExercisePlan } from "./templateExercisePlan.store";
import { ErrorSessionNotFound } from "../repository/patient/errors";
import { ErrorPatientEvaluationNotFound } from "../repository/report/errors";

export class ObservableExercisePlan {
  id: string;
  @observable session: string;
  @observable position: number;
  @observable private _exercise?: ObservableExercise;
  @observable private _exercisePublic?: PublicExercise;
  @observable series?: number;
  @observable repetitions?: string;
  @observable weight?: number;
  @observable weightUnit?: string;
  @observable rest?: number;
  @observable duration?: number;
  @observable description?: string;

  constructor(data: ExercisePlan) {
    this.id = data.id;
    this.session = data.session;
    if (data.exercise) {
      this._exercise = exercisesStore.subscribe(data.exercise);
    } else if (data.exercisePublic) {
      this._exercisePublic = data.exercisePublic;
    }
    this.position = data.position;
    this.series = data.series;
    this.repetitions = data.repetitions;
    this.weight = data.weight;
    this.weightUnit = data.weightUnit;
    this.rest = data.rest;
    this.duration = data.duration;
    this.description = data.description;
    makeObservable(this);
  }

  get exercise() {
    return this._exercise || this._exercisePublic;
  }

  get isExercisePublic() {
    return !!this._exercisePublic;
  }

  @action merge(data: ExercisePlan) {
    this.session = data.session;
    this.position = data.position;
    if (data.exercise) {
      this._exercise = exercisesStore.subscribe(data.exercise);
    } else {
      this._exercise = undefined;
    }
    if (data.exercisePublic) {
      this._exercisePublic = data.exercisePublic;
    } else {
      this._exercisePublic = undefined;
    }
    this.series = data.series;
    this.repetitions = data.repetitions;
    this.weight = data.weight;
    this.weightUnit = data.weightUnit;
    this.rest = data.rest;
    this.duration = data.duration;
    this.description = data.description;
    return this;
  }
}

export class ObservablePatientEvaluation {
  id: string;
  session: string;
  patient: string;
  evaluation: EvaluationSummary;

  constructor(evaluation: PatientEvaluation) {
    this.id = evaluation.id;
    this.session = evaluation.session;
    this.patient = evaluation.patient;
    this.evaluation = evaluation.evaluation;
    makeObservable(this);
  }

  @action merge(evaluation: PatientEvaluation) {
    this.session = evaluation.session;
    this.patient = evaluation.patient;
    this.evaluation = evaluation.evaluation;
    return this;
  }
}

export class ObservableSession {
  id: string;
  diagnostic: string;
  @observable name: string;
  @observable day: string;
  @observable startTime: string;
  @observable endTime: string;
  @observable treatment: string;
  @observable patientNotes: string;
  @observable notes: string;
  @observable exercisesPlan: IObservableArray<ObservableExercisePlan>;
  @observable evaluations: IObservableArray<ObservablePatientEvaluation>;

  constructor(session: Session) {
    this.id = session.id;
    this.diagnostic = session.diagnostic;
    this.name = session.name;
    this.day = session.day;
    this.startTime = session.startTime;
    this.endTime = session.endTime;
    this.treatment = session.treatment ?? "";
    this.patientNotes = session.patientNotes ?? "";
    this.exercisesPlan = observable.array(session.exercisesPlan.map((ep) => new ObservableExercisePlan(ep)));
    this.evaluations = observable.array(session.evaluations.map((ev) => new ObservablePatientEvaluation(ev)));
    this.notes = session.notes ?? "";
    makeObservable(this);
  }

  @action update(data: Session) {
    this.id = data.id;
    this.diagnostic = data.diagnostic;
    this.name = data.name;
    this.day = data.day;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
    this.treatment = data.treatment ?? "";
    this.patientNotes = data.patientNotes ?? "";
    this.notes = data.notes ?? "";
    return this;
  }

  @action merge(session: Session) {
    this.id = session.id;
    this.diagnostic = session.diagnostic;
    this.name = session.name;
    this.day = session.day;
    this.startTime = session.startTime;
    this.endTime = session.endTime;
    this.treatment = session.treatment ?? this.treatment;
    this.patientNotes = session.patientNotes ?? this.patientNotes;
    this.exercisesPlan = observable.array(session.exercisesPlan.map((ep) => new ObservableExercisePlan(ep)));
    this.evaluations = observable.array(session.evaluations.map((ev) => new ObservablePatientEvaluation(ev)));
    this.notes = session.notes ?? this.notes;
    return this;
  }

  @computed get sortedExercisesPlan() {
    return this.exercisesPlan.slice().sort((a, b) => a.position - b.position);
  }
}

class SessionsStore {
  @observable sessions = observable.map<string, ObservableSession>();

  constructor() {
    makeObservable(this);
  }

  find(sessionId: string) {
    return this.sessions.get(sessionId);
  }

  exists(session: ObservableSession) {
    return this.sessions.has(session.id);
  }

  @action save(session: ObservableSession) {
    this.sessions.set(session.id, session);
    return session;
  }

  @action delete(session: ObservableSession) {
    return this.sessions.delete(session.id);
  }

  @action subscribe(session: Session) {
    const observable = this.find(session.id);
    if (observable) {
      observable.update(session);
    }
    return this.save(new ObservableSession(session));
  }

  @action saveExercisePlan(session: ObservableSession, exercisePlan: ExercisePlan) {
    const observable = session.exercisesPlan.find((ep) => ep.id === exercisePlan.id);
    if (observable) {
      return observable.merge(exercisePlan);
    }
    const newObservable = new ObservableExercisePlan(exercisePlan);
    session.exercisesPlan.push(newObservable);
    return newObservable;
  }

  @action savePatientEvaluation(session: ObservableSession, evaluation: PatientEvaluation) {
    const observable = session.evaluations.find((ev) => ev.id === evaluation.id);
    if (observable) {
      return observable.merge(evaluation);
    }
    const newObservable = new ObservablePatientEvaluation(evaluation);
    session.evaluations.push(newObservable);
    return newObservable;
  }

  @action
  async createSession(diagnostic: ObservableDiagnostic, data: CreateSession) {
    const updated = await diagnosticRepository.createSession(data);

    const session = new ObservableSession(updated);

    runInAction(() => {
      diagnostic.sessions.push(session);
      this.save(session);
    });

    return session;
  }

  @action
  async updateSession(session: ObservableSession, data: UpdateSession) {
    const updated = await diagnosticRepository.updateSession(session.id, data);

    runInAction(() => {
      session.update(updated);
    });

    return session;
  }

  @action async deleteSession(diagnostic: ObservableDiagnostic, session: ObservableSession) {
    try {
      await diagnosticRepository.deleteSession(session.id);
      runInAction(() => {
        diagnostic.sessions.remove(session);
        this.delete(session);
      });

      return session;
    } catch (error) {
      if (error instanceof ErrorSessionNotFound) {
        runInAction(() => {
          diagnostic.sessions.remove(session);
          this.delete(session);
        });
        return session;
      }
      throw error;
    }
  }

  @action async createExercisePlan(session: ObservableSession, data: CreateExercisePlan) {
    const result = await diagnosticRepository.createExercisePlan(data);

    const exercisePlan = new ObservableExercisePlan(result);

    runInAction(() => {
      session.exercisesPlan.push(exercisePlan);
    });

    return exercisePlan;
  }

  @action async updateExercisePlan(exercisePlan: ObservableExercisePlan, data: UpdateExercisePlan) {
    if (data.exercise) {
      data.exercisePublic = null;
    } else if (data.exercisePublic) {
      data.exercise = null;
    }

    const result = await diagnosticRepository.updateExercisePlan(exercisePlan.id, data);

    runInAction(() => {
      exercisePlan.merge(result);
    });

    return exercisePlan;
  }

  @action
  async deleteExercisePlan(session: ObservableSession, exercisePlan: ObservableExercisePlan) {
    try {
      await diagnosticRepository.deleteExercisePlan(exercisePlan.id);

      runInAction(() => session.exercisesPlan.remove(exercisePlan));

      return exercisePlan;
    } catch (error) {
      if (error instanceof ErrorSessionNotFound) {
        runInAction(() => session.exercisesPlan.remove(exercisePlan));
        return exercisePlan;
      }
      throw error;
    }
  }

  @action async injectTemplate(session: ObservableSession, template: ObservableTemplateExercisePlan) {
    const results = await diagnosticRepository.injectTemplate(session.id, template.id);

    runInAction(() => {
      session.exercisesPlan.replace(results.map((ep) => new ObservableExercisePlan(ep)));
    });

    return session.exercisesPlan;
  }

  @action
  async createPatientEvaluation(session: ObservableSession, data: CreatePatientEvaluation) {
    const result = await diagnosticRepository.createPatientEvaluation(data);

    const evaluation = new ObservablePatientEvaluation(result);

    runInAction(() => {
      session.evaluations.push(evaluation);
    });

    return evaluation;
  }

  @action
  async deletePatientEvaluation(session: ObservableSession, evaluation: ObservablePatientEvaluation) {
    try {
      await diagnosticRepository.deletePatientEvaluation(evaluation.id);
      runInAction(() => {
        session.evaluations.remove(evaluation);
      });
      return evaluation;
    } catch (error) {
      if (error instanceof ErrorPatientEvaluationNotFound) {
        runInAction(() => {
          session.evaluations.remove(evaluation);
        });
        return evaluation;
      }
      throw error;
    }
  }
}

export const sessionsStore = new SessionsStore();
