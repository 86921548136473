import { customElement } from "lit/decorators.js";
import { html } from "lit";
import { Page, required } from "../components/component";
import { consume } from "@lit/context";
import { routerContext, RouterContext } from "../context/router.context";
import { navigatorContext, type NavigatorContext } from "../controllers/navigator.controller";
import { FinalFormController, zodValidator } from "../controllers/final-form.controller";
import { COLOR_PICKER } from "../components/xt-color-picker.component";
import { CreateCategorySchema, type Category, type CreateCategory } from "../repository/group/category";
import { groupRepository } from "../repository/group/group.repository";
import { localized, msg } from "@lit/localize";
import { alertController } from "@ionic/core";
import { isApiError } from "../error";

@customElement("modal-create-group-category")
@localized()
export class ModalCreateGroupCategory extends Page {
  @consume({ context: routerContext }) router!: RouterContext;
  @consume({ context: navigatorContext }) navigator!: NavigatorContext;

  @required() category!: string;
  @required() onSubmit!: (category: Category) => void;

  #controller = new FinalFormController<CreateCategory>(this, {
    validate: zodValidator(CreateCategorySchema()),
    onSubmit: async (values) => {
      try {
        const category = await groupRepository.createCategory(values);
        this.onSubmit(category);
        this.navigator.close();
      } catch (error) {
        if (isApiError(error)) {
          const alert = await alertController.create({
            header: msg("Erro"),
            message: error.message,
            buttons: ["OK"],
          });
          await alert.present();
        }
      }
    },
  });

  connectedCallback(): void {
    super.connectedCallback();
    this.#controller.form.change("group", this.router.getParam("groupId"));
  }

  handleColorSelected(e: CustomEvent) {
    this.#controller.form.change("color", e.detail.color);
  }

  render() {
    const { register, renderError, submit } = this.#controller;
    return html`
      <div class="inner-content">
        <ion-header>
          <ion-toolbar style="--border-color: #eaeaea;">
            <ion-buttons slot="start">
              <ion-button @click="${this.navigator.goBack}" class="font-semibold">
                <span class="mt-[3px] -ml-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round" />
                  </svg>
                </span>
                ${msg("fechar")}</ion-button
              >
            </ion-buttons>
            <ion-title class="font-display text-lg">${msg("Criar Categoria")}</ion-title>
          </ion-toolbar>
        </ion-header>

        <form class="mt-4" @submit=${submit}>
          <div>
            <span class="font-semibold font-display">${msg("Categoria")}</span>
            <ion-item class="mt-1.5">
              <ion-input
                autocapitalize="on"
                type="text"
                placeholder=${msg("Categoria")}
                ${register("category", {
                  initialValue: this.category,
                })}>
              </ion-input>
            </ion-item>
            ${renderError("category")}
          </div>

          <div class="mt-4">
            <span class="font-semibold font-display">${msg("Cores")}</span>
            <xt-color-picker
              .color=${COLOR_PICKER.GREEN}
              @color-selected=${this.handleColorSelected}
              ${register("color", {
                initialValue: COLOR_PICKER.GREEN,
              })}></xt-color-picker>
            ${renderError("color")}
          </div>

          <ion-button expand="block" shape="round" class="mt-6" type="submit">${msg("Criar Categoria")}</ion-button>
        </form>
      </div>
    `;
  }
}
