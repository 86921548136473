import { html } from "lit";
import { customElement } from "lit/decorators.js";
import { Page } from "../components/component";
import { localized, msg } from "@lit/localize";
import { WithInstallPrompt } from "../mixins/installPrompt";

@customElement("page-onboarding")
@localized()
export class PageOnBoarding extends WithInstallPrompt(Page) {
  render() {
    return html`
      <ion-content style="--background: #000; --color: #fff" fullscreen>
        <div class="w-full h-full flex items-center justify-center">
          <div class="max-w-[343px] flex flex-col items-center text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="109" height="109" viewBox="0 0 109 109" fill="none">
              <rect width="109" height="109" rx="6" fill="white" />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M78.3438 27.4717V75.6301H46.7979L62.3177 59.604V43.5779L78.3438 27.4717ZM47.0432 59.6004V59.6805L31.0171 75.7066V27.5482H63.0693L47.0432 43.5743V59.6004Z"
                fill="black" />
            </svg>

            <div class="mt-6">
              <h1>bfizzo</h1>
              <p class="text-sm mt-1">
                ${msg(
                  "Transforma o teu smartphone um verdadeiro consultório portátil, tornando-o mais acessível que nunca",
                )}
              </p>
            </div>
          </div>
        </div>

        <ion-router-link href="/register" router-direction="root" slot="fixed" class="bottom-0 left-0 w-full px-4 pb-4">
          <ion-button color="white" expand="block" shape="round" class="font-semibold"
            >${msg("Quero juntar-me à app")}</ion-button
          >
        </ion-router-link>
      </ion-content>
    `;
  }
}
