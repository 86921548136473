import { MobxReactionUpdate } from "@adobe/lit-mobx";
import { LitElement, type PropertyDeclaration, type PropertyValueMap } from "lit";
import { property } from "lit/decorators.js";

export class XTElement extends LitElement {
  static requiredProperties: Map<typeof LitElement, Set<string>> = new Map();
  private _hasError: boolean = false;

  static registerRequiredProperty(cls: typeof LitElement, propName: string) {
    if (!this.requiredProperties.has(cls)) {
      this.requiredProperties.set(cls, new Set());
    }
    this.requiredProperties.get(cls)?.add(propName);
  }

  protected shouldUpdate(changedProperties: PropertyValueMap<XTElement>): boolean {
    if (this._hasError) {
      return false;
    }
    return super.shouldUpdate(changedProperties);
  }

  protected updated(changedProperties: PropertyValueMap<XTElement>) {
    super.updated(changedProperties);
    const props = XTElement.requiredProperties.get(this.constructor as typeof LitElement);
    if (props) {
      for (const propName of props) {
        if (this[propName as keyof this] === undefined || this[propName as keyof this] === null) {
          this._hasError = true; // Set error state
          throw new Error(`Required property '${propName}' not provided.`);
        }
      }
    }
  }
}

export class LightElement extends XTElement {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}

export class Page extends MobxReactionUpdate(XTElement) {
  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }
}

export function required(options?: PropertyDeclaration) {
  return (proto: LitElement, propName: string | symbol) => {
    XTElement.registerRequiredProperty(proto.constructor as typeof LitElement, propName.toString());
    return property(options)(proto, propName);
  };
}
