import { z } from "zod";

type FileOptions = {
  size: number;
  allowedFileTypes: string[];
};

export const file = (options: FileOptions, error: string) =>
  z
    .instanceof(File, {
      message: error,
    })
    .refine(
      (file) => {
        const MAX_FILE_SIZE = options.size;
        if (file.size > MAX_FILE_SIZE) {
          return false;
        }

        const allowedFileTypes = options.allowedFileTypes;
        if (!allowedFileTypes.includes(file.type)) {
          return false;
        }

        return true;
      },
      {
        message: error,
      },
    );
