import { z } from "zod";
import { PatientSchema } from "../patient/patient";
import { record } from "../shared/record";
import { unsafeParse } from "../shared/unsafeParse";

export const SectionRecordSchema = record({
  name: z.string(),
  position: z.number(),
  group: z.string(),
});
export interface SectionRecord extends z.infer<typeof SectionRecordSchema> {}

export const SectionSchema = SectionRecordSchema.extend({
  patients: z.array(PatientSchema).default([]),
}).omit({ expand: true })
export interface Section extends z.infer<typeof SectionSchema> {}
export function createSection (data: unknown) {
  return unsafeParse(SectionSchema, data);
}

export const CreateSectionSchema = z.object({
  name: z.string().min(1).max(255),
  group: z.string(),
  position: z
    .number({
      required_error: "A posição é obrigatória",
    })
    .int({
      message: "A posição deve ser um número inteiro",
    })
    .min(0, "A posição deve ser maior ou igual a 0"),
});
export interface CreateSection extends z.infer<typeof CreateSectionSchema> {}

export const UpdateSectionSchema = z.object({
  name: z.string().min(1).max(255).optional(),
  position: z
    .number({
      required_error: "A posição é obrigatória",
    })
    .int({
      message: "A posição deve ser um número inteiro",
    })
    .min(0, "A posição deve ser maior ou igual a 0")
});
export interface UpdateSection extends z.infer<typeof UpdateSectionSchema> {}

export const UpdateSectionWithIdSchema = UpdateSectionSchema.extend({
  id: z.string(),
});
export interface UpdateSectionWithId extends z.infer<typeof UpdateSectionWithIdSchema> {}
